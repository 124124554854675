<div id="page-wrapper" class="page-loading">
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                <div class="content-header">
                    <div class="row">
                        <div class="col-lg-3 col-md-6 col-xs-12 my-auto">
                            <div class="header-section">
                                <h1 class="middle">Bonos regalo</h1>
                                <button class="btn btn-xs btn-secondary middle m-l-10" (click)="CargarGrid()"><i class="fa fa-refresh"></i></button>
                            </div>
                            <ul class="breadcrumb breadcrumb-top text-left">
                                <li><a [routerLink]="['/dashboard']">Inicio</a></li>
                                <li>Bonos regalo</li>
                            </ul>
                        </div>
                        <div class="col-lg-2 col-md-6 col-xs-12 my-auto text-center no-wrap">
                          <label class="col-form-label inline-block m-r-10">Mostrar</label>
                          <select class="form-control inline-block" (change)="Buscar()" [(ngModel)]="params.page_size" style="width:auto;">
                            <option>25</option>
                            <option>50</option>
                            <option>100</option>
                            <option>200</option>
                          </select>
                        </div>
                        <div class="col-lg-5 col-md-6 col-xs-12 my-auto text-center">
                          <div class="input-group">
                            <span class="input-group-addon cursor" (click)="Buscar()"><i class="fa fa-search"></i></span>
                            <input type="search" placeholder="Buscar" class="form-control" (keyup.enter)="Buscar()" [(ngModel)]="params.filtro">
                          </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-xs-12 my-auto text-center">
                          <a class="btn btn-primary btn-block" (click)="Nuevo()"><i class="fa fa-plus"></i> Nuevo bono regalo</a>
                        </div>
                    </div>
                </div>
                <!-- Table Styles Content -->
                <div class="table-responsive table-grid block full break-padding">
                    <!--
                    Available Table Classes:
                        'table'             - basic table
                        'table-bordered'    - table with full borders
                        'table-borderless'  - table with no borders
                        'table-striped'     - striped table
                        'table-condensed'   - table with smaller top and bottom cell padding
                        'table-hover'       - rows highlighted on mouse hover
                        'table-vcenter'     - middle align content vertically
                    -->
                    <table class="table table-striped table-bordered table-vcenter table-hover">
                      <thead>
                          <tr>
                            <th order="referencia">Referencia</th>
                            <th>Código canjeable</th>
                            <th style="width:100px" order="alta">Alta</th>
                            <th style="width:150px" order="estado">Estado</th>
                            <th>Personas</th>
                            <th>Datos regalador</th>
                            <th>Cliente</th>
                            <th style="width:110px">Total</th>
                            <th style="width:50px">Enviar Email</th>
                            <th style="width:50px">Editar</th>
                            <th style="width:50px">Borrar</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr *ngFor="let item of bonosRegalos" class="cursor {{item.id_estado == 4 || item.id_estado == 5  || item.id_estado == 6  ? 'bg-tr-cancelada' : null}}">
                            <td class="" (click)="Editar(item)">
                              <div class="font-bold text-primary">Bono ID #{{item.id}}</div>
                              <div class="font-bold" *ngIf="item.id_reserva">Reserva ID #{{item.id_reserva}}</div>
                              <div class="text-secondary">{{item.evento}}</div>
                            </td>
                            <td>{{item.codigo}}</td>
                            <td>{{item.fecha_alta_format}}</td>
                            <td>
                              <div class="font-bold" *ngIf="item.id_estado_bono == 3">{{item.estado}}</div>
                              <div class="font-bold" *ngIf="item.id_pago == 1 || item.id_pago == 2" style="color: rgb(244, 179, 0); text-shadow: 0px 0px 0px gray;">{{item.estado_pago}}</div>
                              <div class="font-bold" *ngIf="item.id_pago == 3" style="color: #7AAC3F; text-shadow: 0px 0px 0px gray;">{{item.estado_pago}}</div>
                              <div class="font-bold" *ngIf="item.id_pago == 4 || item.id_pago == 5" style="color: red">{{item.estado_pago}}</div>
                              <div *ngIf="item.cli_tipo_pago && item.cli_tipo_pago != '0'" class="font-11 text-primary">{{item.cli_tipo_pago}}</div>
                            </td>
                            <td>
                                <div *ngIf="item.adultos">Adultos: {{item.adultos}}</div>
                                <div *ngIf="item.ninos">Ni&ntilde;os: {{item.ninos}}</div>
                            </td>
                            <td>
                              <div>Nombre: {{item.nombre_regalo}} {{item.apellidos_regalo}}</div>
                              <div>Email: {{item.email_regalo}}</div>
                            </td>
                            <td>
                              <div>Nombre: {{item.nombre}} {{item.apellidos}}</div>
                              <div>Email: {{item.email}}</div>
                            </td>
                            <td class="font-bold text-primary text-center m-l-20" (click)="Editar(item)">
                              <div class="font-26">{{globals.FloatES(item.total)}} &euro;</div>
                            </td>
                            <td class="text-center btntd"><a class="btn btn-warning btn-xs" (click)="EmailConfirmacion(item)"><i class="fa fa-envelope text-white"></i></a></td>
                            <td class="text-center btntd"><a class="btn btn-primary btn-xs" (click)="Editar(item)"><i class="fa fa-pencil text-white"></i></a></td>
                            <td class="text-center btntd"><a class="btn btn-danger btn-xs" (click)="Borrar(item)"><i class="fa fa-trash text-white"></i></a></td>
                          </tr>
                      </tbody>
                  </table>
                    <div class="dataTables_paginate paging_simple_numbers m-r-10">
                      <ul class="pagination justify-content-end">
                        <li class="paginate_button page-item previous {{params.page_index <= 1 ? 'disabled' : ''}}">
                          <a (click)="Paging('prev')" class="page-link">Anterior</a>
                        </li>
                        <li class="paginate_button page-item" *ngIf="params.page_index > 1">
                          <a (click)="Paging('prev')" class="page-link">{{params.page_index-1}}</a>
                        </li>
                        <li class="paginate_button page-item active">
                          <a class="page-link cursor">{{params.page_index}}</a>
                        </li>
                        <li class="paginate_button page-item" *ngIf="params.page_index < params.pages">
                          <a (click)="Paging('next')" class="page-link">{{params.page_index+1}}</a>
                        </li>
                        <li class="paginate_button page-item next {{params.page_index >= params.pages ? 'disabled' : ''}}">
                          <a (click)="Paging('next')" class="page-link">Siguiente</a>
                        </li>
                      </ul>
                    </div>
                </div>

            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->

        <app-backend-footer></app-backend-footer>
    </div>
    <!-- END Page Container -->
</div>