import { Component, OnInit, NgZone, ApplicationRef, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute  } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let toastr: any;
declare let ICAL: any;
declare let Stripe: any;

declare let LANG: any;

@Component({
  selector: 'app-edit-reserva',
  templateUrl: './edit-reserva.component.html',
  styleUrls: ['./edit-reserva.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditReservaComponent implements OnInit {
  public title:string = 'Nueva reserva';
  public data:any = {
    id: '',
    ninos: 0,
    ids_guias: [],
    precios: [{nombre_asiento: null}]
  };
  public aforo:any = [];
  public fechas_bloqueadas = [];
  public fechas_bloqueadas_booking = [];
  public fechas_bloqueadas_airbnb= [];
  public mesesAforo:any = [];
  public diaSelecionado = 'No establecida';
  public plazasDisponibles:any = 50;

  public estados = [];
  public eventos = [];
  public reservasDias = [];
  public evento:any = {};
  public params_alo:any = {
    page_index: 1,
    page_size: 50,
    total: 0,
    pages: 0,
    filtro: null,
    order: null,
    dir: null,
  };
  public guias = [];
  public guias_asignados = null;
  public tarifas = [];
  public pagos = [];
  public dataitem:any = {};
  public noches:number = 1;
  public estados_pago = [];
  public formas_pago = [];
  public temp_estado:any = null;


  public forma_pago_seleccionada:any = null;
  public stripe:any = null;
  public stripe_card:any = null;
  public num_reservas_dias_check = 0;

  public precioAdulto:any;
  public precioNinio:any;
  public precioCalculoAdulto:any = 0;
  public precioCalculoNinio:any = 0;
  public sesiones:any = [];
  public newSesion:any = '';
  public dateSelect:any = '';

  public fecha_expiracion_new_pago:any = '';

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    App.init();
    if(!this.globals.cliente && this.globals.me.id_rol == 0){
      this.ngZone.run(() => this.router.navigateByUrl('/dashboard-superadmin')).then();
    }
    $.disableinputnumberwheel();
    const id_reserva = this.route.snapshot.paramMap.get('id_reserva');

    if (!id_reserva || id_reserva == '') {
      this.ngZone.run(() => this.router.navigateByUrl('/reservas')).then();
    } else {
      this.globals.Api('/eve-reservas', {id: id_reserva}).subscribe((data:any) => {
        if (!data || !data.length) {
          return;
        }
        this.data = data[0];
        this.title = 'Reserva #'+this.data.referencia;
        //this.LoadAforo();
        this.diaSelecionado = this.data.fecha;
        this.GetEventoEditReserva(this.data.id_actividad);
        // console.log(this.data);
        this.temp_estado = this.data.id_estado;
      });
    }

    LANG = this.globals.GetQueryString('lang');
    if (!LANG || LANG == '') LANG = 'es';
    this.data.idioma = LANG;

    if (this.data.noches) this.noches = this.data.noches;
    this.LoadFechasBloqueadas();
    if (!id_reserva || id_reserva == '') {
      if (this.data.fecha_llegada && this.data.fecha_salida) {
        $('#txtfechallegadasalida').val(moment(this.data.fecha_llegada).format('DD/MM/YYYY') + ' - ' + moment(this.data.fecha_salida).format('DD/MM/YYYY'));
        $('#txtfechallegadasalida').data('daterangepicker').setStartDate(moment(this.data.fecha_llegada));
        $('#txtfechallegadasalida').data('daterangepicker').setEndDate(moment(this.data.fecha_salida));
      }
      this.LoadAforo();
    }

    if (this.globals.GetData()) this.estados = this.globals.GetData('estados_reserva');
    this.globals.Api('/eve-estado-reserva').subscribe(data => {
      if (!data || !data.length) {
        this.estados = [];
        this.globals.SaveData([], 'estados_reserva');
        return;
      }
      this.estados = data;
      if (!this.data.id_estado && this.estados.length) this.data.id_estado = this.estados[0].id;
      this.globals.SaveData(this.estados, 'estados_reserva');
    });
    if (this.globals.GetData()) this.estados_pago = this.globals.GetData('estados_pago');
    this.globals.Api('/eve-estado-pago').subscribe(data => {
      if (!data || !data.length) {
        this.estados_pago = [];
        this.globals.SaveData([], 'estados_pago');
        return;
      }
      this.estados_pago = data;
      this.globals.SaveData(this.estados_pago, 'estados_pago');
    });
    if (this.globals.GetData()) this.formas_pago = this.globals.GetData('formas_pago');
    this.globals.Api('/eve-forma-pago').subscribe(data => {
      if (!data || !data.length) {
        this.formas_pago = [];
        this.globals.SaveData([], 'formas_pago');
        return;
      }
      this.formas_pago = data;
      if (!this.data.cli_tipo_pago || this.data.cli_tipo_pago == '') {
        this.data.cli_tipo_pago = this.formas_pago.find(el => el.pordefecto == 1).slug;
      }
      this.globals.SaveData(this.formas_pago, 'formas_pago');
    });
    this.LoadGuias();
  }
  LoadGuias(){
    // console.log(this.data.ids_guias);
    this.globals.Api('/get-guias').subscribe(data => {
      if (!data || !data.length) return;
      this.guias = data;
    });
    this.globals.Api('/get-guias-asignados',{ids_guias: this.data.ids_guias}).subscribe(data => {
      if (!data || !data.length) return;
      this.guias_asignados = data;
      // console.log(data);
    });
  }
  LoadFechasBloqueadas() {
    function getDates(startDate, stopDate) {
      let dateArray = [];
      let currentDate = new Date(startDate);
      stopDate = new Date(stopDate);
      while (currentDate <= stopDate) {
        dateArray.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }
      return dateArray;
    }
    if (this.data.id_actividad && this.data.id_actividad != '') {
      this.globals.Api('/eve-actividades', {id: this.data.id_actividad, id_reserva: this.data.id}).subscribe(data => {
        if (!data || !data.length) return;
        data = data[0];
        this.fechas_bloqueadas = [];
        if (data.fechas_bloqueadas) {
          for (let item of data.fechas_bloqueadas) {
            if (!item || !item.fecha_format2) continue;
            this.fechas_bloqueadas.push(item.fecha_format2);
          }
        }
        if (data.fechas_reservadas) {
          for (let item of data.fechas_reservadas) {
            if (!item || !item.fecha_llegada_format2) continue;
            let dates = getDates(item.fecha_llegada_format2, item.fecha_salida_format2);
            for (let fecha of dates) {
              fecha = moment(fecha).format('YYYY-MM-DD');
              this.fechas_bloqueadas.push(fecha);
            }
          }
        }
      });
      this.globals.Api('/get-ics/'+this.data.id_actividad).subscribe(data => {
        if (!data) return;
        this.fechas_bloqueadas_booking = [];
        if (data.ics_booking && data.ics_booking != '') {
          let jcalData = ICAL.parse(data.ics_booking);
          let vcalendar = new ICAL.Component(jcalData);
          let vevents = vcalendar.getAllSubcomponents("vevent");
          for (let event of vevents) {
            let uid = event.getFirstPropertyValue('uid');
            let start = event.getFirstPropertyValue('dtstart');
            let end = event.getFirstPropertyValue('dtend');
            start = moment(new Date(start.year, start.month-1, start.day)).format('YYYY-MM-DD');
            end = moment(new Date(end.year, end.month-1, end.day)).format('YYYY-MM-DD');
            let dates = getDates(start, end);
            // console.log(uid);
            for (let fecha of dates) {
              fecha = moment(fecha).format('YYYY-MM-DD');
              this.fechas_bloqueadas_booking.push(fecha);
            }
          }
        }
        this.fechas_bloqueadas_airbnb = [];
        if (data.ics_airbnb && data.ics_airbnb != '') {
          let jcalData = ICAL.parse(data.ics_airbnb);
          let vcalendar = new ICAL.Component(jcalData);
          let vevents = vcalendar.getAllSubcomponents("vevent");
          for (let event of vevents) {
            let uid = event.getFirstPropertyValue('uid');
            let start = event.getFirstPropertyValue('dtstart');
            let end = event.getFirstPropertyValue('dtend');
            start = moment(new Date(start.year, start.month-1, start.day)).format('YYYY-MM-DD');
            end = moment(new Date(end.year, end.month-1, end.day)).format('YYYY-MM-DD');
            let dates = getDates(start, end);
            // console.log(uid);
            for (let fecha of dates) {
              fecha = moment(fecha).format('YYYY-MM-DD');
              this.fechas_bloqueadas_airbnb.push(fecha);
            }
          }
        }
        this.appref.tick();
        $('#calendario-cierres').datepicker('update');
      });
    }

    $('.rangepicker').daterangepicker({
      alwaysShowCalendars: true,
      opens: 'left',
      autoUpdateInput: false,
      locale: {
        "format": "DD/MM/YYYY",
        "separator": " - ",
        "applyLabel": "Aplicar",
        "cancelLabel": "Cancel",
        "fromLabel": "Desde",
        "toLabel": "Hasta",
        "customRangeLabel": "Personalizado",
        "weekLabel": "S",
        "daysOfWeek": [
            "Do",
            "Lu",
            "Ma",
            "Mi",
            "Ju",
            "Vi",
            "Sa"
        ],
        "monthNames": [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre"
        ],
        "firstDay": 1
      },
      isInvalidDate: ele => {
        let fecha = moment(ele._d).format('YYYY-MM-DD');
        // if (this.fechas_bloqueadas.indexOf(fecha) != -1) {
        //   return true;
        // }
        // if (this.fechas_bloqueadas_booking.indexOf(fecha) != -1
        //   && this.fechas_bloqueadas_airbnb.indexOf(fecha) != -1) {
        //   return true;
        // }
        // if (this.fechas_bloqueadas_booking.indexOf(fecha) != -1) {
        //   return true;
        // }
        // if (this.fechas_bloqueadas_airbnb.indexOf(fecha) != -1) {
        //   return true;
        // }
        return false;
      }
    }).on('apply.daterangepicker', (ev, picker) => {
      let start = moment(picker.startDate.format('YYYY-MM-DD'));
      let end   = moment(picker.endDate.format('YYYY-MM-DD'));
      let diff = end.diff(start, 'days');
      let clearInput = false;
      for (let fecha of this.fechas_bloqueadas) {
        if (moment(start)<moment(fecha) && moment(end)>moment(fecha)) {
          clearInput = true;
          break;
        }
      }
      for (let fecha of this.fechas_bloqueadas_booking) {
        if (moment(start)<moment(fecha) && moment(end)>moment(fecha)) {
          clearInput = true;
          break;
        }
      }
      for (let fecha of this.fechas_bloqueadas_airbnb) {
        if (moment(start)<moment(fecha) && moment(end)>moment(fecha)) {
          clearInput = true;
          break;
        }
      }
      if (clearInput) return false;
      $('#txtfechallegadasalida').val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
      this.data.fecha_llegada = picker.startDate.format('YYYY-MM-DD');
      this.data.fecha_salida = picker.endDate.format('YYYY-MM-DD');
      this.noches = diff;
      this.CalcularTotal();
    }).on('cancel.daterangepicker', function(ev, picker) {
      $(this).val('');
    });
  }

  DatosLocalidad() {
    this.globals.Api('/localidad', {codpostal: this.data.codpostal}).subscribe(data => {
      if (!data) {
        this.data.localidad = '';
        this.appref.tick();
        return;
      }
      this.data.localidad = data.localidad
      this.appref.tick();
    });
  }

  AddEvento() {
    $('#modal-seleccionar-alojamiento').modal('show');
    $('#modal-seleccionar-alojamiento .modal-title').html('Seleccionar actividad');
    this.CargarGridAlo();
  }
  GetEvento(){
    this.globals.Api('/eve-actividades',{id: this.data.id}).subscribe(data => {
      if (!data || !data.length) {
        this.eventos = [];return;
      }
      this.globals.SaveData(data);
      this.eventos = data;
      this.appref.tick();
      //if (!paginationload) return;
      // this.globals.Api('/eve-actividades/total', this.params_alo).subscribe(data => {
      //   if (!data || !data.length) return;
      //   this.params_alo.total = data[0].total;
      //   this.params_alo.pages = Math.ceil(this.params_alo.total / this.params_alo.page_size);
      // });
    });
  }
  GetEventoEditReserva(id_actividad){
    this.globals.Api('/eve-actividades',{id: id_actividad}).subscribe(data => {
      if (!data || !data.length) {
        this.eventos = [];return;
      }
      this.eventos = data;
      this.appref.tick();
      //if (!paginationload) return;
      // this.globals.Api('/eve-actividades/total', this.params_alo).subscribe(data => {
      //   if (!data || !data.length) return;
      //   this.params_alo.total = data[0].total;
      //   this.params_alo.pages = Math.ceil(this.params_alo.total / this.params_alo.page_size);
      // });
      this.SeleccionarEventoEditar(data[0]);
    });
  }
  EditEvento(item, event, tipo) {
    let html = $(event.target).html().trim();
    if (html.indexOf('<br>') != -1) $(event.target).html(html.replace('<br>', ''));
    let value = $(event.target).html().trim();
    if (isNaN(value)) value = null;
    if (tipo == 'adultos') {
      if (!value || value == '') return;
      this.data.adultos = value;
    }
    if (tipo == 'ninos') {
      this.data.ninos = value;
    }
    this.CalcularTotal();
    // this.CargarEventosCalendario();
  }
  SeleccionarEvento(item:any) {
    $.tempalo = JSON.parse(JSON.stringify(item));
    if (item.tarifas && item.tarifas.length) this.tarifas = JSON.parse(JSON.stringify(item.tarifas));
    else this.tarifas = [];
    // this.dataitem.adultos = '';
    // this.dataitem.ninos = '';

    this.evento = item;
    this.data.id_actividad = item.id;
    this.data.nombre_evento = item.nombre;
    //this.LoadAforo();
    $('#modal-seleccionar-alojamiento').modal('hide');
    $('#modal-seleccionar-tarifa').modal('show');
    $('#modal-seleccionar-tarifa .modal-title').html('Seleccionar tarifa');
  }
  SeleccionarEventoEditar(item:any) {
    $.tempalo = JSON.parse(JSON.stringify(item));
    if (item.tarifas && item.tarifas.length) this.tarifas = JSON.parse(JSON.stringify(item.tarifas));
    else this.tarifas = [];
    // this.dataitem.adultos = '';
    // this.dataitem.ninos = '';

    this.evento = item;
    this.data.id_actividad = item.id;
    this.data.nombre_evento = item.nombre;
    //this.LoadAforo();
    // $('#modal-seleccionar-alojamiento').modal('hide');
    // $('#modal-seleccionar-tarifa').modal('show');
    // $('#modal-seleccionar-tarifa .modal-title').html('Seleccionar tarifa');
  }
  LoadAforo(){
    console.log('Load aforo');
    this.globals.Api('/eve-actividades',{'id': this.data.id_actividad}).subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Error al validar el aforo', 'error');return;
      }
      // this.sesiones = data[0].sesiones.split(',');
      this.sesiones.pop();
      this.precioAdulto = data[0].precio;
      this.precioNinio = data[0].precio_ninios;
    });
    this.globals.Api('/pub-eve-actividades-aforo-v2-calendario',{'id_actividad': this.data.id_actividad, 'oculto': true}).subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Error al validar el aforo', 'error');return;
      }
      this.aforo = data;
      // console.log(this.aforo);
      var month = '';
      this.aforo.forEach(item =>{
        item.percentage = (100 * item.aforo_restante) / item.aforo_total;
        var dt = new Date(item.fecha);
        var monthYear:any = {};
        item.mes = dt.toLocaleString('es-ES', { month: 'long' });
        monthYear.month = dt.toLocaleString('es-ES', { month: 'long' });
        monthYear.year = item.anio = dt.getFullYear();
        if(month != monthYear.month){
          month = monthYear.month;
          this.mesesAforo.push(monthYear);
        }
      });
      //this.CargarEventosCalendario();
      $.LoadingOverlay("hide");
      this.CargarCalendario();
    });
  }
  SeleccionarTarifa(item:any) {
    if (
      this.data.adultos == ''
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }
    if (this.data.ninos == '') this.data.ninos = 0;
    if (!this.data.eventos) this.data.eventos = [];
    let max = 0;
    this.data.eventos.forEach(item => {if (parseInt(item.id) > max) max = item.id;});
    max = max+1;
    this.data.eventos.push({
      id_reserva: '',
      id: max,
      id_alojamiento: $.tempalo.id,
      id_tarifa: item.id,
      eve_nombre: $.tempalo.nombre,
      eve_tarifa: 'Tarifa '+item.temporada,
      adultos: this.data.adultos,
      ninos: this.data.ninos,
      precio: item.precio
    });
    this.CalcularTotal();
    $('#modal-seleccionar-tarifa').modal('hide');
    // this.dataitem.adultos = '';
    // this.dataitem.ninos = '';
  }
  AnadirEve() {
    if (
      this.data.adultos == ''
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }
    this.CalcularTotal();
    // this.CargarEventosCalendario();
    $('#modal-seleccionar-tarifa').modal('hide');
  }
  CalcularTotal() {
    // if (!this.data.eventos || !this.data.eventos.length) return;
    let total:number = this.evento.precio * this.data.adultos;
    total = total + (this.evento.precio_ninios * this.data.ninos);
    // for (let item of this.data.eventos) {
    //   item.subtotal = parseFloat(item.precio)*this.noches
    //   if (item.subtotal && !isNaN(item.subtotal)) total += item.subtotal;
    // }
    // let max = 0;
    // this.data.eventos.forEach(item => {if (parseInt(item.id) > max) max = item.id;});
    // max = max+1;
    this.data.iva = 10;
    this.data.base_imponible = total / 1.10;
    this.data.total = total;
    this.data.eventos = [];
    console.warn('Precio evento:' + this.evento);
    console.warn('Adultos:' + this.data.adultos);
    console.warn('Ninos:' + this.data.ninos);
    console.warn('Total:' + total);
    this.data.eventos.push({
      id_reserva: '',
      id: 1,
      id_actividad: this.data.id_actividad,
      id_tarifa: null,
      eve_nombre: this.data.nombre_evento,
      eve_tarifa: this.data.total,
      adultos: this.data.adultos,
      ninos: this.data.ninos,
      precio: this.data.precio
    });
    $('.nav-tabs li').click(function(){
      $('.nav-tabs li').removeClass('active');
      $('#content-meses>.tab-pane').removeClass('active');
      $('#'+$(this).attr('class')).addClass('active');
      $(this).addClass('active');
    });
    $('.content-days').click(function(){
      $('.content-days').removeClass('checked');
      $(this).addClass('checked');
    });
    //this.appref.tick();
    // this.globals.Api('/pub-total-fechas', this.data).subscribe(data => {
    //   if (!data || !data.total) {
    //     this.data.eventos = [];
    //     this.data.eventos.push({
    //       id_reserva: '',
    //       id: 1,
    //       alo_nombre: 'Seleccione fecha llegada y salida',
    //       alo_tarifa: 'Seleccione fecha llegada y salida',
    //     });
    //     return;
    //   }
    //   let total = parseFloat(data.total);

    // })
  }
  CalcularTotalAlojamiento() {
    // if (!this.data.eventos || !this.data.eventos.length) return;
    let total:number = 0;
    // for (let item of this.data.eventos) {
    //   item.subtotal = parseFloat(item.precio)*this.noches
    //   if (item.subtotal && !isNaN(item.subtotal)) total += item.subtotal;
    // }
    // let max = 0;
    // this.data.eventos.forEach(item => {if (parseInt(item.id) > max) max = item.id;});
    // max = max+1;
    this.globals.Api('/pub-total-fechas', this.data).subscribe(data => {
      if (!data || !data.total) {
        this.data.eventos = [];
        this.data.eventos.push({
          id_reserva: '',
          id: 1,
          eve_nombre: 'Seleccione fecha llegada y salida',
          eve_tarifa: 'Seleccione fecha llegada y salida',
        });
        return;
      }
      let total = parseFloat(data.total);
      this.data.iva = 10;
      this.data.base_imponible = total / 1.21;
      this.data.total = total;
      this.data.eventos = [];
      this.data.eventos.push({
        id_reserva: '',
        id: 1,
        id_actividad: this.data.id_actividad,
        id_tarifa: null,
        eve_nombre: this.data.nombre_evento,
        eve_tarifa: 'Tarifa '+data.precio_noche+' / noche',
        adultos: this.data.adultos,
        ninos: this.data.ninos,
        precio: data.precio
      });
      this.appref.tick();
    })
  }
  DelEvento(item:any) {
    swal({
      title: "¿Borrar evento?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.data.eventos.splice(this.data.eventos.findIndex(el => el.id == item.id), 1);
      this.data.base_imponible = 0;
      this.data.total = 0;
      $('#calendar').fullCalendar('removeEvents');
      this.appref.tick();
    });
  }

  CargarGridAlo(paginationload:boolean = true) {
    if (this.globals.GetData()) this.eventos = this.globals.GetData();
    this.globals.Api('/eve-actividades').subscribe(data => {
      if (!data || !data.length) {
        this.eventos = [];return;
      }
      this.globals.SaveData(data);
      this.eventos = data;
      this.appref.tick();
      if (!paginationload) return;
      this.globals.Api('/eve-actividades/total', this.params_alo).subscribe(data => {
        if (!data || !data.length) return;
        this.params_alo.total = data[0].total;
        this.params_alo.pages = Math.ceil(this.params_alo.total / this.params_alo.page_size);
      });
    });
  }
  PagingAlo(dir, table = '') {
    if (dir == 'prev') {
      this.params_alo.page_index -= 1;
    }
    if (dir == 'next') {
      this.params_alo.page_index += 1;
    }
    this.CargarGridAlo(false);
  }
  BuscarAlo() {
    this.params_alo.page_index = 1;
    this.CargarGridAlo();
  }

  AddPago() {
    $('#modal-crear-pago').modal('show');
    $('#modal-crear-pago .modal-title').html('Nuevo pago');
    Object.keys(this.dataitem).forEach(item => {this.dataitem[item] = ''});
    if (this.estados_pago.length) this.dataitem.id_estado_pago = this.estados_pago[0].id;
    if (this.formas_pago.length) this.dataitem.id_forma_pago = this.formas_pago[0].id;
    this.dataitem.tipo = 'manual';
    $('.datepicker').datepicker({
      todayBtn: "linked",
      keyboardNavigation: false,
      changeMonth: true,
      changeYear: true,
      showButtonPanel: true,
      autoclose: true,
      weekStart: 1,
      language: 'es',
      format: "dd/mm/yyyy"
    });
    setTimeout(() => {
      this.appref.tick();
      $('#txtfechapago').datepicker('setDate', moment().format('DD/MM/YYYY'));
    }, 200);
  }
  EditPago(item:any) {
    $('#modal-crear-pago').modal('show');
    $('#modal-crear-pago .modal-title').html('Editar pago');
    this.dataitem = JSON.parse(JSON.stringify(item));
    $('.datepicker').datepicker({
      todayBtn: "linked",
      keyboardNavigation: false,
      changeMonth: true,
      changeYear: true,
      showButtonPanel: true,
      autoclose: true,
      weekStart: 1,
      language: 'es',
      format: "dd/mm/yyyy"
    });
    setTimeout(() => {
      this.appref.tick();
      if (this.dataitem.fecha_pago) $('#txtfechapago').datepicker('setDate', this.data.fecha_pago);
    }, 200);
  }
  DelPago(item:any) {
    swal({
      title: "¿Borrar pago?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/eve-reservas-del-pago', {id: this.data.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.data.pagos.splice(this.data.pagos.findIndex(el => el.id == item.id), 1);
        this.appref.tick();
      });
    });
  }
  GuardarPago() {
    if (
      $('#txtfechapago').val().trim() == ''
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }
    if (
      !this.dataitem.id_estado_pago
      || this.dataitem.id_estado_pago == ''
      || !this.dataitem.id_forma_pago
      || this.dataitem.id_forma_pago == ''
      || !this.dataitem.tipo
      || this.dataitem.tipo == ''
      || !this.dataitem.total
      || this.dataitem.total == ''
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }
    if (!this.data.pagos) this.data.pagos = [];
    let max = 0;
    this.data.pagos.forEach(item => {if (parseInt(item.id) > max) max = item.id;});
    max = max+1;
    this.data.pagos.push({
      id_reserva: '',
      id: max,
      id_estado_pago: this.dataitem.id_estado_pago,
      id_forma_pago: this.dataitem.id_forma_pago,
      tipo: this.dataitem.tipo,
      fecha_pago: moment($('#txtfechapago').datepicker('getDate')).format('YYYY-MM-DD'),
      fecha_pago_format: moment($('#txtfechapago').datepicker('getDate')).format('DD/MM/YYYY'),
      estado: $('#cmbestadopago option:selected').text(),
      forma_pago: $('#cmbformapago option:selected').text(),
      total: this.dataitem.total
    });
    this.appref.tick();
    $('#modal-crear-pago').modal('hide');
  }

  Cancelar() {
    this.ngZone.run(() => this.router.navigateByUrl('/reservas')).then();
  }
  Guardar() {
    if (
      !this.diaSelecionado
      ) {
      swal('Seleccione fechas', 'Por favor, seleccione una fecha para continuar', 'error');return;
    }
    if (
      this.data.cli_nombre == ''
      || this.data.cli_apellidos == ''
      || this.data.cli_email == ''
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }
    this.data.cli_email = this.data.cli_email.replace(/\s/g, '');
    if (!this.globals.ValidateEmail(this.data.cli_email)) {
      swal('Email no válido', 'Por favor, introduzca un email válido.', 'error');return;
    }
    if (
      !this.data.eventos
      || !this.data.eventos.length
      ) {
      swal('Seleccione actividad', 'Por favor, seleccione una actividad para continuar', 'error');return;
    }

    $.LoadingOverlay("show", {
      background: 'rgba(255, 255, 255, 0.2)',
      imageResizeFactor: 0.5,
      text: 'Enviando datos ...'
    });
    if (this.data.id == '') {
      $.buttonloading('.btn-guardar', 'Enviando');
      this.data.fecha = this.diaSelecionado;
      this.data.impuestos = this.data.total - this.data.base_imponible;
      this.globals.Api('/eve-reservas-add', this.data).subscribe(data => {
        $.LoadingOverlay("hide", true);
        $.buttoncancelloading('.btn-guardar');
        if (!data || data.error) {
          if (data.value == 'Email repetido') {
            swal('Email existente', 'Existe un usuario con este email', 'error');return;
          }
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.Cancelar();
      }, error => {
        $.LoadingOverlay("hide", true);
        $.buttoncancelloading('.btn-guardar');
      });
    } else {
      $.buttonloading('.btn-guardar', 'Enviando');
      this.data.fecha = this.diaSelecionado;
      this.globals.Api('/eve-reservas-edit', this.data).subscribe(data => {
        $.LoadingOverlay("hide", true);
        $.buttoncancelloading('.btn-guardar');
        if (!data || data.error) {
          if (data.value == 'Email repetido') {
            swal('Email existente', 'Existe un usuario con este email', 'error');return;
          }
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        // swal('Guardado', 'Datos guardados correctamente', 'success');
        toastr.success(`Datos guardados correctamente`, 'Guardado');
        // this.LoadAforo();
        // this.CargarCalendario();
        // this.CargarEventosCalendario();
        // this.GetEventoEditReserva(this.data.id_actividad);
        // this.appref.tick();
        this.router.navigateByUrl('/reservas');
      }, error => {
        $.LoadingOverlay("hide", true);
        $.buttoncancelloading('.btn-guardar');
      });
    }
  }
  CargarCalendario() {
    var date = new Date();
    var d = date.getDate();
    var m = date.getMonth();
    var y = date.getFullYear();
    let that = this;
    var heightCalendar = 330;
    if($(window).width() <= 1366 && $(window).width() > 1025){
      heightCalendar = 325;
    }
    $('#calendar').fullCalendar({
        header: {
            left: 'title',
            center: '',
            right: 'today month prev,next'
        },
        defaultView: 'month',
        defaultDate: new Date(),
        locale: LANG,
        allDaySlot: false,
        editable: false,
        droppable: false,
        selectable: false,
        duration: false,
        contentHeight: heightCalendar,
        // //timeFormat: 'H:mm',
        // minTime: '08:00:00',
        // maxTime: '22:00:00',
        viewRender: (view, element) => {
          // this.params.fecha_inicio = view.start.format('YYYY-MM-DD');
          // this.params.fecha_fin = view.end.format('YYYY-MM-DD');
          // this.CargarReparaciones();
          var fechaInicioRender = view.start.format('YYYY-MM-DD');
          var fechaFinRender = view.end.format('YYYY-MM-DD');

          /*Aquí para cargar los datos al calendario*/
          that.CargarEventosCalendario(fechaInicioRender, fechaFinRender);
          $('.fc-day-top').mouseenter(function(){
            $('.content-hover-aforo').remove();
            //console.log('Mouse enter');
            var fechaDiaHover = $(this).attr('data-date');
            // console.log(fechaDiaHover);
            var aforoDia = [];
            var bloqueado = true;
            var sesionesDiaSelect = [];
            that.aforo.forEach(element => {
              if(element.ocultar == 1){
                return;
              }
              if(element.fecha == fechaDiaHover){
                that.sesiones.forEach(element2 => {
                  if(element.sesion == element2){
                    aforoDia.push(element.aforo_restante);
                    sesionesDiaSelect.push(element.sesion);
                    bloqueado = false;
                  }
                });
              }
            });
            if(bloqueado) return;
            // console.log(aforoDia);

            var htmlAforo = `<div class="content-hover-aforo display-flex style="padding-left: 10px; padding-right: 10px;">`;
            for(var i = 0; i < aforoDia.length; i++){
              if(aforoDia[i] <= 10){
                htmlAforo += `<div style="padding-bottom: 10px; width: 50%; padding-top: 10px;">`+sesionesDiaSelect[i]+` <br/><span>`+aforoDia[i]+` plazas</span></div>`;
              }else{
                htmlAforo += `<div style="padding-bottom: 10px; width: 50%; padding-top: 10px;">`+sesionesDiaSelect[i]+`</div>`;
              }
            }
            htmlAforo += `</div>`;
            $(this).append(htmlAforo);
            $('.fc-day-grid.fc-unselectable>div:first-child .content-hover-aforo').addClass('bottom-hover');
            $('.fc-day-grid.fc-unselectable>div:first-child:hover').addClass('z-index-9999');
            $('.fc-day-grid.fc-unselectable>div:nth-child(2) .content-hover-aforo').addClass('bottom-hover');
            $('.fc-day-grid.fc-unselectable>div:nth-child(2):hover').addClass('z-index-9999');
          });
          $('.fc-day-top').mouseleave(function(){
            $('.content-hover-aforo').remove();
            $('.fc-day-grid.fc-unselectable>div:first-child').removeClass('z-index-9999');
            $('.fc-day-grid.fc-unselectable>div:nth-child(2)').removeClass('z-index-9999');
          });
          // console.log('renderizado');
          $('.fc-day-top.fc-today,.fc-day-top.fc-future').click(function(){
            $('.fc-day-top.fc-today,.fc-day-top.fc-future').css('background-color','');
            $('.fc-day-top.fc-today,.fc-day-top.fc-future').css('color','');
            $(this).css('background-color','#7AAC3F');
            $(this).css('color','white');
          });
        },
        eventRender: function(event, element) {
        },
        eventClick: function(info) {
          if (info.disabled) return;
          $('.fc-event').removeClass('checked');
          $(this).addClass('checked');
        },
        dayClick: function(date, allDay, jsEvent, view) {
          that.newSesion = '';
          that.globals.Api('/pub-check-dias-minimos').subscribe(data => {
            if (!data || data.error) {
              swal('Error', 'Error al cargar configuración', 'error');return;
            }
            let dateClick = new Date(date).getTime();
            let dateNow = new Date().getTime();
            var someDate = new Date();
            let horasMinimas = data.dias_min_reserva * 24;
            if(dateClick < dateNow){
              // return;
            }
            let params = {
              fecha: date,
              fecha_format: that.globals.DateString(new Date(date).toDateString()),
              fecha_format_en: that.globals.DateStringEN(new Date(date).toDateString())
            };
            that.dateSelect = that.globals.DateStringEN(new Date(date).toDateString());
            if ($.editcitashow) $.editcitashow(params);

            var today = new Date();
            var tempDate = new Date(date);
            if(today > tempDate){
              // return;
            }
            that.data.fecha = that.globals.DateStringEN(new Date(date).toDateString());
            $('#date-reservar').val(that.data.fecha);
            setTimeout(() => {
                that.globals.Api('/eve-actividades').subscribe(data => {
                if (!data || data.error) {
                  swal('Error', 'Error al cargar datos evento', 'error');return;
                }
                that.eventos = data;
                let idEventoSelect = that.data.id_actividad;
                if(idEventoSelect == 'default') return;
                  that.globals.Api('/eve-actividades',{id: idEventoSelect}).subscribe(data => {
                    if (!data || data.error) {
                      swal('Error', 'Error al cargar datos evento', 'error');return;
                    }
                    that.evento = data[0];
                    if(that.evento.vehiculo){
                      // Cálculo disponibilidad condicionante vehículos
                      that.sesiones = null;
                      that.globals.Api('/eve-actividades-sesiones-vehiculos-v2',{id: that.evento.id, fecha: params.fecha_format_en}).subscribe(data => {
                        if (!data || data.error) {
                          swal('Error', 'Error al cargar datos sesiones', 'error');return;
                        }
                        if(data[1].length > 0){
                          // console.log(data[1]);
                          that.sesiones = data[1];
                          that.precioAdulto = data[1][0].precio_adultos;
                          that.precioNinio = data[1][0].precio_ninios;

                        }else{
                          // console.log(data[0]);
                          that.sesiones = data[0];
                          that.precioAdulto = that.evento.precio;
                          that.precioNinio = that.evento.precio_ninios;
                        }
                        var days = ['domingo', 'lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado'];
                        var d = new Date(params.fecha_format_en);
                        var dayName = days[d.getDay()];
                        that.globals.Api('/check-dia-disponible-v2',{id: that.evento.id, dia: dayName, fecha: params.fecha_format_en}).subscribe(data => {
                          if (!data || data.error) {
                            swal('Error', 'Error al cargar datos sesiones', 'error');return;
                          }
                          if(data[0].count == 0 && data[0].dias_especial_disponible == 0){
                            that.sesiones = null;
                          }else{
                            // console.log(that.sesiones);
                            for(let i = 0; i <= that.sesiones.length; i++){
                              // console.log(that.sesiones[i].sesion);
                              let sesion = that.sesiones[i].sesion;
                              // console.log(sesion);
                              that.globals.Api('/eve-actividades-check-vehiculos-v2',{id: that.evento.id, fecha: params.fecha_format_en, sesion: sesion}).subscribe(data => {
                                if (!data || data.error) {
                                  swal('Error', 'Error al verificar vehículos', 'error');return;
                                }
                                // console.log(data);
                                let plazasDisponibles = 0;
                                data.forEach(vehiculo => {
                                  plazasDisponibles += vehiculo.plazas_disponibles;
                                });
                                // console.log(plazasDisponibles);
                                if(that.sesiones[i].aforo_restante > plazasDisponibles){
                                  that.sesiones[i].aforo_restante = plazasDisponibles;
                                }
                              });
                            }
                          }
                        });
                      });
                    }else{
                      // Cálculo disponibilidad normal sin vehículos
                      that.sesiones = null;
                      that.globals.Api('/pub-eve-actividades-sesiones-v2',{id: that.evento.id, fecha: params.fecha_format_en}).subscribe(data => {
                        if (!data || data.error) {
                          swal('Error', 'Error al cargar datos sesiones', 'error');return;
                        }
                        if(data[1].length > 0){
                          // console.log(data[1]);
                          that.sesiones = data[1];
                          that.precioAdulto = data[1][0].precio_adultos;
                          that.precioNinio = data[1][0].precio_ninios;

                        }else{
                          // console.log(data[0]);
                          that.sesiones = data[0];
                          that.precioAdulto = that.evento.precio;
                          that.precioNinio = that.evento.precio_ninios;
                        }
                        var days = ['domingo', 'lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado'];
                        var d = new Date(params.fecha_format_en);
                        var dayName = days[d.getDay()];
                        that.globals.Api('/pub-check-dia-disponible-v2',{id: that.evento.id, dia: dayName, fecha: params.fecha_format_en}).subscribe(data => {
                          if (!data || data.error) {
                            swal('Error', 'Error al cargar datos sesiones', 'error');return;
                          }
                          if(data[0].count == 0 && data[0].dias_especial_disponible == 0){
                            that.sesiones = null;
                          }
                        });
                      });
                    }
                  });
              });
            }, 100);
            setTimeout(() => {
              // $('#select-actividad-reservar').val(that.dataReserva.id_actividad);
              // $('#select-actividad-reservar').change();
            }, 500);
          });
        }
    });
  }
  CargarEventosCalendario(fechaInicio:any, fechaFin:any) {
    $('#calendar').fullCalendar('removeEvents');
    const d = new Date();
    const date1 = new Date(fechaInicio);
    let timestampInicio = Math.floor(date1.getTime()/1000);
    const date2 = new Date(fechaFin);
    const date3 = new Date('2022/12/01');
    let timestampFin = Math.floor(date2.getTime()/1000);
    this.reservasDias = [];

    var dateMs = timestampInicio * 1000;
    var dateOb = new Date(dateMs);
    var year = dateOb.getFullYear();
    var month = ("0" + (dateOb.getMonth() + 1)).slice(-2);
    var date = ("0" + dateOb.getDate()).slice(-2);
    var dateFormattedInicio = year+'-'+month+'-'+date;

    var dateMs2 = timestampFin * 1000;
    var dateOb2 = new Date(dateMs2);
    var year2 = dateOb2.getFullYear();
    var month2 = ("0" + (dateOb2.getMonth() + 1)).slice(-2);
    var dateTwo = ("0" + dateOb2.getDate()).slice(-2);
    var dateFormattedFin = year2+'-'+month2+'-'+dateTwo;

    this.globals.Api('/pub-eve-reservas-calendario-actividad-v3',{id_actividad: this.data.id_actividad, fecha_inicio: dateFormattedInicio, fecha_fin: dateFormattedFin}).subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Error al cargar reservas del calendario', 'error');return;
      }
      this.reservasDias = data.reservas;
      let fechasNoDisponible = data.fechas_nodisponible;

      fechasNoDisponible.forEach(fechaNoDisponible => {
        if(fechaNoDisponible.disponible == 1) return;
        let start = fechaNoDisponible.fecha;
        let end = fechaNoDisponible.fecha;
        let color = '#000000';
        let cursor = 'pointer';
        let textColor = '#FFF';
        let display = 'none';
        let className = 'day-green';
        let sumPorcentajeMismaFecha = 0;
        let aforoTotalRestante = 0;

        let event = {
          id: fechaNoDisponible.fecha,
          className: 'day-reserva',
          title: null,
          duration: false,
          description: null,
          start: start,
          end: end,
          data: null,
          //color: color,
          color: color,
          // textColor: textColor,
          textColor: 'transparent',
          disabled: false,
          tipo: 'documento'
        };
        // console.log(event);
        $('#calendar').fullCalendar('renderEvent', event, true);
      });

      this.reservasDias.forEach(reserva => {

        let start = reserva.fecha;
        let end = reserva.fecha;
        let color = '#5da84e';
        let cursor = 'pointer';
        let textColor = '#FFF';
        let display = 'none';
        let className = 'day-green';
        let sumPorcentajeMismaFecha = 0;
        let aforoTotalRestante = 0;
        if(parseInt(reserva.sum_sesiones_especiales) == 0){
          let aforoTotalActividad = parseInt(reserva.aforo_total_actividades) * parseInt(reserva.sum_sesiones);
          aforoTotalRestante = aforoTotalActividad - parseInt(reserva.personas);
          sumPorcentajeMismaFecha = (aforoTotalRestante * 100) / aforoTotalActividad;
          // console.log(parseInt(reserva.aforo_total_actividades) + ' * ' + parseInt(reserva.sum_sesiones));
          // console.log(aforoTotalActividad + ' - ' + parseInt(reserva.personas));
          // console.log(sumPorcentajeMismaFecha);
        }else{
          let aforoTotalActividad = parseInt(reserva.aforo_predeterminado_especial) * parseInt(reserva.sum_sesiones_especiales);
          aforoTotalRestante = aforoTotalActividad - parseInt(reserva.personas);
          sumPorcentajeMismaFecha = (aforoTotalRestante * 100) / aforoTotalActividad;
          // console.log(parseInt(reserva.aforo_predeterminado_especial) + ' * ' + parseInt(reserva.sum_sesiones_especiales));
          // console.log(aforoTotalActividad + ' - ' + parseInt(reserva.personas));
          // console.log(sumPorcentajeMismaFecha);
        }
        if(sumPorcentajeMismaFecha < 99){
          color = '#e5b437';
          cursor = 'pointer';
          // className = 'day-yellow';
          if(sumPorcentajeMismaFecha <= 0){
            color = '#a30404';
            cursor = 'default';
            textColor = '#FFF';
            // className = 'day-red';
          }
        }
        let event = {
          id: reserva.fecha,
          className: 'day-reserva',
          title: `${aforoTotalRestante}/${reserva.aforo_total_actividades}`,
          duration: false,
          description: `
            <div style="cursor: ${cursor}">
              <div><b><i class="fa fa-lock" style="display: ${display}; float: left; margin-right: 5px;"></i> Disponiblidad</b><br/><i class="fa fa-user"></i> ${aforoTotalRestante}/${reserva.aforo_total_actividades} <span style="color: white;"><i class="icofont-check-circled"></i></span></div>
            </div>
          `,
          start: start,
          end: end,
          data: reserva.personas,
          //color: color,
          color: color,
          // textColor: textColor,
          textColor: 'transparent',
          disabled: false,
          tipo: 'documento'
        };
        // console.log(event);
        $('#calendar').fullCalendar('renderEvent', event, true);
      });

      $('.fc-row.fc-week.fc-widget-content .fc-content-skeleton tbody td:not(.fc-event-container)').html(`
        <a class="fc-day-grid-event fc-h-event fc-event fc-start fc-end day-reserva" style="background-color:#5da84e;border-color:#5da84e;color:transparent">
          <div class="fc-content">
            <span class="fc-title">0000</span>
          </div>
        </a>
      `);
      this.appref.tick();
    });
  }
  SeleccionarDia(dia:any){
    this.diaSelecionado = dia;
  }

  ConfirmarAsistencia() {
    swal({
      title: "¿Confirmar asistencia?",
      type: "info",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/check-reserva-id', {id: this.data.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.Cancelar();
      });
    });
  }
  Borrar() {
    swal({
      title: "¿Borrar reserva?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/eve-reservas-del', {
        id: this.data.id,
        fecha: this.data.fecha,
        id_actividad: this.data.id_actividad,
        adultos: this.data.adultos,
        ninios: this.data.ninos,
        niniosGratis: this.data.ninos_gratis,
        sesion: this.data.sesion,
        fecha_bd: this.data.fecha_bd}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.Cancelar();
      });
    });
  }
  AsignarGuia(){
    $('#modal-add-guia').modal('show');
  }
  GuardarGuia(){
    let guias = $('#content-guias input');
    let ids_guias = [];
    for(var i = 0; i < guias.length; i++){
      if($(guias[i]).is(':checked')){
        // console.log($(guias[i]).attr('class'));
        let id_guia = $(guias[i]).attr('class');
        ids_guias.push({id: id_guia});
      }
    }
    if(!this.data.id){
      $('#modal-add-guia').modal('hide');
      return;
    }
    this.globals.Api('/add-guias-evento', {id: this.data.id, ids_guias: ids_guias}).subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Se ha producido un error', 'error');return;
      }
      $('#modal-add-guia').modal('hide');
      let str_ids_guias = '';
      ids_guias.forEach(id_guia => {
        str_ids_guias += (id_guia.id + ',');
      });
      this.data.ids_guias = str_ids_guias;
      this.LoadGuias();
    });
  }
  CheckGuia(id_guia){
    $('input.'+id_guia).click();
  }


  VolverPaso1(){
    $('#content-paso-3').hide();
    $('#content-paso-1').fadeIn();
    this.data.codigo_cupon = null;
    this.data.cupon = null;
    this.data.total = null;
    this.data.mostracuponok = null;
    this.data.mostracuponko = null;
  }
  VolverPaso2(){
    $('#content-paso-error').hide();
    $('#content-paso-2').fadeIn();
  }
  SetFormaPago(item:any) {
    // this.forma_pago_seleccionada = item;
    $('#stripe-form #card-element').html('');
    $('#stripe-form #card-errors').html('');
    $('#stripe-form').hide();
    if (!item) return;
    if (item.slug == 'stripe') {
      if (!this.globals.config.pk_test || this.globals.config.pk_test == '') return;
      if (!this.globals.config.pk_live || this.globals.config.pk_live == '') return;
      let that = this;
      if (this.globals.config.st_entorno_pruebas) {
        this.stripe = Stripe(this.globals.config.pk_test);
      } else {
        this.stripe = Stripe(this.globals.config.pk_live);
      }
      var elements = this.stripe.elements();
      $('#stripe-form').show();
      var style = {
        base: {
          fontSize: '16px',
          color: 'var(--color-primary)',
        },
      };
      this.stripe_card = elements.create('card', {style: style, hidePostalCode: true});
      this.stripe_card.mount('#card-element');
    }
  }
  ComprobarGuardarReserva(){
    if(
      this.data.fecha == null || this.data.fecha == '' || this.data.fecha == '0000-00-00' ||
      this.data.sesion == null || this.data.sesion == '' ||
      this.data.adulto == 0 || this.data.precio == 0 ||
      this.data.nombre == '' || this.data.apellidos == '' || this.data.email == '' || this.data.telefono == ''
    ){
      $.buttoncancelloading('btn-guardar-reserva');
      $.LoadingOverlay("hide", true);
      swal('Error', 'Revisa los datos', 'error');
      return;
    }
    if(!this.globals.ValidateEmail(this.data.email)){
      swal('Error', 'Email incorrecto', 'error');return;
    }
    if (
      !$('#check-politicas-privacidad').is(':checked')
    ){
      $.buttoncancelloading('btn-guardar-reserva');
      $.LoadingOverlay("hide", true);
      swal('Política de privacidad', 'Debe marcar que ha leído y acepta la política de privacidad para continuar', 'error');
      return;
    }
    let aforoCheck = (this.data.adultos + this.data.ninios + this.data.niniosGratis);
    $.buttonloading('btn-guardar-reserva', 'Realizando pago ...');
    $.LoadingOverlay("show", {
      background: 'rgba(255, 255, 255, 0.6)',
      imageResizeFactor: 0.5,
      text: 'Realizando pago ...'
    });
    let that = this;
    this.data.cli_tipo_pago = $('input[type="radio"][name="pago"]:checked').val();
    this.data.cli_tipo_pago_slug = $('input[type="radio"][name="pago"]:checked').attr('id');
    if(this.data.cli_tipo_pago == undefined){
      $.buttoncancelloading('btn-guardar-reserva');
      $.LoadingOverlay("hide", true);
      swal('Error', 'Selecciona un tipo de pago', 'warning');return;
    }
    this.GuardarReserva();
  }
  GuardarReserva() {
    this.data.cli_tipo_pago = $('input[type="radio"][name="pago"]:checked').val();
    this.data.cli_tipo_pago_slug = $('input[type="radio"][name="pago"]:checked').attr('id');
    this.data.pagos = [{
      cli_tipo_pago: this.data.cli_tipo_pago,
      cli_tipo_pago_slug: this.data.cli_tipo_pago_slug
    }];
    if (this.data.cupon) {
      this.data.precio = this.data.total;
    }

    // if (gtag) {
    //   gtag('event', 'conversion', {'send_to': 'AW-10882867819/poZJCL_e-LUDEOvErcUo',
    //     'value': this.data.precio,
    //     'currency': 'EUR'
    //   });
    // }

    if (this.forma_pago_seleccionada && this.forma_pago_seleccionada.slug == 'stripe' && this.stripe) {
      let that = this;
      this.stripe.createToken(this.stripe_card).then(function(result) {
        if (result.error) {
          var errorElement = document.getElementById('card-errors');
          errorElement.textContent = result.error.message;
          $.buttoncancelloading('btn-guardar-reserva');
        } else {
          that.data.stripe_token = result.token.id;
          that.globals.Api('/pub-stripe-pago', that.data).subscribe(data => {
            if (!data || !data.value) {
              $.LoadingOverlay("hide", true);
              $.buttoncancelloading('btn-guardar-reserva');
              swal('Error en el pago', 'Revisa los datos de tu tarjeta', 'error');return;
            }
            setTimeout(() => {
              $.LoadingOverlay("hide", true);
              $.buttoncancelloading('btn-guardar-reserva');
              that.data.stripe_order = data.value;
              that.EnviarDatosReserva();
            }, 200);
          }, error => {
            $.LoadingOverlay("hide", true);
            $.buttoncancelloading('btn-guardar-reserva');
            swal('Error en el pago', 'Revisa los datos de tu tarjeta', 'error');
          });
        }
      });
      return;
    }
    this.EnviarDatosReserva();
  }
  EnviarDatosReserva() {
    $.LoadingOverlay("hide", true);
    $.LoadingOverlay("show", {
      background: 'rgba(255, 255, 255, 0.6)',
      imageResizeFactor: 0.5,
      text: 'Enviando datos ...'
    });
    $.buttonloading('btn-guardar-reserva', 'Enviando datos ...');
    this.globals.Api('/pub-reservar-actividad',this.data).subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Error al hacer la reserva', 'error');
        $('#content-paso-2').hide();
        $('#content-paso-error').fadeIn();
        $.LoadingOverlay("hide", true);
        $.buttoncancelloading('btn-guardar-reserva');
        return;
      }

      if (data.form && data.form != '') {
        $('body').append(data.form);
        setTimeout(function() {
            if ($('#paypal-form').length) {
              $('#paypal-form').submit();
              $('#paypal-form').remove();
            }
            if ($('#redsys-form').length) {
              $('#redsys-form').submit();
              $('#redsys-form').remove();
              $.LoadingOverlay("hide", true);
              $('#content-paso-2').hide();
              $('#content-paso-3').fadeIn();
            }
        }, 100);
      } else {
        $.LoadingOverlay("hide", true);
        // swal('¡Reserva realizada con éxito!', 'Recibirá un correo a la dirección facilitada con la confirmación de la reserva.', 'success');
        $('#content-paso-2').hide();
        $('#content-paso-3').fadeIn();
        if (this.forma_pago_seleccionada && this.forma_pago_seleccionada.slug == 'stripe' && this.data.stripe_order) {
          this.globals.Api('/pub-get-reserva', {stripe_order: this.data.stripe_order}).subscribe(data => {
            if (!data || !data.id) return;
            this.data = data;
            this.appref.tick();
          });
        }
      }
    }, error => {
      $.LoadingOverlay("hide", true);
      $.buttoncancelloading('btn-guardar-reserva');
    });
  }
  AnteriorModal(){
    $('#content-paso-1').fadeIn();
    $('#content-paso-2').hide();
    this.data.codigo_cupon = null;
    this.data.cupon = null;
    this.data.total = null;
    this.data.mostracuponok = null;
    this.data.mostracuponko = null;
  }
  SiguienteModal(){
    // console.log('Click siguiente');
    if(this.num_reservas_dias_check == 0){
      let contTotalPlazas = this.data.adultos + this.data.ninios;
      if(this.evento.aforo_minimo > contTotalPlazas){
        swal('¡Atención!', 'Debes de seleccionar un mínimo de '+this.evento.aforo_minimo+' plazas para esta sesión', 'warning');
        return;
      }
    }
    var radiosSeleccion = $('.radios-sesion');
    var checkRadio = false;
    for(var i = 0; i < radiosSeleccion.length; i++){
      if($(radiosSeleccion[i]).is(':checked')){
        checkRadio = true;
      }
    }
    if(!checkRadio){
      swal('¡Atención!', 'Debe seleccionar una sesión', 'warning');
      return;
    }
    if(this.data.adultos == 0){
      swal('¡Atención!', 'Los niños menores deben de ir acompañados al menos de un adulto', 'warning');
      return;
    }

    $('#content-paso-1').hide();
    $('#content-paso-2').fadeIn();
  }
  calcularPrecioModal(){
    this.precioCalculoAdulto = this.data.adultos * this.precioAdulto;
    this.precioCalculoNinio = this.data.ninios * this.precioNinio;
    this.data.precio = this.precioCalculoAdulto + this.precioCalculoNinio;
  }
  setSesionCheck(sesion, aforo_restante){
    this.globals.Api('/pub-check-hay-reservas',{id_actividad: this.data.id_actividad, sesion: sesion, fecha: this.data.fecha}).subscribe(data => {
      if(!data || data == ''){
        return;
      }
      // console.log(data);
      this.num_reservas_dias_check = data.num_reservas;
    });
    this.newSesion = sesion;
    this.plazasDisponibles = parseInt(aforo_restante);
    if(this.plazasDisponibles < (this.data.adultos + this.data.ninios + this.data.niniosGratis)){
      this.data.adultos = 0;
      this.data.ninios = 0;
      this.data.niniosGratis = 0;
      this.precioCalculoAdulto = 0;
      this.precioCalculoNinio = 0;
      this.calcularPrecioModal();
      $('#custom-mas-adulto').removeClass('disabled-custom-input');
      $('#custom-mas-ninio').removeClass('disabled-custom-input');
      $('#custom-mas-ninio-gratis').removeClass('disabled-custom-input');
      $('#custom-menos-adulto').addClass('disabled-custom-input');
      $('#custom-menos-ninio').addClass('disabled-custom-input');
      $('#custom-menos-ninio-gratis').addClass('disabled-custom-input');
    }else{
      $('#custom-mas-adulto').removeClass('disabled-custom-input');
      $('#custom-mas-ninio').removeClass('disabled-custom-input');
      $('#custom-mas-ninio-gratis').removeClass('disabled-custom-input');
    }
    if(this.plazasDisponibles == 0){
      $('#custom-mas-adulto').addClass('disabled-custom-input');
      $('#custom-mas-ninio').addClass('disabled-custom-input');
      $('#custom-mas-ninio-gratis').addClass('disabled-custom-input');
      $('#custom-menos-adulto').addClass('disabled-custom-input');
      $('#custom-menos-ninio').addClass('disabled-custom-input');
      $('#custom-menos-ninio-gratis').addClass('disabled-custom-input');
    }
  }
  mostrarModal(){
    $('#modal-calendar').modal('show');
  }
  ActualizarFecha(){
    if(this.plazasDisponibles < (this.data.adultos + this.data.ninios + this.data.niniosGratis)){
      swal('error','No hay plazas suficientes para la sesión seleccionada','error');
      return;
    }
    if(this.newSesion == '' || this.dateSelect == ''){
      swal('error','Debes seleccionar un día y una sesión','error');
      return;
    }
    // swal('error','Si hay plazas suficientes para la sesión seleccionada','error');
    if(this.data.ninios == undefined) this.data.ninios = 0;
    if(this.data.niniosGratis == undefined) this.data.niniosGratis = 0;
    let totalPlazas = (this.data.adultos + this.data.ninios + this.data.niniosGratis);
    // console.log(this.data.adultos);
    // console.log(this.data.ninios);
    // console.log(this.data.niniosGratis);
    // console.log(this.newSesion);
    // console.log(this.dateSelect);
    // console.log(this.data.id);
    // console.log(this.data.id_actividad);
    // console.log(totalPlazas);
    this.globals.Api('/eve-reservas-edit-calendario-v2',{id_actividad: this.data.id_actividad, id_reserva: this.data.id, sesion: this.newSesion, fecha: this.dateSelect, total_plazas: totalPlazas}).subscribe(data => {
      if(!data || data == ''){
        return;
      }
      // console.log(data);
      swal('Guardado','Fecha y sesión actualizada','success');
      this.diaSelecionado = this.dateSelect;
      this.LoadAforo();
    });
  }
  VolverEnviarPago(){
    if(this.fecha_expiracion_new_pago == '' || this.fecha_expiracion_new_pago == null){
      swal('Atención','Debes establecer la fecha de expiración','warning');
      return;
    }
    this.globals.Api('/eve-reservas-volver-enviar-pago',{id: this.data.id, anticipo_fecha_expiracion: this.fecha_expiracion_new_pago, anticipo_importe: this.data.anticipo_importe, total_importe_reserva: this.data.total}).subscribe(data => {
      if (!data || data.error) {
        $.LoadingOverlay("hide", true);
        swal('Error', 'Error al hacer la reserva', 'error');return;
      }
      $.LoadingOverlay("hide", true);
      swal('Enviado', '¡Pago enviado con éxito!', 'success');
      this.data.id_estado = 7;
    });
  }
  changeTotalReserva(){
    $('#input-change-total-reserva').fadeIn();
  }
  calcularBaseImponible(){
    this.data.base_imponible = this.data.total - (this.data.total * (this.data.iva / 100));
  }
}
