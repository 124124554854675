import { Component, OnInit, NgZone, ApplicationRef, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let toastr: any;
declare let tinymce: any;
declare let ace: any;

@Component({
  selector: 'app-configuracion',
  templateUrl: './configuracion.component.html',
  styleUrls: ['./configuracion.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConfiguracionComponent implements OnInit {
  public temporadas = [];
  public data:any = {};
  public esquemas_color:any = [];
  public email_template:any = 'opiniones';
  public idioma_email_template:any = 'es';
  public idioma:string = 'es';

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
    $.checkrolredirect(3); //No clientes
    tinymce.remove();
    if(!this.globals.cliente && this.globals.me.id_rol == 0){
      this.ngZone.run(() => this.router.navigateByUrl('/dashboard-superadmin')).then();
      return;
    }
    if (this.globals.GetData()) this.data = this.globals.GetData();
    let id_cliente = null;
    if(this.globals.cliente){
      id_cliente = this.globals.cliente.id;
    }else{
      id_cliente = this.globals.me.id_cliente;
    }
    this.globals.Api('/configuracion-cliente', {id_cliente: id_cliente}).subscribe(data => {
      if (!data) return;
      this.data = data;
    });
    this.globals.Api('/esquemas-color').subscribe(data => {
      if (!data) return;
      this.esquemas_color = data;
    });
    setTimeout(() => {
      let move = function (arr, from, to) {
        arr.splice(to, 0, arr.splice(from, 1)[0]);
      };
      let that = this;
      tinymce.init({
        selector:'.text-editor',
        height: 300,
        plugins: 'print preview powerpaste casechange importcss tinydrive searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount tinymcespellchecker a11ychecker imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap tinycomments mentions quickbars linkchecker emoticons advtable',
        toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
        language: 'es'
      });
    }, 300);
    setTimeout(() => {
      $.csseditor = ace.edit("txtcustomcss");
      $.csseditor.setTheme("ace/theme/monokai");
      $.csseditor.session.setMode("ace/mode/css");
    }, 500);
    this.LoadPreviewEmail();
  }

  Guardar() {
    this.data.politicas_cancelacion = tinymce.EditorManager.get('txtcancelacion').getContent();
    if ($.csseditor && this.data) {
      this.data.customcss = $.csseditor.getValue();
      this.data.customcss = this.data.customcss.replace(/<br\s*\/?>/gi, '\n').replace(/<[^>]*>?/gm, '');
    }
    $.buttonloading('.btn-guardar', 'Enviando');
    this.globals.Api('/configuracion-cliente-edit', this.data).subscribe(data => {
      $.buttoncancelloading('.btn-guardar');
      if (!data || data.error) {
        swal('Error', 'Se ha producido un error', 'error');return;
      }
      swal('Guardado', 'Datos guardados correctamente', 'success');
      this.globals.Api('/configuracion-cliente', {id_cliente: this.data.id_cliente}).subscribe(data => {
        if (!data) return;
        if (this.globals.cliente) {
          this.globals.cliente.config = data;
          this.globals.SaveData(this.globals.cliente, 'cliente-activo');
        }
        else this.globals.config = data;
      });
      this.globals.CargarColorCliente();
      this.LoadPreviewEmail();
    }, error => {
      $.buttoncancelloading('.btn-guardar');
    });
  }
  EmailTest() {
    this.globals.Api('/email-test', {id_cliente: this.data.id_cliente}).subscribe(data => {
      swal('Enviado', 'Intento de email enviado al servidor', 'info');
    }, error =>  {
      swal('Error', 'Se ha producido un error', 'error');
    });
  }
  LoadPreviewEmail(){
    this.globals.Api('/configuracion-cliente-get-mail-template',{template: this.email_template, idioma: this.idioma_email_template}).subscribe(data => {
      if (!data) return;
      console.log(data);
      $('#preview-email-opinion').html(data.value);
    });
  }
  ChangeIdioma() {
    $('#txtcancelacion + div,#txtcancelacion-en + div,#txtcancelacion-fr + div,#txtcancelacion-pt + div').hide();
    let id = 'txtcancelacion';
    if (this.idioma != 'es') id += '-' + this.idioma;
    $('#' + id + ' + div').show();
  }
}
