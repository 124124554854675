<div id="page-wrapper" class="page-loading">
  <div class="preloader">
    <div class="inner">
      <!-- Animation spinner for all modern browsers -->
      <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

      <!-- Text for IE9 -->
      <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
    </div>
  </div>
  <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
    <!-- Main Sidebar -->
    <app-backend-menu></app-backend-menu>
    <!-- END Main Sidebar -->

    <!-- Main Container -->
    <div id="main-container">
      <!-- Header -->
      <app-backend-header></app-backend-header>
      <!-- END Header -->

      <!-- Page content -->
      <div id="page-content" class="add-edit-actividad-page">
        <div class="content-header">
          <div class="row-equal-cols">
            <div class="col-lg-8 col-md-6 col-xs-12">
              <div class="header-section">
                <h1 class="middle">{{title}}</h1>
                <div>
                  <a [href]="data.url" target="_blank" *ngIf="data && data.url" style="opacity:.6;">
                    {{data.url}} <i class="fa fa-external-link"></i>
                  </a>
                </div>
              </div>
              <ul class="breadcrumb breadcrumb-top text-left">
                <li><a [routerLink]="['/dashboard']">Inicio</a></li>
                <li><a [routerLink]="['/eventos']">Eventos</a></li>
                <li>{{title}}</li>
              </ul>
            </div>
            <div class="col-lg-4 col-md-6 col-xs-12 col-botones text-center">
              <a class="btn btn-default" (click)="Cancelar()">Cancelar</a>
              <a class="btn btn-primary btn-guardar m-l-20" (click)="Guardar()">Guardar</a>
            </div>
          </div>
        </div>

        <div class="block full break-padding">
          <div class="block-title">
            <ul class="nav nav-tabs" data-toggle="tabs">
              <li class="active"><a href="#tab-general"><i class="icofont-book-alt"></i> Resumen</a></li>
              <li *ngIf="data.id && data.id != '' && !data.reserva_externa"><a href="#tab-tarifas"><i class="icofont-euro"></i> Tarifas</a></li>
              <li><a href="#tab-web" (click)="ChangeIdioma()"><i class="icofont-web"></i> Ficha WEB</a></li>
            </ul>
          </div>
          <div class="tab-content">
            <div class="tab-pane active" id="tab-general">

              <div class="row">
                <div class="col-lg-8 col-md-12">

                  <div class="row">
                    <div class="col-md-10 col-xs-12">
                      <div class="form-group">
                        <label class="text-primary">Nombre del evento <span class="text-danger">*</span></label>
                        <div class="input-group">
                          <input type="text" class="form-control font-bold" *ngIf="idioma == 'es'" [(ngModel)]="data.nombre" maxlength="80">
                          <input type="text" class="form-control font-bold" *ngIf="idioma == 'en'" [(ngModel)]="data.en.nombre" maxlength="80">
                          <input type="text" class="form-control font-bold" *ngIf="idioma == 'fr'" [(ngModel)]="data.fr.nombre" maxlength="80">
                          <input type="text" class="form-control font-bold" *ngIf="idioma == 'pt'" [(ngModel)]="data.pt.nombre" maxlength="80">
                          <span class="input-group-addon">
                            <select class="form-control select-idioma" [(ngModel)]="idioma" style="width:auto;height:auto;padding:0">
                                <option value="es">ES</option>
                                <option value="en">EN</option>
                                <option value="fr">FR</option>
                                <option value="pt">PT</option>
                            </select>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 col-xs-12">
                      <div class="form-group">
                        <label>Activo en WEB <span class="text-danger">*</span></label><br>
                        <label class="switch switch-success">
                          <input type="checkbox"
                            [attr.checked]="data.web == 1 ? true : null"
                            [(ngModel)]="data.web"><span></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="flex align-items-center" style="gap:1.5rem">
                    <div class="form-group">
                      <label>¿Gestión de asientos?</label><br>
                      <label class="switch switch-success">
                        <input type="checkbox"
                          [attr.checked]="data.gestion_asientos == 1 ? true : null"
                          [(ngModel)]="data.gestion_asientos"><span></span>
                      </label>
                    </div>
                    <div class="form-group">
                      <label>¿Reserva externa?</label><br>
                      <label class="switch switch-success">
                        <input type="checkbox"
                          [attr.checked]="data.reserva_externa == 1 ? true : null"
                          [(ngModel)]="data.reserva_externa"><span></span>
                      </label>
                    </div>
                    <div class="form-group">
                      <label>¿Se realiza en el mismo día?</label><br>
                      <label class="switch switch-success">
                        <input type="checkbox"
                          [attr.checked]="data.mismo_dia == 1 ? true : null"
                          [(ngModel)]="data.mismo_dia"><span></span>
                      </label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-3 col-lg-3 col-md-3 col-xs-12">
                      <div class="form-group">
                        <label>Organizador</label>
                        <input type="text" class="form-control" placeholder="Opcional" [(ngModel)]="data.organizador" maxlength="100">
                      </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-xs-12">
                      <div class="form-group">
                        <label>Colaborador</label>
                        <input type="text" class="form-control" placeholder="Opcional" [(ngModel)]="data.colaborador" maxlength="100">
                      </div>
                    </div>
                    <ng-container *ngIf="data.gestion_asientos == 0">
                      <div class="col-xl-2 col-lg-2 col-md-3 col-xs-12">
                        <div class="form-group">
                          <label>Aforo</label>
                          <input type="number" class="form-control" [(ngModel)]="data.aforo" maxlength="3">
                        </div>
                      </div>
                    </ng-container>
                    <div class="col-xl-4 col-lg-4 col-md-4 col-xs-12">
                      <div class="form-group">
                        <label>Lugar del evento</label>
                        <input type="text" placeholder="Sin coordenadas" class="form-control" (click)="AbrirMapa()"
                          [(ngModel)]="data.localizacion_maps" maxlength="400">
                      </div>
                    </div>
                    <div class="col-xl-2 col-lg-2 col-md-2 col-xs-12">
                      <div class="form-group">
                        <label>Código postal</label>
                        <input type="text" class="form-control" [(ngModel)]="data.cod_postal" maxlength="5"
                          (change)="SetLocalidad()">
                      </div>
                    </div>
                    <div class="col-xl-3 col-lg-4 col-md-4 col-xs-12">
                      <div class="form-group">
                        <label>Localidad</label>
                        <input type="text" class="form-control" [(ngModel)]="data.localidad" maxlength="50">
                      </div>
                    </div>
                    <div class="col-xl-3 col-lg-4 col-md-4 col-xs-12">
                      <div class="form-group">
                        <label>Provincia</label>
                        <!-- <input type="text" class="form-control" [(ngModel)]="data.provincia"/> -->
                        <select required name="provincia" class="form-control" [(ngModel)]="data.provincia">
                          <option value="">Elige Provincia</option>
                          <option value="Álava/Araba">Álava/Araba</option>
                          <option value="Albacete">Albacete</option>
                          <option value="Alicante">Alicante</option>
                          <option value="Almería">Almería</option>
                          <option value="Asturias">Asturias</option>
                          <option value="Ávila">Ávila</option>
                          <option value="Badajoz">Badajoz</option>
                          <option value="Baleares">Baleares</option>
                          <option value="Barcelona">Barcelona</option>
                          <option value="Burgos">Burgos</option>
                          <option value="Cáceres">Cáceres</option>
                          <option value="Cádiz">Cádiz</option>
                          <option value="Cantabria">Cantabria</option>
                          <option value="Castellón">Castellón</option>
                          <option value="Ceuta">Ceuta</option>
                          <option value="Ciudad Real">Ciudad Real</option>
                          <option value="Córdoba">Córdoba</option>
                          <option value="Cuenca">Cuenca</option>
                          <option value="Gerona/Girona">Gerona/Girona</option>
                          <option value="Granada">Granada</option>
                          <option value="Guadalajara">Guadalajara</option>
                          <option value="Guipúzcoa/Gipuzkoa">Guipúzcoa/Gipuzkoa</option>
                          <option value="Huelva">Huelva</option>
                          <option value="Huesca">Huesca</option>
                          <option value="Jaén">Jaén</option>
                          <option value="La Coruña/A Coruña">La Coruña/A Coruña</option>
                          <option value="La Rioja">La Rioja</option>
                          <option value="Las Palmas">Las Palmas</option>
                          <option value="León">León</option>
                          <option value="Lérida/Lleida">Lérida/Lleida</option>
                          <option value="Lugo">Lugo</option>
                          <option value="Madrid">Madrid</option>
                          <option value="Málaga">Málaga</option>
                          <option value="Melilla">Melilla</option>
                          <option value="Murcia">Murcia</option>
                          <option value="Navarra">Navarra</option>
                          <option value="Orense/Ourense">Orense/Ourense</option>
                          <option value="Palencia">Palencia</option>
                          <option value="Pontevedra">Pontevedra</option>
                          <option value="Salamanca">Salamanca</option>
                          <option value="Segovia">Segovia</option>
                          <option value="Sevilla">Sevilla</option>
                          <option value="Soria">Soria</option>
                          <option value="Tarragona">Tarragona</option>
                          <option value="Tenerife">Tenerife</option>
                          <option value="Teruel">Teruel</option>
                          <option value="Toledo">Toledo</option>
                          <option value="Valencia">Valencia</option>
                          <option value="Valladolid">Valladolid</option>
                          <option value="Vizcaya/Bizkaia">Vizcaya/Bizkaia</option>
                          <option value="Zamora">Zamora</option>
                          <option value="Zaragoza">Zaragoza</option>
                        </select>
                      </div>
                    </div>
                    <ng-container *ngIf="data.reserva_externa == 1">
                      <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                        <div class="form-group">
                          <label>URL Reserva</label>
                          <input type="url" class="form-control" [(ngModel)]="data.url_reserva" />
                        </div>
                      </div>
                    </ng-container>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-xs-12">
                      <div class="form-group">
                        <label>Fecha inicio</label>
                        <input type="date" class="form-control"
                          [(ngModel)]="data.fecha_inicio" />
                      </div>
                    </div>
                    <ng-container *ngIf="data.mismo_dia != 1">
                      <div class="col-xl-3 col-lg-3 col-md-3 col-xs-12">
                        <div class="form-group">
                          <label>Fecha fin</label>
                          <input type="date" class="form-control"
                            [(ngModel)]="data.fecha_fin" />
                        </div>
                      </div>
                    </ng-container>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-xs-12">
                      <div class="form-group">
                        <label>Hora inicio</label>
                        <input type="time" class="form-control"
                          [(ngModel)]="data.hora_inicio" />
                      </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-xs-12">
                      <div class="form-group">
                        <label>Duración</label>
                        <div class="input-group">
                          <input type="text" class="form-control" *ngIf="idioma == 'es'" [(ngModel)]="data.duracion" maxlength="50">
                          <input type="text" class="form-control" *ngIf="idioma == 'en'" [(ngModel)]="data.en.duracion" maxlength="50">
                          <input type="text" class="form-control" *ngIf="idioma == 'fr'" [(ngModel)]="data.fr.duracion" maxlength="50">
                          <input type="text" class="form-control" *ngIf="idioma == 'pt'" [(ngModel)]="data.pt.duracion" maxlength="50">
                          <span class="input-group-addon">
                            <select class="form-control select-idioma" [(ngModel)]="idioma" style="width:auto;height:auto;padding:0">
                                <option value="es">ES</option>
                                <option value="en">EN</option>
                                <option value="fr">FR</option>
                                <option value="pt">PT</option>
                            </select>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 col-xs-12">
                      <div class="block">
                        <div class="block-title">
                          <h2>Información adicional adjunta en el email de la confirmación de la reserva</h2>
                        </div>
                        <div class="form-group">
                          <div class="input-group">
                            <textarea id="txtinfo" class="info-adicional {{idioma != 'es' ? 'hidden' : ''}}" type="text"
                              placeholder="Escriba aqu&iacute; la información adicional"
                              style="border-color: #dedede;"
                              [(ngModel)]="data.informacion_adicional" maxlength="10000" rows="5"> </textarea>
                            <textarea id="txtinfo" class="info-adicional {{idioma != 'en' ? 'hidden' : ''}}" type="text"
                              placeholder="Escriba aqu&iacute; la información adicional"
                              style="border-color: #dedede;"
                              [(ngModel)]="data.en.informacion_adicional" maxlength="10000" rows="5"> </textarea>
                            <textarea id="txtinfo" class="info-adicional {{idioma != 'fr' ? 'hidden' : ''}}" type="text"
                              placeholder="Escriba aqu&iacute; la información adicional"
                              style="border-color: #dedede;"
                              [(ngModel)]="data.fr.informacion_adicional" maxlength="10000" rows="5"> </textarea>
                            <textarea id="txtinfo" class="info-adicional {{idioma != 'pt' ? 'hidden' : ''}}" type="text"
                              placeholder="Escriba aqu&iacute; la información adicional"
                              style="border-color: #dedede;"
                              [(ngModel)]="data.pt.informacion_adicional" maxlength="10000" rows="5"> </textarea>
                            <span class="input-group-addon" style="vertical-align:top">
                              <select class="form-control select-idioma" [(ngModel)]="idioma" style="width:auto;height:auto;padding:0">
                                  <option value="es">ES</option>
                                  <option value="en">EN</option>
                                  <option value="fr">FR</option>
                                  <option value="pt">PT</option>
                              </select>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12">
                  <ng-container *ngIf="data.gestion_asientos && !data.reserva_externa">
                    <div class="block block-table">
                      <div class="block-title display-flex justify-content-between">
                        <h2>Asientos</h2>
                        <div class="display-flex justify-content-between" style="gap:10px;align-items:center;">
                          <select class="form-control select-idioma" [(ngModel)]="idioma" style="width:auto;height:25px;padding:0">
                            <option value="es">ES</option>
                            <option value="en">EN</option>
                            <option value="fr">FR</option>
                            <option value="pt">PT</option>
                          </select>
                          <div class="text-right content-buttton-add-sesion" *ngIf="!asientos || asientos.length <= 20">
                            <a class="btn btn-primary m-r-10 button-add-sesion" (click)="AddAsientos()">Añadir tipo asiento</a>
                          </div>
                        </div>
                      </div>
                      <ng-container *ngIf="asientos.length">
                        <div class="table-responsive table-grid">
                            <table class="table table-striped table-bordered table-vcenter table-hover">
                              <thead>
                                <tr>
                                  <th>Nombre de asiento</th>
                                  <th>Numero de asientos</th>
                                  <th style="width:50px">Editar</th>
                                  <th style="width:50px">Borrar</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let asiento of asientos" class="cursor">
                                  <td class="font-bold text-primary">
                                    <div [innerHTML]="asiento.nombre" (click)="EditAsiento(asiento)" *ngIf="idioma == 'es'"></div>
                                    <div [innerHTML]="asiento.en.nombre" (click)="EditAsiento(asiento)" *ngIf="idioma == 'en'"></div>
                                    <div [innerHTML]="asiento.fr.nombre" (click)="EditAsiento(asiento)" *ngIf="idioma == 'fr'"></div>
                                    <div [innerHTML]="asiento.pt.nombre" (click)="EditAsiento(asiento)" *ngIf="idioma == 'pt'"></div>
                                  </td>
                                  <td class="font-bold text-primary">
                                    <div [innerHTML]="asiento.num_asientos"></div>
                                  </td>
                                  <td class="text-center btntd"><a class="btn btn-warning btn-xs"
                                      (click)="EditAsiento(asiento)"><i class="fa fa-pencil text-white"></i></a></td>
                                  <td class="text-center btntd"><a class="btn btn-danger btn-xs"
                                      (click)="DelAsiento(asiento)"><i class="fa fa-trash text-white"></i></a></td>
                                </tr>
                              </tbody>
                            </table>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="!asientos.length">
                        <label class="no-margin">No hay asientos establecidos</label>
                      </ng-container>
                    </div>
                  </ng-container>
                  <div id="content-iframe-gmaps">
                    <iframe *ngIf="data.localizacion_maps" width="100%" height="350" frameborder="0" scrolling="no"
                      marginheight="0" marginwidth="0"
                      src="https://maps.google.com/maps?width=100%25&height=600&hl=es&t=&z=14&ie=UTF8&iwloc=B&output=embed"
                      allowfullscreen webkitallowfullscreen mozallowfullscreen></iframe>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane" id="tab-tarifas">
              <ng-container *ngIf="!data.gestion_asientos">
                <div class="row">
                  <!-- <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                    <label class="text-primary">¿Cómo queres que sean tus tarifas, precios por personas o por grupos de personas?</label>
                  </div>
                  <div class="col-xl-3 col-lg-6 col-md-6 col-xs-12">
                    <div class="form-group">
                      <select class="form-control" [(ngModel)]="data.precio_persona_grupo">
                        <option value="0">Precio por persona</option>
                        <option value="1">Precio por grupos de personas</option>
                      </select>
                    </div>
                  </div> -->
                  <div class="clearfix"></div>
                  <div class="col-xl-2 col-lg-3 col-md-3 col-xs-12">
                    <div class="form-group">
                      <label>Nombre tarifa</label>
                      <input type="text" placeholder="Adultos, niños..." class="form-control"
                        [(ngModel)]="data.tempNombreTarifa" />
                    </div>
                  </div>
                  <ng-container *ngIf="data.precio_persona_grupo == 1">
                    <div class="col-xl-2 col-lg-3 col-md-3 col-xs-12">
                      <div class="form-group">
                        <label>Número de personas</label>
                        <input type="number" class="form-control"
                          [(ngModel)]="data.tempNumeroPersonasTarifa" />
                      </div>
                    </div>
                  </ng-container>
                  <div class="col-xl-2 col-lg-3 col-md-3 col-xs-12">
                    <div class="form-group">
                      <label>Precio tarifa</label>
                      <input type="number" class="form-control"
                        [(ngModel)]="data.tempPrecioTarifa" />
                    </div>
                  </div>
                  <div class="col-xl-2 col-lg-3 col-md-3 col-xs-12">
                    <ng-container *ngIf="data.precio_persona_grupo == 0">
                      <button type="button" class="btn btn-primary btn-add-tarifa" (click)="AddTarifaS()">Añadir tarifa</button>
                    </ng-container>
                    <ng-container *ngIf="data.precio_persona_grupo == 1">
                      <button type="button" class="btn btn-primary btn-add-tarifa" (click)="AddTarifaG()">Añadir tarifa</button>
                    </ng-container>
                  </div>
                  <div class="clearfix"></div>
                  <ng-container *ngIf="data.precio_persona_grupo == 0">
                    <div class="col-xl-6 col-lg-6 col-md-12 col-xs-12">
                      <div class="block block-table">
                        <div class="block-title display-flex justify-content-between">
                          <h2>Precios <span class="pincha-casilla">*Pincha en una casilla para editar</span></h2>
                          <div class="display-flex justify-content-between m-r-10" style="gap:10px;align-items:center;">
                            <select class="form-control select-idioma" [(ngModel)]="idioma" style="width:auto;height:25px;padding:0">
                              <option value="es">ES</option>
                              <option value="en">EN</option>
                              <option value="fr">FR</option>
                              <option value="pt">PT</option>
                            </select>
                          </div>
                        </div>
                        <ng-container *ngIf="data.tarifas.length != 0">
                        <div class="table-responsive table-grid">
                            <table class="table" id="table-precio-por-persona">
                              <thead>
                                  <tr>
                                      <th style="width:40px;"></th>
                                      <th>Nombre</th>
                                      <th style="width:50px">Precio</th>
                                      <th style="width:50px"></th>
                                      <th style="width:50px">Borrar</th>
                                  </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let item of data.tarifas" [attr.itemid]="item.nombre">
                                  <td tabindex="-1" class="btn-sort cursor"><i class="fa fa-sort"></i></td>
                                  <td contenteditable="true" [(innerHTML)]="item.nombre" *ngIf="idioma == 'es'" (keydown)="KeyS($event, item, 'nombre')" (blur)="BlurS($event, item, 'nombre')"></td>
                                  <td contenteditable="true" [(innerHTML)]="item.en.nombre" *ngIf="idioma == 'en'" (keydown)="KeyS($event, item.en, 'nombre')" (blur)="BlurS($event, item.en, 'nombre')"></td>
                                  <td contenteditable="true" [(innerHTML)]="item.fr.nombre" *ngIf="idioma == 'fr'" (keydown)="KeyS($event, item.fr, 'nombre')" (blur)="BlurS($event, item.fr, 'nombre')"></td>
                                  <td contenteditable="true" [(innerHTML)]="item.pt.nombre" *ngIf="idioma == 'pt'" (keydown)="KeyS($event, item.pt, 'nombre')" (blur)="BlurS($event, item.pt, 'nombre')"></td>
                                  <td contenteditable="true" class="text-center" [(innerHTML)]="item.precio" (keydown)="KeyS($event, item, 'precio')" (blur)="BlurS($event, item, 'precio')"></td>
                                  <td tabindex="-1"><i class="fa fa-euro"></i></td>
                                  <td tabindex="-1"><a class="btn btn-sm" (click)="DelTarifaS(item)"><i class="fa fa-trash text-danger"></i></a></td>
                                </tr>
                              </tbody>
                            </table>
                            <div class="text-center">
                                <a class="btn btn-sm btn-primary" (click)="AddTarifaS(item)"><i class="fa fa-plus"></i> Añadir Tarifa</a>
                            </div>
                            <div class="text-left m-t-10 p-l-10">
                              <p>Pincha y arrastra en el icono <i class="fa fa-sort"></i> para cambiar el orden</p>
                            </div>
                        </div>
                        </ng-container>
                        <ng-container *ngIf="data.tarifas.length == 0">
                          <div>No hay precios añadidos</div>
                        </ng-container>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="data.precio_persona_grupo == 1">
                    <div class="col-xl-5 col-lg-12 col-md-12 col-xs-12">
                      <div class="block">
                        <div class="block-title display-flex justify-content-between">
                          <h2>Precios de grupos</h2>
                        </div>
                        <div class="form-group row">
                          <div class="col-lg-12">
                            <div class="table-responsive table-grid">
                              <ng-container *ngIf="data.tarifas_grupos.length != 0">
                                <table class="table" id="table-precio-por-persona">
                                  <thead>
                                      <tr>
                                          <th style="width:40px;"></th>
                                          <th>Nombre</th>
                                          <th>Nº de personas</th>
                                          <th style="width:50px">Precio</th>
                                          <th style="width:50px"></th>
                                          <th style="width:50px">Borrar</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let item of data.tarifas" [attr.itemid]="item.nombre">
                                      <td tabindex="-1" class="btn-sort cursor"><i class="fa fa-sort"></i></td>
                                      <td contenteditable="true" [(innerHTML)]="item.nombre" *ngIf="idioma == 'es'" (keydown)="KeyG($event, item, 'nombre')" (blur)="BlurG($event, item, 'nombre')"></td>
                                      <td contenteditable="true" [(innerHTML)]="item.en.nombre" *ngIf="idioma == 'en'" (keydown)="KeyG($event, item.en, 'nombre')" (blur)="BlurG($event, item.en, 'nombre')"></td>
                                      <td contenteditable="true" [(innerHTML)]="item.fr.nombre" *ngIf="idioma == 'fr'" (keydown)="KeyG($event, item.fr, 'nombre')" (blur)="BlurG($event, item.fr, 'nombre')"></td>
                                      <td contenteditable="true" [(innerHTML)]="item.pt.nombre" *ngIf="idioma == 'pt'" (keydown)="KeyG($event, item.pt, 'nombre')" (blur)="BlurG($event, item.pt, 'nombre')"></td>
                                      <td contenteditable="true" [(innerHTML)]="item.num_personas" (keydown)="KeyG($event, item, 'num_personas')" (blur)="BlurG($event, item, 'num_personas')"></td>
                                      <td contenteditable="true" class="text-center" [(innerHTML)]="item.precio" (keydown)="KeyG($event, item, 'precio')" (blur)="BlurG($event, item, 'precio')"></td>
                                      <td tabindex="-1"><i class="fa fa-euro"></i></td>
                                      <td tabindex="-1"><a class="btn btn-sm" (click)="DelTarifaG(item)"><i class="fa fa-trash text-danger"></i></a></td>
                                    </tr>
                                  </tbody>
                                </table>
                                <div class="text-left">
                                  <p>Pincha y arrastra en el icono <i class="fa fa-sort"></i> para cambiar el orden</p>
                                </div>
                                <div class="text-center">
                                    <a class="btn btn-sm btn-primary" (click)="AddTarifaG(item)"><i class="fa fa-plus"></i> Añadir Tarifa</a>
                                </div>
                              </ng-container>
                              <ng-container *ngIf="data.tarifas_grupos.length == 0">
                                <label>No hay precios añadidos</label>
                              </ng-container>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
              <ng-container *ngIf="data.gestion_asientos">
                <div class="col-lg-6" *ngFor="let item of asientos">
                  <div class="block">
                    <div class="block-title display-flex justify-content-between">
                      <div>
                        <h2>
                          <span *ngIf="idioma == 'es'">{{item.nombre}}</span>
                          <span *ngIf="idioma == 'en'">{{item.en.nombre}}</span>
                          <span *ngIf="idioma == 'fr'">{{item.fr.nombre}}</span>
                          <span *ngIf="idioma == 'pt'">{{item.pt.nombre}}</span>
                          <br>
                          <span class="pincha-casilla">*Pincha en una casilla para editar</span>
                        </h2>
                      </div>
                      <div class="display-flex justify-content-between m-r-10" style="gap:10px;align-items:center;">
                        <select class="form-control select-idioma" [(ngModel)]="idioma" style="width:auto;height:25px;padding:0">
                          <option value="es">ES</option>
                          <option value="en">EN</option>
                          <option value="fr">FR</option>
                          <option value="pt">PT</option>
                        </select>
                        <a class="btn btn-primary m-r-10 button-add-categoria" (click)="AddAsiento(item)"><i class="fa fa-plus"></i> Añadir tarifa</a>
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="col-lg-12">
                        <table class="table table-{{item.id}}">
                          <thead>
                              <tr>
                                  <th style="width:40px;"></th>
                                  <th>Tipo persona</th>
                                  <th style="width:80px;" class="text-center">Precio</th>
                                  <th style="width:20px;"></th>
                                  <th style="width:40px;"></th>
                              </tr>
                          </thead>
                          <tbody [attr.itemid]="item.id">
                              <tr *ngFor="let tarifa of item.tarifas" [attr.itemid]="tarifa.id">
                                  <td tabindex="-1" class="btn-sort cursor"><i class="fa fa-sort"></i></td>
                                  <td contenteditable="true" [(innerHTML)]="tarifa.nombre" *ngIf="idioma == 'es'" (keydown)="Key($event, tarifa, 'nombre')" (blur)="Blur($event, tarifa, 'nombre')"></td>
                                  <td contenteditable="true" [(innerHTML)]="tarifa.en.nombre" *ngIf="idioma == 'en'" (keydown)="Key($event, tarifa.en, 'nombre')" (blur)="Blur($event, tarifa.en, 'nombre')"></td>
                                  <td contenteditable="true" [(innerHTML)]="tarifa.fr.nombre" *ngIf="idioma == 'fr'" (keydown)="Key($event, tarifa.fr, 'nombre')" (blur)="Blur($event, tarifa.fr, 'nombre')"></td>
                                  <td contenteditable="true" [(innerHTML)]="tarifa.pt.nombre" *ngIf="idioma == 'pt'" (keydown)="Key($event, tarifa.pt, 'nombre')" (blur)="Blur($event, tarifa.pt, 'nombre')"></td>
                                  <td contenteditable="true" class="text-center" [(innerHTML)]="tarifa.precio" (keydown)="Key($event, tarifa, 'precio')" (blur)="Blur($event, tarifa, 'precio')"></td>
                                  <td tabindex="-1"><i class="fa fa-euro"></i></td>
                                  <td tabindex="-1"><a class="btn btn-sm" (click)="DelAsientoTarifas2(tarifa)"><i class="fa fa-trash text-danger"></i></a></td>
                              </tr>
                          </tbody>
                        </table>
                        <div class="text-left">
                          <p>Pincha y arrastra en el icono <i class="fa fa-sort"></i> para cambiar el orden</p>
                        </div>
                        <div class="text-center">
                            <a class="btn btn-sm btn-primary" (click)="AddAsiento(item)"><i class="fa fa-plus"></i> Añadir Tarifa</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="clearfix"></div>
              </ng-container>
            </div>
            <div class="tab-pane" id="tab-web">
              <div class="row">
                <div class="col-xl-9 col-lg-9 col-md-9 col-xs-12">
                  <div class="form-group">
                    <label class="text-primary">Descripci&oacute;n</label>
                    <div class="input-group">
                      <textarea id="txtdescripcion" type="text" class="form-control text-editor"
                        placeholder="Escriba aqu&iacute; la descripci&oacute;n" [(ngModel)]="data.descripcion"
                        maxlength="5000" rows="4"></textarea>
                      <textarea id="txtdescripcion-en" type="text" class="form-control text-editor"
                        placeholder="Escriba aqu&iacute; la descripci&oacute;n" [(ngModel)]="data.en.descripcion"
                        maxlength="5000" rows="4"></textarea>
                      <textarea id="txtdescripcion-fr" type="text" class="form-control text-editor"
                        placeholder="Escriba aqu&iacute; la descripci&oacute;n" [(ngModel)]="data.fr.descripcion"
                        maxlength="5000" rows="4"></textarea>
                      <textarea id="txtdescripcion-pt" type="text" class="form-control text-editor"
                        placeholder="Escriba aqu&iacute; la descripci&oacute;n"[(ngModel)]="data.pt.descripcion"
                        maxlength="5000" rows="4"></textarea>
                      <span class="input-group-addon" style="vertical-align:top">
                        <select class="form-control select-idioma" [(ngModel)]="idioma" style="width:auto;height:auto;padding:0">
                            <option value="es">ES</option>
                            <option value="en">EN</option>
                            <option value="fr">FR</option>
                            <option value="pt">PT</option>
                        </select>
                      </span>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="flex-between">
                      <label class="text-primary">Galería de imágenes</label>
                      <div class="text-right" *ngIf="!data.imagenes || data.imagenes.length <= 3">
                        <a id="btn-add-imagen" class="btn btn-primary m-l-20" (click)="AddImagen()">Añadir imagen</a>
                      </div>
                    </div>
                    <div class="text-center" *ngIf="!data || !data.imagenes || !data.imagenes.length">
                      <h4>No hay imágenes aún</h4>
                    </div>
                    <div class="row gallery" data-toggle="lightbox-gallery" *ngIf="data && data.imagenes">
                      <div class="col-xl-3 col-lg-3 col-md-4 col-sm-4" *ngFor="let item of data.imagenes" [attr.itemid]="item.id">
                          <div class="gallery-image-container animation-fadeInQuick2Inv">
                            <img src="{{item.imagen}}" alt="Image">
                            <a class="btn btn-danger cursor absolute" style="top:0;right:0" (click)="DelImagen($event, item)"><i class="fa fa-trash"></i> Borrar</a>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-xs-12">
                  <label class="text-primary">Imagen principal del evento</label>
                  <div *ngIf="!data.imagen || data.imagen == ''" class="cursor text-center m-t-10 no-image"
                    (click)="SelecImagen()">
                    <i class="icofont-camera-alt font-50"></i>
                  </div>
                  <div class="img-preview-container cursor relative" (click)="SelecImagen()">
                    <img *ngIf="data.imagen && data.imagen != ''" [src]="data.imagen">
                    <small class="display-block">(Sin imagen establecida)<br><br><span><b style="text-decoration: underline;">Establecer imagen <i class="fa fa-hand-pointer-o"></i></b></span></small>
                    <button *ngIf="data.imagen && data.imagen != ''" class="btn btn-danger absolute"
                      (click)="BorrarImagen($event)" style="top:0;right:0"><i class="fa fa-trash"></i></button>
                  </div>
                  <div class="block block-table">
                    <div class="block-title display-flex justify-content-between flex-wrap">
                      <h2>Características</h2>
                      <div class="display-flex justify-content-between" style="gap:10px;align-items:center;">
                        <select class="form-control select-idioma" [(ngModel)]="idioma" style="width:auto;height:25px;padding:0">
                          <option value="es">ES</option>
                          <option value="en">EN</option>
                          <option value="fr">FR</option>
                          <option value="pt">PT</option>
                        </select>
                        <div class="text-right content-buttton-add-caracteristica" *ngIf="!data.caracteristicas || data.caracteristicas.length <= 20">
                          <a class="btn btn-primary m-r-10 button-add-caracteristica" (click)="AddCaracteristica()">Añadir</a>
                        </div>
                      </div>
                    </div>
                    <ng-container *ngIf="data.caracteristicas && data.caracteristicas.length">
                    <div class="table-responsive table-grid table-caracteristicas">
                        <table class="table table-striped table-bordered table-vcenter table-hover">
                          <thead>
                            <tr>
                              <th style="width:30px"></th>
                              <th>Característica</th>
                              <th style="width:50px">Borrar</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of data.caracteristicas" class="cursor" [attr.itemid]="item.id">
                              <td><i class="fa fa-sort"></i></td>
                              <td class="font-bold text-primary" (click)="EditCaracteristica(item)">
                                <div [innerHTML]="item.caracteristica" *ngIf="idioma == 'es'"></div>
                                <div [innerHTML]="item.en.caracteristica" *ngIf="idioma == 'en'"></div>
                                <div [innerHTML]="item.fr.caracteristica" *ngIf="idioma == 'fr'"></div>
                                <div [innerHTML]="item.pt.caracteristica" *ngIf="idioma == 'pt'"></div>
                              </td>
                              <td class="text-center btntd"><a class="btn btn-danger btn-xs"
                                  (click)="DelCaracteristica(item)"><i class="fa fa-trash text-white"></i></a></td>
                            </tr>
                          </tbody>
                        </table>
                    </div>
                    </ng-container>
                    <ng-container *ngIf="!data.caracteristicas.length">
                      <div>No hay características</div>
                    </ng-container>
                  </div>
                  <div class="block block-table">
                    <div class="block-title display-flex justify-content-between flex-wrap">
                      <h2>Categorías</h2>
                      <div class="display-flex justify-content-between" style="gap:10px;align-items:center;">
                        <select class="form-control select-idioma" [(ngModel)]="idioma" style="width:auto;height:25px;padding:0">
                          <option value="es">ES</option>
                          <option value="en">EN</option>
                          <option value="fr">FR</option>
                          <option value="pt">PT</option>
                        </select>
                        <div class="text-right content-buttton-add-categoria" *ngIf="!data.categorias || data.categorias.length <= 20">
                          <a class="btn btn-primary m-r-10 button-add-categoria" (click)="AddCategoria()">Añadir</a>
                        </div>
                      </div>
                    </div>
                    <ng-container *ngIf="data.categorias && data.categorias.length">
                    <div class="table-responsive table-grid table-categorias">
                        <table class="table table-striped table-bordered table-vcenter table-hover">
                          <thead>
                            <tr>
                              <th style="width:30px"></th>
                              <th>Categoría</th>
                              <th style="width:50px">Borrar</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of data.categorias" class="cursor" [attr.itemid]="item.id">
                              <td><i class="fa fa-sort"></i></td>
                              <td class="font-bold text-primary" (click)="EditCategoria(item)">
                                <div [innerHTML]="item.categoria" *ngIf="idioma == 'es'"></div>
                                <div [innerHTML]="item.en.categoria" *ngIf="idioma == 'en'"></div>
                                <div [innerHTML]="item.fr.categoria" *ngIf="idioma == 'fr'"></div>
                                <div [innerHTML]="item.pt.categoria" *ngIf="idioma == 'pt'"></div>
                              </td>
                              <td class="text-center btntd"><a class="btn btn-danger btn-xs"
                                  (click)="DelCategoria(item)"><i class="fa fa-trash text-white"></i></a></td>
                            </tr>
                          </tbody>
                        </table>
                    </div>
                    </ng-container>
                    <ng-container *ngIf="!data.categorias.length">
                      <div>No hay categorías</div>
                    </ng-container>
                  </div>
                </div>
              </div>
              <div class="clearfix"></div>
            </div>
          </div>

          <div class="col-botones">
            <a class="btn btn-default" (click)="Cancelar()">Cancelar</a>
            <a class="btn btn-primary btn-guardar m-l-20" (click)="Guardar()">Guardar</a>
          </div>

        </div>
        <!-- END Page Content -->
      </div>
      <!-- END Main Container -->

        <app-backend-footer></app-backend-footer>
    </div>
    <!-- END Page Container -->
</div>
<!--Modal crear característica-->
<div class="modal inmodal fade" id="modal-crear-caracteristica" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content modal-lg">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
            class="sr-only">Cerrar</span></button>
        <h4 class="modal-title"></h4>
      </div>
      <div class="modal-body">
        <div class="form-group m-t-10">
          <label class="text-primary">Característica <span class="text-danger">*</span></label>
          <div class="input-group">
            <input type="text" class="form-control" *ngIf="idioma == 'es'" [(ngModel)]="dataitem.caracteristica" maxlength="50">
            <input type="text" class="form-control" *ngIf="idioma == 'en'" [(ngModel)]="dataitem.en.caracteristica" maxlength="50">
            <input type="text" class="form-control" *ngIf="idioma == 'fr'" [(ngModel)]="dataitem.fr.caracteristica" maxlength="50">
            <input type="text" class="form-control" *ngIf="idioma == 'pt'" [(ngModel)]="dataitem.pt.caracteristica" maxlength="50">
            <span class="input-group-addon">
              <select class="form-control select-idioma" [(ngModel)]="idioma" style="width:auto;height:auto;padding:0">
                  <option value="es">ES</option>
                  <option value="en">EN</option>
                  <option value="fr">FR</option>
                  <option value="pt">PT</option>
              </select>
            </span>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
        <button type="button" class="btn btn-primary" id="btn-guardar-caracteristica"
          (click)="GuardarCaracteristica()">Guardar</button>
      </div>
    </div>
  </div>
</div>
<!--Modal crear categoría-->
<div class="modal inmodal fade" id="modal-crear-categoria" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content modal-lg">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
            class="sr-only">Cerrar</span></button>
        <h4 class="modal-title"></h4>
      </div>
      <div class="modal-body">
        <div class="form-group m-t-10">
          <label class="text-primary">Categoría <span class="text-danger">*</span></label>
          <div class="input-group">
            <input type="text" class="form-control" *ngIf="idioma == 'es'" [(ngModel)]="dataitem.categoria" maxlength="50">
            <input type="text" class="form-control" *ngIf="idioma == 'en'" [(ngModel)]="dataitem.en.categoria" maxlength="50">
            <input type="text" class="form-control" *ngIf="idioma == 'fr'" [(ngModel)]="dataitem.fr.categoria" maxlength="50">
            <input type="text" class="form-control" *ngIf="idioma == 'pt'" [(ngModel)]="dataitem.pt.categoria" maxlength="50">
            <span class="input-group-addon">
              <select class="form-control select-idioma" [(ngModel)]="idioma" style="width:auto;height:auto;padding:0">
                  <option value="es">ES</option>
                  <option value="en">EN</option>
                  <option value="fr">FR</option>
                  <option value="pt">PT</option>
              </select>
            </span>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
        <button type="button" class="btn btn-primary" id="btn-guardar-categoria"
          (click)="GuardarCategoria()">Guardar</button>
      </div>
    </div>
  </div>
</div>
<!--Modal crear servicio-->
<div class="modal inmodal fade" id="modal-crear-servicio" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
            class="sr-only">Cerrar</span></button>
        <h4 class="modal-title"></h4>
      </div>
      <div class="modal-body">
        <div class="form-group row">
          <label class="col-lg-4 col-form-label">Servicio <span class="text-danger">*</span></label>
          <div class="col-lg-8">
            <input type="text" class="form-control" [(ngModel)]="dataitem.servicio" maxlength="50">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-lg-4 col-form-label">Icono <span class="text-danger">*</span></label>
          <div class="col-lg-8 chosen-iconos cmb-icono-servicio">
            <select class="form-control chosen-select" [(ngModel)]="dataitem.icono">
            </select>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
        <button type="button" class="btn btn-primary" id="btn-guardar-servicio"
          (click)="GuardarServicio()">Guardar</button>
      </div>
    </div>
  </div>
</div>
<!--Modal mapa-->
<div class="modal inmodal fade" id="modal-mapa" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" style="font-size: 3rem;"><span
            aria-hidden="true">&times;</span><span class="sr-only">Close</span></button>
        <h4 class="modal-title">Seleccione localizaci&oacute;n</h4>
        <small></small>
      </div>
      <div id="mapa-container" class="modal-body" style="padding: 0;">
        <div id="mapa" style="width:100%;height:400px;"></div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-white" style="margin-right: auto;" data-dismiss="modal">Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="SelecLocalizacion()">Seleccionar</button>
      </div>
    </div>
  </div>
</div>
<!--Modal crear asiento-->
<div class="modal inmodal fade" id="modal-crear-asiento" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content modal-lg">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
            class="sr-only">Cerrar</span></button>
        <h4 class="modal-title"></h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-6 col-xs-12">
            <div class="form-group">
              <label>Nombre asiento <span class="text-danger">*</span></label>
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Asiento normal, VIP..." *ngIf="idioma == 'es'" [(ngModel)]="asiento.nombre">
                <input type="text" class="form-control" placeholder="Asiento normal, VIP..." *ngIf="idioma == 'en'" [(ngModel)]="asiento.en.nombre">
                <input type="text" class="form-control" placeholder="Asiento normal, VIP..." *ngIf="idioma == 'fr'" [(ngModel)]="asiento.fr.nombre">
                <input type="text" class="form-control" placeholder="Asiento normal, VIP..." *ngIf="idioma == 'pt'" [(ngModel)]="asiento.pt.nombre">
                <span class="input-group-addon">
                  <select class="form-control select-idioma" [(ngModel)]="idioma" style="width:auto;height:auto;padding:0">
                      <option value="es">ES</option>
                      <option value="en">EN</option>
                      <option value="fr">FR</option>
                      <option value="pt">PT</option>
                  </select>
                </span>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-xs-12">
            <div class="form-group">
              <label>Número de asientos <span class="text-danger">*</span></label>
              <div class="input-group">
                <input type="number" class="form-control" [(ngModel)]="asiento.num_asientos">
              </div>
            </div>
          </div>
          <div class="clearfix"></div>
          <div class="col-lg-12">
            <div class="form-group row">
              <div class="col-lg-12">
                <label style="margin-bottom: 15px;">Configura las tarifas para este asiento</label>
                <table class="table">
                  <thead>
                      <tr>
                          <th style="width:40px;"></th>
                          <th>Tipo persona</th>
                          <th style="width:80px;" class="text-center">Precio</th>
                          <th style="width:20px;"></th>
                          <th style="width:40px;"></th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let item of asientos_temp" [attr.itemid]="item.id">
                          <td tabindex="-1" class="btn-sort cursor"><i class="fa fa-sort"></i></td>
                          <ng-container *ngIf="idioma == 'es'">
                            <td contenteditable="true" [(innerHTML)]="item.nombre" (keydown)="Key($event, item, 'nombre')" (blur)="Blur($event, item, 'nombre')"></td>
                          </ng-container>
                          <ng-container *ngIf="idioma == 'en'">
                            <td contenteditable="true" [(innerHTML)]="item.en.nombre" (keydown)="Key($event, item.en, 'nombre')" (blur)="Blur($event, item.en, 'nombre')"></td>
                          </ng-container>
                          <ng-container *ngIf="idioma == 'fr'">
                            <td contenteditable="true" [(innerHTML)]="item.fr.nombre" (keydown)="Key($event, item.fr, 'nombre')" (blur)="Blur($event, item.fr, 'nombre')"></td>
                          </ng-container>
                          <ng-container *ngIf="idioma == 'pt'">
                            <td contenteditable="true" [(innerHTML)]="item.pt.nombre" (keydown)="Key($event, item.pt, 'nombre')" (blur)="Blur($event, item.pt, 'nombre')"></td>
                          </ng-container>
                          <td contenteditable="true" class="text-center" [(innerHTML)]="item.precio" (keydown)="Key($event, item, 'precio')" (blur)="Blur($event, item, 'precio')"></td>
                          <td tabindex="-1"><i class="fa fa-euro"></i></td>
                          <td tabindex="-1"><a class="btn btn-sm" (click)="DelAsientoTarifas(item)"><i class="fa fa-trash text-danger"></i></a></td>
                      </tr>
                  </tbody>
              </table>
              <div class="text-center">
                  <a class="btn btn-sm btn-primary" *ngIf="asiento.nombre != '' && asiento.num_asientos != '' && asiento.num_asientos != null" (click)="AddAsiento(data.asientos)"><i class="fa fa-plus"></i> Añadir Tarifa</a>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
        <button type="button" class="btn btn-primary" id="btn-guardar-asiento"
          (click)="GuardarAsientos()">Guardar</button>
      </div>
    </div>
  </div>
</div>
<!--Modal editar asiento-->
<div class="modal inmodal fade" id="modal-editar-asiento" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content modal-lg">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
            class="sr-only">Cerrar</span></button>
        <h4 class="modal-title"></h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-6 col-xs-12">
            <div class="form-group">
              <label>Nombre asiento <span class="text-danger">*</span></label>
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Asiento normal, VIP..." *ngIf="idioma == 'es'" [(ngModel)]="asiento.nombre">
                <input type="text" class="form-control" placeholder="Asiento normal, VIP..." *ngIf="idioma == 'en'" [(ngModel)]="asiento.en.nombre">
                <input type="text" class="form-control" placeholder="Asiento normal, VIP..." *ngIf="idioma == 'fr'" [(ngModel)]="asiento.fr.nombre">
                <input type="text" class="form-control" placeholder="Asiento normal, VIP..." *ngIf="idioma == 'pt'" [(ngModel)]="asiento.pt.nombre">
                <span class="input-group-addon">
                  <select class="form-control select-idioma" [(ngModel)]="idioma" style="width:auto;height:auto;padding:0">
                      <option value="es">ES</option>
                      <option value="en">EN</option>
                      <option value="fr">FR</option>
                      <option value="pt">PT</option>
                  </select>
                </span>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-xs-12">
            <div class="form-group">
              <label>Número de asientos <span class="text-danger">*</span></label>
              <div class="input-group">
                <input type="number" class="form-control" [(ngModel)]="asiento.num_asientos">
              </div>
            </div>
          </div>
          <div class="clearfix"></div>
          <div class="col-lg-12">
            <div class="form-group row">
              <div class="col-lg-12">
                <label style="margin-bottom: 15px;">Configura las tarifas para este asiento</label>
                <table class="table">
                  <thead>
                      <tr>
                          <th style="width:40px;"></th>
                          <th>Tipo persona</th>
                          <th style="width:80px;" class="text-center">Precio</th>
                          <th style="width:20px;"></th>
                          <th style="width:40px;"></th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let item of asientos_temp" [attr.itemid]="item.id">
                          <td tabindex="-1" class="btn-sort cursor"><i class="fa fa-sort"></i></td>
                          <ng-container *ngIf="idioma == 'es'">
                            <td contenteditable="true" [(innerHTML)]="item.nombre" (keydown)="Key($event, item, 'nombre')" (blur)="Blur($event, item, 'nombre')"></td>
                          </ng-container>
                          <ng-container *ngIf="idioma == 'en'">
                            <td contenteditable="true" [(innerHTML)]="item.en.nombre" (keydown)="Key($event, item.en, 'nombre')" (blur)="Blur($event, item.en, 'nombre')"></td>
                          </ng-container>
                          <ng-container *ngIf="idioma == 'fr'">
                            <td contenteditable="true" [(innerHTML)]="item.fr.nombre" (keydown)="Key($event, item.fr, 'nombre')" (blur)="Blur($event, item.fr, 'nombre')"></td>
                          </ng-container>
                          <ng-container *ngIf="idioma == 'pt'">
                            <td contenteditable="true" [(innerHTML)]="item.pt.nombre" (keydown)="Key($event, item.pt, 'nombre')" (blur)="Blur($event, item.pt, 'nombre')"></td>
                          </ng-container>
                          <td contenteditable="true" class="text-center" [(innerHTML)]="item.precio" (keydown)="Key($event, item, 'precio')" (blur)="Blur($event, item, 'precio')"></td>
                          <td tabindex="-1"><i class="fa fa-euro"></i></td>
                          <td tabindex="-1"><a class="btn btn-sm" (click)="DelAsientoTarifas(item)"><i class="fa fa-trash text-danger"></i></a></td>
                      </tr>
                  </tbody>
                </table>
              <div class="text-left">
                <p>Pincha y arrastra en el icono <i class="fa fa-sort"></i> para cambiar el orden</p>
              </div>
              <div class="text-center">
                  <a class="btn btn-sm btn-primary" *ngIf="asiento.nombre != '' && asiento.num_asientos != '' && asiento.num_asientos != null" (click)="AddAsiento()"><i class="fa fa-plus"></i> Añadir Tarifa</a>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
        <button type="button" class="btn btn-primary" id="btn-guardar-butaca"
          (click)="GuardarEditModalAsiento()">Guardar</button>
      </div>
    </div>
  </div>
</div>