import { Component, OnInit, NgZone, ApplicationRef, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let tinymce: any;
declare let toastr: any;
declare let ICAL: any;
declare let L: any;

@Component({
  selector: 'app-edit-actividad',
  templateUrl: './edit-actividad.component.html',
  styleUrls: ['./edit-actividad.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditActividadComponent implements OnInit {
  public title:string = '';
  public params:any = {
    page_index: 1,
    page_size: 50,
    total: 0,
    pages: 0,
    filtro: null,
    order: null,
    dir: null,
  };
  public data:any = {
    id: '',
    adultos: 2,
    ninos: 0,
    num_minimo_noches: 2,
    lunes: true,
    martes: true,
    miercoles: true,
    jueves: true,
    viernes: true,
    sabado: true,
    domingo: true,
    permanente: true,
    tarifas: [],
    tarifas_grupos: [],
    web: 1,
    gestion_asientos: 0,
    categorias: [],
    caracteristicas: [],
    precio_persona_grupo: 0,
    asientos: []
  };
  public aforo = [];
  public aforoV2 = [];
  public diaAforo = {
    id: '',
    id_actividad: '',
    aforo_total: '',
    aforo_restante: '',
    fecha: '',
    fecha_format: '',
    timestamp: '',
    ocultar: '',
    aforo_temp: ''
  };
  public imgsubir:string = '';
  public dataitem:any = {
    en: {}, fr: {}, pt: {}
  };
  public temporadas = [];
  public fechas_bloqueadas = [];
  public fechas_reservadas = [];
  public fechas_bloqueadas_booking = [];
  public fechas_bloqueadas_airbnb= [];
  public sesiones = [];
  public asientos:any = [];
  public asientos_temp:any = [];
  public sesion:any = '';
  public asiento:any = {
    nombre: '',
    num_asientos: '',
    en: {}, fr: {}, pt: {}
  };
  public nuevoPrecioEspecial = {
    fechaInicio:    '',
    fechaFin:       '',
    precioAdulto:   0,
    precioNinio:    0,
    sesion:         '',
    aforo:          0,
    sesionesArray:  [],
    sesiones:       '',
    concepto:       '',
    cierre_dias:     ''
  }
  public editarPrecioEspecial = {
    fecha_inicio:    '',
    fecha_fin:       '',
    precio_adultos:   0,
    precio_ninios:    0,
    sesion:         '',
    aforo:          0,
    sesionesArray:  [],
    sesiones:       '',
    concepto:       '',
    cierre_dias:     ''
  }
  public reservasDay:any = {};
  public categoriasUsadas:any = [];
  public srcGMap = '';
  public diasCerrados = [];
  public arrRangosFechasBloqueo:any = {};
  public inputFechaInicioBloqueo = '';
  public inputFechaFinBloqueo = '';
  public vehiculos:any = [];
  public vehiculosSelect:any = [];
  public idsVehiculosSelect:any = [];
  public idsDelVehiculosSelect:any = [];

  //Variables tarifas
  public tempNombreTarifa = '';
  public tempPrecioTarifa = '';
  public tempNumeroPersonasTarifa = '';
  public sesionSelect = '';

  public idioma:string = 'es';

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals,
    public sanitizer: DomSanitizer,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
    $.checkrolredirect(3); //No clientes
    // if (window.location.pathname.indexOf('add') != -1) this.globals.passData = null;
    // if (window.location.pathname.indexOf('edit') != -1 && !Object.keys(this.globals.passData).length) window.location.href = window.location.href.replace('edit', 'add');
    // if (this.globals.passData && this.globals.passData != '' && Object.keys(this.globals.passData).length > 0) this.data = this.globals.passData;
    const id_actividad = this.route.snapshot.paramMap.get('id_actividad');
    if (!id_actividad || id_actividad == '') {
      this.title = 'Nueva actividad';
    } else {
      this.globals.Api('/eve-actividades', {id: id_actividad}).subscribe(data => {
        if (!data || !data.length) {
          return;
        }
        this.data = data[0];
        if(!this.data.tarifas) this.data.tarifas = [];
        this.title = this.data.nombre;
        if (this.data.localizacion_maps && this.data.localizacion_maps != '') {
          this.data.mapa_iframe = this.sanitizer.bypassSecurityTrustResourceUrl(`
            https://maps.google.com/maps?width=100%25&height=600&hl=es&q=${this.data.localizacion_maps}+(dsf)&t=&z=14&ie=UTF8&iwloc=B&output=embed
          `);
        }
        var arrDiasSemana = this.data.dias_evento.split(',');
        arrDiasSemana.forEach(item => {
          switch(item){
            case 'lunes':{
              this.data.lunes = true;
              break;
            }
            case 'martes':{
              this.data.martes = true;
              break;
            }
            case 'miercoles':{
              this.data.miercoles = true;
              break;
            }
            case 'jueves':{
              this.data.jueves = true;
              break;
            }
            case 'viernes':{
              this.data.viernes = true;
              break;
            }
            case 'sabado':{
              this.data.sabado = true;
              break;
            }
            case 'domingo':{
              this.data.domingo = true;
              break;
            }
            default: break;
          }
        });
        if (!this.data.en) this.data.en = {};
        if (!this.data.fr) this.data.fr = {};
        if (!this.data.pt) this.data.pt = {};
        // this.sesiones = this.data.sesiones.split(',');
        // this.sesiones.pop();
        this.loadAsientos();
        this.LoadSesiones();
        this.LoadDiasEspeciales();
        this.CargarCalendario();
        this.CargarRangosBloqueos();
        this.CargarVehiculos();
        this.CargarVehiculosSelect();
        this.cargarTarifasPorPersona();
        const date = new Date();
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let dateFormatted = `${year}-${month}-${day}`;
        let dateFormatted2 = `${day}/${month}/${year}`;
        this.LoadAforoByDay(dateFormatted,dateFormatted2);
        $('.fc-today').click();
      });
    }
    if (!this.data.en) this.data.en = {};
    if (!this.data.fr) this.data.fr = {};
    if (!this.data.pt) this.data.pt = {};
    this.loadCategoriasUsadas();
    // if (this.globals.GetData('temporadas')) this.temporadas = this.globals.GetData('temporadas');
    // this.globals.Api('/alo-temporadas').subscribe(data => {
    //   if (!data || !data.length) {
    //     this.temporadas = [];
    //     this.globals.SaveData([], 'temporadas');
    //     return;
    //   }
    //   this.globals.SaveData(data, 'temporadas');
    //   this.temporadas = data;
    // });
    tinymce.remove();
    $(document).on('change', '.select-idioma', () => {
      this.ChangeIdioma();
    });
    setTimeout(() => {
      let move = function (arr, from, to) {
        arr.splice(to, 0, arr.splice(from, 1)[0]);
      };
      let that = this;
      tinymce.init({
        selector:'.text-editor',
        height: 500,
        plugins: 'print preview powerpaste casechange importcss tinydrive searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount tinymcespellchecker a11ychecker imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap tinycomments mentions quickbars linkchecker emoticons advtable',
        toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
        language: 'es'
      });
      $('#tab-imagenes .gallery').sortable({
        stop: (event, ui) => {
          let arr = [];
          $('#tab-imagenes .gallery .ui-sortable-handle').each(function() {
            let id = $(this).attr('itemid');
            if (!id || id == '') return;
            arr.push(id);
          });
          if (!that.data.id || that.data.id == '') return;
          that.globals.Api('/eve-actividades-imagenes-order', {id_actividad: that.data.id, ordenid: arr}).subscribe(data => {
            toastr.success(`Orden cambiado correctamente`, 'Orden cambiado');
          });
        }
      });
      $('#tab-general .gallery').sortable({
        stop: (event, ui) => {
          let arr = [];
          $('#tab-general .gallery .ui-sortable-handle').each(function() {
            let id = $(this).attr('itemid');
            if (!id || id == '') return;
            arr.push(id);
          });
          if (!that.data.id || that.data.id == '') return;
          that.globals.Api('/eve-actividades-imagenes-order', {id_actividad: that.data.id, ordenid: arr}).subscribe(data => {
            toastr.success(`Orden cambiado correctamente`, 'Orden cambiado');
          });
        }
      });
      $('.table-caracteristicas table tbody').sortable({
        stop: (event, ui) => {
          let arr = [];
          $('.table-caracteristicas table tbody tr').each(function() {
            let id = $(this).attr('itemid');
            if (!id || id == '') return;
            arr.push(id);
          });
          if (!that.data.id || that.data.id == '') return;
          that.globals.Api('/eve-actividades-caracteristicas-order', {id_actividad: that.data.id, ordenid: arr}).subscribe(data => {
            toastr.success(`Orden cambiado correctamente`, 'Orden cambiado');
          });
        }
      });
      $('.table-categorias table tbody').sortable({
        stop: (event, ui) => {
          let arr = [];
          $('.table-categorias table tbody tr').each(function() {
            let id = $(this).attr('itemid');
            if (!id || id == '') return;
            arr.push(id);
          });
          if (!that.data.id || that.data.id == '') return;
          that.globals.Api('/eve-actividades-categorias-order', {id_actividad: that.data.id, ordenid: arr}).subscribe(data => {
            toastr.success(`Orden cambiado correctamente`, 'Orden cambiado');
          });
        }
      });
      // $('.table-servicios table tbody').sortable({
      //   stop: (event, ui) => {
      //     let arr = [];
      //     $('#tab-servicios table tbody tr').each(function() {
      //       let id = $(this).attr('itemid');
      //       if (!id || id == '') return;
      //       arr.push(id);
      //     });
      //     if (!that.data.id || that.data.id == '') return;
      //   }
      // });
      $('#tab-tarifas table tbody').sortable({
        stop: (event, ui) => {
          let arr = [];
          $('#tab-tarifas table tbody tr').each(function() {
            let id = $(this).attr('itemid');
            if (!id || id == '') return;
            arr.push(id);
          });
          if (!that.data.id || that.data.id == '') return;
        }
      });
      this.LoadFechasBloqueadas();
    }, 300);
    if(this.data.dias_evento != null && this.data.id != ''){
    }
    if (this.data.ubicacion && this.data.localidad && this.data.provincia) {
      this.data.link_mapa = this.sanitizer.bypassSecurityTrustResourceUrl(`https://maps.google.com/maps?q=${this.globals.CleanedString(this.data.ubicacion.replace('c/', 'calle').replace('C/', 'calle'), '%20')}${this.globals.CleanedString(this.data.localidad, '%20')}${this.globals.CleanedString(this.data.provincia, '%20')}&t=&z=14&ie=UTF8&iwloc=&output=embed`);
    }
  }
  cargarTarifasPorPersona(){
    this.globals.Api('/eve-actividades-tarifas-por-persona-actividad', {id: this.data.id}).subscribe(data => {
      if (!data || !data.length) return;
      this.data.tarifas = data;
      setTimeout(() => {
          $('#table-precio-por-persona tbody').sortable({
            handle: '.btn-sort',
            stop: (event:any, ui:any) => {
              let arr:any = [];
              let elements = $(event.target).find('tr');
              let id_tarifa = $(event.target).attr('itemid');
              let tarifa = this.data.tarifas.find((el:any) => el.id == id_tarifa);
              if (!tarifa) return;
              if (!elements || !elements.length) return;
              let index = 0;
              for (let item of elements) {
                let arritem = tarifa.tarifas.find((el:any) => el.id == item.getAttribute('itemid'));
                if (!arritem) continue;
                arr.push(arritem);
              }
              if (arr && arr.length) tarifa.tarifas = JSON.parse(JSON.stringify(arr));
              this.appref.tick();
            }
          });
      }, 500);
    });
  }
  loadAsientos(){
    this.globals.Api('/eve-actividades-asientos-actividad', {id: this.data.id}).subscribe(data => {
      if (!data || !data.length) return;
      this.asientos = data;
      this.asientos.forEach(asiento => {
        asiento.tarifas.forEach(tarifa => {
          tarifa.precio = JSON.parse(tarifa.precio);
        });
      });
      // console.log(this.asientos);
      setTimeout(() => {
        for (let i = 0; i < this.asientos.length; i++){
          $('#tab-tarifas .table-'+this.asientos[i].id+' tbody').sortable({
            handle: '.btn-sort',
            stop: (event:any, ui:any) => {
              let arr:any = [];
              let elements = $(event.target).find('tr');
              let id_asiento = $(event.target).attr('itemid');
              let asiento = this.asientos.find((el:any) => el.id == id_asiento);
              if (!asiento) return;
              if (!elements || !elements.length) return;
              let index = 0;
              for (let item of elements) {
                let arritem = asiento.tarifas.find((el:any) => el.id == item.getAttribute('itemid'));
                if (!arritem) continue;
                arr.push(arritem);
              }
              if (arr && arr.length) asiento.tarifas = JSON.parse(JSON.stringify(arr));
              this.appref.tick();
              // console.log(this.asientos);
            }
          });
        }
      }, 500);
    });
  }
  LoadSesiones(){
    this.globals.Api('/eve-actividades-sesiones-actividad', {id: this.data.id}).subscribe(data => {
      if (!data || !data.length) return;
      this.sesiones = data;
      this.sesionSelect = data[0].sesiones;
    });
  }
  CargarCalendario() {
    var date = new Date();
    var d = date.getDate();
    var m = date.getMonth();
    var y = date.getFullYear();
    let that = this;
    var heightCalendar = 425;
    if($(window).width() <= 1366 && $(window).width() > 1025){
      heightCalendar = 380;
    }
    if($(window).width() <= 500){
      heightCalendar = 340;
    }
    $('#calendar').fullCalendar({
        header: {
            left: 'title',
            center: '',
            right: 'today month prev,next'
        },
        defaultView: 'month',
        defaultDate: new Date(),
        locale: 'es',
        allDaySlot: false,
        editable: false,
        droppable: false,
        selectable: false,
        duration: false,
        contentHeight: heightCalendar,
        // //timeFormat: 'H:mm',
        // minTime: '08:00:00',
        // maxTime: '22:00:00',
        viewRender: (view, element) => {
          this.params.fecha_inicio = view.start.format('YYYY-MM-DD');
          this.params.fecha_fin = view.end.format('YYYY-MM-DD');
          this.CargarEventosCalendario();
          $('.calendario-listado-reservas .fc-day-top').click(function(){
            $('.calendario-listado-reservas .fc-day-top').css('background-color','unset');
            $('.calendario-listado-reservas .fc-day-top').css('color','unset');
            $(this).css('background-color','#7AAC3F');
            $(this).css('color','white');
          });
        },
        eventRender: function(event, element) {
        },
        eventClick: function(info) {
        },
        dayClick: (date, allDay, jsEvent, view)=>{
          var tempDate = new Date(date).toDateString();
          var dateFormatted = moment(tempDate).format('YYYY-MM-DD');
          var dateFormatted2 = moment(tempDate).format('DD/MM/YYYY');
          this.LoadAforoByDay(dateFormatted,dateFormatted2);
        }
    });
  }
  CargarEventosCalendario(){
    $('#calendar').fullCalendar('removeEvents');
    var calendar = $('#calendar').fullCalendar('getCalendar');
    var view = calendar.view;
    var start = view.start._d;
    var end = view.end._d;
    let params = {
      fecha_inicio: moment(start).format('YYYY-MM-DD'),
      fecha_fin: moment(end).format('YYYY-MM-DD')
    };
    this.globals.Api('/eve-actividades-dias-cerrados-actividad', {id_actividad: this.data.id, fecha_inicio: params.fecha_inicio, fecha_fin: params.fecha_fin}).subscribe(data => {
      if (!data || !data.length) return;
      let daysLock = [];
      if (this.globals.GetData('eventos-calendario-reservas')) {
        daysLock = this.globals.GetData('eventos-calendario-reservas');
        $('#calendar').fullCalendar('renderEvents', daysLock, true);
      }
      for (let item of data) {
        let start = new Date(item.fecha);
        let end = moment(start);
        let color = 'black';
        let cursor = 'pointer';
        let textColor = '#FFF';
        let display = 'block';
        // let className = 'day-red';
        let dayLock = {
          id: item.id,
          className: '',
          //title: `${item.aforo_restante}/${item.aforo_total}`,
          title: '',
          duration: false,
          description: `
            <div style="cursor: ${cursor}">
              <div><b><i class="fa fa-lock" style="display: ${display}; float: left; margin-right: 5px;"> <span style="color: white;"><i class="icofont-check-circled"></i></span></div>
            </div>
          `,
          start: start,
          end: end,
          data: item,
          color: color,
          //color: 'transparent',
          textColor: textColor,
          //textColor: 'transparent',
          disabled: false,
          tipo: 'documento'
        };
        daysLock.push(dayLock);
        // $('#calendar').fullCalendar('renderEvent', event, true);
      }
      $('#calendar').fullCalendar('renderEvents', daysLock, true);
      // $('#calendar').fullCalendar('refetchEvents');
    });

    // this.appref.tick();
  }
  LoadAforoByDay(fecha:any, fecha2:any){
    this.globals.Api('/eve-actividades-aforo-v2-ficha', {id_actividad: this.data.id, fecha: fecha}).subscribe(data => {
      /* if (!data || !data.length) return;*/
      // console.log(data);
      this.aforoV2 = data[0];
      this.reservasDay = data[1];
      var diasCerrados = data[2];
      var diasEspeciales = data[3];
      if(diasEspeciales.length == 0){
        for(var i = 0; i < this.aforoV2.length; i++){
          this.aforoV2[i].aforo_disponible = this.aforoV2[i].aforo_predeterminado;
          this.aforoV2[i].sesion = this.aforoV2[i].sesiones;
          this.aforoV2[i].fecha = fecha
          this.aforoV2[i].fecha_format = fecha2
          this.aforoV2[i].personas = 0;
          this.aforoV2[i].porcentage = 100;
          this.aforoV2[i].cerrado = false;
          for(var j = 0; j < this.reservasDay.length; j++){
            if(this.aforoV2[i].sesiones == this.reservasDay[j].sesion){
              var plazasLibres = this.aforoV2[i].aforo_predeterminado - this.reservasDay[j].personas;
              this.aforoV2[i].porcentage = (plazasLibres * 100) / this.aforoV2[i].aforo_predeterminado;
              this.aforoV2[i].aforo_disponible = plazasLibres;
              this.aforoV2[i].personas = this.aforoV2[i].aforo_predeterminado - plazasLibres;
              this.aforoV2[i].sesion = this.reservasDay[j].sesion;
              this.aforoV2[i].fecha_format = this.reservasDay[j].fecha_format;
            }
          }
          if(diasCerrados != null){
            for(var k = 0; k < diasCerrados.length; k++){
              if(this.aforoV2[i].sesion == diasCerrados[k].sesion){
                this.aforoV2[i].cerrado = true;
              }
            }
          }
        }
      }else{
        this.aforoV2 = [];
        var arrSesiones = diasEspeciales[0].sesiones.split(',');
        //console.log(arrSesiones);
        //console.log(arrSesiones.lenght);
        arrSesiones.forEach(sesion => {
          let aforo:any = {};
          aforo.aforo_predeterminado = diasEspeciales[0].aforo;
          aforo.aforo_disponible = diasEspeciales[0].aforo;
          aforo.sesion = sesion;
          aforo.fecha = fecha
          aforo.fecha_format = fecha2
          aforo.personas = 0;
          aforo.porcentage = 100;
          aforo.cerrado = false;
          // //console.log(aforo);
          for(var j = 0; j < this.reservasDay.length; j++){
            if(sesion == this.reservasDay[j].sesion){
              var plazasLibres = aforo.aforo_predeterminado - this.reservasDay[j].personas;
              aforo.porcentage = (plazasLibres * 100) / aforo.aforo_predeterminado;
              aforo.aforo_disponible = plazasLibres;
              aforo.personas = aforo.aforo_predeterminado - plazasLibres;
              aforo.sesion = this.reservasDay[j].sesion;
              aforo.fecha_format = this.reservasDay[j].fecha_format;
            }
          }
          if(diasCerrados != null){
            for(var k = 0; k < diasCerrados.length; k++){
              if(aforo.sesion == diasCerrados[k].sesion){
                aforo.cerrado = true;
              }
            }
          }
          this.aforoV2.push(aforo);
        });
        //console.table(this.aforoV2);
      }
      //console.table(data);
    }, error => {
      toastr.error('Error al cargar el aforo calendario','Error');
    });
  }
  loadCategoriasUsadas(){
    this.globals.Api('/eve-actividades-categorias-usadas').subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Se ha producido un error', 'error');return;
      }
      this.categoriasUsadas = data;
      //console.log(this.categoriasUsadas);
    });
  }

  LoadDiasEspeciales(){
    this.globals.Api('/eve-actividades-dias-especiales', {id_actividad: this.data.id}).subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Se ha producido un error', 'error');return;
      }
      this.data.diasEspeciales = data;
      for(var i = 0; i < this.data.diasEspeciales.length; i++){
        this.data.diasEspeciales[i].sesionesFormat = this.data.diasEspeciales[i].sesiones.replaceAll(","," ");
      }
      //console.table(data);
    }, error => {
      toastr.error('No se han los días especiales','Error');
    });
  }

  LoadFechasBloqueadas() {
    function getDates(startDate, stopDate) {
      let dateArray = [];
      let currentDate = new Date(startDate);
      stopDate = new Date(stopDate);
      while (currentDate <= stopDate) {
        dateArray.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }
      return dateArray;
    }

    this.fechas_bloqueadas = [];
    if (this.data.fechas_bloqueadas) {
      for (let item of this.data.fechas_bloqueadas) {
        if (!item || !item.fecha_format2) continue;
        this.fechas_bloqueadas.push(item.fecha_format2);
      }
    }

    if (this.data.id && this.data.id != '') {
      this.globals.Api('/get-ics/'+this.data.id).subscribe(data => {
        //console.log(data);
        if (!data) return;
        this.fechas_bloqueadas_airbnb = [];
        if (data.ics_airbnb && data.ics_airbnb != '') {
          let jcalData = ICAL.parse(data.ics_airbnb);
          let vcalendar = new ICAL.Component(jcalData);
          let vevents = vcalendar.getAllSubcomponents("vevent");
          for (let event of vevents) {
            let uid = event.getFirstPropertyValue('uid');
            let start = event.getFirstPropertyValue('dtstart');
            let end = event.getFirstPropertyValue('dtend');
            let summary = event.getFirstPropertyValue('summary');
            let hora = moment(new Date(start.year, start.month-1, start.day, start.hour, start.minute)).format('HH:mm');
            start = moment(new Date(start.year, start.month-1, start.day)).format('YYYY-MM-DD');
            end = moment(new Date(end.year, end.month-1, end.day)).format('YYYY-MM-DD');
            let dates = getDates(start, end);
            // //console.log(uid);
            for (let fecha of dates) {
              fecha = moment(fecha).format('YYYY-MM-DD');
              this.fechas_bloqueadas_airbnb.push({
                fecha: fecha,
                hora: hora,
                titulo: summary
              });
            }
          }
        }
        this.appref.tick();
        $('#calendario-cierres').datepicker('update');
      });
    }

    /*Bloqueos*/
    $('#calendario-cierres').datepicker({
      todayBtn: "linked",
      keyboardNavigation: false,
      changeMonth: true,
      changeYear: true,
      showButtonPanel: true,
      weekStart: 1,
      language: 'es',
      format: "dd/mm/yyyy",
      beforeShowDay: date => {
        let fecha = moment(date).format('YYYY-MM-DD');
        if (this.fechas_bloqueadas.indexOf(fecha) != -1) {
          return {classes: 'bloqueada'};
        }
        if (this.fechas_bloqueadas_airbnb.find(el => el.fecha == fecha)) {
          let item = this.fechas_bloqueadas_airbnb.find(el => el.fecha == fecha);
          return {classes: 'bloqueada airbnb', tooltip: item.titulo+'<br>Hora: <b>'+item.hora+'</b>'};
        }
      }
    });
    let that = this;
    $('body').on({
      mouseenter: function() {
        $(this).popover({
          content: $(this).attr('title'),
          placement: 'auto',
          container: '.datepicker',
          title: 'Reserva',
          html: true
        }).popover('show');
        $('.popover-title').html('Reserva');
      },
      mouseleave: function() {
        $(this).popover('hide')
      }
    }, '.datepicker .day.bloqueada');
  }

  ChangeIdioma() {
    $('#txtdescripcion + div,#txtdescripcion-en + div,#txtdescripcion-fr + div,#txtdescripcion-pt + div').hide();
    let id = 'txtdescripcion';
    if (this.idioma != 'es') id += '-' + this.idioma;
    $('#' + id + ' + div').show();
  }

  SelecImagen() {
    let input = this.globals.FileInput('image/*', event => {
      this.globals.Base64Image(input, base64 => {
        this.data.imagen = base64;
        this.imgsubir = base64;
        this.appref.tick();
      });
    });
    input.click();
  }
  BorrarImagen(event:any) {
    event.stopPropagation();
    swal({
      title: "¿Borrar imagen principal del alojamiento?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      if (!this.data.id || this.data.id == '') {
        this.imgsubir = '';
        this.data.imagen = '';
        return;
      }
      this.globals.Api('/borrar-imagen-evento', {id: this.data.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.imgsubir = '';
        this.data.imagen = '';
        this.appref.tick();
      });
    });
  }

  Cancelar() {
    this.ngZone.run(() => this.router.navigateByUrl('/actividades')).then();
  }
  Guardar() {
    this.data.descripcion = tinymce.EditorManager.get('txtdescripcion').getContent();
    if (tinymce.EditorManager.get('txtdescripcion-en')) this.data.en.descripcion = tinymce.EditorManager.get('txtdescripcion-en').getContent();
    if (tinymce.EditorManager.get('txtdescripcion-fr')) this.data.fr.descripcion = tinymce.EditorManager.get('txtdescripcion-fr').getContent();
    if (tinymce.EditorManager.get('txtdescripcion-pt')) this.data.pt.descripcion = tinymce.EditorManager.get('txtdescripcion-pt').getContent();
    // this.data.informacion_adicional = tinymce.EditorManager.get('txtinfo').getContent();
    if (
      !this.data.nombre
      //|| !this.data.adultos
      || this.data.nombre == ''
      //|| this.data.adultos == ''
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }
    // if (!this.data.num_minimo_noches
    //   || isNaN(this.data.num_minimo_noches)
    //   || this.data.num_minimo_noches == ''
    //   || parseInt(this.data.num_minimo_noches) < 1) {
    //   swal('Número mínimo no válido', 'El número de noches mínimas para reservar no es válido', 'error');return;
    // }
    if (!this.data.gestion_asientos){
      if (!this.data.aforo_predeterminado
        || isNaN(this.data.aforo_predeterminado)
        || this.data.aforo_predeterminado == ''
        || parseInt(this.data.aforo_predeterminado) < 1) {
        swal('Número mínimo no válido', 'El número de aforo no es válido', 'error');return;
      }
    }
    this.data.tipo = 'actividad';

    this.data.img = null;
    if (this.imgsubir != '') this.data.img = this.imgsubir;
    this.title = this.data.nombre;

    if (!this.data.id || this.data.id == '') {
      this.data.sesiones = this.sesiones;
      this.data.asientos = this.asientos;
      $.buttonloading('.btn-guardar', 'Enviando');
      this.globals.Api('/eve-actividades-add', this.data).subscribe(data => {
        $.buttoncancelloading('.btn-guardar');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.Cancelar();
      }, error => {
        $.buttoncancelloading('.btn-guardar');
      });
    } else {
      $.buttonloading('.btn-guardar', 'Enviando');
      this.data.sesiones = this.sesiones;
      this.data.asientos = this.asientos;
      this.globals.Api('/eve-actividades-edit', this.data).subscribe(data => {
        $.buttoncancelloading('.btn-guardar');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error al editar', 'error');return;
        }
        // swal('Guardado', 'Datos guardados correctamente', 'success');
        toastr.success(`Datos guardados correctamente`, 'Guardado');
        //this.LoadFechasBloqueadas();
      }, error => {
        $.buttoncancelloading('.btn-guardar');
      });
    }
  }

  //Galeria de imagenes
  AddImagen() {
    let input = this.globals.FileInput('image/*', event => {
      this.globals.Base64Image(input, base64 => {
        // this.data.imagen = base64;
        // this.imgsubir = base64;
        this.appref.tick();
        if (!this.data.id || this.data.id == '') {
          if (!this.data.imagenes) this.data.imagenes = [];
          this.data.imagenes.push({imagen: base64});
          return;
        }
        $.buttonloading('btn-add-imagen', 'Enviando');
        this.globals.Api('/eve-actividades-imagenes-add', {id_actividad: this.data.id, imagen: base64}).subscribe(data => {
          $.buttoncancelloading('btn-add-imagen');
          if (!data || data.error) {
            swal('Error', 'Se ha producido un error', 'error');return;
          }
          if (!this.data.imagenes) this.data.imagenes = [];
          this.data.imagenes.push({id: data.value, imagen: base64});
        }, error => {
          $.buttoncancelloading('btn-add-imagen');
        });

      });
    });
    input.click();
  }
  DelImagen(event:any, item:any) {
    event.stopPropagation();
    if (!item || !item.id) {
      this.data.imagenes.splice(this.data.imagenes.findIndex(el => el.imagen == item.imagen), 1);
      return;
    }
    if (!this.data.id || this.data.id == '') return;
    swal({
      title: "¿Borrar imagen?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/eve-actividades-imagenes-del', {id_actividad: this.data.id, id: item.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.data.imagenes.splice(this.data.imagenes.findIndex(el => el.id == item.id), 1);
        this.appref.tick();
      });
    });
  }

  //Caracteristicas
  CaracteristicaDefecto(icon, label) {
    this.dataitem.caracteristica = label;
    this.dataitem.icono = icon;
    $('.cmb-icono-caracteristica .chosen-single span').html(`<i class="${icon}"></i> ${icon}`);
  }
  AddCaracteristica() {
    this.idioma = 'es';
    $('#modal-crear-caracteristica').modal('show');
    $('#modal-crear-caracteristica .modal-title').html('Nueva característica');
    Object.keys(this.dataitem).forEach(item => {this.dataitem[item] = ''});
    this.dataitem.en = {};
    this.dataitem.fr = {};
    this.dataitem.pt = {};
    setTimeout(() => {
      $('#modal-crear-caracteristica input[type="text"]')[0].focus();
    }, 500);
  }
  EditCaracteristica(item:any) {
    $('#modal-crear-caracteristica').modal('show');
    $('#modal-crear-caracteristica .modal-title').html('Editar característica');
    this.dataitem = item;
    if (!this.dataitem.en) this.dataitem.en = {};
    if (!this.dataitem.fr) this.dataitem.fr = {};
    if (!this.dataitem.pt) this.dataitem.pt = {};
    setTimeout(() => {
      $('#modal-crear-caracteristica input[type="text"]')[0].focus();
    }, 500);
  }
  DelCaracteristica(item:any) {
    if (!item || !item.id) {
      this.data.caracteristicas.splice(this.data.caracteristicas.findIndex(el => el.caracteristica == item.caracteristica), 1);
      return;
    }
    if (!this.data.id || this.data.id == '') return;
    swal({
      title: "¿Borrar característica?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/eve-actividades-caracteristicas-del', {id_actividad: this.data.id, id: item.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.data.caracteristicas.splice(this.data.caracteristicas.findIndex(el => el.id == item.id), 1);
        this.appref.tick();
      });
    });
  }
  GuardarCaracteristica() {
    if (
      this.dataitem.caracteristica == ''
      || !this.dataitem.caracteristica
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }

    if (!this.data.id || this.data.id == '') {
      if (!this.data.caracteristicas) this.data.caracteristicas = [];
      this.data.caracteristicas.push({caracteristica: this.dataitem.caracteristica, icono: this.dataitem.icono});
      $('#modal-crear-caracteristica').modal('hide');
      return;
    }

    $.buttonloading('btn-guardar-caracteristica', 'Enviando');
    this.dataitem.id_actividad = this.data.id;
    if (!this.dataitem.id || this.dataitem.id == '') {
      this.globals.Api('/eve-actividades-caracteristicas-add', this.dataitem).subscribe(data => {
        $.buttoncancelloading('btn-guardar-caracteristica');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        if (!this.data.caracteristicas) this.data.caracteristicas = [];
        this.data.caracteristicas.push({
          id: data.value,
          caracteristica: this.dataitem.caracteristica,
          en: {caracteristica: this.dataitem.caracteristica},
          fr: {caracteristica: this.dataitem.caracteristica},
          pt: {caracteristica: this.dataitem.caracteristica}
        });
        $('#modal-crear-caracteristica').modal('hide');
      }, error => {
        $.buttoncancelloading('btn-guardar-caracteristica');
      });
    } else {
      this.globals.Api('/eve-actividades-caracteristicas-edit', this.dataitem).subscribe(data => {
        $.buttoncancelloading('btn-guardar-caracteristica');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        $('#modal-crear-caracteristica').modal('hide');
      }, error => {
        $.buttoncancelloading('btn-guardar-caracteristica');
      });
    }
  }

  //Categorías
  CategoriaDefecto(icon, label) {
    this.dataitem.categoria = label;
    this.dataitem.icono = icon;
    $('.cmb-icono-categoria .chosen-single span').html(`<i class="${icon}"></i> ${icon}`);
  }
  AddCategoria() {
    this.idioma = 'es';
    $('#modal-crear-categoria').modal('show');
    $('#modal-crear-categoria .modal-title').html('Nueva categoria');
    Object.keys(this.dataitem).forEach(item => {this.dataitem[item] = ''});
    if (!this.dataitem.en) this.dataitem.en = {};
    if (!this.dataitem.fr) this.dataitem.fr = {};
    if (!this.dataitem.pt) this.dataitem.pt = {};
    setTimeout(() => {
      $('#modal-crear-categoria input[type="text"]')[0].focus();
    }, 500);
  }
  EditCategoria(item:any) {
    $('#modal-crear-categoria').modal('show');
    $('#modal-crear-categoria .modal-title').html('Editar categoria');
    this.dataitem = item;
    if (!this.dataitem.en) this.dataitem.en = {};
    if (!this.dataitem.fr) this.dataitem.fr = {};
    if (!this.dataitem.pt) this.dataitem.pt = {};
    setTimeout(() => {
      $('#modal-crear-categoria input[type="text"]')[0].focus();
    }, 500);
  }
  DelCategoria(item:any) {
    if (!item || !item.id) {
      this.data.categorias.splice(this.data.categorias.findIndex(el => el.categoria == item.categoria), 1);
      return;
    }
    if (!this.data.id || this.data.id == '') return;
    swal({
      title: "¿Borrar categoria?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/eve-actividades-categorias-del', {id_actividad: this.data.id, id: item.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.data.categorias.splice(this.data.categorias.findIndex(el => el.id == item.id), 1);
        this.appref.tick();
        this.loadCategoriasUsadas();
      });
    });
  }
  GuardarCategoria() {
    if (
      this.dataitem.categoria == ''
      || !this.dataitem.categoria
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }

    if (!this.data.id || this.data.id == '') {
      if (!this.data.categorias) this.data.categorias = [];
      this.data.categorias.push({categoria: this.dataitem.categoria, icono: this.dataitem.icono});
      $('#modal-crear-categoria').modal('hide');
      this.loadCategoriasUsadas();
      return;
    }

    $.buttonloading('btn-guardar-categoria', 'Enviando');
    this.dataitem.id_actividad = this.data.id;
    if (!this.dataitem.id || this.dataitem.id == '') {
      this.globals.Api('/eve-actividades-categorias-add', this.dataitem).subscribe(data => {
        $.buttoncancelloading('btn-guardar-categoria');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        if (!this.data.categorias) this.data.categorias = [];
        this.data.categorias.push({
          id: data.value, categoria: this.dataitem.categoria,
          en: {categoria: this.dataitem.categoria},
          fr: {categoria: this.dataitem.categoria},
          pt: {categoria: this.dataitem.categoria}
        });
        $('#modal-crear-categoria').modal('hide');
        this.loadCategoriasUsadas();
      }, error => {
        $.buttoncancelloading('btn-guardar-categoria');
      });
    } else {
      this.globals.Api('/eve-actividades-categorias-edit', this.dataitem).subscribe(data => {
        $.buttoncancelloading('btn-guardar-categoria');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        $('#modal-crear-categoria').modal('hide');
        this.loadCategoriasUsadas();
      }, error => {
        $.buttoncancelloading('btn-guardar-categoria');
      });
    }
  }

  //Sesiones
  AddSesion() {
    $('#modal-crear-sesion').modal('show');
    $('#modal-crear-sesion .modal-title').html('Nueva sesión');
    Object.keys(this.dataitem).forEach(item => {this.dataitem[item] = ''});
    setTimeout(() => {
      // $('#modal-crear-sesion input[type="text"]')[0].focus();
    }, 500);
  }
  GuardarSesion() {
    if (this.sesion == '') {
      swal('Complete campos', 'Por favor, rellene el campo para continuar', 'error');return;
    }
    if (!this.data.id || this.data.id == '') {
      this.sesiones.push({sesiones: this.sesion});
      this.sesiones.sort(this.compare);
      this.data.sesiones = this.sesiones;
      $('#modal-crear-sesion').modal('hide');
      if(this.sesionSelect == '') this.sesionSelect = this.sesion
      this.data.asientos.forEach(asiento => {
        asiento.precio.push({sesion: this.sesion, precio: 0});
      });
      return;
    }
    this.sesiones.push({sesiones: this.sesion});
    $.buttonloading('btn-guardar-sesion', 'Enviando');
    this.globals.Api('/eve-actividades-sesiones-add', {id_actividad: this.data.id, sesiones: this.sesiones}).subscribe(data => {
      $.buttoncancelloading('btn-guardar-sesion');
      if (!data || data.error) {
        swal('Error', 'Se ha producido un error', 'error');return;
      }
      $('#modal-crear-sesion').modal('hide');
      if(this.data.gestion_asientos){
        this.asientos.forEach(asiento => {
          asiento.tarifas.forEach(tarifa => {
            tarifa.precio.push({sesion: this.sesion, precio: 0});
          });
        });
        this.Guardar();
      }
    }, error => {
      $.buttoncancelloading('btn-guardar-sesion');
    });
  }
  DelSesion(item:any) {
    if (!item.id) {
      this.sesiones.splice(this.sesiones.findIndex(el => el == item), 1);
      this.data.asientos.forEach(asiento => {
        asiento.precio.splice(asiento.precio.findIndex(el => el.sesion == item.sesiones),1)
      });
      return;
    }
    if (!this.data.id || this.data.id == '') return;
    swal({
      title: "¿Borrar sesión?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.sesiones.splice(this.sesiones.findIndex(el => el == item), 1);
      this.globals.Api('/eve-actividades-sesiones-add', {id_actividad: this.data.id, sesiones: this.sesiones}).subscribe(data => {
        $.buttoncancelloading('btn-guardar-sesion');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        $('#modal-crear-sesion').modal('hide');
        this.asientos.forEach(asiento => {
          asiento.tarifas.forEach(tarifa => {
            tarifa.precio.splice(tarifa.precio.findIndex(el => el.sesion == item.sesiones),1)
          });
        });
        this.Guardar();
      }, error => {
        $.buttoncancelloading('btn-guardar-sesion');
      });
    });
  }

  //Servicios
  AddServicio() {
    $('#modal-crear-servicio').modal('show');
    $('#modal-crear-servicio .modal-title').html('Nuevo servicio');
    Object.keys(this.dataitem).forEach(item => {this.dataitem[item] = ''});
    setTimeout(() => {
      $('#modal-crear-servicio input[type="text"]')[0].focus();
    }, 500);
  }
  DelServicio(item:any) {
    if (!item || !item.id) {
      this.data.servicios.splice(this.data.servicios.findIndex(el => el.servicio == item.servicio), 1);
      return;
    }
    if (!this.data.id || this.data.id == '') return;
    swal({
      title: "¿Borrar servicio?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {

    });
  }
  GuardarServicio() {
    this.dataitem.icono = $('.cmb-icono-servicio .chosen-single i').attr('class');
    if (
      this.dataitem.servicio == ''
      || !this.dataitem.servicio
      || this.dataitem.icono == ''
      || !this.dataitem.icono
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }

    if (!this.data.id || this.data.id == '') {
      if (!this.data.servicios) this.data.servicios = [];
      this.data.servicios.push({servicio: this.dataitem.servicio, icono: this.dataitem.icono});
      $('#modal-crear-servicio').modal('hide');
      return;
    }

    $.buttonloading('btn-guardar-servicio', 'Enviando');
    this.dataitem.id_alojamiento = this.data.id;
  }

  //Aforo
  EditarDia(item:any){
    $('#modal-editar-dia').modal('show');
    this.diaAforo = item;
    this.diaAforo.aforo_temp = item.aforo_total;
  }
  GuardarDiaAforo(){
    if(this.diaAforo.aforo_restante == ''){
      this.diaAforo.aforo_restante = '0';
    }
    if (this.diaAforo.aforo_restante == '' || this.diaAforo.aforo_total == ''){
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }
    if(parseInt(this.diaAforo.aforo_total) < parseInt(this.diaAforo.aforo_restante)){
      var tempAforoRestante = 0;
      tempAforoRestante = parseInt(this.diaAforo.aforo_restante) - (parseInt(this.diaAforo.aforo_temp) - parseInt(this.diaAforo.aforo_total));

      if(tempAforoRestante < 0){
        this.diaAforo.aforo_restante = '0';
      }else{
        this.diaAforo.aforo_restante = tempAforoRestante.toString();
      }
    }

    $.buttonloading('btn-guardar-dia-aforo', 'Guardando...');
    this.globals.Api('/eve-actividades-aforo-edit', this.diaAforo).subscribe(data => {
      $.buttoncancelloading('btn-guardar-dia-aforo');
      if (!data || data.error) {
        swal('Error', 'Se ha producido un error', 'error');return;
      }
      toastr.success(`Datos guardados correctamente`, 'Guardado');
      $('#modal-editar-dia').modal('hide');
    }, error => {
      $.buttoncancelloading('btn-guardar-dia-aforo');
    });
  }
  BorrarAforo(){
    this.globals.Api('/eve-actividades-aforo-del', {id_actividad: this.data.id}).subscribe(data => {
      //$.buttoncancelloading('btn-guardar-dia-aforo');
      if (!data || data.error) {
        swal('Error', 'Se ha producido un error', 'error');return;
      }
      toastr.success(`Aforo restablecido`, 'Guardado');
      //$('#modal-editar-dia').modal('hide');
    }, error => {
      //$.buttoncancelloading('btn-guardar-dia-aforo');
    });
  }
  BloquearDiaAforo(item:any){
    this.globals.Api('/eve-actividades-aforo-lock', item).subscribe(data => {
      $.buttoncancelloading('btn-guardar-dia-aforo');
      if (!data || data.error) {
        swal('Error', 'Se ha producido un error', 'error');return;
      }
      toastr.success(`Día `+item.fecha_format+` bloqueado`, 'Bloqueado');
    }, error => {

    });
  }
  DesbloquearDiaAforo(item:any){
    this.globals.Api('/eve-actividades-aforo-unlock', item).subscribe(data => {
      $.buttoncancelloading('btn-guardar-dia-aforo');
      if (!data || data.error) {
        swal('Error', 'Se ha producido un error', 'error');return;
      }
      toastr.success(`Día `+item.fecha_format+` desbloqueado`, 'Desbloqueado');
    }, error => {

    });
  }
  //Días especiales
  AddDiaEspecial() {
    if(this.data.id == ''){
      swal('Aviso','Debes guardar primero la actividad para poder añadir dias y precios personalizados', 'warning');
      return;
    }
    $('#modal-crear-dia-especial').modal('show');
    $('#modal-crear-dia-especial .modal-title').html('Nueva tarifa');
    Object.keys(this.dataitem).forEach(item => {this.dataitem[item] = ''});
    setTimeout(() => {
      $('#modal-crear-dia-especial input[type="text"]')[0].focus();
    }, 500);
  }
  GuardarNuevoDiaEspecial(){
    if(this.data.id == ''){
      swal('Aviso','Debe guardar primero la actividad para poder añadir dias y precios personalizados', 'warning');
      return;
    }
    if(
      this.nuevoPrecioEspecial.fechaInicio == '' ||
      this.nuevoPrecioEspecial.fechaFin == '' ||
      this.nuevoPrecioEspecial.precioAdulto == 0 ||
      this.nuevoPrecioEspecial.precioNinio == 0 ||
      this.nuevoPrecioEspecial.aforo == 0  ||
      this.nuevoPrecioEspecial.sesionesArray.length == 0
      ){
        swal('Error','Hay campos sin rellenar', 'error');
        return;
    }
    this.nuevoPrecioEspecial.sesionesArray.forEach(element => {
      this.nuevoPrecioEspecial.sesiones += (element+',');
    });
    this.globals.Api('/eve-actividades-dia-especial-add', {id: this.data.id, diaEspecial: this.nuevoPrecioEspecial}).subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Se ha producido un error', 'error');return;
      }
      $('#odal-crear-dia-especial').modal('hide');

      this.LoadDiasEspeciales();
      toastr.success(`Añadido`, 'Día especial');
      this.nuevoPrecioEspecial.sesiones = '';
    }, error => {

    });
  }
  DelSesionModalNuevoPrecioEspecial(item:any){
    this.nuevoPrecioEspecial.sesionesArray = this.nuevoPrecioEspecial.sesionesArray.filter(e => e !== item);
  }
  addSesionNuevoDia(){
    if(this.nuevoPrecioEspecial.sesion == '') return;
    this.nuevoPrecioEspecial.sesionesArray.push(this.nuevoPrecioEspecial.sesion);
    this.nuevoPrecioEspecial.sesion = '';
    this.nuevoPrecioEspecial.sesionesArray.sort();
  }
  EditDiaEspecial(item:any) {
    $('#modal-editar-dia-especial').modal('show');
    $('#modal-editar-dia-especial .modal-title').html('Editar tarifa');
    this.editarPrecioEspecial = item;
    //console.log(this.editarPrecioEspecial);
    this.editarPrecioEspecial.sesionesArray = this.editarPrecioEspecial.sesiones.split(',');
    Object.keys(this.dataitem).forEach(item => {this.dataitem[item] = ''});
    setTimeout(() => {
      $('#modal-editar-dia-especial input[type="text"]')[0].focus();
    }, 500);
  }
  GuardarEditarDiaEspecial(){
    if(this.data.id == ''){
      swal('Error','Debe guardar primero la actividad', 'error');
      return;
    }
    if(
      this.editarPrecioEspecial.fecha_inicio == '' ||
      this.editarPrecioEspecial.fecha_fin == '' ||
      this.editarPrecioEspecial.precio_adultos == 0 ||
      this.editarPrecioEspecial.precio_ninios == 0 ||
      this.editarPrecioEspecial.aforo == 0  ||
      this.editarPrecioEspecial.sesionesArray.length == 0
      ){
        swal('Error','Hay campos sin rellenar', 'error');
        return;
    }
    this.editarPrecioEspecial.sesiones = '';
    this.editarPrecioEspecial.sesionesArray.forEach(element => {
      this.editarPrecioEspecial.sesiones += (element+',');
    });
    //console.log(this.editarPrecioEspecial);
    this.globals.Api('/eve-actividades-dia-especial-edit', {diaEspecial: this.editarPrecioEspecial}).subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Se ha producido un error', 'error');return;
      }
      $('#modal-editar-dia-especial').modal('hide');

      this.LoadDiasEspeciales();
      toastr.success(`Actualizado`, 'Día especial');
    }, error => {

    });
  }
  DelSesionModalEditarPrecioEspecial(item:any){
    this.editarPrecioEspecial.sesionesArray = this.editarPrecioEspecial.sesionesArray.filter(e => e !== item);
  }
  addSesionEditarDia(){
    if(this.editarPrecioEspecial.sesion == '') return;
    this.editarPrecioEspecial.sesionesArray.push(this.editarPrecioEspecial.sesion);
    this.editarPrecioEspecial.sesion = '';
    this.editarPrecioEspecial.sesionesArray.sort();
  }
  CancelEditarDiaEspecial(){
    $('#modal-editar-dia-especial').modal('hide');
    this.LoadDiasEspeciales();
  }
  DelDiaEspecial(id:any){
    this.globals.Api('/eve-actividades-dia-especial-del', {id: id}).subscribe(data => {

      this.LoadDiasEspeciales();
    }, error => {
      toastr.error(`Al borrar día especial`, 'Error');
    });
  }
  BloquearDiaSesion(aforo:any){
    this.globals.Api('/eve-actividades-aforo-lock-v2', {id_actividad: this.data.id, fecha: aforo.fecha, sesion: aforo.sesion}).subscribe(data => {
      this.LoadAforoByDay(aforo.fecha,aforo.fecha_format);
      this.CargarEventosCalendario();
    }, error => {
      toastr.error(`Al bloquear sesión`, 'Error');
    });
  }
  DesbloquearDiaSesion(aforo:any){
    this.globals.Api('/eve-actividades-aforo-unlock-v2', {id_actividad: this.data.id, fecha: aforo.fecha, sesion: aforo.sesion}).subscribe(data => {
      this.LoadAforoByDay(aforo.fecha,aforo.fecha_format);
      this.CargarEventosCalendario();
    }, error => {
      toastr.error(`Al desbloquear día especial`, 'Error');
    });
  }
  SetPermanente() {
  }
  AbrirMapa() {
    $('#modal-mapa').modal('show');
    $('#mapa').remove();
    $('#mapa-container').html('<div id="mapa" style="width:100%;height:400px;"></div>');
    $('#mapa').removeAttr('class');
    setTimeout(() => {
      let point = [40.036198, -6.088190];
      $.localizaciontemp = point[0]+','+point[1];
      // let m = L.DomUtil.get('mapa'); if (m != null) { m._leaflet_id = null; }
      var mymap = L.map('mapa').setView(point, 8);
      if (this.data.localizacion_maps) {
        point = [this.data.localizacion_maps.split(',')[0], this.data.localizacion_maps.split(',')[1]];
        $.localizaciontemp = point[0]+','+point[1];
        mymap.setView(point, 14);
      }
      L.tileLayer('http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
        maxZoom: 20,
        subdomains:['mt0','mt1','mt2','mt3']
      }).addTo(mymap);
      var marker = L.marker(point).addTo(mymap);
      mymap.on('click', function(e) {
        marker.setLatLng([e.latlng.lat, e.latlng.lng]);
        $.localizaciontemp = e.latlng.lat+','+e.latlng.lng;
      });
    }, 500);
  }
  SelecLocalizacion() {
    if ($.localizaciontemp) this.data.localizacion_maps = $.localizaciontemp;
    $('#modal-mapa').modal('hide');
    if (this.data.localizacion_maps && this.data.localizacion_maps != '') {
      this.data.mapa_iframe = this.sanitizer.bypassSecurityTrustResourceUrl(`
        https://maps.google.com/maps?width=100%25&height=600&hl=es&q=${this.data.localizacion_maps}+(dsf)&t=&z=14&ie=UTF8&iwloc=B&output=embed
      `);
    }
    this.appref.tick();
  }
  SetLocalidad(){
    this.globals.Api('/localidad', {codpostal: this.data.cod_postal}).subscribe(data => {
      if (!data || data.error) {
        // swal('Error', 'Se ha producido un error', 'error');return;
      }
      this.data.localidad = data.localidad;
      this.data.provincia = data.provincia;
    }, error => {
      // toastr.error(`Al bloquear sesión`, 'Error');
    });
  }
  CargarRangosBloqueos(){
    this.globals.Api('/bloquear-rango-fechas-cargar', {id_actividad: this.data.id}).subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Error al cargar bloqueo de fechas', 'error');return;
      }
      this.arrRangosFechasBloqueo = data;
    }, error => {
      // toastr.error(`Al bloquear sesión`, 'Error');
    });
  }
  GuardarRangosFecha(){
    if(!this.inputFechaInicioBloqueo || !this.inputFechaFinBloqueo) return;
    this.globals.Api('/bloquear-rango-fechas', {id_actividad: this.data.id, fecha_inicio: this.inputFechaInicioBloqueo, fecha_fin: this.inputFechaFinBloqueo, sesiones: this.sesiones}).subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Error al guardar bloqueo de fechas', 'error');return;
      }
      this.CargarEventosCalendario();
      this.CargarRangosBloqueos();
      this.inputFechaInicioBloqueo = null;
      this.inputFechaFinBloqueo = null;
    }, error => {
      // toastr.error(`Al bloquear sesión`, 'Error');
    });
  }
  EliminarRangosFecha(id_rango_fecha:any){
    swal({
      title: "¿Borrar rango?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/bloquear-rango-fechas-del', {id_rango_fecha: id_rango_fecha}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Error al borrar bloqueo de fechas', 'error');return;
        }
        this.CargarEventosCalendario();
        this.CargarRangosBloqueos();
      }, error => {
        // toastr.error(`Al bloquear sesión`, 'Error');
      });
    });
  }
  CargarVehiculos(){
    this.globals.Api('/eve-vehiculos-actividad',{id: this.data.id}).subscribe(data => {
      if (!data || !data.length) {
        this.vehiculos = [];
        this.globals.SaveData([]);
        return;
      }
      this.globals.SaveData(data);
      this.vehiculos = data;
      // setTimeout(()=>{
      //   $('.list-vehiculos').click(function(){
      //     let idVehiculo = $(this).attr('id');
      //     $('.vehiculo-'+idVehiculo).click();
      //   });
      // },1000);
    });
  }
  CargarVehiculosSelect(){
    this.globals.Api('/eve-vehiculos-select',{id: this.data.id}).subscribe(data => {
      if (!data || !data.length) {
        this.vehiculosSelect = [];
        this.globals.SaveData([]);
        return;
      }
      this.globals.SaveData(data);
      this.vehiculosSelect = data;
      // setTimeout(()=>{
      //   $('.list-vehiculos').click(function(){
      //     let idVehiculo = $(this).attr('id');
      //     $('.vehiculo-'+idVehiculo).click();
      //   });
      // },1000);
    });
  }
  HabilitarVehiculos(){
    if (!this.data.id || this.data.id == ''){
      this.data.vehiculo = 0;
      swal('Aviso', 'Debes guardar primero la actividad para gestionar vehículos', 'warning');return;
    }
    setTimeout(()=>{
      $('.list-vehiculos').click(function(){
        let idVehiculo = $(this).attr('id');
        $('.vehiculo-'+idVehiculo).click();
      });
    },500);
  }
  addIdVehiculoSelect(vehiculo_id:any){
    let estado = !$('.vehiculo-'+vehiculo_id).prop('checked');
    $('.vehiculo-'+vehiculo_id).prop('checked', estado);
    if($('.vehiculo-'+vehiculo_id).is(':checked')){
      this.idsVehiculosSelect.push(vehiculo_id);
    }else{
      const index = this.idsVehiculosSelect.indexOf(vehiculo_id);
      if (index > -1) { // only splice array when item is found
        this.idsVehiculosSelect.splice(index, 1); // 2nd parameter means remove one item only
      }
    }
    // console.log(this.idsVehiculosSelect);
  }
  removeIdVehiculoSelect(vehiculo_id:any){
    let estado = !$('.vehiculo-'+vehiculo_id).prop('checked');
    $('.vehiculo-'+vehiculo_id).prop('checked', estado);
    if($('.vehiculo-'+vehiculo_id).is(':checked')){
      this.idsDelVehiculosSelect.push(vehiculo_id);
    }else{
      const index = this.idsDelVehiculosSelect.indexOf(vehiculo_id);
      if (index > -1) { // only splice array when item is found
        this.idsDelVehiculosSelect.splice(index, 1); // 2nd parameter means remove one item only
      }
    }
  }
  addVehiculos(){
    this.globals.Api('/eve-vehiculos-select-add', {id_actividad: this.data.id, ids_vehiculos: this.idsVehiculosSelect}).subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Se ha producido un error', 'error');return;
      }
      toastr.success(`Datos guardados correctamente`, 'Guardado');
      this.CargarVehiculos();
      this.CargarVehiculosSelect();
      this.idsVehiculosSelect = [];
    }, error => {

    });
  }
  removeVehiculos(){
    this.globals.Api('/eve-vehiculos-select-del', {id_actividad: this.data.id, ids_vehiculos: this.idsDelVehiculosSelect}).subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Se ha producido un error', 'error');return;
      }
      toastr.success(`Datos guardados correctamente`, 'Guardado');
      this.CargarVehiculos();
      this.CargarVehiculosSelect();
      this.idsDelVehiculosSelect = [];
    }, error => {

    });
  }

  //Tarifas por personas
  AddTarifaS(){
    console.log('Add tarifa S');
    this.data.tarifas.push({
      nombre: this.data.tempNombreTarifa, precio: this.data.tempPrecioTarifa,
      en: {nombre: this.data.tempNombreTarifa},
      fr: {nombre: this.data.tempNombreTarifa},
      pt: {nombre: this.data.tempNombreTarifa},
    });
    this.data.tempNombreTarifa = '';
    this.data.tempPrecioTarifa = '';
  }
  DelTarifaS(item:any){
    this.data.tarifas.splice(this.data.tarifas.findIndex(el => el == item), 1);
  }

  KeyS(event:any, item:any, field:any) {
    if (event.which == 13 || event.keyCode == 13) {
      if (!event.target.textContent || event.target.textContent.trim() == '') return;
      item[field] = event.target.textContent.trim();
      event.target.blur();
      this.appref.tick();
    }
    if (event.which == 9 || event.keyCode == 9) {
      if (field == 'precio') {
        event.target.blur();
        this.AddTarifaS();
        this.appref.tick();
      }
    }
  }
  BlurS(event:any, item:any, field:any) {
    if (!event.target.textContent || event.target.textContent.trim() == '') return;
    item[field] = event.target.textContent.trim();
    item[field] = this.globals.StripHTML(item[field]);
    this.appref.tick();
  }

  //Tarifas por grupos de personas
  AddTarifaG(){
    console.log('Add tarifa S');
    this.data.tarifas_grupos.push({
      nombre: this.data.tempNombreTarifa,num_personas: this.data.tempNumeroPersonasTarifa, precio: this.data.tempPrecioTarifa
    });
    this.data.tempNombreTarifa = '';
    this.data.tempPrecioTarifa = '';
    this.data.tempNumeroPersonasTarifa = '';
  }
  DelTarifaG(item:any){
    this.data.tarifas_grupos.splice(this.data.tarifas.findIndex(el => el == item), 1);
  }

  //Asientos
  AddAsientos() {
    this.idioma = 'es';
    this.asientos_temp = [];
    this.asiento = {};
    this.asiento.en = {};
    this.asiento.fr = {};
    this.asiento.pt = {};
    $('#modal-crear-asiento').modal('show');
    $('#modal-crear-asiento .modal-title').html('Nuevo asiento');
    // Object.keys(this.data.asientos).forEach(item => {this.data.asientos[item] = ''});
    setTimeout(() => {
      $('#modal-crear-asiento .table tbody').sortable({
        handle: '.btn-sort',
        stop: (event:any, ui:any) => {
          let arr:any = [];
          let elements = $('#modal-crear-asiento .table tbody tr');
          if (!elements || !elements.length) return;
          let index = 0;
          for (let item of elements) {
            let arritem = this.asientos_temp.find((el:any) => el.id == item.getAttribute('itemid'));
            if (!arritem) continue;
            arr.push(arritem);
          }
          if (arr && arr.length) this.asientos_temp = JSON.parse(JSON.stringify(arr));
          this.appref.tick();
        }
      });

    }, 500);
  }
  EditAsiento(item) {
    this.asiento = item;
    if (!this.asiento.en) this.asiento.en = {};
    if (!this.asiento.fr) this.asiento.fr = {};
    if (!this.asiento.pt) this.asiento.pt = {};
    if (this.data.id && this.data.id != '') {
      this.globals.Api('/eve-actividades-asiento-tarifas-actividad', {id_actividad: this.data.id, id_asiento: this.asiento.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.asientos_temp = data;
        this.asientos_temp.forEach(asiento_temp => {
          asiento_temp.precio = JSON.parse(asiento_temp.precio);
        });
        // this.data.asientos.splice(this.data.asientos.findIndex(el => el == this.asientos_temp), 1);
        $('#modal-editar-asiento .table tbody').sortable({
          handle: '.btn-sort',
          stop: (event:any, ui:any) => {
            let arr:any = [];
            let elements = $('#modal-editar-asiento .table tbody tr');
            if (!elements || !elements.length) return;
            let index = 0;
            for (let item of elements) {
              let arritem = this.asientos_temp.find((el:any) => el.id == item.getAttribute('itemid'));
              if (!arritem) continue;
              arr.push(arritem);
            }
            if (arr && arr.length) this.asientos_temp = JSON.parse(JSON.stringify(arr));
            var orden = 0;
            this.asientos_temp.forEach(asiento => {
              asiento.orden = orden;
              orden++;
            });
            this.appref.tick();
          }
        });
      $('#modal-editar-asiento').modal('show');
      $('#modal-editar-asiento .modal-title').html('Editar asiento');
      });
    } else {
      this.asientos_temp = [];
      this.data.asientos.forEach(item => {
        if (item.nombre_asiento == this.asiento.nombre) {
          if (!item.en) item.en = {};
          if (!item.fr) item.fr = {};
          if (!item.pt) item.pt = {};
          this.asientos_temp.push(item);
        }
      });

      $('#modal-editar-asiento').modal('show');
      $('#modal-editar-asiento .modal-title').html('Editar asiento');
    }
  }
  GuardarEditModalAsiento() {
    if (this.data.id && this.data.id != '') {
      let params:any = {id_actividad: this.data.id, nombre_asiento: this.asiento.nombre, num_asientos: this.asiento.num_asientos, id_asiento: this.asiento.id, descripcion: this.asiento.descripcion, tarifas: this.asientos_temp};
      params.en = this.asiento.en;
      params.fr = this.asiento.fr;
      params.pt = this.asiento.pt;
      this.globals.Api('/eve-actividades-asiento-tarifas-edit', params).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.asientos_temp = [];
        this.asientos = [];
        this.asiento = {};
        if (!this.asiento.en) this.asiento.en = {};
        if (!this.asiento.fr) this.asiento.fr = {};
        if (!this.asiento.pt) this.asiento.pt = {};
        this.loadAsientos();
        $('#modal-editar-asiento').modal('hide');
      });
    }else{
      let asientoExiste = false;
      this.asientos.forEach(asiento => {
        if(asiento.nombre == this.asiento.nombre){
          asientoExiste = true;
        }
      });
      if(!asientoExiste){
        this.asientos.push(this.asiento);
      }
      this.asientos_temp.forEach(asiento_temp => {
        let asientoExiste = false;
        this.data.asientos.forEach(item => {
            if(item.nombre_asiento == asiento_temp.nombre_asiento && item.nombre == asiento_temp.nombre){
              // this.data.asientos.push(this.asientos_temp);
              item.precio = asiento_temp.precio;
              asientoExiste = true;
            }
          });
          if(!asientoExiste){
            this.data.asientos.push(asiento_temp);
            asientoExiste = true;
          }
      });
      this.asientos_temp = [];
      this.asiento = {};
      if (!this.asiento.en) this.asiento.en = {};
      if (!this.asiento.fr) this.asiento.fr = {};
      if (!this.asiento.pt) this.asiento.pt = {};
      $('#modal-editar-asiento').modal('hide');
    }
  }
  GuardarAsientos() {
    if (this.asiento.nombre == '' || this.asiento.num_asientos == '') {
      swal('Complete campos', 'Por favor, rellene el campo para continuar', 'error');return;
    }

    if (!this.data.id || this.data.id == '') {
      this.asientos.push(this.asiento);
      this.asientos_temp.forEach(asiento_temp => {
        if (!asiento_temp.en) asiento_temp.en = {};
        if (!asiento_temp.fr) asiento_temp.fr = {};
        if (!asiento_temp.pt) asiento_temp.pt = {};
        this.data.asientos.push(asiento_temp);
      });
      this.asientos_temp = [];
      this.asiento = {};
      $('#modal-crear-asiento').modal('hide');
      return;
    }
    this.asientos.push({asiento: this.asiento});
    $.buttonloading('btn-guardar-asiento', 'Enviando');
    // console.log(this.sesiones);
    this.globals.Api('/eve-actividades-asiento-tarifas-add', {id_actividad: this.data.id, asiento: this.asiento, asientos_tarifas: this.asientos_temp}).subscribe(data => {
      $.buttoncancelloading('btn-guardar-asiento');
      if (!data || data.error) {
        swal('Error', 'Se ha producido un error', 'error');return;
      }
      this.asientos_temp = [];
      this.asientos = [];
      this.asiento = {};
      if (!this.asiento.en) this.asiento.en = {};
      if (!this.asiento.fr) this.asiento.fr = {};
      if (!this.asiento.pt) this.asiento.pt = {};
      this.loadAsientos();
      $('#modal-crear-asiento').modal('hide');
    }, error => {
      $.buttoncancelloading('btn-guardar-asiento');
    });
  }
  DelAsientoTarifas(item:any) {
    console.log(item);
    if (!item) {
      this.asientos.splice(this.asientos.findIndex(el => el == item), 1);
      this.data.asientos.splice(this.data.asientos.findIndex(el => el == item), 1);
      return;
    }
    if (!this.data.id || this.data.id == ''){
      this.data.asientos.splice(this.asientos_temp.findIndex(el => el == item), 1);
      this.asientos_temp.splice(this.asientos_temp.findIndex(el => el == item), 1);
    }else{
      this.asientos_temp.splice(this.asientos_temp.findIndex(el => el == item), 1);
    }
  }
  DelAsientoTarifas2(tarifa:any) {
    for(var i = 0; i <= this.asientos.length; i++){
      console.log(this.asientos[i].id);
      console.log(tarifa.id_asiento);
      if(this.asientos[i].id == tarifa.id_asiento){
        console.log('coincidencia');
        this.asientos[i].tarifas.splice(this.asientos[i].tarifas.findIndex(el => el == tarifa), 1);
      }
    }
  }
  DelAsiento(item:any){
    console.log(item);
    swal({
      title: "¿Borrar asiento?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      if (!this.data.id || this.data.id == '') {

        return;
      }
      this.globals.Api('/eve-actividades-asiento-tarifas-del', {id: item.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.asientos_temp = [];
        this.asientos = [];
        this.asiento = {};
        this.loadAsientos();
      });
    });
  }
  GetPropiedadesGrupo(asientos:number) {
    return this.asientos_temp.filter((el:any) => el.asientos == asientos);
  }
  Key(event:any, item:any, field:any) {
    if (event.which == 13 || event.keyCode == 13) {
      if (!event.target.textContent || event.target.textContent.trim() == '') return;
      item[field] = event.target.textContent.trim();
      console.log(item);
      event.target.blur();
      this.appref.tick();
    }
    if (event.which == 9 || event.keyCode == 9) {
      if (field == 'precio') {
        // let index:number = this.GetPropiedadesGrupo(item.nombre_asiento).findIndex((el:any) => el.id == item.id);
        // if (index == this.GetPropiedadesGrupo(item.nombre_asiento).length-1) {
        // }
        event.target.blur();
        this.AddAsiento(item.nombre_asiento);
        this.appref.tick();
      }
    }
  }
  Blur(event:any, item:any, field:any) {
    if (!event.target.textContent || event.target.textContent.trim() == '') return;
    console.log(item);
    item[field] = event.target.textContent.trim();
    item[field] = this.globals.StripHTML(item[field]);
    this.appref.tick();
  }
  AddAsiento(asiento:any = null) {
    // console.log(asiento);
    if (!this.data.id || this.data.id == ''){
      console.log(this.sesiones);
      let nombre:any = this.asiento.nombre;
      let num_asientos:any = this.asiento.num_asientos;
      let jsonPrecios = [];
      this.sesiones.forEach(sesion => {
        jsonPrecios.push({"sesion": sesion.sesiones, "precio": 0})
      });
      this.asientos_temp.push({
        nombre: '',num_asientos: num_asientos, nombre_asiento: nombre, precio: jsonPrecios
      });
      console.log(this.asientos_temp);
    }else{
      if(asiento == null || asiento.length == 0){
        console.log(asiento);
        let nombre:any = this.asiento.nombre;
        let num_asientos:any = this.asiento.num_asientos;
        console.log(this.asiento);
        let jsonPrecios = [];
        this.sesiones.forEach(sesion => {
          jsonPrecios.push({"sesion": sesion.sesiones, "precio": 0})
        });
        this.asientos_temp.push({
          nombre: '',num_asientos: num_asientos, nombre_asiento: nombre, precio: jsonPrecios
        });
      }else{
        console.log(asiento);
        for(var i = 0; i < this.asientos.length; i++){
          if(this.asientos[i].id == asiento.id){
            console.log(this.sesiones);
            let jsonPrecios = [];
            this.sesiones.forEach(sesion => {
              jsonPrecios.push({"sesion": sesion.sesiones, "precio": 0})
            });
            this.asientos[i].tarifas.push({
              id_actividad: asiento.id_actividad, id_asiento: asiento.id, nombre: '', nombre_asiento: asiento.nombre, precio: jsonPrecios
            });
          }
        }
      }
    }
  }
  compare( a, b ) {
    if ( a.sesiones < b.sesiones ){
      return -1;
    }
    if ( a.sesiones > b.sesiones ){
      return 1;
    }
    return 0;
  }
}
