import { Component, OnInit, NgZone, ApplicationRef, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let toastr: any;
declare let Chart: any;

@Component({
  selector: 'app-estadisticas',
  templateUrl: './estadisticas.component.html',
  styleUrls: ['./estadisticas.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EstadisticasComponent implements OnInit {


  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) {
  }
  ngOnInit(): void {
    this.cargarEstadisticasReservaActividades();
    this.cargarEstadisticasReservasDiasMes();
  }

  cargarEstadisticasReservaActividades(){
    const data = {
        labels: ['Ruta 4x4','Kayak','Recogida cerezas'],
        datasets: [
            {
            label: 'Dataset 1',
            data: [10,25,60],
            backgroundColor: ['#000','#d83a1d','#03ef40']
            }
        ]
    };
    const config = {
        type: 'doughnut',
        data: data,
        options: {
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
                legend: {
                    display: false
                },
                title: {
                    display: true,
                    text: 'Nº de reservas por actividad'
                }
            }
        },
    };
    const ctx = document.getElementById('grafico-reserva-actividades');
    const myChart = new Chart(ctx,config);
  }

  cargarEstadisticasReservasDiasMes(){
    const getDays = (year, month) => {
      console.log(year);
      console.log(month);
      return new Date(year, month, 0).getDate();
    };
    const numDaysMonth = getDays(new Date().getFullYear(), new Date().getMonth()+1);
    var labels2 = [];
    for(var i = 1; i <= numDaysMonth; i++){
      labels2.push(i);
    }
    console.log(labels2);
    const data2 = {
      labels: labels2,
      datasets: [
        {
          label: 'WEB',
          data: [1,46,112,95,64,222,167,128,243,239,230,7,199,133,212,237,155,18,83,162,96,23,177,115,203,123,107,136,20,120,234],
          backgroundColor: '#000',
          stack: 'Stack 0',
        },
        {
          label: 'Admin',
          data: [54,171,237,123,162,150,53,217,10,133,214,60,131,27,117,28,2,250,114,223,26,126,153,181,188,113,55,46,245,232,24],
          backgroundColor: '#004385',
          stack: 'Stack 0',
        },
        {
          label: 'Partners',
          data: [54,171,237,123,162,150,53,217,10,133,214,60,131,27,117,28,2,250,114,223,26,126,153,181,188,113,55,46,245,232,134],
          backgroundColor: '#FFBE57',
          stack: 'Stack 0',
        },
        {
          label: 'AirBnb',
          data: [54,171,237,123,162,150,53,217,10,133,214,60,131,27,117,28,2,250,114,223,26,126,153,181,188,113,55,46,245,232,24],
          backgroundColor: '#F73659',
          stack: 'Stack 0',
        },
      ]
    };
    const config2 = {
      type: 'bar',
      data: data2,
      options: {
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            text: 'Chart.js Bar Chart - Stacked'
          },
        },
        responsive: true,
        interaction: {
          intersect: false,
        },
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true
          }
        }
      }
    };
    const ctx2 = document.getElementById('grafico-reserva-dias-mes');
    const myChart2 = new Chart(ctx2,config2);
  }

}
