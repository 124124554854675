import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let toastr: any;

@Component({
  selector: 'app-backend-menu',
  templateUrl: './menu.component.html'
})
export class MenuComponent implements OnInit {

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      App.init();
    }, 500);
    //------------ Antiguo ----------------
    // this.globals.Api('/configuracion').subscribe(data => {
    //   if (!data || data.error) return;
    //   this.globals.config = data;
    //   this.MensajesWhatsApp();
    //   // console.log(this.globals.me);
    //   //console.log(this.globals.cliente);
    // });
    //------------ END Antiguo --------------
    this.globals.Api('/configuracion-cliente', {id_cliente: this.globals.me.id_cliente}).subscribe(data => {
      if (!data || data.error) return;
      this.globals.config = data;
      this.MensajesWhatsApp();
      // console.log(this.globals.me);
      //console.log(this.globals.cliente);
    });
  }
  PrimerosPasos() {
    if ($.PrimerosPasos) $.PrimerosPasos();
  }

  MensajesWhatsApp() {
    let timeout:number = 10000;
    clearTimeout($.IDTimeMensajesCount);
    // clearTimeout($.IDTMWhatsApp);
    if (!this.globals.config) {
      if (!this.globals.config.whatsapp_fbid) this.globals.cmensajeswhatsapp = 0;
      $.IDTimeMensajesCount = setTimeout(() => { this.MensajesWhatsApp(); }, timeout);
      return;
    }
    if (this.globals.config && this.globals.config.whatsapp_fbid) {
      this.globals.Api('/crm-mensajes-count', {fbid: this.globals.config.whatsapp_fbid}).subscribe((data:any) => {
        if (!data || data.error) return;
        this.globals.cmensajeswhatsapp = data.value;
        $.IDTimeMensajesCount = setTimeout(() => { this.MensajesWhatsApp(); }, timeout);
      }, (error:any) => {
        $.IDTimeMensajesCount = setTimeout(() => { this.MensajesWhatsApp(); }, timeout);
      });
    }
  }
}
