<div id="content-iframe-frontend" *ngIf="dataReserva">
    <!-- SELECCIÓN FECHA, HORA, OPCIONES -->
    <div class="{{paso}}" *ngIf="paso == 'inicial'">
        <div class="title-inicial noselect">{{LG('Elegir la fecha deseada y reservar experiencia')}}</div>
        <div class="paso paso-fecha {{!dataReserva.fecha_format ? 'active' : ''}}">
            <div class="title noselect" (click)="Paso('fecha')">
                <b>1. {{LG('Fecha')}}</b>
                <span *ngIf="dataReserva.fecha_format">
                    {{dataReserva.fecha_format}}
                    <i class="fa fa-chevron-down"></i>
                </span>
            </div>
            <div class="{{!dataReserva.fecha_format ? '' : 'collapse'}}">
                <div style="padding-bottom:2rem;">
                    <div id="calendar" class="calendario calendario-dashboard"></div>
                    <div id="content-legend" *ngIf="reservar || canjear">
                        <div class="legend">
                            <div style="width: 10px; background-color: #5da84e; border-radius: 50px; height: 10px;border: solid 1px white;"></div>
                            <div class="legend-text">{{LG('Disponibles')}}</div>
                        </div>
                        <div class="legend">
                            <div style="width: 10px; background-color: #e5b437; border-radius: 50px; height: 10px;border: solid 1px white;"></div>
                            <div class="legend-text">{{LG('Poca disp.')}}</div>
                        </div>
                        <div class="legend">
                            <div style="width: 10px; background-color: #a30404; border-radius: 50px; height: 10px;border: solid 1px white;"></div>
                            <div class="legend-text">{{LG('Agotadas')}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="paso paso-hora {{!dataReserva.sesion ? 'active' : ''}}" *ngIf="dataReserva.fecha_format">
            <div class="title noselect" (click)="Paso('hora')">
                <b>2. {{LG('Hora')}}</b>
                <span *ngIf="dataReserva.sesion">
                    {{dataReserva.sesion}}h
                    <i class="fa fa-chevron-down"></i>
                </span>
            </div>
            <div class="sesiones-container {{!dataReserva.sesion ? '' : 'collapse'}}">
                <ng-container *ngIf="!sesiones || !sesiones.length">
                    <div class="no-results">
                        *No hay sesiones disponibles para este día
                    </div>
                </ng-container>
                <ng-container *ngIf="sesiones && sesiones.length">
                    <ng-container *ngIf="sesiones_tipo.maniana.length">
                        <div class="subtitle">{{LG('Por la mañana')}}</div>
                        <div class="sesiones">
                            <ng-container *ngFor="let item of sesiones_tipo.maniana">
                                <div class="content-radio {{item.active ? 'active' : ''}}">
                                    <input id="{{item.sesion}}" type="radio" class="form-control radios-sesion" value="{{item.sesion}}-{{item.aforo}}"
                                        (click)="SetSesion(item, item.aforo_restante)"
                                        name="sesion" style="width: fit-content; height: fit-content; margin-right: 5px;">
                                    <label for="{{item.sesion}}">{{item.sesion}}h</label>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="sesiones_tipo.tarde.length">
                        <div class="subtitle">{{LG('Por la tarde')}}</div>
                        <div class="sesiones">
                            <ng-container *ngFor="let item of sesiones_tipo.tarde">
                                <div class="content-radio {{item.active ? 'active' : ''}}">
                                    <input id="{{item.sesion}}" type="radio" class="form-control radios-sesion" value="{{item.sesion}}-{{item.aforo}}"
                                        (click)="SetSesion(item, item.aforo_restante)"
                                        name="sesion" style="width: fit-content; height: fit-content; margin-right: 5px;">
                                    <label for="{{item.sesion}}">{{item.sesion}}h</label>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="sesiones_tipo.noche.length">
                        <div class="subtitle">{{LG('Por la noche')}}</div>
                        <div class="sesiones">
                            <ng-container *ngFor="let item of sesiones_tipo.noche">
                                <div class="content-radio {{item.active ? 'active' : ''}}">
                                    <input id="{{item.sesion}}" type="radio" class="form-control radios-sesion" value="{{item.sesion}}-{{item.aforo}}"
                                        (click)="SetSesion(item, item.aforo_restante)"
                                        name="sesion" style="width: fit-content; height: fit-content; margin-right: 5px;">
                                    <label for="{{item.sesion}}">{{item.sesion}}h</label>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
        <ng-container *ngIf="asientos && asientos.length">
            <div class="paso paso-asiento {{!dataReserva.asiento ? 'active' : ''}}" *ngIf="dataReserva.sesion">
                <div class="title noselect" (click)="Paso('asiento')">
                    <b>3. {{LG('Asiento')}}</b>
                    <span *ngIf="dataReserva.asiento">
                        {{dataReserva.asiento.nombre}}
                        <i class="fa fa-chevron-down"></i>
                    </span>
                </div>
                <div class="sesiones-container {{!dataReserva.asiento ? '' : 'collapse'}}">
                    <div class="sesiones">
                        <ng-container *ngFor="let item of asientos">
                            <div class="content-radio {{item.active ? 'active' : ''}}">
                                <input id="asiento-{{item.id}}" type="radio" class="form-control radios-sesion" value="{{item.nombre}}"
                                    (click)="SetAsiento(item)"
                                    name="asiento" style="width: fit-content; height: fit-content; margin-right: 5px;">
                                <label *ngIf="!item.descripcion" for="asiento-{{item.id}}">{{item.nombre}}</label>
                                <label *ngIf="item.descripcion" class="tooltip" for="asiento-{{item.id}}">{{item.nombre}} <i class="fa fa-info-circle" *ngIf="item.descripcion"></i><span class="tooltiptext" *ngIf="item.descripcion">{{item.descripcion}}</span></label>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-container>
        <div class="paso paso-opcion {{!dataReserva.opcion ? 'active' : ''}}" *ngIf="dataReserva.asiento || (dataReserva.sesion && !asientos.length)">
            <div class="title noselect">
                <b><span *ngIf="!asientos || !asientos.length">3.</span><span *ngIf="asientos && asientos.length">4.</span>
                    {{LG('Opción')}}
                </b>
                <span *ngIf="dataReserva.opcion">
                    {{dataReserva.opcion}}
                    <i class="fa fa-chevron-down"></i>
                </span>
            </div>
            <div class="{{!dataReserva.opcion ? '' : 'collapse'}}">
                <ul class="list-group list-group-flush list-tarifas">
                    <li class="list-group-item" *ngFor="let item of tarifas">
                        <ng-container *ngFor="let precio of item.precio">
                            <div *ngIf="precio.sesion == sesionSelect">
                                {{item.nombre}}<br>
                                <b *ngIf="precio.precio == '0' || precio.precio == 0">{{precio.precio}}€</b>
                                <b *ngIf="precio.precio != '0' || precio.precio != 0">{{globals.FloatES(precio.precio)}}€</b>
                            </div>
                        </ng-container>
                        <div class="mas-menos">
                            <div class="input-group">
                                <div class="input-group-addon {{item.puede_menos ? '' : 'disabled'}}" (click)="Menos(item)"><i class="fa fa-minus"></i></div>
                                <input class="form-control" type="number" maxlength="2" [(ngModel)]="item.cantidad" disabled>
                                <div class="input-group-addon {{item.puede_mas ? '' : 'disabled'}}" (click)="Mas(item)"><i class="fa fa-plus"></i></div>
                                <ng-container *ngFor="let precio of item.precio">
                                    <div *ngIf="precio.sesion == sesionSelect">
                                        <span id="precio-calculado-{{item.id}}" style="Display: none !important;">{{CalcularPrecioTarifa(precio.precio,item.cantidad)}}</span>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="bottom" *ngIf="dataReserva.asiento || (dataReserva.sesion && !asientos.length)">
            <ng-container *ngIf="dataReserva.precio">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                        <div class="font-bold">
                            {{actividad.nombre}}
                        </div>
                        <div class="total-right">
                            <div>{{globals.FloatES(dataReserva.precio)}}€</div>
                            <span>{{LG('inc. IVA')}}</span>
                        </div>
                    </li>
                </ul>
            </ng-container>
            <a class="btn btn-block btn-lg btn-primary {{!dataReserva.precio ? 'disabled' : ''}}" (click)="Continuar('datos')">
                {{LG('Continuar')}}
            </a>
        </div>
    </div>

    <!-- DATOS DEL CLIENTE -->
    <div class="{{paso}}" *ngIf="paso == 'datos'">
        <div class="title-inicial noselect" *ngIf="reservar || canjear">{{LG('Tus datos')}}</div>
        <div class="title-inicial noselect" *ngIf="regalar">{{LG('Datos del destinatario')}}</div>
        <div class="form-group datos-personales row m-b-5">
            <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="form-group-lbl">
                    <label>{{LG('Nombre')}} *</label>
                    <input class="form-control" type="text" [(ngModel)]="dataReserva.nombre" maxlength="80">
                </div>
            </div>
            <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="form-group-lbl">
                    <label>{{LG('Apellidos')}} *</label>
                    <input class="form-control" type="text" [(ngModel)]="dataReserva.apellidos" maxlength="80">
                </div>
            </div>
            <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="form-group-lbl">
                    <label>{{LG('Teléfono')}} *</label>
                    <input class="form-control" type="tel" [(ngModel)]="dataReserva.telefono" maxlength="20">
                </div>
            </div>
            <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="form-group-lbl">
                    <label>{{LG('Email')}} *</label>
                    <input class="form-control" type="email" [(ngModel)]="dataReserva.email" maxlength="80">
                </div>
            </div>
            <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="form-group-lbl">
                    <label>{{LG('Código postal')}} *</label>
                    <input class="form-control" type="number" [(ngModel)]="dataReserva.cli_codpostal" maxlength="20" (change)="SetLocalidad()">
                </div>
            </div>
            <div class="col-lg-12 col-xs-12" *ngIf="reservar || canjear">
                <div class="form-group-lbl">
                    <label>{{LG('Notas o comentarios')}}</label>
                    <textarea class="form-control" [(ngModel)]="dataReserva.cli_notas" maxlength="500" rows="3"></textarea>
                </div>
            </div>
            <div class="col-lg-12 col-xs-12" *ngIf="regalar">
                <div class="form-group-lbl">
                    <label>{{LG('Dedicatoria (Opcional)')}}</label>
                    <textarea class="form-control" [(ngModel)]="dataPersonaRegala.dedicatoria" maxlength="500" rows="3"></textarea>
                </div>
            </div>
        </div>
        <ng-container *ngIf="regalar">
            <div class="title-inicial noselect">{{LG('Rellena tus datos')}}</div>
            <div class="form-group row m-b-5">
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="form-group-lbl">
                        <label>{{LG('Nombre')}} *</label>
                        <input class="form-control" type="text" [(ngModel)]="dataPersonaRegala.nombre" maxlength="80">
                    </div>
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="form-group-lbl">
                        <label>{{LG('Apellidos')}} *</label>
                        <input class="form-control" type="text" [(ngModel)]="dataPersonaRegala.apellidos" maxlength="80">
                    </div>
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="form-group-lbl">
                        <label>{{LG('Teléfono')}} *</label>
                        <input class="form-control" type="tel" [(ngModel)]="dataPersonaRegala.telefono" maxlength="20">
                    </div>
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="form-group-lbl">
                        <label>{{LG('Email')}} *</label>
                        <input class="form-control" type="email" [(ngModel)]="dataPersonaRegala.email" maxlength="80">
                    </div>
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="form-group-lbl">
                        <label>{{LG('Código postal')}} *</label>
                        <input class="form-control" type="number" [(ngModel)]="dataPersonaRegala.cli_codpostal" maxlength="20" (change)="SetLocalidadRegalo()">
                    </div>
                </div>
            </div>
        </ng-container>
        <div class="input-cupon" *ngIf="reservar">
            <div class="title-inicial noselect" *ngIf="reservar || canjear">{{LG('¿Tienes un cupón de descuento?')}}</div>
            <div class="form-group-lbl">
                <label>{{LG('Cupón')}}</label>
                <input class="form-control" type="text" [(ngModel)]="dataReserva.codigo_cupon" maxlength="50"
                (input)="ComprobarCupon()" [disabled]="dataReserva.email && dataReserva.email.trim() != '' ? false : true">
                <i class="fa fa-check text-success icon-cupon" *ngIf="dataReserva.mostracuponok"></i>
                <i class="fa fa-times text-danger icon-cupon" *ngIf="dataReserva.mostracuponko"></i>
            </div>
        </div>
        <div class="clearfix"></div>
        <div class="row">
            <div class="col-lg-12 col-sm-12 col-xs-12">
                <div id="content-politicas-privacidad">
                    <input id="check-politicas-privacidad" type="checkbox" />
                    <label for="check-politicas-privacidad">{{LG('Acepto la')}} <a href="{{url_politicas_privacidad.url_politica_privacidad}}" target="_blank"
                        style="font-weight: 700;">{{LG('política de privacidad')}}</a> {{LG('y las')}} <a href="{{url_politicas_privacidad.url_politica_cancelacion}}" target="_blank">{{LG('políticas de cancelación')}}</a>
                    </label>
                </div>
            </div>
        </div>
        <div class="bottom" *ngIf="dataReserva.precio">
            <ul class="list-group list-group-flush">
                <li class="list-group-item">
                    <div class="font-bold">
                        {{actividad.nombre}}
                    </div>
                    <div class="total-right">
                        <div>{{globals.FloatES(dataReserva.precio)}}€</div>
                        <span>{{LG('inc. IVA')}}</span>
                    </div>
                </li>
            </ul>
            <div class="bottom-botones">
                <a class="btn btn-block btn-lg btn-default" (click)="Continuar('inicial')">
                    {{LG('Atrás')}}
                </a>
                <a class="btn btn-block btn-lg btn-primary" (click)="Continuar('pago')">
                    {{LG('Continuar')}}
                </a>
            </div>
        </div>
    </div>

    <!-- PAGO -->
    <div class="{{paso}}" *ngIf="paso == 'pago'" style="padding-bottom:2rem;">
        <ul class="list-group list-resumen" *ngIf="dataReserva && actividad">
            <li class="list-group-item active">
                {{LG('Resumen de reserva')}}
            </li>
            <li class="list-group-item">
                <div>{{LG('Fecha')}}</div>
                <div>{{dataReserva.fecha_format}}</div>
            </li>
            <li class="list-group-item">
                <div>{{LG('Sesión')}}</div>
                <div>{{dataReserva.sesion}}h</div>
            </li>
            <ng-container *ngIf="dataReserva.asiento">
                <li class="list-group-item">
                    <div>{{LG('Asiento')}}</div>
                    <div>{{dataReserva.asiento.nombre}}</div>
                </li>
            </ng-container>
            <ng-container *ngIf="!dataReserva.tienecupon">
                <li class="list-group-item">
                    <div>{{actividad.nombre}}</div>
                    <div class="total-right">
                        <div>{{globals.FloatES(dataReserva.precio)}}€</div>
                        <span>{{LG('inc. IVA')}}</span>
                    </div>
                </li>
            </ng-container>
            <ng-container *ngIf="dataReserva.tienecupon">
                <li class="list-group-item">
                    <div>{{LG('Precio')}}</div>
                    <div>{{globals.FloatES(dataReserva.precio_original)}}€</div>
                </li>
                <li class="list-group-item">
                    <div>{{actividad.nombre}}</div>
                    <div class="total-right">
                        <div>{{LG('Descuento')}}: -{{dataReserva.cupon.descuento}}</div>
                        <div>{{globals.FloatES(dataReserva.total)}}€</div>
                        <span>{{LG('inc. IVA')}}</span>
                    </div>
                </li>
            </ng-container>
        </ul>
        <div class="title-inicial noselect"
            *ngIf="!dataReserva.cli_tipo_pago_slug || dataReserva.cli_tipo_pago_slug == ''">{{LG('Pago')}}</div>
        <div *ngIf="!canjear">
            <ng-container *ngIf="dataReserva.cli_tipo_pago_slug &&
                ['paypal','tarjeta','bizum','addon-payments'].includes(dataReserva.cli_tipo_pago_slug)">
                <div class="mensaje-pasarela">
                    <img src="assets/credit-card.svg" alt="">
                    <p>{{LG('Por favor, completa el pago en la pasarela online')}}</p>
                </div>
            </ng-container>
            <ng-container *ngIf="!dataReserva.cli_tipo_pago_slug || dataReserva.cli_tipo_pago_slug == ''">
                <a id="btn-guardar-reserva" class="btn-guardar-reserva btn btn-lg btn-default btn-block font-bold" *ngFor="let item of formas_pago" (click)="SetFormaPago(item)">
                    <i class="icofont-paypal-alt" *ngIf="item.forma_pago == 'Paypal'"></i>
                    <i class="icofont-credit-card" *ngIf="item.forma_pago == 'Tarjeta bancaria' || item.forma_pago == 'Tarjeta de crédito'"></i>
                    <i class="icofont-stripe-alt" *ngIf="item.forma_pago == 'Stripe'"></i>
                    {{LG(item.forma_pago)}}
                </a>
            </ng-container>
            <ng-container *ngIf="dataReserva.cli_tipo_pago_slug == 'stripe'">
                <div id="stripe-form" class="m-t-10 m-b-10">
                    <div id="card-element"></div>
                    <div id="card-errors" role="alert"></div>
                </div>
                <a id="btn-guardar-reserva" class="btn-guardar-reserva btn btn-lg btn-primary btn-block font-bold m-t-30" (click)="Pago()">
                    {{LG('Realizar Pago')}}
                </a>
                <a class="btn btn-lg btn-default btn-block m-t-20" (click)="AtrasPago()">
                    <i class="fa fa-chevron-left"></i> {{LG('Atrás')}}
                </a>
            </ng-container>
            <ng-container *ngIf="!dataReserva.cli_tipo_pago_slug || dataReserva.cli_tipo_pago_slug == ''">
                <div class="bottom-botones m-t-30">
                    <a class="btn btn-block btn-lg btn-default font-bold" (click)="Continuar('datos')">
                        <i class="fa fa-chevron-left"></i> {{LG('Atrás')}}
                    </a>
                </div>
            </ng-container>
        </div>
    </div>
</div>