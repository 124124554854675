<div id="page-wrapper" class="page-loading">
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                <div class="content-header">
                    <div class="row">
                        <div class="col-lg-3 col-md-6 col-xs-12 my-auto">
                            <div class="header-section">
                                <h1 class="middle">Vehículos</h1>
                                <button class="btn btn-xs btn-secondary middle m-l-10" (click)="CargarGrid()"><i class="fa fa-refresh"></i></button>
                            </div>
                            <ul class="breadcrumb breadcrumb-top text-left">
                                <li><a [routerLink]="['/dashboard']">Inicio</a></li>
                                <li>Vehículos</li>
                            </ul>
                        </div>
                        <div class="col-lg-2 col-md-6 col-xs-12 my-auto text-center no-wrap">
                          <label class="col-form-label inline-block m-r-10">Mostrar</label>
                          <select class="form-control inline-block" (change)="Buscar()" [(ngModel)]="params.page_size" style="width:auto;">
                            <option>25</option>
                            <option>50</option>
                            <option>100</option>
                            <option>200</option>
                          </select>
                        </div>
                        <div class="col-lg-5 col-md-6 col-xs-12 my-auto text-center">
                          <div class="input-group">
                            <span class="input-group-addon cursor" (click)="Buscar()"><i class="fa fa-search"></i></span>
                            <input type="search" placeholder="Buscar" class="form-control" (keyup.enter)="Buscar()" [(ngModel)]="params.filtro">
                          </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-xs-12 my-auto text-center hide-tablet">
                          <a class="btn btn-primary btn-block" (click)="Nuevo()"><i class="fa fa-plus"></i> Nuevo vehículo</a>
                        </div>
                    </div>
                </div>

                <div class="block full break-padding">
                  <div class="block-title">
                    <!-- <div class="block-options pull-right">
                        <a href="javascript:void(0)" class="btn btn-effect-ripple btn-default" data-toggle="tooltip" title=""
                            style="overflow: hidden; position: relative;" data-original-title="Settings"><i
                                class="fa fa-cog"></i></a>
                    </div> -->
                    <ul class="nav nav-tabs" data-toggle="tabs">
                        <li class="active"><a href="#tab-vehiculos">Vehículos</a></li>
                        <!-- <li><a href="#tab-tarifas"><i class="icofont-credit-card"></i> Tarifas</a></li> -->
                        <!-- <li><a href="#block-tabs-settings" data-toggle="tooltip" title="" data-original-title="Settings"><i class="gi gi-settings"></i></a></li> -->
                    </ul>
                  </div>
                  <div class="tab-content">
                    <div class="tab-pane active" id="tab-vehiculos">
                      <!-- Table Styles Content -->
                      <div class="table-responsive table-grid block full break-padding">
                        <!--
                        Available Table Classes:
                            'table'             - basic table
                            'table-bordered'    - table with full borders
                            'table-borderless'  - table with no borders
                            'table-striped'     - striped table
                            'table-condensed'   - table with smaller top and bottom cell padding
                            'table-hover'       - rows highlighted on mouse hover
                            'table-vcenter'     - middle align content vertically
                        -->
                        <table class="table table-striped table-bordered table-vcenter table-hover">
                            <thead>
                                <tr>
                                  <!-- <th style="width:50px;" class="text-center"><label class="csscheckbox csscheckbox-primary"><input type="checkbox"><span></span></label></th> -->
                                  <th style="width:50px"></th>
                                  <th order="nombre">Nombre</th>
                                  <th order="tipo">Tipo</th>
                                  <th style="width:80px">Color</th>
                                  <th style="width:130px" order="alta">Alta</th>
                                  <th style="width:80px" order="aforo">Plazas</th>
                                  <!-- <th style="width:80px" order="tamano">Tama&ntilde;o</th> -->
                                  <th style="width:50px">Duplicar</th>
                                  <th style="width:50px">Editar</th>
                                  <th style="width:50px">Borrar</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of vehiculos" class="cursor">
                                  <!-- <td class="text-center"><label class="csscheckbox csscheckbox-primary"><input type="checkbox"><span></span></label></td> -->
                                  <td class="text-center" (click)="Editar(item)">
                                    <img *ngIf="item.imagen" [src]="item.imagen" class="img-listado square">
                                    <i *ngIf="!item.imagen || item.imagen == ''" class="fa fa-car fa-2x"></i>
                                  </td>
                                  <td class="font-bold text-primary" (click)="Editar(item)">
                                    <div>{{item.nombre}}
                                      <a class="btn btn-default btn-xs hide" (click)="Portapapeles($event, item)"><i class="fa fa-clipboard text-white"></i></a>
                                    </div>
                                  </td>
                                  <td>{{item.tipo}}</td>
                                  <td><div class="distintivo-color" [style.background-color]="item.distintivo_color"></div></td>
                                  <td>{{item.alta_format}}</td>
                                  <td>{{item.plazas}}</td>
                                  <!-- <td><div *ngIf="item.tamano">{{item.tamano}} m<sup>2</sup></div></td> -->
                                  <td class="text-center btntd"><a class="btn btn-primary btn-xs" (click)="Duplicar(item.id)"><i class="fa fa-clone text-white"></i></a></td>
                                  <td class="text-center btntd"><a class="btn btn-primary btn-xs" (click)="Editar(item)"><i class="fa fa-pencil text-white"></i></a></td>
                                  <td class="text-center btntd"><a class="btn btn-danger btn-xs" (click)="Borrar(item)"><i class="fa fa-trash text-white"></i></a></td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="dataTables_paginate paging_simple_numbers m-r-10">
                          <ul class="pagination justify-content-end">
                            <li class="paginate_button page-item previous {{params.page_index <= 1 ? 'disabled' : ''}}">
                              <a (click)="Paging('prev')" class="page-link">Anterior</a>
                            </li>
                            <li class="paginate_button page-item" *ngIf="params.page_index > 1">
                              <a (click)="Paging('prev')" class="page-link">{{params.page_index-1}}</a>
                            </li>
                            <li class="paginate_button page-item active">
                              <a class="page-link cursor">{{params.page_index}}</a>
                            </li>
                            <li class="paginate_button page-item" *ngIf="params.page_index < params.pages">
                              <a (click)="Paging('next')" class="page-link">{{params.page_index+1}}</a>
                            </li>
                            <li class="paginate_button page-item next {{params.page_index >= params.pages ? 'disabled' : ''}}">
                              <a (click)="Paging('next')" class="page-link">Siguiente</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->

        <app-backend-footer></app-backend-footer>
    </div>
    <!-- END Page Container -->
</div>