<div id="page-wrapper" class="page-loading">
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                <div class="content-header">
                    <div class="row-equal-cols">
                        <div class="col-lg-6 col-md-6 col-xs-12">
                            <div class="header-section">
                                <h1 class="middle">{{title}}</h1>
                                <!-- <button class="btn btn-xs btn-secondary middle m-l-10" (click)="CargarGrid()"><i class="fa fa-refresh"></i></button> -->
                            </div>
                            <ul class="breadcrumb breadcrumb-top text-left">
                                <li><a [routerLink]="['/dashboard']">Inicio</a></li>
                                <li><a [routerLink]="['/reservas']">Reservas</a></li>
                                <li>{{title}}</li>
                            </ul>
                        </div>
                        <div class="col-lg-6 col-md-6 col-xs-12 col-botones text-center" *ngIf="temp_estado != 5">
                          <a class="btn btn-default m-r-20" (click)="ConfirmarAsistencia()" *ngIf="data.id && data.id != '' && !data.confirmada"><i class="fa fa-check text-success"></i> Confirmar asistencia</a>
                          <a class="btn btn-danger m-r-50" (click)="Borrar()" *ngIf="data.id && data.id != ''"><i class="fa fa-trash"></i></a>
                          <a class="btn" style="border: solid 1px black" (click)="Cancelar()">Cancelar</a>
                          <a class="btn btn-default btn-guardar m-l-20" (click)="Guardar()">Guardar</a>
                        </div>
                        <div class="col-lg-6 col-md-6 col-xs-12 col-botones text-center" *ngIf="temp_estado == 5">
                          <a class="btn btn-default" (click)="Cancelar()">Cerrar</a>
                        </div>
                    </div>
                </div>

                <div class="block full break-padding">
                    <!-- <div class="block-title">
                        <div class="block-options pull-right">
                            <a href="javascript:void(0)" class="btn btn-effect-ripple btn-default" data-toggle="tooltip" title=""
                                style="overflow: hidden; position: relative;" data-original-title="Settings"><i
                                    class="fa fa-cog"></i></a>
                        </div> -- >
                        <ul class="nav nav-tabs" data-toggle="tabs">
                            <li class="active"><a href="#tab-general">Datos generales</a></li>
                            < !-- <li><a href="#block-tabs-settings" data-toggle="tooltip" title="" data-original-title="Settings"><i class="gi gi-settings"></i></a></li> -- >
                        </ul>
                    </div> -->
                    <div class="tab-content">
                        <div class="tab-pane active" id="tab-general">

                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                  <div class="block">
                                    <div class="block-title">
                                        <h2 *ngIf="data.tipo_reserva == 'actividad'">Actividad <span class="text-secondary">{{data.evento}}</span></h2>
                                        <h2 *ngIf="data.tipo_reserva == 'evento'">Evento <span class="text-secondary">{{data.evento}}</span></h2>
                                    </div>
                                    <table class="table table-striped table-bordered table-vcenter table-hover">
                                        <thead>
                                            <tr>
                                              <th>Persona</th>
                                              <th>Número</th>
                                              <th *ngIf="data.precios.length && data.precios[0].nombre_asiento">Asiento</th>
                                              <th>Precio</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of data.precios" class="cursor">
                                              <td class="font-bold">{{item.nombre}}</td>
                                              <td>{{item.cantidad}}</td>
                                              <td *ngIf="item.nombre_asiento">{{item.nombre_asiento}}</td>
                                              <td>{{item.total}}€</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <ng-container *ngIf="data.tipo_reserva == 'actividad'">
                                      <div id="guia-asociado">
                                        <ng-container *ngIf="guias_asignados && guias_asignados.length">
                                          <p class="font-bold">Guías asignados a esta actividad:</p>
                                          <div class="row">
                                            <div id="content-guias" class="col-lg-4" style="padding-bottom: 30px;" *ngFor="let guia_asignado of guias_asignados">
                                              <a class="btn row" style="background-color: #efefef;
                                              font-weight: 600;
                                              font-size: 18px;
                                              width: 100%;
                                              text-align: left;
                                              " id="eZcTJIObge4pt57ipWcGnOoy00JsaBQbtybuthCreZn5FcwCcH">
                                                <img class="col-lg-3 col-md-3 col-sm-3 col-xs-3" style="border-radius: 35px; margin-right: 15px; padding: 0px;" src="{{guia_asignado.imagen}}">
                                                <div class="col-lg-7 col-md-7 col-sm-7 col-xs-7" style="padding: 0px; font-size: 16px; line-height: 1.2em;">{{guia_asignado.nombre}}<br>{{guia_asignado.apellidos}}</div>
                                              </a>
                                            </div>
                                          </div>
                                          <a class="btn btn-default" (click)="AsignarGuia()">Editar guías</a>
                                        </ng-container>
                                        <ng-container *ngIf="!guias_asignados">
                                          <a class="btn btn-default" (click)="AsignarGuia()">Asignar guías</a>
                                        </ng-container>
                                      </div>
                                    </ng-container>
                                </div>
                                    <div class="block">
                                        <div class="block-title"><h2>Resumen</h2></div>
                                        <!-- <p *ngIf="data.id && data.id != ''">Referencia: #{{data.referencia}}</p> -->
                                        <div class="form-group">
                                          <!-- <label class="text-primary">Días disponibles <span class="text-danger">*</span></label> -->
                                          <!-- <div class="block full break-padding" style="padding-bottom: 0px;">
                                            <div class="block-title" style="margin-bottom: 0px;">
                                              <div class="content-tabs-month">
                                                <ul class="nav nav-tabs" data-toggle="tabs">
                                                  <li class="tab-{{item.month}}" style="cursor: pointer;" *ngFor="let item of mesesAforo"><a>{{item.month}} - {{item.year}}</a></li>
                                              </ul>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="tab-content" id="content-meses">
                                            <div class="tab-pane " id="tab-{{item1.month}}" *ngFor="let item1 of mesesAforo">
                                              <div class="row">
                                                <ng-container *ngFor="let item of aforo">
                                                  <div class="col-lg-3 content-days cursor-pointer" style="margin-bottom: 25px;"  *ngIf="item1.month == item.mes" (click)="seleccionarDia(item1)">
                                                    <div style="box-shadow: 0px 0px 3px; padding-left: 5px; padding-right: 5px; padding-bottom: 5px;">
                                                      <div>{{item.fecha_format}} <span class="icon-checket-day" style="float: right;"><i class="icofont-check-circled"></i></span><span style="float: right;">{{item.aforo_restante}}/{{item.aforo_total}}</span></div>
                                                      <div>
                                                        <div class="progress progress-mini active" style="height: 12px;line-height: 12px;margin-bottom: 0px;margin-top: 4px;">
                                                          <div *ngIf="item.ocultar == 0" class="progress-bar progress-bar-pendding" role="progressbar" aria-valuenow="{{item.percentage}}" aria-valuemin="0" aria-valuemax="100" style="height: 12px;line-height: 12px;" [style.width]="item.percentage+'%'"></div>
                                                          <div *ngIf="item.ocultar == 1" class="progress-bar progress-bar-danger" role="progressbar" aria-valuenow="{{item.percentage}}" aria-valuemin="0" aria-valuemax="100" style="height: 12px;line-height: 12px;" [style.width]="item.percentage+'%'"></div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </ng-container>
                                              </div>
                                            </div>
                                          </div>-->
                                          <!-- <input type="text" class="form-control rangepicker" id="txtfechallegadasalida" maxlength="50" autocomplete="off"
                                            [disabled]="(!data.id_evento || data.id_evento == '' ? 'true' : null )"> -->
                                          <!-- <div id="calendar" class="calendario"></div> -->
                                        </div>
                                        <div class="clearfix"></div>
                                        <!-- <p class="text-warning" *ngIf="!data.id_evento || data.id_evento == ''">Seleccione un evento previamente</p> -->
                                        <div class="form-group row" *ngIf="temp_estado != 5">
                                            <label class="col-lg-4 col-form-label">Estado <span class="text-warning">*</span></label>
                                            <div class="col-lg-8">
                                              <select id="cmbestado" class="form-control" placeholder="Estado" [(ngModel)]="data.id_estado">
                                                <option *ngFor="let item of estados" [value]="item.id">{{item.estado}}</option>
                                              </select>
                                            </div>
                                        </div>
                                        <div class="form-group row" *ngIf="temp_estado != 5">
                                            <label class="col-lg-4 col-form-label">Forma de pago <span class="text-warning">*</span></label>
                                            <div class="col-lg-8">
                                              <select class="form-control" placeholder="Forma de pago" [(ngModel)]="data.cli_tipo_pago">
                                                <option *ngFor="let item of formas_pago" [value]="item.forma_pago">{{item.forma_pago}}</option>
                                              </select>
                                              <ng-container *ngIf="data.id_estado == 8 ||data.id_estado == 6">
                                                <br>
                                                <label class="col-form-label">Fecha expiración del pago <span class="text-warning">*</span></label>
                                                <input type="datetime-local" class="form-control" [(ngModel)]="fecha_expiracion_new_pago"/>
                                                <br>
                                                <br>
                                                <label>Establecer importe personalizado<br><span style="font-size: 12px;">(*dejar en blanco para enviar el total de la reserva)</span></label><br>
                                                <input type="number" id="anticipo-importe"
                                                name="anticipo-importe" placeholder="{{data.total}}" [(ngModel)]="data.anticipo_importe"><br>
                                                <a class="btn btn-default" (click)="VolverEnviarPago()">Volver a enviar el pago</a>
                                              </ng-container>
                                            </div>
                                        </div>
                                        <!-- Datos Pago Anticipado -->
                                        <ng-container *ngIf="data.anticipo_url_id && data.anticipo_url_id != '' && data.anticipo_fecha_expiracion && data.anticipo_fecha_expiracion != ''">
                                          <div class="form-group row">
                                            <label class="col-lg-4 col-form-label">Fecha límite pago</label>
                                            <div class="col-lg-8">
                                              <input type="datetime-local" class="form-control" style="width:auto;" [(ngModel)]="data.anticipo_fecha_expiracion" maxlength="30">
                                            </div>
                                          </div>
                                          <div class="form-group row">
                                            <label class="col-lg-4 col-form-label">Página de pago</label>
                                            <div class="col-lg-8">
                                              <a class="btn btn-light" [href]="'/pago-anticipo?token=' + data.anticipo_url_id" target="_blank">
                                                <i class="fa fa-external-link"></i> Ver página pago anticipado
                                              </a>
                                            </div>
                                          </div>
                                        </ng-container>
                                        <div *ngIf="data.total">Resumen:</div>
                                        <div class="resumen-total block" *ngIf="data.total">
                                          <!-- <p>Noches: <span>{{noches}}</span></p> -->
                                          <p>I.V.A.: <span>{{globals.FloatES(data.iva)}}%</span></p>
                                          <p>Base Imponible: <span>{{globals.FloatES(data.base_imponible)}}&euro;</span></p>
                                          <p class="total">Total: <span>{{globals.FloatES(data.total)}}&euro;</span></p>
                                          <ng-container *ngIf="data.cli_tipo_pago == 'Pago en mano'">
                                            <a class="btn btn-default" (click)="changeTotalReserva()">Editar precio total reserva</a>
                                            <input id="input-change-total-reserva" type="number" style="display: none" [(ngModel)]="data.total" (click)="calcularBaseImponible()">
                                          </ng-container>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-lg-6 col-md-12">
                                  <div class="block">
                                    <div class="block-title"><h2>Cliente</h2></div>
                                      <div class="row">
                                        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                          <div class="form-group">
                                            <label class="text-primary" *ngIf="data.tipo_reserva == 'actividad'">Fecha seleccionada</label>
                                            <label class="text-primary" *ngIf="data.tipo_reserva == 'evento'">Fecha</label>
                                            <div class="input-group">
                                              <div class="input-group-addon"><i class="fa fa-calendar"></i></div>
                                              <input type="text" disabled class="form-control" [(ngModel)]="data.fecha_format" maxlength="80">
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                          <div class="form-group">
                                            <label class="text-primary" *ngIf="data.tipo_reserva == 'actividad'">Sesión</label>
                                            <label class="text-primary" *ngIf="data.tipo_reserva == 'evento'">Hora</label>
                                            <div class="input-group">
                                              <div class="input-group-addon"><i class="fa fa-clock-o"></i></div>
                                              <input type="text" disabled class="form-control" [(ngModel)]="data.sesion">
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="data.tipo_reserva == 'actividad'">
                                          <div class="form-group" style="margin-top: 24px;">
                                            <button class="btn btn-default" (click)="mostrarModal()">Cambiar fecha o sesión</button>
                                          </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 col-xs-12">
                                          <div class="form-group">
                                            <label class="text-primary">Nombre <span class="text-danger">*</span></label>
                                            <input type="text" class="form-control" [(ngModel)]="data.cli_nombre" maxlength="80">
                                          </div>
                                        </div>
                                        <div class="col-md-6 col-xs-12">
                                          <div class="form-group">
                                            <label class="text-primary">Apellidos <span class="text-danger">*</span></label>
                                            <input type="text" class="form-control" [(ngModel)]="data.cli_apellidos" maxlength="80">
                                          </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 col-xs-12">
                                          <div class="form-group">
                                            <label>Empresa</label>
                                            <input type="text" class="form-control" [(ngModel)]="data.cli_empresa" maxlength="80">
                                          </div>
                                        </div>
                                        <div class="col-md-6 col-xs-12">
                                          <div class="form-group">
                                            <label>NIF / CIF</label>
                                            <input type="text" class="form-control" [(ngModel)]="data.cli_nifcif" maxlength="20">
                                          </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                      <div class="col-md-8 col-xs-12">
                                        <div class="form-group">
                                          <label>Direcci&oacute;n</label>
                                          <input type="text" class="form-control" [(ngModel)]="data.cli_direccion" maxlength="200">
                                        </div>
                                      </div>
                                      <div class="col-md-4 col-xs-12">
                                        <div class="form-group">
                                          <label>C&oacute;digo postal</label>
                                          <input type="text" class="form-control" [(ngModel)]="data.cli_codpostal" maxlength="10"
                                            (change)="DatosLocalidad()">
                                        </div>
                                      </div>
                                    </div>
                                    <div class="row">
                                      <div class="col-md-6 col-xs-12">
                                        <div class="form-group">
                                          <label>Localidad</label>
                                          <input type="text" class="form-control" [(ngModel)]="data.cli_localidad" maxlength="80">
                                        </div>
                                      </div>
                                      <div class="col-md-6 col-xs-12">
                                        <div class="form-group">
                                          <label>Provincia</label>
                                          <input type="text" class="form-control" [(ngModel)]="data.cli_provincia" maxlength="80">
                                        </div>
                                      </div>
                                    </div>
                                    <div class="row">
                                      <div class="col-md-6 col-xs-12">
                                        <div class="form-group">
                                          <label>Tel&eacute;fono</label>
                                          <input type="tel" class="form-control" [(ngModel)]="data.cli_telefono" maxlength="20">
                                        </div>
                                      </div>
                                      <div class="col-md-6 col-xs-12">
                                        <div class="form-group">
                                          <label class="text-primary">Email <span class="text-danger">*</span></label>
                                          <input type="email" class="form-control"[(ngModel)]="data.cli_email" maxlength="80">
                                        </div>
                                      </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                      <div class="col-md-12 col-xs-12">
                                        <div class="form-group">
                                          <label>Notas del cliente</label>
                                          <textarea class="form-control" rows="2" [(ngModel)]="data.cli_notas" maxlength="500"></textarea>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                </div>
                            </div>
                            <div class="block" *ngIf="data.id_estado != 5">
                                <div class="block-title">
                                    <div class="block-options pull-right">
                                        <a  (click)="AddPago()"
                                            class="btn btn-effect-ripple btn-primary" data-toggle="tooltip" style="overflow: hidden; position: relative;" data-original-title="A&ntilde;adir pago">
                                            <i class="fa fa-plus"></i> A&ntilde;adir pago
                                        </a>
                                    </div>
                                    <h2>Pagos</h2>
                                </div>
                                <table class="table table-striped table-bordered table-vcenter table-hover">
                                    <thead>
                                        <tr>
                                        <!-- <th style="width:50px;" class="text-center"><label class="csscheckbox csscheckbox-primary"><input type="checkbox"><span></span></label></th> -->
                                        <th>Estado</th>
                                        <th>Forma pago</th>
                                        <th>Tipo</th>
                                        <th>Id pago</th>
                                        <th>Fecha</th>
                                        <th>Importe</th>
                                        <th style="width:50px">Borrar</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of data.pagos">
                                          <td class="font-bold">{{item.estado}}</td>
                                          <td>{{item.forma_pago}}</td>
                                          <td>{{item.tipo == 'manual' ? 'Manual' : 'Pasarela TPV Virtual'}}</td>
                                          <td>
                                            <ng-container *ngIf="data.paypal_order">{{data.paypal_order}}</ng-container>
                                            <ng-container *ngIf="data.redsys_order">{{data.redsys_order}}</ng-container>
                                            <ng-container *ngIf="data.stripe_order">{{data.stripe_order}}</ng-container>
                                            <ng-container *ngIf="!data.paypal_order && !data.redsys_order && !data.stripe_order">No disponible</ng-container>
                                          </td>
                                          <td>{{item.fecha_pago_format}}</td>
                                          <td class="font-bold">{{globals.FloatES(item.total)}}€</td>
                                          <td class="text-center btntd" (click)="DelPago(item)"><a class="btn btn-danger btn-xs"><i class="fa fa-trash text-white"></i></a></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>


                        </div>
                    </div>
                </div>

                <div class="col-botones" *ngIf="temp_estado != 5">
                  <a class="btn" style="border: solid 1px black" (click)="Cancelar()">Cancelar</a>
                  <a class="btn btn-default btn-guardar m-l-20" (click)="Guardar()">Guardar</a>
                </div>
                <div class="col-botones" *ngIf="temp_estado == 5">
                  <a class="btn btn-default" (click)="Cancelar()">Cerrar</a>
                </div>

            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->

        <app-backend-footer></app-backend-footer>
    </div>
    <!-- END Page Container -->
</div>
<div class="modal inmodal fade" id="modal-seleccionar-alojamiento" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
            <h4 class="modal-title"></h4>
        </div>
        <div class="modal-body">
          <div class="table-responsive table-grid block full break-padding">
              <!--
              Available Table Classes:
                  'table'             - basic table
                  'table-bordered'    - table with full borders
                  'table-borderless'  - table with no borders
                  'table-striped'     - striped table
                  'table-condensed'   - table with smaller top and bottom cell padding
                  'table-hover'       - rows highlighted on mouse hover
                  'table-vcenter'     - middle align content vertically
              -->
              <table class="table table-striped table-bordered table-vcenter table-hover">
                  <thead>
                      <tr>
                        <!-- <th style="width:50px;" class="text-center"><label class="csscheckbox csscheckbox-primary"><input type="checkbox"><span></span></label></th> -->
                        <th style="width:50px"></th>
                        <th order="nombre">Nombre</th>
                        <!-- <th style="width:130px" order="alta">Alta</th> -->
                        <!-- <th style="width:80px" order="aforo">Personas</th> -->
                        <!-- <th style="width:80px" order="tamano">Tama&ntilde;o</th> -->
                        <th style="width:50px">Seleccionar</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let item of eventos" class="cursor" (click)="SeleccionarEvento(item)">
                        <!-- <td class="text-center"><label class="csscheckbox csscheckbox-primary"><input type="checkbox"><span></span></label></td> -->
                        <td class="text-center">
                          <img *ngIf="item.imagen" [src]="item.imagen" class="img-listado square">
                          <i *ngIf="!item.imagen || item.imagen == ''" class="fa fa-user fa-2x"></i>
                        </td>
                        <td class="font-bold text-primary">
                          <div>{{item.nombre}}</div>
                        </td>
                        <!-- <td>{{item.alta_format}}</td> -->
                        <!-- <td>{{item.aforo}}</td> -->
                        <!-- <td><div *ngIf="item.tamano">{{item.tamano}} m<sup>2</sup></div></td> -->
                        <td class="text-center btntd"><a class="btn btn-primary btn-xs"><i class="fa fa-check text-white"></i></a></td>
                      </tr>
                  </tbody>
              </table>
              <div class="dataTables_paginate paging_simple_numbers m-r-10">
                <ul class="pagination justify-content-end">
                  <li class="paginate_button page-item previous {{params_alo.page_index <= 1 ? 'disabled' : ''}}">
                    <a (click)="PagingAlo('prev')" class="page-link">Anterior</a>
                  </li>
                  <li class="paginate_button page-item" *ngIf="params_alo.page_index > 1">
                    <a (click)="PagingAlo('prev')" class="page-link">{{params_alo.page_index-1}}</a>
                  </li>
                  <li class="paginate_button page-item active">
                    <a class="page-link cursor">{{params_alo.page_index}}</a>
                  </li>
                  <li class="paginate_button page-item" *ngIf="params_alo.page_index < params_alo.pages">
                    <a (click)="PagingAlo('next')" class="page-link">{{params_alo.page_index+1}}</a>
                  </li>
                  <li class="paginate_button page-item next {{params_alo.page_index >= params_alo.pages ? 'disabled' : ''}}">
                    <a (click)="PagingAlo('next')" class="page-link">Siguiente</a>
                  </li>
                </ul>
              </div>
          </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
        </div>
        </div>
    </div>
</div>
<div class="modal inmodal fade" id="modal-seleccionar-tarifa" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
            <h4 class="modal-title"></h4>
        </div>
        <div class="modal-body">
          <div class="form-group row">
              <label class="col-lg-4 col-form-label">Adultos <span class="text-danger">*</span></label>
              <div class="col-lg-8">
                  <input type="number" class="form-control" placeholder="Nº Adultos" [(ngModel)]="data.adultos" maxlength="20">
              </div>
          </div>
          <div class="form-group row">
              <label class="col-lg-4 col-form-label">Niños</label>
              <div class="col-lg-8">
                  <input type="number" class="form-control" placeholder="Nº Niños" [(ngModel)]="data.ninos" maxlength="20">
              </div>
          </div>
          <!-- <div class="table-responsive table-grid">
              <table class="table table-striped table-bordered table-vcenter table-hover">
                  <thead>
                      <tr>
                        <th style="width:100px">Tarifa</th>
                        <th>Temporada</th>
                        <th>Adultos</th>
                        <th>Niños</th>
                        <th>Precio</th>
                        <th style="width:50px">Seleccionar</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let item of tarifas; let i = index" class="cursor" [attr.itemid]="item.id" (click)="SeleccionarTarifa(item)">
                        <td class="font-bold">Tarifa #{{i+1}}</td>
                        <td class="font-bold">{{item.temporada}}</td>
                        <td>{{item.adultos}}</td>
                        <td>{{item.ninos}}</td>
                        <td>{{globals.FloatES(item.precio)}}€ / noche</td>
                        <td class="text-center btntd"><a class="btn btn-primary btn-xs"><i class="fa fa-check text-white"></i></a></td>
                      </tr>
                  </tbody>
              </table>
          </div> -->
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
            <button type="button" class="btn btn-primary" (click)="AnadirEve()">Añadir</button>
        </div>
        </div>
    </div>
</div>
<div class="modal inmodal fade" id="modal-crear-pago" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
            <h4 class="modal-title"></h4>
        </div>
        <div class="modal-body">
            <div class="form-group row">
                <label class="col-lg-4 col-form-label">Estado <span class="text-danger">*</span></label>
                <div class="col-lg-8">
                  <select id="cmbestadopago" class="form-control" placeholder="Estado" [(ngModel)]="dataitem.id_estado_pago">
                    <option *ngFor="let item of estados_pago" [value]="item.id">{{item.estado}}</option>
                  </select>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-lg-4 col-form-label">Forma de pago <span class="text-danger">*</span></label>
                <div class="col-lg-8">
                  <select id="cmbformapago" class="form-control" placeholder="Forma de pago" [(ngModel)]="dataitem.id_forma_pago">
                    <option *ngFor="let item of formas_pago" [value]="item.id">{{item.forma_pago}}</option>
                  </select>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-lg-4 col-form-label">Tipo <span class="text-danger">*</span></label>
                <div class="col-lg-8">
                  <select class="form-control" placeholder="Tipo" [(ngModel)]="dataitem.tipo">
                    <option value="manual" selected>Manual</option>
                    <option value="pasarela" selected>Pasarela TPV Virtual</option>
                  </select>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-lg-4 col-form-label">Fecha pago <span class="text-danger">*</span></label>
                <div class="col-lg-6">
                    <input type="text" class="form-control datepicker width-auto" id="txtfechapago" [(ngModel)]="dataitem.fecha_pago" maxlength="20">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-lg-4 col-form-label">Total <span class="text-danger">*</span></label>
                <div class="col-lg-8">
                    <input type="number" class="form-control width-auto" placeholder="Total" [(ngModel)]="dataitem.total" maxlength="20">
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
            <button type="button" class="btn btn-primary" id="btn-guardar-temporada" (click)="GuardarPago()">Guardar</button>
        </div>
      </div>
    </div>
</div>
<div class="modal inmodal fade" id="modal-add-guia" tabindex="-1" role="dialog"  aria-hidden="true">
  <div class="modal-dialog modal-lg" style="max-width: 1200px">
      <div class="modal-content">
      <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
          <h4 class="modal-title">Asignar guías</h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div id="content-guias" class="col-lg-4 col-md-4 col-sm-6 col-xs-12" *ngFor="let guia of guias" style="padding-bottom: 30px;">
            <a id="{{guia.id}}" class="btn row" style="background-color: #efefef;font-weight: 600;font-size: 18px;width: 100%;text-align: left;" (click)="CheckGuia(guia.id)">
              <img class="col-lg-4 col-md-3 col-sm-3 col-xs-3" src="{{guia.imagen}}" style="border-radius: 35px;margin-right: 15px; padding: 0px"/>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="padding: 0px;
              margin-top: 14px;
              line-height: 1.2em;
              ">{{guia.nombre}}<br>{{guia.apellidos}}</div>
              <input class="{{guia.id}}" type="checkbox" style="position: absolute;
              right: 25px;
              top: 31px;
              width: 20px;
              height: 20px;
              "/>
            </a>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
        <button type="button" class="btn btn-primary" id="btn-guardar-temporada" (click)="GuardarGuia()">Añadir</button>
      </div>
    </div>
  </div>
</div>
<div class="modal inmodal fade" id="modal-calendar" tabindex="-1" role="dialog">
  <div id="modal-reservar-calendar">
      <div class="modal-dialog">
          <div class="modal-content modal-lg">
          <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal" style="font-size: 35px;"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
              <h3 class="modal-title" style="text-align: center;">Editar fecha y sesión</h3>
          </div>
          <div>
            <div id="content-paso-1" class="row modal-body">
              <div class="col-md-12 col-xs-12">
                  <label class="col-lg-4 col-form-label label-modal-title hide-tablet-desktop label-calendar-title hide" ><i class="fa fa-calendar"></i> Selecciona fecha</label>
                  <hr class="separador-general hide-tablet-desktop custom-form-reservar hide">
                  <div class="row display-flex width-fit-auto hide-movil">
                  </div>
                  <div id="content-calendar-data" class="row">
                      <div class="col-lg-5 col-sm-5 col-xs-12">
                        <div id="calendar" class="calendario calendario-dashboard calendario-front"></div>
                      </div>
                      <div id="content-entradas" class="col-lg-7 col-sm-7 col-xs-12">
                          <div class="form-group row">
                              <div id="content-date-reserva" class="display-flex hide">
                                  <label class="col-lg-4 col-form-label label-modal-title"><i class="icofont-calendar"></i> Fecha seleccionada</label>
                                  <div class="col-lg-8">
                                      <input id="date-reservar" type="date" class="form-control" maxlength="5" style="width: fit-content;" disabled>
                                  </div>
                              </div>
                              <div class="margin-top-10 display-flex" id="content-radio-reserva">
                                  <label class="col-lg-4 col-form-label label-modal-title hide-tablet-desktop" ><i class="icofont-clock-time"></i> Elige una sesión</label>
                                  <label class="col-lg-3 col-form-label label-modal-title hide-movil" ><i class="icofont-clock-time"></i> Sesiones</label>
                                  <hr class="separador-general hide-tablet-desktop custom-form-reservar" style="margin-left: 16px !important;">
                                  <ng-container *ngIf="sesiones.length == 0">
                                      <div class="content-radio-sesion display-flex aviso-sesiones">
                                          *Seleccione un día para ver las sesiones
                                      </div>
                                  </ng-container>
                                  <div class="content-radio-sesion display-flex" *ngFor="let sesion of sesiones">
                                      <input id="{{sesion.sesion}}" type="radio" class="form-control radios-sesion" value="{{sesion.sesion}}-{{sesion.aforo}}" (click)="setSesionCheck(sesion.sesion,sesion.aforo_restante)" name="sesion" style="width: fit-content; height: fit-content; margin-right: 10px;"><label for="{{sesion.sesion}}">{{sesion.sesion}}h ({{sesion.aforo_restante}} plazas)</label>
                                  </div>
                              </div>
                              <div class="margin-top-10" id="content-radio-personas">
                                <label class="col-lg-4 col-form-label label-modal-title hide-tablet-desktop hide" ><i class="fa fa-ticket"></i> Entradas</label>
                                <hr class="separador-general hide-tablet-desktop custom-form-reservar hide">
                                <div class="col-lg-12 margin-bottom-10">
                                    <div class="row">
                                        <div class="col-lg-7 col-xs-6">
                                            <label class="col-form-label label-modal-title" ><i class="icofont-user-alt-1"></i> Adultos <span class="precio-persona">{{data.adultos}}</span></label><br>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 margin-bottom-10" *ngIf="data.ninios">
                                    <div class="row">
                                        <div class="col-lg-7 col-xs-6">
                                            <label class="col-form-label label-modal-title" ><i class="icofont-user-alt-1"></i> Niños <span class="precio-persona">{{data.ninios}}</span><br/><span> (hasta {{evento.edad_ninios}} años) </span></label><br>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 margin-bottom-10 margin-bottom-0-movil hide">
                                    <div class="row">
                                        <div class="col-lg-7 col-xs-6">
                                            <label class="col-form-label label-modal-title" ><i class="icofont-user-alt-1"></i> Niños gratis <br/><span>(menores de 3 años) </span> </label><br>

                                        </div>
                                        <div class="col-lg-3 col-xs-3 padding-left-0-movil">
                                            <div class="display-flex">
                                                <i id="custom-menos-ninio-gratis" class="icofont-minus-circle custom-menos disabled-custom-input"></i><input id="personas-ninios-gratis" class="form-control custom-input-number" type="text" maxlength="2" [(ngModel)]="data.niniosGratis" disabled><i id="custom-mas-ninio-gratis" class="icofont-plus-circle custom-mas"></i>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-xs-3">
                                            <p class="precio-calculo">0 €</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="modal-footer col-md-12 col-xs-12 padding-55-70 padding-top-0">
                  <!-- <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button> -->
                  <button type="button" class="btn btn-primary" id="btn-siguiente" (click)="ActualizarFecha()">Actualizar fechas</button>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>