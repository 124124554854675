import { Component, OnInit, NgZone, ApplicationRef, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let ICAL: any;
declare let Chart: any;
declare let Stripe: any;
declare let RealexHpp: any;
// declare let gtag: any;

declare let LANG: any;
declare let LG: any;

@Component({
  selector: 'app-nueva-reserva-actividad',
  templateUrl: './nueva-reserva-actividad.component.html',
  styleUrls: ['./nueva-reserva-actividad.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NuevaReservaActividadComponent implements OnInit {
  public id_cliente:any = this.route.snapshot.paramMap.get('id_cliente');
  public id_actividad:any = this.route.snapshot.paramMap.get('id_actividad');
  public fecha:string = this.route.snapshot.paramMap.get('fecha');
  public css:string = (this.globals.GetQueryString('css') ? this.globals.GetQueryString('css') : '');
  public admin:boolean = (this.globals.GetQueryString('modaladmin') == 'true');
  public colaborador:boolean = (this.globals.GetQueryString('modalcolaborador') == 'true');
  public estilo:string = (this.globals.GetQueryString('estilo') ? this.globals.GetQueryString('estilo') : '');
  public bgcolor:string = (this.globals.GetQueryString('bgcolor') ? this.globals.GetQueryString('bgcolor') : '');
  public LG:any = LG;
  public aforo = [];
  public actividad:any = null;
  public dataReserva:any = {};
  public dataCanjearBono:any = {};
  public url_politicas_privacidad:string = '';
  public paso:string = 'inicial';

  public reservar:boolean = true;
  public regalar:boolean = false;
  public canjear:boolean = false;

  public reservasDias:any = [];
  public formas_pago:any = [];
  public sesiones:any = [];
  public sesiones_tipo:any = {
    maniana: [],
    tarde: [],
    noche: []
  };
  public asientos:any = [];
  public tarifas:any = [];
  public sesionSelect = '';

  public plazasDisponibles:number = 0;
  public num_reservas_dias_check:number = 0;
  public stripe:any = null;
  public stripe_card:any = null;

  public google_analytics:any = {};

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals,
    private route: ActivatedRoute
    ) { }

  ngOnInit(): void {
    App.init();
    setTimeout(() => {
      if (!this.globals.cliente) this.globals.cliente = {id: this.id_cliente};
      this.globals.CargarColorCliente();
    }, 100);

    LANG = this.globals.GetQueryString('lang');
    if (!LANG || LANG == '') LANG = 'es';
    this.dataReserva.idioma = LANG;
    console.log(LANG);

    this.globals.Api('/pub-formas-pago', {id_cliente: this.id_cliente}).subscribe(data => {
      if (!data || !data.length) return;
      this.formas_pago = data;
    });
    this.globals.Api('/stripe-cliente', {id_cliente: this.id_cliente}).subscribe(data => {
      if (!data || !data.pk_test) return;
      if (!this.globals.config) this.globals.config = {};
      this.globals.config.pk_test = data.pk_test;
      this.globals.config.pk_live = data.pk_live;
      this.globals.config.st_entorno_pruebas = data.entorno_pruebas;
    });
    this.globals.Api('/pub-politicas-privacidad', {id_cliente: this.id_cliente}).subscribe(data => {
      if (!data || data.error) {
        return;
      }
      this.url_politicas_privacidad = data;
    });
    this.globals.Api('/pub-google-analytics', {id_cliente: this.id_cliente}).subscribe(data => {
      if (!data || data.error) {
        return;
      }
      this.google_analytics = data;
      if(this.google_analytics.tagmanagerga){
        $('head').append(this.google_analytics.tagmanagerga);
      }
      if(this.google_analytics.tagmanagergabody){
        $('body').prepend(this.google_analytics.tagmanagergabody);
      }
    });
    this.globals.Api('/pub-actividades', {id_cliente: this.id_cliente, id: this.id_actividad, idioma: LANG}).subscribe(data => {
      if (!data || !data.length) return;
      this.actividad = data[0];
    });
    this.CargarCalendario();
    this.IframePostMessages();
    window.onresize = () => {
      this.IframePostMessages();
    };
    setTimeout(() => {
      if (this.css && this.css != '' && !this.admin) {
        var styleSheet = document.createElement("style");
        styleSheet.innerText = this.css;
        document.body.appendChild(styleSheet);
      }
      if (this.admin) {
        $('#global-styles').remove();
        if (document.querySelector('#global-styles')) document.querySelector('#global-styles').outerHTML = '';
        $(document).ready(function(e) {
          $('#global-styles').remove();
          if (document.querySelector('#global-styles')) document.querySelector('#global-styles').outerHTML = '';
        });
      }
      function inIframe() {
        try {return window.self !== window.top; } catch (e) {return true;}
      }
      if (inIframe()) {
        var styleSheet = document.createElement("style");
        let css = `body {overflow:hidden}`;
        css = css.replace(/(\r\n|\n|\r)/gm, "");
        styleSheet.innerText = css;
        document.body.appendChild(styleSheet);
      }
      if (this.estilo == 'oscuro') {
        document.body.classList.add('oscuro');
      }
      if (this.bgcolor && this.bgcolor != '') {
        var styleSheet = document.createElement("style");
        let css = `
          body {--color-bg: #${this.bgcolor};}
          #content-iframe-frontend { background-color: var(--color-bg); }
        `;
        css = css.replace(/(\r\n|\n|\r)/gm, "");
        styleSheet.innerText = css;
        document.body.appendChild(styleSheet);
      }
    }, 100);
  }

  IframePostMessages() {
    setTimeout(() => {
      window.parent.postMessage({
        url: window.location.href,
        height: $('#content-iframe-frontend').height()
      }, '*');
      window.postMessage({
        url: window.location.href,
        height: $('#content-iframe-frontend').height()
      }, '*');
    }, 100);
  }

  GetSesiones(tipo:string = 'maniana') {
    if (!this.sesiones) return [];
    let arr:any = JSON.parse(JSON.stringify(this.sesiones));
    if (tipo == 'maniana') arr = arr.filter((el:any) => moment(el.sesion, 'HH:mm') < moment('12:00', 'HH:mm'));
    if (tipo == 'tarde') arr = arr.filter((el:any) => moment(el.sesion, 'HH:mm') >= moment('12:00', 'HH:mm') && moment(el.sesion, 'HH:mm') < moment('20:00', 'HH:mm'));
    if (tipo == 'noche') arr = arr.filter((el:any) => moment(el.sesion, 'HH:mm') >= moment('20:00', 'HH:mm'));
    return arr;
  }
  Paso(paso:string = 'fecha') {
    switch(paso) {
      case 'fecha': {
        this.dataReserva = {};
        this.CargarCalendario();
      } break;
      case 'hora': {
        delete this.dataReserva.sesion;
        delete this.dataReserva.asiento;
      } break;
      case 'asiento': {
        delete this.dataReserva.asiento;
      } break;
      case 'opcion': {
        delete this.dataReserva.opcion;
        delete this.dataReserva.precio;
      } break;
    }
    this.appref.tick();
    this.IframePostMessages();
  }
  CargarCalendario() {
    var date = new Date();
    var d = date.getDate();
    var m = date.getMonth();
    var y = date.getFullYear();
    let that = this;
    var heightCalendar = 280;
    if ($(window).width() <= 1366 && $(window).width() > 1025) {
      heightCalendar = 325;
    }

    let dayclick = (date, allDay, jsEvent, view) => {
      if (this.canjear && this.dataCanjearBono && this.dataCanjearBono.bono) {
        this.actividad.id = this.dataCanjearBono.bono.id_actividad;
      }
      this.sesiones = [];
      this.sesiones_tipo.maniana = [];
      this.sesiones_tipo.tarde = [];
      this.sesiones_tipo.noche = [];
      this.globals.Api('/pub-check-dias-minimos').subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Error al cargar configuración', 'error');return;
        }
        let dateClick = new Date(date).getTime();
        let dateNow = new Date().getTime();
        var someDate = new Date();
        var errorDate = new Date();
        var numberOfDaysToAdd = data.dias_min_reserva;
        var result = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
        var result2 = errorDate.setDate(errorDate.getDate() - 1);
        let dateMin = new Date(result).getTime();
        let horasMinimas = data.dias_min_reserva * 24;
        if (!this.admin) {
          if (dateClick < result2) {
            return;
          }
          if (dateClick <= dateMin && data.dias_min_reserva != 0) {
            this.sesiones = [];
            this.sesiones_tipo.maniana = [];
            this.sesiones_tipo.tarde = [];
            this.sesiones_tipo.noche = [];
            let msg:string = '';
            if (data.tel_contacto) msg = LG('Para reservar con antelación de {{horas}}h, contacte con nosotros por teléfono {{telefono}}');
            else msg = LG('Para reservar con antelación de {{horas}}h, contacte con nosotros.');
            msg = msg.replace('{{horas}}', horasMinimas.toString());
            msg = msg.replace('{{telefono}}', data.tel_contacto);
            swal({
              title: LG("Información"),
              text: msg,
              type: "info",
              showCancelButton: true,
              confirmButtonClass: "btn-primary",
              confirmButtonText: "Llamar",
              cancelButtonText: "Cerrar",
              closeOnConfirm: true
            },() => {
              window.open('tel:'+data.tel_contacto);
            });
            return;
          }
          var today = new Date();
          var tempDate = new Date(date);
          if (today > tempDate && data.dias_min_reserva != 0) {
            return;
          }
        }
        let params = {
          fecha: date,
          fecha_format: moment(date).format('DD/MM/YYYY'),
          fecha_format_en: moment(date).format('YYYY-MM-DD')
        };

        this.dataReserva.fecha_format = moment(date).locale(LANG).format('dddd[,] LL');
        this.dataReserva.fecha = moment(date).format('YYYY-MM-DD');
        this.appref.tick();
        $('#date-reservar').val(this.dataReserva.fecha);
        setTimeout(() => {
          this.globals.Api('/eve-actividades', {id: this.id_actividad, idioma: LANG}).subscribe(data => {
            if (!data || data.error) {
              swal('Error', 'Error al cargar datos evento', 'error');return;
            }
            this.actividad = data[0];
            if (this.actividad.vehiculo) {
              // Cálculo disponibilidad condicionante vehículos
              this.sesiones = [];
              this.sesiones_tipo.maniana = [];
              this.sesiones_tipo.tarde = [];
              this.sesiones_tipo.noche = [];
              this.globals.Api('/eve-actividades-sesiones-vehiculos-v2',{id: this.actividad.id, fecha: params.fecha_format_en}).subscribe(data => {
                if (!data || data.error) {
                  swal('Error', 'Error al cargar datos sesiones', 'error');return;
                }
                if (data[1].length > 0) {
                  this.sesiones = data[1];
                  if (this.sesiones && this.sesiones.length) {
                    this.sesiones_tipo.maniana = this.GetSesiones('maniana');
                    this.sesiones_tipo.tarde = this.GetSesiones('tarde');
                    this.sesiones_tipo.noche = this.GetSesiones('noche');
                  }
                } else {
                  this.sesiones = data[0];
                  if (this.sesiones && this.sesiones.length) {
                    this.sesiones_tipo.maniana = this.GetSesiones('maniana');
                    this.sesiones_tipo.tarde = this.GetSesiones('tarde');
                    this.sesiones_tipo.noche = this.GetSesiones('noche');
                  }
                }
                this.IframePostMessages();
                setTimeout(()=>{
                  $('.content-radio-sesion').click(function() {
                    $('.content-radio-sesion').removeClass('active');
                    $(this).addClass('active');
                  });
                },100);
                var days = ['domingo', 'lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado'];
                var d = new Date(params.fecha_format_en);
                var dayName = days[d.getDay()];
                this.globals.Api('/check-dia-disponible-v2',{id: this.actividad.id, dia: dayName, fecha: params.fecha_format_en}).subscribe(data => {
                  if (!data || data.error) {
                    swal('Error', 'Error al cargar datos sesiones', 'error');return;
                  }
                  if (data[0].count == 0 && data[0].dias_especial_disponible == 0) {
                    this.sesiones = [];
                    this.sesiones_tipo.maniana = [];
                    this.sesiones_tipo.tarde = [];
                    this.sesiones_tipo.noche = [];
                  } else {
                    for(let i = 0; i < this.sesiones.length; i++) {
                      let sesion = this.sesiones[i].sesion;
                      this.globals.Api('/eve-actividades-check-vehiculos-v2',{id: this.actividad.id, fecha: params.fecha_format_en, sesion: sesion}).subscribe(data => {
                        if (!data || data.error) {
                          swal('Error', 'Error al verificar vehículos', 'error');return;
                        }
                        let plazasDisponibles = 0;
                        let plazasDisponiblesSinCoche = that.sesiones[i].aforo + that.sesiones[i].plazas_adicionales;
                        that.sesiones[i].aforo_restante = that.sesiones[i].aforo_restante + that.sesiones[i].plazas_adicionales;
                        data.forEach(vehiculo => {
                          plazasDisponibles += vehiculo.plazas_disponibles;
                        });
                        if(that.sesiones[i].aforo_restante > plazasDisponibles){
                          that.sesiones[i].aforo_restante = plazasDisponibles;
                        }
                        if(plazasDisponibles > plazasDisponiblesSinCoche){
                          that.sesiones[i].aforo_restante = plazasDisponiblesSinCoche;
                        }
                      });
                    }
                  }
                  this.IframePostMessages();
                });
              });
            } else {
              // Cálculo disponibilidad normal sin vehículos
              this.sesiones = [];
              this.sesiones_tipo.maniana = [];
              this.sesiones_tipo.tarde = [];
              this.sesiones_tipo.noche = [];
              this.globals.Api('/pub-eve-actividades-sesiones-v2',{id: this.actividad.id, fecha: params.fecha_format_en}).subscribe(data => {
                if (!data || data.error) {
                  swal('Error', 'Error al cargar datos sesiones', 'error');return;
                }
                if (data[1] && data[1].length > 0) {
                  this.sesiones = data[1];
                  if (this.sesiones && this.sesiones.length) {
                    this.sesiones_tipo.maniana = this.GetSesiones('maniana');
                    this.sesiones_tipo.tarde = this.GetSesiones('tarde');
                    this.sesiones_tipo.noche = this.GetSesiones('noche');
                  }

                } else {
                  this.sesiones = data[0];
                  if (this.sesiones && this.sesiones.length) {
                    this.sesiones_tipo.maniana = this.GetSesiones('maniana');
                    this.sesiones_tipo.tarde = this.GetSesiones('tarde');
                    this.sesiones_tipo.noche = this.GetSesiones('noche');
                  }
                }
                var days = ['domingo', 'lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado'];
                var d = new Date(params.fecha_format_en);
                var dayName = days[d.getDay()];
                this.globals.Api('/pub-check-dia-disponible-v2',{id: this.actividad.id, dia: dayName, fecha: params.fecha_format_en}).subscribe(data => {
                  if (!data || data.error) {
                    swal('Error', 'Error al cargar datos sesiones', 'error');return;
                  }
                  if (data[0].count == 0 && data[0].dias_especial_disponible == 0) {
                    this.sesiones = [];
                    this.sesiones_tipo.maniana = [];
                    this.sesiones_tipo.tarde = [];
                    this.sesiones_tipo.noche = [];
                  } else {
                    for(let i = 0; i < this.sesiones.length; i++) {
                      if (this.sesiones[i].plazas_adicionales) {

                      } else {
                        this.sesiones[i].plazas_adicionales = 0;
                      }
                      this.sesiones[i].aforo_restante = this.sesiones[i].aforo_restante + this.sesiones[i].plazas_adicionales;
                      if (!this.actividad.gestion_asientos) {
                        if (this.sesiones[i].aforo_restante < 0) this.sesiones[i].aforo_restante = 0;
                      }
                    }
                    if (this.sesiones && this.sesiones.length) {
                      this.sesiones_tipo.maniana = this.GetSesiones('maniana');
                      this.sesiones_tipo.tarde = this.GetSesiones('tarde');
                      this.sesiones_tipo.noche = this.GetSesiones('noche');
                    }
                  }
                  if (this.actividad.gestion_asientos) {
                    this.sesiones.forEach(item => {
                      item.asientos = item.asientos - item.personas;
                    });
                  }
                  this.IframePostMessages();
                  setTimeout(()=>{
                    $('.content-radio-sesion').click(function() {
                      $('.content-radio-sesion').removeClass('active');
                      $(this).addClass('active');
                    });
                  },100);
                });
                setTimeout(()=>{
                  $('.content-radio-sesion').click(function() {
                    $('.content-radio-sesion').removeClass('active');
                    $(this).addClass('active');
                  });
                },100);
              });
            }
            if (this.actividad.gestion_asientos) {
              this.globals.Api('/eve-actividades-asientos-actividad',{id: this.actividad.id, idioma: LANG}).subscribe(data => {
                if (!data || data.error) {
                  swal('Error', 'Error al cargar datos sesiones', 'error');return;
                }
                this.asientos = data;
                setTimeout(()=>{
                  $('.content-radio-asiento').click(function() {
                    $('.content-radio-asiento').removeClass('active');
                    $(this).addClass('active');
                  });
                },100);
              });
            } else {
              this.globals.Api('/eve-actividades-tarifas-por-persona-actividad',{id: this.actividad.id, fecha: params.fecha_format_en}).subscribe(data => {
                this.tarifas = data;
                this.dataReserva.precio = 0;
                if (this.tarifas && this.tarifas.length) {
                  this.tarifas.forEach(element => {
                    element.cantidad = 0;
                    element.total = 0;
                  });
                }
              });
            }
            this.IframePostMessages();
          });
        }, 100);
      });
    };
    if (this.fecha) {
      date = moment(this.fecha);
      dayclick(date, null, null, null);
    }
    setTimeout(() => {
      $('#calendar').fullCalendar({
          header: {
              left: 'title',
              center: '',
              right: 'today month prev,next'
          },
          defaultView: 'month',
          defaultDate: date,
          locale: LANG,
          allDaySlot: false,
          editable: false,
          droppable: false,
          selectable: false,
          duration: false,
          contentHeight: heightCalendar,
          // //timeFormat: 'H:mm',
          // minTime: '08:00:00',
          // maxTime: '22:00:00',
          viewRender: (view, element) => {
            // this.params.fecha_inicio = view.start.format('YYYY-MM-DD');
            // this.params.fecha_fin = view.end.format('YYYY-MM-DD');
            // this.CargarReparaciones();
            var fechaInicioRender = view.start.format('YYYY-MM-DD');
            var fechaFinRender = view.end.format('YYYY-MM-DD');

            /*Aquí para cargar los datos al calendario*/
            that.CargarEventosCalendario(fechaInicioRender, fechaFinRender);
            $('.fc-day-top.fc-today,.fc-day-top.fc-future').click(function() {
              $('.fc-day-top.fc-today,.fc-day-top.fc-future').css('background-color','');
              $('.fc-day-top.fc-today,.fc-day-top.fc-future').css('color','');
              $(this).css('background-color','#7AAC3F');
              $(this).css('color','white');
            });
          },
          eventRender: function(event, element) {
          },
          eventClick: function(info) {
            if (info.disabled) return;
            $('.fc-event').removeClass('checked');
            $(this).addClass('checked');
          },
          dayClick: dayclick
      });
      this.IframePostMessages();
    }, 200);
  }
  CargarEventosCalendario(fechaInicio:any, fechaFin:any) {
    $('#calendar').fullCalendar('removeEvents');
    const d = new Date();
    const date1 = new Date(fechaInicio);
    let timestampInicio = Math.floor(date1.getTime()/1000);
    const date2 = new Date(fechaFin);
    const date3 = new Date('2022/12/01');
    let timestampFin = Math.floor(date2.getTime()/1000);
    this.reservasDias = [];

    var dateMs = timestampInicio * 1000;
    var dateOb = new Date(dateMs);
    var year = dateOb.getFullYear();
    var month = ("0" + (dateOb.getMonth() + 1)).slice(-2);
    var date = ("0" + dateOb.getDate()).slice(-2);
    var dateFormattedInicio = year+'-'+month+'-'+date;

    var dateMs2 = timestampFin * 1000;
    var dateOb2 = new Date(dateMs2);
    var year2 = dateOb2.getFullYear();
    var month2 = ("0" + (dateOb2.getMonth() + 1)).slice(-2);
    var dateTwo = ("0" + dateOb2.getDate()).slice(-2);
    var dateFormattedFin = year2+'-'+month2+'-'+dateTwo;

    this.globals.Api('/pub-eve-reservas-calendario-actividad-v3', {id_actividad: this.id_actividad, fecha_inicio: dateFormattedInicio, fecha_fin: dateFormattedFin}).subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Error al cargar reservas del calendario', 'error');return;
      }
      this.reservasDias = data;

      this.reservasDias.reservas.forEach(reserva => {
        if(reserva.aforo_predeterminado_asientos != 0){
          reserva.aforo_total_actividades = parseInt(reserva.aforo_predeterminado_asientos) * reserva.sum_sesiones;
          reserva.personas = parseInt(reserva.personas) / reserva.sum_sesiones;
        }
        let aforoTotalRestante = parseInt(reserva.aforo_total_actividades) - parseInt(reserva.personas);
        let sumPorcentajeMismaFecha = (aforoTotalRestante * 100) / parseInt(reserva.aforo_total_actividades);
        if (moment(reserva.fecha).unix() < moment().subtract(1, "days").unix()) return;
        let start = reserva.fecha;
        let end = reserva.fecha;
        let color = '#5da84e';
        let cursor = 'pointer';
        let textColor = '#FFF';
        let display = 'none';
        let className = 'day-green';
        if (sumPorcentajeMismaFecha < 99) {
          color = '#e5b437';
          cursor = 'pointer';
          // className = 'day-yellow';
          if (sumPorcentajeMismaFecha <= 0) {
            color = '#a30404';
            cursor = 'default';
            textColor = '#FFF';
            // className = 'day-red';
          }
        }
        let event = {
          id: reserva.fecha,
          className: 'day-reserva',
          title: `${aforoTotalRestante}/${reserva.aforo_total_actividades}`,
          duration: false,
          description: `
            <div style="cursor: ${cursor}">
              <div><b><i class="fa fa-lock" style="display: ${display}; float: left; margin-right: 5px;"></i> Disponiblidad</b><br/><i class="fa fa-user"></i> ${aforoTotalRestante}/${reserva.aforo_total_actividades} <span style="color: white;"><i class="icofont-check-circled"></i></span></div>
            </div>
          `,
          start: start,
          end: end,
          data: reserva.personas,
          //color: color,
          color: color,
          // textColor: textColor,
          textColor: 'transparent',
          disabled: false,
          tipo: 'documento'
        };
        $('#calendar').fullCalendar('renderEvent', event, true);
      });
      for (let item of $('.fc-day-top')) {
        item = $(item);
        if (!item.hasClass('fc-past')) {
          let tr = item.closest('table').find('tbody tr');
          let index = item.index();
          let tdTemp = $(tr.children('td').get(index));
          if($(tdTemp).hasClass('fc-event-container')) continue;
          $(tr.children('td').get(index)).html(`
              <a class="fc-day-grid-event fc-h-event fc-event fc-start fc-end day-reserva" style="background-color:#5da84e;border-color:#5da84e;color:transparent">
                <div class="fc-content">
                  <span class="fc-title">0000</span>
                </div>
              </a>
            `);
        }
      }
      // $('.fc-row.fc-week.fc-widget-content .fc-content-skeleton tbody td:not(.fc-event-container)').html(`
      //   <a class="fc-day-grid-event fc-h-event fc-event fc-start fc-end day-reserva" style="background-color:#5da84e;border-color:#5da84e;color:transparent">
      //     <div class="fc-content">
      //       <span class="fc-title">0000</span>
      //     </div>
      //   </a>
      // `);
      this.appref.tick();
    });
  }

  SetSesion(item:any, aforo_restante:any) {
    this.dataReserva.precio = 0;
    for (let el of this.sesiones) delete el.active;
    for (let el of this.sesiones_tipo.maniana) delete el.active;
    for (let el of this.sesiones_tipo.tarde) delete el.active;
    for (let el of this.sesiones_tipo.noche) delete el.active;
    for (let el of this.asientos) delete el.active;
    item.active = true;
    this.sesionSelect = item.sesion;
    // console.log(this.sesionSelect);

    //Solo para Canjear
    if (this.canjear) {
      this.dataCanjearBono.puedepaso2 = true;
      this.dataCanjearBono.sesion = item.sesion;
      let plazasBono:number = this.dataCanjearBono.bono.adultos + this.dataCanjearBono.bono.ninios;
      this.plazasDisponibles = parseInt(aforo_restante);
      if (this.plazasDisponibles < plazasBono) {
        this.dataCanjearBono.puedepaso2 = false;
        this.dataCanjearBono.mensajesesiones = '* No ha plazas disponibles para esta sesión';
      }
      return;
    }
    //****************/
    this.globals.Api('/pub-check-hay-reservas',{id_actividad: this.id_actividad, sesion: item.sesion, fecha: this.dataReserva.fecha}).subscribe(data => {
      if (!data || data == '') {
        return;
      }
      this.num_reservas_dias_check = data.num_reservas;
    });
    delete this.dataReserva.codigo_cupon;
    delete this.dataReserva.cupon;
    delete this.dataReserva.tienecupon;
    delete this.dataReserva.mostracuponok;
    delete this.dataReserva.mostracuponko;
    this.dataReserva.id_sesion = item.id;
    this.dataReserva.sesion = item.sesion;
    this.plazasDisponibles = parseInt(aforo_restante);
    let contTotalPlazas = 0;
    for (let el of this.tarifas) {
      el.cantidad = 0;
      el.total = 0;
    }
    if (this.plazasDisponibles <= contTotalPlazas) {
      if (this.tarifas && this.tarifas.length) {
        this.tarifas.forEach((el:any) => {
          el.cantidad = 0;
        });
      }
      $('.custom-mas-icon').removeClass('disabled-custom-input');
      $('.custom-menos-icon').addClass('disabled-custom-input');
    } else {
      $('.custom-menos-icon').addClass('disabled-custom-input');
      $('.custom-mas-icon').removeClass('disabled-custom-input');
    }
    if (this.plazasDisponibles == 0) {
      $('.custom-mas-icon').addClass('disabled-custom-input');
      $('.custom-menos-icon').addClass('disabled-custom-input');
    }
    if (this.actividad.gestion_asientos) {
      // this.tarifas = [];
      // $('.radios-asientos').prop('checked', false);
      // this.sesiones.forEach((el:any) => {
      //   if (el.sesion == item) {
      //     if (el.asientoPersonas) {
      //       el.asientoPersonas.forEach(asientoPersona => {
      //           this.asientos.forEach(asiento => {
      //             if (asiento.nombre == asientoPersona.asiento) {
      //               asiento.plazas = asientoPersona.total_asientos - asientoPersona.personas;
      //             }
      //           });
      //       });
      //     }
      //   }
      // });
      console.log(this.dataReserva);
      this.globals.Api('/pub-check-asientos-adicionales',{id_actividad: this.id_actividad, sesion: item.sesion, fecha: this.dataReserva.fecha}).subscribe(data => {
        if (!data || data == '') {
          this.tarifas = [];
          $('.radios-asientos').prop('checked', false);
          this.sesiones.forEach((el:any) => {
            if (el.sesion == item.sesion) {
              if (el.asientoPersonas) {
                el.asientoPersonas.forEach(asientoPersona => {
                    this.asientos.forEach(asiento => {
                      if (asiento.nombre == asientoPersona.asiento) {
                        asiento.plazas = asientoPersona.total_asientos - asientoPersona.personas;
                        asiento.num_asientos = asientoPersona.total_asientos;
                      }
                    });
                });
              }
            }
          });
          return;
        }
        this.tarifas = [];
        $('.radios-asientos').prop('checked', false);
        this.sesiones.forEach((el:any) => {
          if (el.sesion == item.sesion) {
            if (el.asientoPersonas) {
              el.asientoPersonas.forEach(asientoPersona => {
                  this.asientos.forEach(asiento => {
                    if (asiento.nombre == asientoPersona.asiento) {
                      console.log('=nombre');
                      asiento.plazas = asientoPersona.total_asientos - asientoPersona.personas;
                      if(asiento.id == data.id_asiento){
                        console.log('=id_asiento');
                        asiento.num_asientos = asientoPersona.total_asientos;
                        asiento.num_asientos += data.plazas_asiento_adicionales;
                        asiento.plazas += data.plazas_asiento_adicionales;
                      }
                    }
                  });
              });
            }
          }
        });
        console.log(this.asientos);
      });
    }
    this.IframePostMessages();
  }
  SetAsiento(item:any) {
    this.asientos.map((el:any) => delete el.activo);
    item.activo = true;
    this.dataReserva.precio = 0;
    this.dataReserva.asiento = item;
    this.tarifas = item.tarifas;
    for (let item of this.tarifas) {
      item.cantidad = 0;
      item.total = 0;
      item.puede_menos = false;
      item.puede_mas = true;
      if(item.precio[0].sesion){
        item.precio = item.precio;
      }else{
        item.precio = JSON.parse(item.precio);
      }
      item.precio.forEach(precio => {
        precio.precio = parseFloat(precio.precio);
      });
      // console.log(item.precio);
    }
    // console.log(this.tarifas);
    this.plazasDisponibles = item.num_asientos;
    this.sesiones.forEach(sesion => {
      if (sesion.sesion == this.dataReserva.sesion) {
        if (sesion.asientoPersonas) {
          sesion.asientoPersonas.forEach(item2 => {
            if (item2.id_asiento == item.id) {
              this.plazasDisponibles = this.plazasDisponibles - item2.personas;
            }
          });
        }
      }
    });
    if (this.plazasDisponibles == 0) {
      for (let item of this.tarifas) item.puede_mas = false;
    }
    this.appref.tick();
    this.IframePostMessages();
  }
  Mas(item:any) {
    console.log(this.plazasDisponibles);
    if (this.plazasDisponibles == 0) return;
    if (this.tarifas && this.tarifas.length) {
      this.tarifas.forEach(element => {
        let contTotalPlazas = 0;
        this.tarifas.forEach(item => {
          contTotalPlazas += item.cantidad;
        });
        if (this.plazasDisponibles <= contTotalPlazas) {
          item.puede_mas = false;
          return;
        }
        if (element.id == item.id) {
          element.cantidad++;
          element.precio.forEach(precio => {
            if(precio.sesion == this.sesionSelect){
              element.total += precio.precio;
              this.dataReserva.precio += precio.precio;
            }
          });
          item.puede_menos = true;
        }
        contTotalPlazas = 0;
        this.tarifas.forEach(item => {
          contTotalPlazas += item.cantidad;
        });
        if (this.plazasDisponibles <= contTotalPlazas) {
          item.puede_mas = false;
          return;
        }
      });
    }
    this.IframePostMessages();
  }
  Menos(item:any) {
    if (this.plazasDisponibles == 0) return;
    if (this.tarifas && this.tarifas.length) {
      this.tarifas.forEach(element => {
        if (element.id == item.id) {
          if (element.cantidad == 0) {
            item.puede_menos = false;
            return;
          }
          if (!item.puede_menos) return;
          element.cantidad--;
          element.precio.forEach(precio => {
            if(precio.sesion == this.sesionSelect){
              element.total -= precio.precio;
              this.dataReserva.precio -= precio.precio;
            }
          });
          if (element.cantidad <= 0) {
            item.puede_menos = false;
          }
        }
      });
    }
    item.puede_mas = true;
    this.IframePostMessages();
  }
  Continuar(paso:string = 'datos') {
    if (paso == 'datos') {
      if (!this.dataReserva.precio) return;
      if (!this.num_reservas_dias_check || this.num_reservas_dias_check == 0) {
        let contTotalPlazas = 0;
        if (this.tarifas && this.tarifas.length) {
          this.tarifas.forEach(item => {
            contTotalPlazas += item.cantidad;
          });
        }
        if (this.actividad.aforo_minimo > contTotalPlazas) {
          let msg:string = LG('Debes de seleccionar un mínimo de {{x}} plazas para esta sesión');
          msg = msg.replace('{{x}}', this.actividad.aforo_minimo);
          swal(LG('¡Atención!'), msg, 'warning');
          return;
        }
        let adulto = false;
        if (this.tarifas && this.tarifas.length) {
          this.tarifas.forEach(element => {
            if (element.nombre.search('Niño') == -1 && element.nombre.search('Niños') == -1 && element.nombre.search('niño') == -1 && element.nombre.search('niños') == -1
                && element.nombre.search('Children') == -1 && element.nombre.search('Childrens') == -1 && element.nombre.search('children') == -1 && element.nombre.search('childrens') == -1
                && element.nombre.search('Enfant') == -1 && element.nombre.search('Enfants') == -1 && element.nombre.search('enfants') == -1 && element.nombre.search('enfant') == -1
              ) {
              if (element.cantidad > 0) {
                adulto = true;
              }
            }
          });
        }
        if (!adulto) {
          swal(LG('¡Atención!'), LG('Los niños menores deben de ir acompañados al menos de un adulto'), 'warning');
          return;
        }
      }
    }
    if (paso == 'pago') {
      if (!this.ValidarCampos()) return;
    }
    this.paso = paso;
    this.IframePostMessages();
  }
  ValidarCampos() {
    if (this.dataReserva.fecha == null || this.dataReserva.fecha == '' || this.dataReserva.fecha == '0000-00-00') {
      $.buttoncancelloading('btn-guardar-reserva');
      $.LoadingOverlay("hide", true);
      swal('Error', LG('Fecha incorrecta'), 'error');
      return false;
    }
    if (this.dataReserva.sesion == null || this.dataReserva.sesion == '' && !this.regalar) {
      $.buttoncancelloading('btn-guardar-reserva');
      $.LoadingOverlay("hide", true);
      swal('Error', LG('Sesión incorrecta'), 'error');
      return false;
    }
    if (!this.dataReserva.nombre || !this.dataReserva.apellidos || this.dataReserva.nombre == '' || this.dataReserva.apellidos == '') {
      $.buttoncancelloading('btn-guardar-reserva');
      $.LoadingOverlay("hide", true);
      swal('Error', LG('Rellena el nombre y los apellidos'), 'error');
      return false;
    }
    if (!this.dataReserva.telefono || this.dataReserva.telefono == '') {
      $.buttoncancelloading('btn-guardar-reserva');
      $.LoadingOverlay("hide", true);
      swal('Error', LG('Rellena el teléfono'), 'error');
      return false;
    }
    if (!this.dataReserva.email || this.dataReserva.email == '') {
      $.buttoncancelloading('btn-guardar-reserva');
      $.LoadingOverlay("hide", true);
      swal('Error', LG('Rellena el email'), 'error');
      return false;
    }
    if (!this.globals.ValidateEmail(this.dataReserva.email)) {
      swal('Error', 'Email incorrecto', 'error');
      return false;
    }
    if (!this.dataReserva.cli_codpostal || this.dataReserva.cli_codpostal == '') {
      $.buttoncancelloading('btn-guardar-reserva');
      $.LoadingOverlay("hide", true);
      swal('Error', LG('Rellena el código postal'), 'error');
      return false;
    }
    if (
      !$('#check-politicas-privacidad').is(':checked')
    ) {
      $.buttoncancelloading('btn-guardar-reserva');
      $.LoadingOverlay("hide", true);
      swal('Política de privacidad', 'Debe marcar que ha leído y acepta la política de privacidad para continuar', 'error');
      return false;
    }
    return true;
  }
  SetLocalidad() {
    this.globals.Api('/localidad', {codpostal: this.dataReserva.cli_codpostal}).subscribe(data => {
      if (!data || data.error) return;
      this.dataReserva.cli_localidad = data.localidad;
      this.dataReserva.cli_provincia = data.provincia;
    }, error => {});
  }
  ComprobarCupon() {
    if (!this.dataReserva.codigo_cupon || this.dataReserva.codigo_cupon.trim() == '') return;
    if (!this.dataReserva.email || this.dataReserva.email.trim() == '') return;
    if (!this.dataReserva.precio) return;
    this.dataReserva.cupon = null;
    this.dataReserva.total = null;
    this.dataReserva.precio_original = this.dataReserva.precio;
    clearTimeout($.idtimecupon);
    $.idtimecupon = setTimeout(() => {
      this.globals.Api('/pub-check-cupon', {
        codigo: this.dataReserva.codigo_cupon.trim(),
        cli_email: this.dataReserva.email,
        total: this.dataReserva.precio,
        id_actividad: this.id_actividad,
        id_sesion: this.dataReserva.id_sesion,
        fecha: this.dataReserva.fecha
      }).subscribe((data:any) => {
        if (!data) return;
        if (data.valido) {
          this.dataReserva.cupon = data.datos;
          this.dataReserva.tienecupon = true;
          this.dataReserva.mostracuponok = true;
          this.dataReserva.mostracuponko = false;
          if (this.dataReserva.cupon.tipo == 'porcentaje') {
            this.dataReserva.total = this.dataReserva.precio - (this.dataReserva.precio * this.dataReserva.cupon.importe / 100);
          } else {
            this.dataReserva.total = this.dataReserva.precio - this.dataReserva.cupon.importe;
          }
        } else {
          this.dataReserva.mostracuponok = false;
          this.dataReserva.mostracuponko = true;
        }
      });
    }, 400);
  }

  SetFormaPago(item:any) {
    if (!item) return;
    this.dataReserva.forma_pago = item;
    this.dataReserva.cli_tipo_pago = item.forma_pago;
    this.dataReserva.cli_tipo_pago_slug = item.slug;
    this.dataReserva.pagos = [{
      cli_tipo_pago: this.dataReserva.cli_tipo_pago,
      cli_tipo_pago_slug: this.dataReserva.cli_tipo_pago_slug
    }];
    if (item.slug == 'stripe') {
      $('#stripe-form #card-element').html('');
      $('#stripe-form #card-errors').html('');
      $('#stripe-form').hide();
      setTimeout(() => {
        if (!this.globals.config.pk_test || this.globals.config.pk_test == '') {
          swal('Error cargar Stripe', 'Error al cargar método de pago', 'error');
          this.AtrasPago();
          return;
        }
        if (!this.globals.config.pk_live || this.globals.config.pk_live == '') {
          swal('Error cargar Stripe', 'Error al cargar método de pago', 'error');
          this.AtrasPago();
          return;
        }
        let that = this;
        if (this.globals.config.st_entorno_pruebas) {
          this.stripe = Stripe(this.globals.config.pk_test);
        } else {
          this.stripe = Stripe(this.globals.config.pk_live);
        }
        var elements = this.stripe.elements();
        $('#stripe-form').show();
        var style = {
          base: {
            fontSize: '16px',
            color: 'var(--color-primary)',
          },
        };
        this.stripe_card = elements.create('card', {style: style, hidePostalCode: true});
        this.stripe_card.mount('#card-element');
        this.appref.tick();
      }, 400);
      return;
    }
    this.Pago();
    this.appref.tick();
  }
  AtrasPago() {
    delete this.dataReserva.forma_pago;
    delete this.dataReserva.cli_tipo_pago;
    delete this.dataReserva.cli_tipo_pago_slug;
    delete this.dataReserva.pagos;
    this.IframePostMessages();
  }

  Pago() {
    window.parent.postMessage('btn-guardar-reserva', '*');
    $.buttonloading('btn-guardar-reserva', 'Realizando pago ...');
    $.LoadingOverlay("show", {
      background: 'rgba(255, 255, 255, 0.6)',
      imageResizeFactor: 0.5,
      text: LG('Enviando datos ...')
    });
    if (this.dataReserva.cli_tipo_pago == undefined) {
      $.buttoncancelloading('btn-guardar-reserva');
      $.LoadingOverlay("hide", true);
      swal('Error', 'Selecciona un tipo de pago', 'warning');
      this.AtrasPago();
      return;
    }
    this.GuardarReserva();
  }
  GuardarReserva() {
    this.dataReserva.id_cliente = this.id_cliente;
    this.dataReserva.id_actividad = this.id_actividad;
    if (this.dataReserva.cupon) this.dataReserva.precio = this.dataReserva.total;
    if (this.dataReserva.anticipo_importe && this.dataReserva.anticipo_importe > 0) {
      let fecha = $('#txt-fecha-expiracion').val();
      let hora = $('#txt-hora-expiracion').val();
      if (fecha && fecha != '' && hora && hora != '') {
        this.dataReserva.anticipo_fecha_expiracion = fecha+' '+hora;
      }
    }
    if (this.admin) this.dataReserva.tipo = 'Admin';
    if (this.colaborador) this.dataReserva.tipo = 'Partner';
    this.dataReserva.tarifas = this.tarifas.filter((el:any) => el.cantidad && el.cantidad > 0);

    if (this.dataReserva.forma_pago && this.dataReserva.forma_pago.slug == 'stripe') {
      if (!this.stripe) {
        $.LoadingOverlay("hide", true);
        swal('Error en el pago', 'Revisa los datos de tu tarjeta', 'error');return;
      }
      let that = this;
      this.stripe.createToken(this.stripe_card).then(function(result) {
        if (result.error) {
          $.LoadingOverlay("hide", true);
          var errorElement = document.getElementById('card-errors');
          errorElement.textContent = result.error.message;
          $.buttoncancelloading('btn-guardar-reserva');
        } else {
          that.dataReserva.stripe_token = result.token.id;
          that.globals.Api('/pub-stripe-pago', that.dataReserva).subscribe(data => {
            if (!data || !data.value) {
              $.LoadingOverlay("hide", true);
              swal('Error en el pago', 'Revisa los datos de tu tarjeta', 'error');return;
            }
            setTimeout(() => {
              that.dataReserva.stripe_order = data.value;
              that.EnviarDatosReserva();
            }, 200);
          }, error => {
            $.LoadingOverlay("hide", true);
            $.buttoncancelloading('btn-guardar-reserva');
            swal('Error en el pago', 'Revisa los datos de tu tarjeta', 'error');
          });
        }
      });
      return;
    }
    this.EnviarDatosReserva();
  }
  EnviarDatosReserva() {
    this.globals.Api('/pub-reservar-actividad', this.dataReserva).subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Error al hacer la reserva', 'error');
        $.LoadingOverlay("hide", true);
        $.buttoncancelloading('btn-guardar-reserva');
        this.AtrasPago();
        return;
      }

      this.dataReserva.id = data.id;
      this.dataReserva.referencia = data.referencia;
      this.dataReserva.token_reserva = data.token;

      if (this.dataReserva.cli_tipo_pago_slug == 'addon-payments') {
        this.OpenAddonPayments();
        return;
      }
      if (data.form && data.form != '') {
        $('body').append(data.form);
        setTimeout(function() {
          if ($('#paypal-form').length) {
            $('#paypal-form').submit();
            $('#paypal-form').remove();
          }
          if ($('#redsys-form').length) {
            $('#redsys-form').submit();
            $('#redsys-form').remove();
          }
          $.LoadingOverlay("hide", true);
        }, 100);
      } else {
        $.LoadingOverlay("hide", true);
        if (this.canjear) {
          this.globals.Api('/pub-get-reserva', {id: data.value}).subscribe((data:any) => {
            if (!data || !data.id) return;
            this.paso = 'confirmacion';
            this.dataReserva = data;
            this.appref.tick();
            this.IframePostMessages();
          });
          return;
        }
        let url = '/confirmacion-reserva?cliente='+this.id_cliente+'&token='+this.dataReserva.token_reserva;
        if (this.estilo) url += '&estilo=' + this.estilo;
        if (this.bgcolor) url += '&bgcolor=' + this.bgcolor;
        this.ngZone.run(() => this.router.navigateByUrl(url)).then();
      }
    }, error => {
      $.LoadingOverlay("hide", true);
      $.buttoncancelloading('btn-guardar-reserva');
      this.AtrasPago();
    });
  }
  OpenAddonPayments() {
    if (this.dataReserva.cli_tipo_pago_slug == 'addon-payments') {
      $.LoadingOverlay("show", {
        background: 'rgba(255, 255, 255, 0.6)',
        imageResizeFactor: 0.5,
        text: 'Espere ...'
      });
      setTimeout(() => {
        this.dataReserva.id_cliente = this.id_cliente;
        this.dataReserva.total = this.dataReserva.precio;
        this.globals.Api('/pub-addon-payments-form', this.dataReserva).subscribe((data:any) => {
          if (!data) {
            $.LoadingOverlay("hide", true);
            swal('Lo sentimos, se ha producido un error', 'error');
            return;
          }
          // console.log(data.HPP_POST_RESPONSE);
          RealexHpp.setHppUrl(data.PAY_URL);
          setTimeout(() => {
            $.LoadingOverlay("hide", true);
            $('.blackdrop').show();
            RealexHpp.embedded.init("autoload", "iframe-addon-payments",
              (answer, close) => {
                // console.log('embed answer', answer);
                close();
                if (answer.AUTHCODE) {
                  $.LoadingOverlay("show", {
                    background: 'rgba(255, 255, 255, 0.6)',
                    imageResizeFactor: 0.5,
                    text: 'Espere ...'
                  });
                  this.globals.Api('/pub-addon-payments-ipn', {orderid: data.ORDER_ID}).subscribe(data => {
                    // console.log(data);
                    if (!data || !data.value) {
                      $.LoadingOverlay("hide", true);
                      swal('Lo sentimos, se ha producido un error', 'error');
                      return;
                    }
                    swal('¡Reserva realizada con éxito!', 'Recibirá un correo a la dirección facilitada con la confirmación de la reserva.', 'success');
                    let url = '/confirmacion-reserva?cliente='+this.id_cliente+'&token='+this.dataReserva.token_reserva;
                    if (this.estilo) url += '&estilo=' + this.estilo;
                    if (this.bgcolor) url += '&bgcolor=' + this.bgcolor;
                    this.ngZone.run(() => this.router.navigateByUrl(url)).then();
                  });
                } else {
                  swal('Lo sentimos, se ha producido un error', 'error');
                  $('.blackdrop').hide();
                  // console.log(answer.MESSAGE);
                  this.globals.Api('/pub-addon-payments-fallida', {id: this.dataReserva.id}).subscribe();
                  $('.iframe-container').html(`<iframe id="iframe-addon-payments" frameborder="0" style="display:none"></iframe>`);
                }
              }, data, { onResize: function(data) {
                $('#iframe-addon-payments').css(data)
              }});
            window.addEventListener('message', response => {
              // console.log(response.data);
            }, false);
          }, 100);
          $('body').addClass('loaded');
        });
      }, 200);
    }
  }
  CalcularPrecioTarifa(precio = 0, cantidad = 0){
    return precio*cantidad;
  }
}
