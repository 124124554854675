import { ConditionalExpr } from '@angular/compiler';
import { Component, OnInit, NgZone, ApplicationRef, ViewEncapsulation  } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let ICAL: any;
declare let Chart: any;
declare let toastr: any;

@Component({
  selector: 'app-backend-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit {
  public data:any = {};
  public aforo = [];
  public evento:any = {};
  public eventos:any = [];
  public reservasDias = [];
  public mesesAforo:any = [];
  public historicoReservas:any = [];
  public actividades:any = [];
  public idCalendario:any;
  public primeraCargaCalendario = true;
  public sesiones:any = [];
  public idUsuario:any;
  public dataReserva:any = {
    id_actividad:    '',
    fecha:        '',
    sesion:       '',
    tarifas:      [],
    nombre:       '',
    apellidos:    '',
    email:        '',
    telefono:     '',
    precio:       0,
    tipoPago:     1,
    cli_provincia: 'Elige provincia'
  };
  public plazasDisponibles:any = 50;
  public rankingColaboradores:any;

  public contadorReservas:any = 0;
  public myChart:any;
  public num_reservas_dias_check = 0;

  public asientos:any = [];
  public tarifas:any = [];

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    // this.idUsuario = this.globals.me.id_rol;
    if(!this.globals.cliente && this.globals.me.id_rol == 0){
      this.ngZone.run(() => this.router.navigateByUrl('/dashboard-superadmin')).then();
      return;
    }
    switch(this.globals.me.id_rol){
      case 0:{
        this.loadDataAdmin();
        this.dataReserva.tipo = 'Admin';
      } break;
      case 1:{
        this.loadDataAdmin();
        this.dataReserva.tipo = 'Admin';
      } break;
      case 4:{
        this.dataReserva.id_colaborador = this.globals.me.id;
        this.dataReserva.tipo = 'Partner';
        this.loadDataColaborador();
      } break;
      default:{
      } break;
    }
  }

  loadDataAdmin(){
    this.globals.Api('/datos-generales').subscribe(data => {
      if (!data) return;
      this.data = data;
      this.CargarGrafica();
      $('#pago-en-mano').click();
    });
    this.globals.Api('/ranking-colaboradores').subscribe(data => {
      if (!data) return;
      this.rankingColaboradores = data;
    });
    this.globals.historico = setInterval(() => {
      //toastr.warning(`Actualizando datos...`);
      this.globals.Api('/datos-generales').subscribe(data => {
        if (!data) return;
        this.data = data;
        this.CargarGrafica();
        this.loadHistoricoReservas();
        this.globals.Api('/ranking-colaboradores').subscribe(data => {
          if (!data) return;
          this.rankingColaboradores = data;
          setTimeout(() => {
            //toastr.success(`¡Datos actualziados!`);
          }, 2000);
        });
      });
    },120000);
    setTimeout(() => {
      this.idCalendario = 1;
      this.dataReserva.id_actividad = this.idCalendario;
      this.LoadAforo();
    }, 100);
    this.loadHistoricoReservas();
    this.loadActividades();
    let that = this;
    setTimeout(() => {
      $('#selector-actividades-calendario').val('5');
      $('#selector-actividades-calendario').change(function(){
        $.LoadingOverlay("show", {
          background: 'rgba(255, 255, 255, 0.7)',
          imageResizeFactor: 0.5
        });
        that.idCalendario = $(this).val();
        that.LoadAforo();
      });
    }, 1000);
  }
  loadDataColaborador(){
    this.globals.Api('/datos-generales').subscribe(data => {
      if (!data) return;
      this.data = data;
    });
    setTimeout(() => {
      this.LoadAforo();
      this.CargarGrafica();
    }, 100);
    this.loadContadorReservasColaborador();
    this.loadActividades();
    let that = this;
    setTimeout(() => {
      $('#selector-actividades-calendario').val('5');
      $('#selector-actividades-calendario').change(function(){
        $.LoadingOverlay("show", {
          background: 'rgba(255, 255, 255, 0.7)',
          imageResizeFactor: 0.5
        });
        that.idCalendario = $(this).val();
        that.LoadAforo();
      });
      $('#custom-menos-adulto').click(function(){
        if(!$(this).hasClass('disabled-custom-input')){
          that.dataReserva.adultos--;
          if(that.dataReserva.adultos == 0){
            $(this).addClass('disabled-custom-input');
          }
          that.calcularPrecioModal();
          if(that.plazasDisponibles > (that.dataReserva.adultos + that.dataReserva.ninios + that.dataReserva.niniosGratis)){
            $('#custom-mas-adulto').removeClass('disabled-custom-input');
            $('#custom-mas-ninio').removeClass('disabled-custom-input');
            $('#custom-mas-ninio-gratis').removeClass('disabled-custom-input');
          }
        }
      });
      $('#custom-mas-adulto').click(function(){
        if(!$(this).hasClass('disabled-custom-input')){
          that.dataReserva.adultos++;
          $('#custom-menos-adulto').removeClass('disabled-custom-input');
          that.calcularPrecioModal();
          if(that.plazasDisponibles <= (that.dataReserva.adultos + that.dataReserva.ninios + that.dataReserva.niniosGratis)){
            $(this).addClass('disabled-custom-input');
            $('#custom-mas-ninio').addClass('disabled-custom-input');
            $('#custom-mas-ninio-gratis').addClass('disabled-custom-input');
          }else{
            $(this).removeClass('disabled-custom-input');
            $('#custom-mas-ninio').removeClass('disabled-custom-input');
            $('#custom-mas-ninio-gratis').removeClass('disabled-custom-input');
          }
        }
      });
      $('#custom-menos-ninio').click(function(){
        if(!$(this).hasClass('disabled-custom-input')){
          that.dataReserva.ninios--;
          if(that.dataReserva.ninios == 0){
            $(this).addClass('disabled-custom-input');
          }
          that.calcularPrecioModal();
          if(that.plazasDisponibles > (that.dataReserva.adultos + that.dataReserva.ninios + that.dataReserva.niniosGratis)){
            $('#custom-mas-adulto').removeClass('disabled-custom-input');
            $('#custom-mas-ninio').removeClass('disabled-custom-input');
            $('#custom-mas-ninio-gratis').removeClass('disabled-custom-input');
          }
        }
      });
      $('#custom-mas-ninio').click(function(){
        if(!$(this).hasClass('disabled-custom-input')){
          that.dataReserva.ninios++;
          $('#custom-menos-ninio').removeClass('disabled-custom-input');
          that.calcularPrecioModal();
          if(that.plazasDisponibles <= (that.dataReserva.adultos + that.dataReserva.ninios + that.dataReserva.niniosGratis)){
            $(this).addClass('disabled-custom-input');
            $('#custom-mas-adulto').addClass('disabled-custom-input');
            $('#custom-mas-ninio-gratis').addClass('disabled-custom-input');
          }else{
            $(this).removeClass('disabled-custom-input');
            $('#custom-mas-ninio-gratis').removeClass('disabled-custom-input');
            $('#custom-mas-adulto').removeClass('disabled-custom-input');
          }
        }
      });
      $('#custom-menos-ninio-gratis').click(function(){
        if(!$(this).hasClass('disabled-custom-input')){
          that.dataReserva.niniosGratis--;
          if(that.dataReserva.niniosGratis == 0){
            $(this).addClass('disabled-custom-input');
          }
          if(that.plazasDisponibles > (that.dataReserva.adultos + that.dataReserva.ninios + that.dataReserva.niniosGratis)){
            $('#custom-mas-adulto').removeClass('disabled-custom-input');
            $('#custom-mas-ninio').removeClass('disabled-custom-input');
            $('#custom-mas-ninio-gratis').removeClass('disabled-custom-input');
          }
        }
      });
      $('#custom-mas-ninio-gratis').click(function(){
        if(!$(this).hasClass('disabled-custom-input')){
          that.dataReserva.niniosGratis++;
          $('#custom-menos-ninio-gratis').removeClass('disabled-custom-input');
          if(that.plazasDisponibles <= (that.dataReserva.adultos + that.dataReserva.ninios + that.dataReserva.niniosGratis)){
            $(this).addClass('disabled-custom-input');
            $('#custom-mas-ninio').addClass('disabled-custom-input');
            $('#custom-mas-adulto').addClass('disabled-custom-input');
          }else{
            $(this).removeClass('disabled-custom-input');
            $('#custom-mas-ninio').removeClass('disabled-custom-input');
            $('#custom-mas-adulto').removeClass('disabled-custom-input');
          }
        }
      });
    }, 1000);
  }
  //Checkeamos si hay aforos configurados
  LoadAforo(){
    this.globals.Api('/eve-actividades',{'id': this.idCalendario}).subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Error al validar el aforo', 'error');return;
      }
      //console.log(data[0].sesiones);
      // this.sesiones = data[0].sesiones.split(',');
      this.sesiones.pop();
    });
    this.globals.Api('/eve-actividades-aforo-v2-calendario',{'id_actividad': this.idCalendario, 'oculto': true}).subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Error al validar el aforo', 'error');return;
      }
      this.aforo = data;
      // console.log(this.aforo);
      var month = '';
      this.aforo.forEach(item =>{
        item.percentage = (100 * item.aforo_restante) / item.aforo_total;
        var dt = new Date(item.fecha);
        var monthYear:any = {};
        item.mes = dt.toLocaleString('es-ES', { month: 'long' });
        monthYear.month = dt.toLocaleString('es-ES', { month: 'long' });
        monthYear.year = item.anio = dt.getFullYear();
        if(month != monthYear.month){
          month = monthYear.month;
          this.mesesAforo.push(monthYear);
        }
      });
      if(this.primeraCargaCalendario){
        this.CargarCalendario();
        this.primeraCargaCalendario = false;
      }else{
        //this.CargarEventosCalendario();
        $.LoadingOverlay("hide");
      }
    });
  }

  CargarCalendario() {
    var date = new Date();
    var d = date.getDate();
    var m = date.getMonth();
    var y = date.getFullYear();
    let that = this;
    var heightCalendar = 425;
    if($(window).width() <= 1600 && $(window).width() > 1440){
      heightCalendar = 390;
    }
    if($(window).width() <= 1440 && $(window).width() > 1025){
      heightCalendar = 380;
    }
    if($(window).width() <= 500){
      heightCalendar = 340;
    }
    $('#calendar').fullCalendar({
        header: {
            left: 'title',
            center: '',
            right: 'today month prev,next'
        },
        defaultView: 'month',
        defaultDate: new Date(),
        locale: 'es',
        allDaySlot: false,
        editable: false,
        droppable: false,
        selectable: false,
        duration: false,
        contentHeight: heightCalendar,
        // //timeFormat: 'H:mm',
        // minTime: '08:00:00',
        // maxTime: '22:00:00',
        viewRender: (view, element) => {
          var fechaInicioRender = view.start.format('YYYY-MM-DD');
          var fechaFinRender = view.end.format('YYYY-MM-DD');
          /*Aquí para cargar los datos al calendario*/
          that.CargarEventosCalendario(fechaInicioRender, fechaFinRender);
          $('.fc-day-top').mouseenter(function(){
            $('.content-hover-aforo').remove();
            var fechaDiaHover = $(this).attr('data-date');
            var aforoDia = [];
            var bloqueado = true;
            var sesionesDiaSelect = [];
            that.aforo.forEach(element => {
              if(element.ocultar == 1){
                return;
              }
              if(element.fecha == fechaDiaHover){
                //console.log(element);
                //console.log(that.sesiones);
                that.sesiones.forEach(element2 => {
                  if(element.sesion == element2){
                    aforoDia.push(element.aforo_restante);
                    sesionesDiaSelect.push(element.sesion);
                    bloqueado = false;
                  }
                });
              }
            });
            if(bloqueado) return;

            var htmlAforo = `<div class="content-hover-aforo display-flex style="padding-left: 10px; padding-right: 10px;">`;
            for(var i = 0; i < aforoDia.length; i++){
              htmlAforo += `<div style="padding-bottom: 10px; width: 50%; padding-top: 10px;">`+sesionesDiaSelect[i]+` <br/><span>`+aforoDia[i]+` plazas</span></div>`;
            }
            htmlAforo += `</div>`;
            $(this).append(htmlAforo);
            $('.fc-day-grid.fc-unselectable>div:first-child .content-hover-aforo').addClass('bottom-hover');
            $('.fc-day-grid.fc-unselectable>div:first-child:hover').addClass('z-index-9999');
          });
          $('.fc-day-top').mouseleave(function(){
            $('.content-hover-aforo').remove();
            $('.fc-day-grid.fc-unselectable>div:first-child').removeClass('z-index-9999');
          });
        },
        eventRender: function(event, element) {
          //element.find('.fc-title').append(`<div class="descripcion-evento">${event.description}</div>`);
        },
        eventClick: function(info) {
          if (info.disabled) return;
          $('.fc-event').removeClass('checked');
          $(this).addClass('checked');
        },
        dayClick: function(date, allDay, jsEvent, view) {
          let fecha = moment(date).format('YYYY-MM-DD');
          if(moment(date).format('YYYY-MM-DD') != moment().format('YYYY-MM-DD')){
            if(moment()>moment(date)){
              swal({
                title: "Vas a añadir una reserva de un día pasado, ¿deseas continuar?",
                type: "warning",
                showCancelButton: true,
                confirmButtonClass: "btn-primary",
                confirmButtonText: "Sí",
                cancelButtonText: "No",
                closeOnConfirm: true
              },() => {
                if ($.NuevaReservaGeneral) $.NuevaReservaGeneral(fecha);
              });
              return;
            }
          }
          if ($.NuevaReservaGeneral) $.NuevaReservaGeneral(fecha);
          return;
          let params = {
            fecha: date,
            fecha_format: that.globals.DateString(new Date(date).toDateString()),
            fecha_format_en: that.globals.DateStringEN(new Date(date).toDateString())
          };
          if ($.editcitashow) $.editcitashow(params);
          var today = new Date();
          var tempDate = new Date(date);
          if(today > tempDate){
            return;
          }
          that.dataReserva.fecha = that.globals.DateStringEN(new Date(date).toDateString());
          $('#date-reservar').val(that.dataReserva.fecha);

          $('#modal-reservar-calendar').modal('show');
            setTimeout(() => {
            that.globals.Api('/eve-actividades',{dia: that.dataReserva.fecha}).subscribe(data => {
              if (!data || data.error) {
                swal('Error', 'Error al cargar datos evento', 'error');return;
              }
              that.eventos = data;
              $('#select-actividad-reservar').off('change');
              $('#select-actividad-reservar').change(function(){
                let idEventoSelect = $(this).val();
                that.dataReserva.ninios = 0;
                that.dataReserva.adultos = 0;
                that.calcularPrecioModal();
                $('#custom-menos-adulto').addClass('disabled-custom-input');
                $('#custom-menos-ninio').addClass('disabled-custom-input');
                if(idEventoSelect == 'default') return;
                that.globals.Api('/eve-actividades',{id: idEventoSelect}).subscribe(data => {
                  if (!data || data.error) {
                    swal('Error', 'Error al cargar datos evento', 'error');return;
                  }
                  that.evento = data[0];
                  if(that.evento.vehiculo){
                    // Cálculo disponibilidad condicionante vehículos
                    that.sesiones = null;
                    that.globals.Api('/eve-actividades-sesiones-vehiculos-v2',{id: that.evento.id, fecha: params.fecha_format_en}).subscribe(data => {
                      if (!data || data.error) {
                        swal('Error', 'Error al cargar datos sesiones', 'error');return;
                      }
                      if(data[1].length > 0){
                        // console.log(data[1]);
                        that.sesiones = data[1];

                      }else{
                        // console.log(data[0]);
                        that.sesiones = data[0];
                      }
                      var days = ['domingo', 'lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado'];
                      var d = new Date(params.fecha_format_en);
                      var dayName = days[d.getDay()];
                      that.globals.Api('/check-dia-disponible-v2',{id: that.evento.id, dia: dayName, fecha: params.fecha_format_en}).subscribe(data => {
                        if (!data || data.error) {
                          swal('Error', 'Error al cargar datos sesiones', 'error');return;
                        }
                        if(data[0].count == 0 && data[0].dias_especial_disponible == 0){
                          that.sesiones = null;
                        }else{
                          // console.log(that.sesiones);
                          for(let i = 0; i <= that.sesiones.length; i++){
                            // console.log(that.sesiones[i].sesion);
                            let sesion = that.sesiones[i].sesion;
                            // console.log(sesion);
                            that.globals.Api('/eve-actividades-check-vehiculos-v2',{id: that.evento.id, fecha: params.fecha_format_en, sesion: sesion}).subscribe(data => {
                              if (!data || data.error) {
                                swal('Error', 'Error al verificar vehículos', 'error');return;
                              }
                              // console.log(data);
                              let plazasDisponibles = 0;
                              data.forEach(vehiculo => {
                                plazasDisponibles += vehiculo.plazas_disponibles;
                              });
                              // console.log(plazasDisponibles);
                              if(that.sesiones[i].aforo_restante > plazasDisponibles){
                                that.sesiones[i].aforo_restante = plazasDisponibles;
                              }
                              that.sesiones[i].aforo_restante = that.sesiones[i].aforo_restante + that.sesiones[i].plazas_adicionales;
                              if(that.sesiones[i].aforo_restante < 0) that.sesiones[i].aforo_restante = 0;
                            });
                          }
                        }
                      });
                    });

                  }else{
                    // Cálculo disponibilidad normal sin vehículos
                    that.sesiones = null;
                    that.globals.Api('/eve-actividades-sesiones-v2',{id: that.evento.id, fecha: params.fecha_format_en}).subscribe(data => {
                      if (!data || data.error) {
                        swal('Error', 'Error al cargar datos sesiones', 'error');return;
                      }
                      if(data[1].length > 0){
                        // console.log(data[1]);
                        that.sesiones = data[1];

                      }else{
                        // console.log(data[0]);
                        that.sesiones = data[0];
                      }
                      var days = ['domingo', 'lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado'];
                      var d = new Date(params.fecha_format_en);
                      var dayName = days[d.getDay()];
                      that.globals.Api('/check-dia-disponible-v2',{id: that.evento.id, dia: dayName, fecha: params.fecha_format_en}).subscribe(data => {
                        if (!data || data.error) {
                          swal('Error', 'Error al cargar datos sesiones', 'error');return;
                        }
                        if(data[0].count == 0 && data[0].dias_especial_disponible == 0){
                          that.sesiones = null;
                        }else{
                          for(let i = 0; i < that.sesiones.length; i++){
                            if(that.sesiones[i].plazas_adicionales){
                              // console.log('if');
                            }else{
                              that.sesiones[i].plazas_adicionales = 0;
                            }
                            that.sesiones[i].aforo_restante = that.sesiones[i].aforo_restante + that.sesiones[i].plazas_adicionales;
                            if(!that.evento.gestion_asientos){
                              if(that.sesiones[i].aforo_restante < 0) that.sesiones[i].aforo_restante = 0;
                            }
                          }
                        }
                        if(that.evento.gestion_asientos){
                          that.sesiones.forEach(item => {
                            item.asientos = item.asientos - item.personas;
                          });
                        }
                      });
                    });
                  }
                  if(that.evento.gestion_asientos){
                    that.globals.Api('/eve-actividades-asientos-actividad',{id: that.evento.id}).subscribe(data => {
                      if (!data || data.error) {
                        swal('Error', 'Error al cargar datos sesiones', 'error');return;
                      }
                      that.asientos = data;
                    });
                  }else{
                    that.globals.Api('/eve-actividades-tarifas-por-persona-actividad',{id: that.evento.id, fecha: params.fecha_format_en}).subscribe(data => {
                      that.tarifas = data;
                      that.tarifas.forEach(element => {
                        element.cantidad = 0;
                        element.total = 0;
                      });
                    });
                  }
                });
              });
            });
          }, 100);
        }
    });
  }
  CargarEventosCalendario(fechaInicio:any, fechaFin:any) {
    $('#calendar').fullCalendar('removeEvents');
    const d = new Date();
    const date1 = new Date(fechaInicio);
    let timestampInicio = Math.floor(date1.getTime()/1000);
    const date2 = new Date(fechaFin);
    const date3 = new Date('2022/12/01');
    let timestampFin = Math.floor(date2.getTime()/1000);
    this.reservasDias = [];

    var dateMs = timestampInicio * 1000;
    var dateOb = new Date(dateMs);
    var year = dateOb.getFullYear();
    var month = ("0" + (dateOb.getMonth() + 1)).slice(-2);
    var date = ("0" + dateOb.getDate()).slice(-2);
    var dateFormattedInicio = year+'-'+month+'-'+date;

    var dateMs2 = timestampFin * 1000;
    var dateOb2 = new Date(dateMs2);
    var year2 = dateOb2.getFullYear();
    var month2 = ("0" + (dateOb2.getMonth() + 1)).slice(-2);
    var dateTwo = ("0" + dateOb2.getDate()).slice(-2);
    var dateFormattedFin = year2+'-'+month2+'-'+dateTwo;

    this.globals.Api('/eve-reservas-calendario-dashboard-v2',{id_actividad: this.idCalendario, fecha_inicio: dateFormattedInicio, fecha_fin: dateFormattedFin}).subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Error al cargar reservas del calendario', 'error');return;
      }
      // console.log(data);
      this.reservasDias = data;

      this.reservasDias.forEach(reserva => {
        let aforoTotalRestante = parseInt(reserva.aforo_total_actividades) - parseInt(reserva.personas);
        let sumPorcentajeMismaFecha = (aforoTotalRestante * 100) / parseInt(reserva.aforo_total_actividades);
        // console.log(sumPorcentajeMismaFecha);

        let start = reserva.fecha;
        let end = reserva.fecha;
        let color = '#5da84e';
        let cursor = 'pointer';
        let textColor = '#FFF';
        let display = 'none';
        let className = 'day-green';
        if(sumPorcentajeMismaFecha < 50){
          color = '#e5b437';
          cursor = 'pointer';
          // className = 'day-yellow';
          if(sumPorcentajeMismaFecha < 25){
            color = '#a30404';
            cursor = 'default';
            textColor = '#FFF';
            // className = 'day-red';
          }
        }
        let event = {
          id: reserva.fecha,
          className: 'day-reserva',
          title: `${aforoTotalRestante}/${reserva.aforo_total_actividades}`,
          duration: false,
          description: `
            <div style="cursor: ${cursor}">
              <div><b><i class="fa fa-lock" style="display: ${display}; float: left; margin-right: 5px;"></i> Disponiblidad</b><br/><i class="fa fa-user"></i> ${aforoTotalRestante}/${reserva.aforo_total_actividades} <span style="color: white;"><i class="icofont-check-circled"></i></span></div>
            </div>
          `,
          start: start,
          end: end,
          data: reserva.personas,
          //color: color,
          color: color,
          // textColor: textColor,
          textColor: 'transparent',
          disabled: false,
          tipo: 'documento'
        };
        // console.log(event);
        $('#calendar').fullCalendar('renderEvent', event, true);
      });
      this.appref.tick();
    });
  }

  CargarGrafica(){
    const labels = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre'
    ];

    const data = {
      labels: labels,
      datasets: [{
        label: 'Reservas',
        fill: false,
        backgroundColor: '#7AAC3F',
        // borderColor: 'rgb(255, 99, 132)',
        borderColor: '#707070',
        data: [
          this.data.total_reservas_enero,
          this.data.total_reservas_febrero,
          this.data.total_reservas_marzo,
          this.data.total_reservas_abril,
          this.data.total_reservas_mayo,
          this.data.total_reservas_junio,
          this.data.total_reservas_julio,
          this.data.total_reservas_agosto,
          this.data.total_reservas_septiembre,
          this.data.total_reservas_octubre,
          this.data.total_reservas_noviembre,
          this.data.total_reservas_diciembre],
        pointRadius: 8,
      }]
    };

    const config = {
      type: 'line',
      data: data,
      options: {
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          }
        }
      }
    };
    if(this.myChart){
      //this.myChart.chart(config);
      const data = {
        labels: labels,
        datasets: [{
          label: 'Reservas',
          fill: false,
          backgroundColor: '#7AAC3F',
          // borderColor: 'rgb(255, 99, 132)',
          borderColor: '#707070',
          data: [
            this.data.total_reservas_enero,
            this.data.total_reservas_febrero,
            this.data.total_reservas_marzo,
            this.data.total_reservas_abril,
            this.data.total_reservas_mayo,
            this.data.total_reservas_junio,
            this.data.total_reservas_julio,
            this.data.total_reservas_agosto,
            this.data.total_reservas_septiembre,
            this.data.total_reservas_octubre,
            this.data.total_reservas_noviembre,
            this.data.total_reservas_diciembre],
          pointRadius: 8,
        }]
      };
      this.myChart.data.datasets.forEach(element => {
        element.data.push(data);
      });
      this.myChart.update();
    }else{
      this.myChart = new Chart(
        document.getElementById('myChart'),
        config
      );
    }
  }
  loadHistoricoReservas(){
    this.globals.Api('/eve-reservas-actividad-historico').subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Error al cargar histórico 1', 'error');return;
      }
      this.historicoReservas = data;
    });
  }
  loadContadorReservasColaborador(){
    this.globals.Api('/eve-reservas-actividad-colaborador',{id: this.globals.me.id}).subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Error al cargar histórico 2', 'error');return;
      }
      this.contadorReservas = data.contador;
    });
  }
  loadActividades(){
    this.globals.Api('/eve-actividades-dashboard').subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Error al cargar actividades', 'error');return;
      }
      this.actividades = data;
    });
  }
  ComprobarGuardarReserva(){
    if(
      this.dataReserva.fecha == null || this.dataReserva.fecha == '' || this.dataReserva.fecha == '0000-00-00' ||
      this.dataReserva.sesion == null || this.dataReserva.sesion == '' ||
      this.dataReserva.adulto == 0 || this.dataReserva.precio == 0 ||
      this.dataReserva.nombre == '' || this.dataReserva.apellidos == '' || this.dataReserva.email == '' || this.dataReserva.telefono == ''
    ){
      swal('Error', 'Rellena todos los datos', 'error');return;
      //setInterval
    }
    if(!this.globals.ValidateEmail(this.dataReserva.email)){
      swal('Error', 'Email incorrecto', 'error');return;
    }
    this.GuardarReserva();
  }
  GuardarReserva(){
    $.LoadingOverlay("show", {
      background: 'rgba(255, 255, 255, 0.7)',
      imageResizeFactor: 0.5,
      text: 'Guardando reserva...'
    });
    this.dataReserva.cli_tipo_pago = $('input[type="radio"][name="pago"]:checked').val();
    this.dataReserva.cli_tipo_pago_slug = $('input[type="radio"][name="pago"]:checked').attr('id');
    this.dataReserva.pagos = [{
      cli_tipo_pago: this.dataReserva.cli_tipo_pago,
      cli_tipo_pago_slug: this.dataReserva.cli_tipo_pago_slug
    }];
    this.globals.Api('/pub-reservar-actividad',this.dataReserva).subscribe(data => {
      if (!data || data.error) {
        $.LoadingOverlay("hide", true);
        swal('Error', 'Error al hacer la reserva', 'error');return;
      }
      $.LoadingOverlay("hide", true);
      swal('Guardado', '¡Reserva hecha con éxito!', 'success');
      $('#modal-reservar-calendar').modal('hide');
      this.cerrarModalCalendario();
      window.location.reload();
    });
  }
  AnteriorModal(){
    $('#content-paso-1').fadeIn();
    $('#content-paso-2').hide();
  }
  SiguienteModal(){
    $('#pago-en-mano').click();
    if($('#select-actividad-reservar').val() == 'default'){
      swal('¡Atención!', 'Debes seleccionar una actividad', 'warning');
      return;
    }
    if(this.num_reservas_dias_check == 0){
      let contTotalPlazas = 0;
      this.tarifas.forEach(item => {
        contTotalPlazas += item.cantidad;
      });
      if(this.evento.aforo_minimo > contTotalPlazas){
        swal('¡Atención!', 'Debes de seleccionar un mínimo de '+this.evento.aforo_minimo+' plazas para esta sesión', 'warning');
        return;
      }
    }
    var radiosSeleccion = $('.radios-sesion');
    var checkRadio = false;
    for(var i = 0; i < radiosSeleccion.length; i++){
      if($(radiosSeleccion[i]).is(':checked')){
        checkRadio = true;
      }
    }
    if(!checkRadio){
      swal('¡Atención!', 'Debe seleccionar una sesión', 'warning');
      return;
    }
    let adulto = false;
    this.tarifas.forEach(element => {
      if(element.nombre.search('Niño') == -1 && element.nombre.search('Niños') == -1 && element.nombre.search('niño') == -1 && element.nombre.search('niños') == -1){
        if(element.cantidad > 0){
          adulto = true;
        }
      }
    });
    if(!adulto){
      swal('¡Atención!', 'Los niños menores deben de ir acompañados al menos de un adulto', 'warning');
      return;
    }
    this.dataReserva.tarifas = this.tarifas;
    $('#content-paso-1').hide();
    $('#content-paso-2').fadeIn();
  }
  calcularPrecioModal(){

  }
  SelectReservaProveedor(event){
    if($('#select-colaboradores-reserva-admin').val() != 'admin'){
      this.dataReserva.id_colaborador = $('#select-colaboradores-reserva-admin').val();
      //console.log($('#select-colaboradores-reserva-admin').val());
      this.dataReserva.tipo = 'Partner';
    }else{
      this.dataReserva.id_colaborador = '';
      this.dataReserva.tipo = 'Admin';
    }
  }
  setSesionCheck(sesion, aforo_restante){
    this.globals.Api('/pub-check-hay-reservas',{id_actividad: this.dataReserva.id_actividad, sesion: sesion, fecha: this.dataReserva.fecha}).subscribe(data => {
      if(!data || data == ''){
        return;
      }
      // console.log(data);
      this.num_reservas_dias_check = data.num_reservas;
    });
    this.dataReserva.sesion = sesion;
    this.plazasDisponibles = parseInt(aforo_restante);
    let contTotalPlazas = 0;
    this.tarifas.forEach(item => {
      contTotalPlazas += item.cantidad;
    });
    if(this.plazasDisponibles <= contTotalPlazas){
      this.tarifas.forEach(item => {
        item.cantidad = 0;
      });
      this.calcularPrecioModal();
      $('.custom-mas-icon').removeClass('disabled-custom-input');
      $('.custom-menos-icon').addClass('disabled-custom-input');
    }else{
      $('.custom-mas-icon').removeClass('disabled-custom-input');
    }
    if(this.plazasDisponibles == 0){
      $('.custom-mas-icon').addClass('disabled-custom-input');
      $('.custom-menos-icon').addClass('disabled-custom-input');
    }
    if(this.evento.gestion_asientos){
      this.tarifas = [];
      $('.radios-asientos').prop('checked', false);
      this.sesiones.forEach(item => {
        if(item.sesion == sesion){
          item.asientoPersonas.forEach(asientoPersona => {
              this.asientos.forEach(asiento => {
                if(asiento.nombre == asientoPersona.asiento){
                  asiento.plazas = asientoPersona.total_asientos - asientoPersona.personas;
                }
              });
          });
        }
      });
    }
  }
  SeleccionarActividad(){
    this.dataReserva.id_actividad = $('#select-actividad-reservar').val();
    this.asientos = [];
  }
  cerrarModalCalendario(){
    this.dataReserva = {
      id_actividad:    '',
      fecha:        '',
      sesion:       '',
      adultos:      0,
      ninios:       0,
      niniosGratis: 0,
      nombre:       '',
      apellidos:    '',
      email:        '',
      telefono:     '',
      precio:       0,
      tipoPago:     1
    };
    $('#pago-en-mano').click();
    this.sesiones = [];
    this.asientos = [];
    this.AnteriorModal();
  }
  setTipoPago(idEstado: any){
    this.dataReserva.id_estado = idEstado;
    // console.log(this.dataReserva.id_estado);
  }
  Editar(item:any) {
    this.globals.Api('/eve-reservas', {id: item.id}).subscribe(data => {
      $('.popover').popover('hide');
      this.globals.passData = JSON.parse(JSON.stringify(data[0]));
      this.ngZone.run(() => this.router.navigateByUrl('/reservas/edit')).then();
    });
  }
  VerColaborador(item:any) {
    this.globals.Api('/usuarios', {id: item.id}).subscribe(data => {
      $('.popover').popover('hide');
      this.globals.passData = JSON.parse(JSON.stringify(data[0]));
      this.ngZone.run(() => this.router.navigateByUrl('/colaboradores/edit')).then();
    });
  }
  setAsientoCheck(item:any){
    this.dataReserva.asiento = item;
    this.tarifas = item.tarifas;
    this.tarifas.forEach(element => {
      element.cantidad = 0;
      element.total = 0;
    });
    this.plazasDisponibles = item.num_asientos;
    this.sesiones.forEach(sesion => {
      if(sesion.sesion == this.dataReserva.sesion){
        sesion.asientoPersonas.forEach(item2 => {
          if(item2.asiento == item.nombre){
            this.plazasDisponibles = this.plazasDisponibles - item2.personas;
          }
        });
      }
    });
  }
  sumCantidadTarifa(item:any){
    this.tarifas.forEach(element => {
      let contTotalPlazas = 0;
      this.tarifas.forEach(item => {
        contTotalPlazas += item.cantidad;
      });
      if(this.plazasDisponibles <= contTotalPlazas){
        $('.custom-mas-icon').addClass('disabled-custom-input');
        return;
      }
      if(element.id == item.id){
        element.cantidad++;
        element.total += element.precio;
        this.dataReserva.precio += element.precio;
        $('#menos-'+item.id).removeClass('disabled-custom-input');
      }
      contTotalPlazas = 0;
      this.tarifas.forEach(item => {
        contTotalPlazas += item.cantidad;
      });
      if(this.plazasDisponibles <= contTotalPlazas){
        $('.custom-mas-icon').addClass('disabled-custom-input');
        return;
      }
    });
  }
  resCantidadTarifa(item:any){
    this.tarifas.forEach(element => {
      if(element.id == item.id){
        if($('#menos-'+item.id).hasClass('disabled-custom-input')) return;
        element.cantidad--;
        element.total -= element.precio;
        this.dataReserva.precio -= element.precio;
        if(element.cantidad <= 0){
          $('#menos-'+item.id).addClass('disabled-custom-input');
        }
      }
    });
    $('.custom-mas-icon').removeClass('disabled-custom-input');
  }
}
