import { Component, OnInit, NgZone } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';
import { Html5Qrcode } from 'src/assets/js/html5-qrcode-master/src';

declare let $: any;
declare let swal: any;
declare let moment: any;

@Component({
  selector: 'app-backend-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
  public html5QrCode:any;
  public lectorabierto:boolean = false;

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public sanitizer: DomSanitizer,
    public globals: Globals
    ) {
  }

  ngOnInit(): void {
    $(document).on('click', '.fab-container .dropdown-item', () => {
      $('.fab-container').removeClass('blur');
      $('.fab-container .dropdown-menu').removeClass('show');
      window.scrollTo(0, 0);
    });
    $(document).on('click', '.fab-container .bg', () => {
      $('.fab-container').removeClass('blur');
      $('.fab-container .dropdown-menu').removeClass('show');
    });
  }
  callCameraQr() {
    Html5Qrcode.getCameras().then(devices => {
      /**
       * devices would be an array of objects of type:
       * { id: "id", label: "label" }
       */
      if (devices && devices.length) {
        this.lectorabierto = true;
        var cameraId = devices[0].id;
        // .. use this to start scanning.
        this.html5QrCode = new Html5Qrcode(/* element id */ "reader");
        $('#close-lector-qr').show();
        window.scrollTo(0, 0);
        $('body').addClass('stop-scrolling');

        this.html5QrCode.start(
          { facingMode: "environment"
          },
          {
            fps: 60,    // Optional, frame per seconds for qr code scanning
            qrbox: { width: 250, height: 250 }  // Optional, if you want bounded box UI
          }, (decodedText, decodedResult) => {
            // do something when code is read
              // let dataQr = decodedText.split(':');
              // if(dataQr.length != 2){
              //   swal('Error', 'QR no válido', 'error');
              // } else {

              // }
              this.globals.Api('/check-reserva-qr', {token_reserva: decodedText}).subscribe(data => {
                if (!data || data.error) {
                  swal('Error', 'Reserva ya validada', 'error');return;
                }
                swal('Correcto','Reserva válida para '+data.value.cli_nombre+' '+data.value.cli_apellidos,'success');
              }, error => {
                swal('Error', 'Se ha producido un error conectando con el servidor', 'error');
              });
              this.html5QrCode.stop().then((ignore) => {
                // QR Code scanning is stopped.
              }).catch((err) => {
                // Stop failed, handle it.
              });
              $('body').removeClass('stop-scrolling');
              $('#close-lector-qr').hide();
              this.lectorabierto = false;
          },
          (errorMessage) => {
            // parse error, ignore it.
          })
        .catch((err) => {
          // Start failed, handle it.
          this.lectorabierto = false;
        });
      } else {
        swal('Error', 'Este dispositivo no tiene cámara', 'error');
        this.lectorabierto = false;
      }
    }).catch(err => {
      swal('Error', 'Este dispositivo no tiene cámara', 'error');
      this.lectorabierto = false;
    });
  }
  cerrarLector() {
    this.lectorabierto = false;
    this.html5QrCode.stop().then((ignore) => {
      // QR Code scanning is stopped.
    }).catch((err) => {
      // Stop failed, handle it.
      swal('Error', 'Error al detener el lector', 'error');
    });
    $('#close-lector-qr').hide();
    $('body').removeClass('stop-scrolling');
  }
  Fab() {
    if (window.location.pathname == '/reservas') {
      this.Opcion('crear-reserva');
      return;
    }
    if (window.location.pathname == '/actividades') {
      this.Opcion('crear-actividad');
      return;
    }
    if (window.location.pathname == '/eventos') {
      this.Opcion('crear-evento');
      return;
    }
    $('.fab-container').toggleClass('blur');
    $('.fab-container .dropdown-menu').toggleClass('show');
  }
  Opcion(option:string = '') {
    switch (option) {
      case 'qr': {
        this.callCameraQr();
      } break;
      case 'crear-reserva': {
        if ($.NuevaReservaGeneral) $.NuevaReservaGeneral();
      } break;
      case 'crear-actividad': {
        this.globals.passData = '';
        this.ngZone.run(() => this.router.navigateByUrl('/actividades/add')).then();
      } break;
      case 'crear-evento': {
        this.globals.passData = '';
        this.ngZone.run(() => this.router.navigateByUrl('/eventos/add')).then();
      } break;
    };
  }

}
