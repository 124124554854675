<div id="page-wrapper" class="page-loading">
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                <div class="content-header">
                    <div class="row">
                        <div class="col-lg-2 col-md-6 col-xs-12 my-auto">
                            <div class="header-section">
                                <h1 class="middle" *ngIf="!misreservas">Reservas</h1>
                                <h1 class="middle" *ngIf="misreservas">Mis reservas</h1>
                                <button class="btn btn-xs btn-secondary middle m-l-10" (click)="Actualizar()"><i class="fa fa-refresh"></i></button>
                            </div>
                            <ul class="breadcrumb breadcrumb-top text-left">
                                <li><a [routerLink]="['/dashboard']">Inicio</a></li>
                                <li *ngIf="!misreservas">Reservas</li>
                                <li *ngIf="misreservas">Mis reservas</li>
                            </ul>
                        </div>
                        <div class="col-lg-8 col-md-8 col-sm-9 col-xs-12 my-auto display-flex">
                          <div id="buscador-header" class="input-group">
                            <span class="input-group-addon cursor" (click)="Buscar()"><i class="fa fa-search"></i></span>
                            <input type="search" placeholder="Actividad, Cliente, Nº Reserva, Email, ..." class="form-control" (keyup.enter)="Buscar()" [(ngModel)]="params.filtro">
                          </div>
                          <label class="col-form-label inline-block m-r-10">Mostrar</label>
                          <select class="form-control inline-block" (change)="Buscar()" [(ngModel)]="params.page_size" style="width:auto;">
                            <option>25</option>
                            <option>50</option>
                            <option>100</option>
                            <option>200</option>
                          </select>
                        </div>
                        <div class="col-lg-2 col-md-2 col-sm-3 col-xs-12 my-auto">
                          <a class="btn btn-primary" (click)="Nuevo()"><i class="fa fa-plus"></i> Añadir reserva</a>
                        </div>
                        <!-- <div class="col-lg-4 col-md-6 col-xs-12 my-auto text-center">

                        </div>
                        <div class="col-lg-2 col-md-6 col-xs-12 my-auto text-center no-wrap">

                        </div> -->
                        <!-- <div class="col-lg-2 col-md-6 col-xs-12 my-auto text-center hide-tablet" *ngIf="!misreservas">
                           <a class="btn btn-primary btn-block" (click)="Nuevo()"><i class="fa fa-plus"></i> Nueva reserva</a>
                        </div> -->
                    </div>
                </div>
                <!-- Table Styles Content -->
                <ul class="nav nav-tabs break-padding m-t--20" data-toggle="tabs">
                  <li class="active"><a href="#tab-vista-dia"><i class="fa fa-calendar"></i> Vista día</a></li>
                  <!-- <li><a href="#tab-vista-calendario"><i class="fa fa-calendar"></i> Vista calendario</a></li> -->
                  <li><a href="#tab-vista-listado"><i class="fa fa-list"></i> Vista listado</a></li>
                </ul>
                <div class="tab-content">
                  <div class="tab-pane active" id="tab-vista-dia">
                    <div class="full break-padding">
                      <a class="btn btn-block btn-primary btn-filtro visible-xs" data-toggle="collapse" href="#filtro"><i class="fa fa-calendar"></i> Filtro</a>
                      <div class="row">
                        <div class="col-lg-4 col-md-12 col-xs-12">
                          <div id="filtro" class="{{width && width <= 768 ? 'collapse' : ''}}">
                            <div class="block full calendar-container">
                              <div class="block-title">
                                  <h2>Calendario</h2>
                              </div>
                              <div id="calendar" class="calendario-listado-reservas"></div>
                              <ng-container *ngIf="[0,1,2].includes(globals.me.id_rol)">
                                <hr style="margin: 4px 0">
                                <div class="row">
                                  <div class="col-lg-6 col-md-6 col xs-12"><a class="btn btn-default btn-block" (click)="ExportarExcel()"><i class="fa fa-file-excel-o"></i> Exportar reservas día</a></div>
                                  <div class="col-lg-6 col-md-6 col xs-12"><a class="btn btn-default btn-block" (click)="ExportarExcelMes()"><i class="fa fa-file-excel-o"></i> Exportar reservas mes</a></div>
                                </div>
                              </ng-container>
                            </div>
                            <div class="block full">
                              <div class="block-title">
                                  <h2>Filtrar por actividad</h2>
                              </div>
                              <select id="select-actividades-header" class="form-control inline-block" (change)="Buscar()">
                                <option default value="">Todas las actividades</option>
                                <option value="{{actividad.id}}" *ngFor="let actividad of actividades">{{actividad.nombre}}</option>
                              </select>
                            </div>
                            <ng-container *ngIf="[0,1,2].includes(globals.me.id_rol)">
                              <div class="block full">
                                <div class="block-title" id="title-guias">
                                  <h2>Guías</h2><a id="asignar-guias" class="btn btn-default float-right margin-right-20" (click)="AsignarGuias()">Asignar guías</a><a id="guardar-guias" class="btn btn-default float-right margin-right-20" style="display: none" (click)="GuardarGuias()">Guardar asignaciones</a>
                                </div>
                                <div class="row" id="guias">
                                  <div class="col-lg-12 content-guias" style="padding-bottom: 10px;" *ngFor="let guia of guias">
                                    <a id="{{guia.id}}" class="btn row" (click)="CheckGuia(guia.id)">
                                      <img class="col-lg-3 col-md-3 col-xs-3" src="{{guia.imagen}}">
                                      <div class="col-lg-9 col-md-9 col-xs-9 nombre-guia">{{guia.nombre}} {{guia.apellidos}} <input type="checkbox" val="{{guia.id}}" style="display: none;"/></div>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                        <div class="col-lg-8 col-md-12 col-xs-12">
                            <div class="block full">
                                <div class="block-title">
                                  <div class="row">
                                    <div class="col-md-12 col-sm-12 display-flex content-count-person-filter">
                                      <h2>
                                          <span class="text-primary m-l-5 font-20" *ngIf="params_dia.fecha_format">Reservas {{params_dia.fecha_format}}</span>
                                          <span class="m-l-15 font-20 m-l-m-0" *ngIf="reservas_calendario && reservas_calendario.length" style="text-transform: none;"><span>Total reservas:</span> {{GetTotalReservas()}}</span>
                                          <span class="m-l-30 font-20" *ngIf="reservas_calendario && reservas_calendario.length" style="text-transform: none;"><span>Nº Personas: </span>{{GetTotalPersonas()}}</span>
                                      </h2>
                                    </div>
                                    <div class="col-md-12 col-sm-12 content-buttons-sesiones">
                                      <div class="btn-sesiones m-t-5 m-b-5 text-left">
                                          <button type="button" class="btn btn-primary btn-todas active m-r-10 line-heigh-1-1" (click)="mostrarTodasSesiones()" style="min-height: 60.78px;">
                                            Todas<br>
                                            <span>Personas: <strong>{{GetTotalPersonas()}}</strong></span>
                                          </button>
                                          <ng-container *ngFor="let sesion of sesiones;let i = index">
                                            <button type="button" class="btn btn-primary sesion-{{i}} m-r-10 line-heigh-1-1" *ngIf="sesion" (click)="mostrarSesion(sesion.sesion, sesion.id_evento, i)">
                                              <i class="icofont-clock-time"></i> Sesión {{sesion.sesion}}<br>
                                              <span><strong>{{sesion.personas}} / {{sesion.aforo}}</strong></span><div class="icon-color-sesion" [style.background-color]="sesion.color"></div>
                                              <div class="progress progress-mini active custom-progress-bar-red" style="height: 12px;
                                                  line-height: 12px;
                                                  width: 100%;
                                                  margin-bottom: 0px;
                                                  margin-top: 4px;
                                                  background-color: #efefef !important">
                                                <div class="progress-bar progress-bar-success" role="progressbar" aria-valuenow="{{sesion.porcentaje}}" aria-valuemin="0" aria-valuemax="100" style="height: 12px;line-height: 12px;" [style.width]="sesion.porcentaje+'%'"></div>
                                              </div>
                                            </button>
                                          </ng-container>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="no-resultados text-center" *ngIf="!reservas_calendario || !reservas_calendario.length">
                                    <div>No existen reservas para esta fecha.</div>
                                    <a class="btn btn-primary m-t-10 m-b-30" (click)="Nuevo()"><i class="fa fa-plus"></i> Añadir reserva</a>
                                </div>
                                <div class="row row-flexbox content-cards-reservas">
                                    <ng-container *ngFor="let item of reservas_calendario">
                                        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12" style="padding: 0 10px;" *ngIf="item.id_estado != 5 && item.id_estado != 6">
                                            <a id="{{item.id}}" href="javascript:void(0)" class="
                                              widget
                                              reserva-restaurante
                                              border-bottom-card
                                              {{item.tipo_slug}} {{item.id_estado == 4 || item.id_estado == 5  || item.id_estado == 6  ? 'bg-tr-cancelada' : null}}
                                              " [style.border-bottom-color]="item.distintivo_color" (click)="CheckActividad(item)">
                                              <div class="row">
                                                <div class="col-lg-8 col-md-12 col-xs-12">
                                                  <h3 class="widget-heading h4 title-actividad-card">
                                                    <i class="{{item.icono_caracteristica_actividad}}" style="font-size: 28px; margin-right: 5px;" *ngIf="item.icono_caracteristica_actividad"></i>
                                                    <strong>{{item.evento}}</strong>
                                                  </h3>
                                                  <div class="text-primary font-bold" *ngIf="item.asiento">{{item.asiento}}</div>
                                                </div>
                                                <div class="col-lg-4 col-md-12 col-xs-12 content-symbol-reserva">
                                                  <span class="badge-reserva">
                                                    <img *ngIf="item.tipo == 'Web'" src="assets/icon-ref-web.svg" />
                                                    <img *ngIf="item.tipo == 'Civitatis'" src="assets/icon-ref-c.svg" />
                                                    <img *ngIf="item.tipo == 'Partner'" src="assets/icon-ref-p.svg" />
                                                    <img *ngIf="item.tipo == 'GetYourGuide'" src="assets/icon-ref-g.svg" />
                                                    <img *ngIf="item.tipo == 'Admin'" src="assets/icon-ref-admin.svg" />
                                                    <img *ngIf="item.tipo == 'Airbnb'" src="assets/icon-ref-airbnb.svg" />
                                                    {{item.tipo}}
                                                  </span>
                                                </div>
                                                <input class="checkbox-actividades" type="checkbox" val="{{item.id}}" style="display: none;"/>
                                              </div>
                                                <div class="widget-content text-right clearfix padding-bottom-0 padding-top-0">
                                                    <div class="pull-left icon-tipo text-left" style="width:45%;">
                                                        <!-- <i class="fa fa-ticket sidebar-nav-icon img-circle img-thumbnail img-thumbnail-avatar"
                                                            style="font-size:35px;text-align:center;color:var(--color-primary);line-height: 1.6;"></i> -->

                                                        <h5 class="text-left h5" *ngIf="item.codigo_bono">Bono: <strong>{{item.codigo_bono}}</strong></h5>
                                                        <h5 class="text-left h5">Fecha: <strong>{{item.fecha_format}}</strong></h5>
                                                        <h5 class="text-left h5" *ngIf="item.tipo_reserva == 'actividad'">Sesión: <strong>{{item.sesion}}h</strong></h5>
                                                        <h5 class="text-left h5" *ngIf="item.tipo_reserva == 'evento'">Hora: <strong>{{item.sesion}}h</strong></h5>
                                                        <h5 class="text-left h5" *ngIf="item.cli_tipo_pago">Pago: <strong>{{item.cli_tipo_pago}}</strong></h5>
                                                        <h5 class="text-left h5" *ngIf="item.id_pago">ID Pago:<br><small class="breakword" style="font-weight:400;">{{item.id_pago}}</small></h5>
                                                        <h5 class="text-left h5">Estado: <strong>{{item.estado}}</strong></h5>
                                                        <h5 class="text-left h5">Asistencia:
                                                          <strong *ngIf="item.validada"><i class="fa fa-check" style="color: green; font-size: 16px;"></i></strong>
                                                          <strong *ngIf="!item.validada"><i class="fa fa-times" style="color: red; font-size: 16px;"></i></strong>
                                                        </h5>
                                                    </div>
                                                    <div class="pull-left icon-tipo text-left" style="width:100%;">
                                                      <h5 class="text-left h5 margin-top-0 margin-bottom-0">Guías:
                                                        <ng-container *ngFor="let guia of item.guias">
                                                          <strong *ngIf="guia"> | {{guia.nombre}} {{guia.apellidos}}</strong>
                                                        </ng-container>
                                                        <ng-container *ngIf="!item.guias[0]">
                                                          <strong>Sin asignar</strong>
                                                        </ng-container>
                                                      </h5>
                                                    </div>
                                                    <span *ngIf="item.id_colaborador" class="autor">Creada por: {{item.nombre_colaborador}}</span>
                                                    <h3 class="widget-heading h4">
                                                      <img src="assets/flag-en.svg" class="idioma" alt="" *ngIf="item.idioma_res == 'en'">
                                                      <img src="assets/flag-it.svg" class="idioma" alt="" *ngIf="item.idioma_res == 'it'">
                                                      <img src="assets/flag-fr.svg" class="idioma" alt="" *ngIf="item.idioma_res == 'fr'">
                                                      <img src="assets/flag-pt.svg" class="idioma" alt="" *ngIf="item.idioma_res == 'pt'">
                                                      <strong class="nombre persona">{{item.cli_nombre}} {{item.cli_apellidos}}</strong>
                                                    </h3>
                                                    <div class="h4 counter-person"><strong>{{item.personas}}</strong> personas</div>
                                                    <div class="h4 nombre-vehiculo" *ngIf="item.vehiculo"><strong>{{item.vehiculo}}: {{item.vehiculo_plazas}}</strong></div>
                                                    <div class="h4 nombre-vehiculo" *ngIf="item.vehiculo_2"><strong>{{item.vehiculo_2}}: {{item.vehiculo_2_plazas}}</strong></div>
                                                    <div class="h4 nombre-vehiculo" *ngIf="item.vehiculo_3"><strong>{{item.vehiculo_3}}: {{item.vehiculo_3_plazas}}</strong></div>
                                                    <div class="h4 nombre-vehiculo" *ngIf="item.vehiculo_4"><strong>{{item.vehiculo_4}}: {{item.vehiculo_4_plazas}}</strong></div>
                                                    <div class="h4 nombre-vehiculo" *ngIf="item.plazas_adicionales"><strong>Plazas adicionales: {{item.plazas_adicionales}}</strong></div>
                                                    <div class="text-muted breakword" *ngIf="item.cli_email"><i class="fa fa-envelope-o"></i> {{item.cli_email}}</div>
                                                    <div class="text-muted" *ngIf="item.cli_telefono"><i class="fa fa-phone"></i> {{item.cli_telefono}}</div>
                                                    <div class="m-t-10">
                                                      <a class="btn btn-sm btn-alert m-l-10" *ngIf="misreservas" (click)="VistaPrevia(item)" href="javascript:void(0)"><i class="fa fa-eye font-14"></i></a>
                                                      <ng-container *ngIf="!misreservas">
                                                        <a class="btn btn-sm btn-primary" (click)="EnviarEmail(item, $event)" *ngIf="item.cli_email && item.id_estado == 3"><i class="fa fa-envelope font-14"></i></a>
                                                        <a class="btn btn-sm btn-warning m-l-10" (click)="Editar(item)" href="javascript:void(0)"><i class="fa fa-pencil font-14"></i></a>
                                                        <a class="btn btn-sm btn-success m-l-10" (click)="EnviarWhatsapp(item, $event)" *ngIf="item.cli_telefono"><i class="fa fa-whatsapp font-14"></i></a>
                                                      </ng-container>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </ng-container>
                                </div>
                                <ng-container *ngIf="GetReservasCanceladas().length">
                                  <h3 class="text-center font-bold">Reservas canceladas o fallidas</h3>
                                  <hr style="border-color:#000;">
                                  <div class="row row-flexbox">
                                      <ng-container *ngFor="let item of GetReservasCanceladas()">
                                          <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12" style="padding: 0 10px;" *ngIf="item.id_estado != 7">
                                            <a id="{{item.id}}" href="javascript:void(0)" class="
                                            widget
                                            reserva-restaurante
                                            border-bottom-card
                                            {{item.tipo_slug}} {{item.id_estado == 4 || item.id_estado == 5  || item.id_estado == 6  ? 'bg-tr-cancelada' : null}}
                                            " [style.border-bottom-color]="item.distintivo_color" (click)="CheckActividad(item.id)">
                                            <div class="row">
                                              <div class="col-lg-8 col-md-12 col-xs-12">
                                                <h3 class="widget-heading h4 title-actividad-card">
                                                  <i class="{{item.icono_caracteristica_actividad}}" style="font-size: 28px; margin-right: 5px;" *ngIf="item.icono_caracteristica_actividad"></i>
                                                  <strong>{{item.evento}}</strong>
                                                </h3>
                                                <div class="text-primary font-bold" *ngIf="item.asiento">{{item.asiento}}</div>
                                              </div>
                                              <div class="col-lg-4 col-md-12 col-xs-12 content-symbol-reserva">
                                                <span class="badge-reserva">
                                                  <img *ngIf="item.tipo == 'Web'" src="assets/icon-ref-web.svg" />
                                                  <img *ngIf="item.tipo == 'Civitatis'" src="assets/icon-ref-c.svg" />
                                                  <img *ngIf="item.tipo == 'Partner'" src="assets/icon-ref-p.svg" />
                                                  <img *ngIf="item.tipo == 'GetYourGuide'" src="assets/icon-ref-g.svg" />
                                                  <img *ngIf="item.tipo == 'Admin'" src="assets/icon-ref-admin.svg" />
                                                  <img *ngIf="item.tipo == 'Airbnb'" src="assets/icon-ref-airbnb.svg" />
                                                  {{item.tipo}}
                                                </span>
                                              </div>
                                              <input class="checkbox-actividades" type="checkbox" val="{{item.id}}" style="display: none;"/>
                                            </div>
                                              <div class="widget-content text-right clearfix padding-bottom-0 padding-top-0">
                                                  <div class="pull-left icon-tipo text-left" style="width:45%;">
                                                      <!-- <i class="fa fa-ticket sidebar-nav-icon img-circle img-thumbnail img-thumbnail-avatar"
                                                          style="font-size:35px;text-align:center;color:var(--color-primary);line-height: 1.6;"></i> -->
                                                      <h5 class="text-left h5" *ngIf="item.codigo_bono">Bono: <strong>{{item.codigo_bono}}</strong></h5>
                                                      <h5 class="text-left h5">Fecha: <strong>{{item.fecha_format}}</strong></h5>
                                                      <h5 class="text-left h5" *ngIf="item.tipo_reserva == 'actividad'">Sesión: <strong>{{item.sesion}}h</strong></h5>
                                                      <h5 class="text-left h5" *ngIf="item.tipo_reserva == 'evento'">Hora: <strong>{{item.sesion}}h</strong></h5>
                                                      <h5 class="text-left h5" *ngIf="item.cli_tipo_pago">Pago: <strong>{{item.cli_tipo_pago}}</strong></h5>
                                                      <h5 class="text-left h5" *ngIf="item.id_pago">ID Pago:<br><small class="breakword" style="font-weight:400;">{{item.id_pago}}</small></h5>
                                                      <h5 class="text-left h5">Estado: <strong>{{item.estado}}</strong></h5>
                                                      <h5 class="text-left h5">Asistencia:
                                                        <strong *ngIf="item.validada"><i class="fa fa-check" style="color: green; font-size: 16px;"></i></strong>
                                                        <strong *ngIf="!item.validada"><i class="fa fa-times" style="color: red; font-size: 16px;"></i></strong>
                                                      </h5>
                                                  </div>
                                                  <div class="pull-left icon-tipo text-left" style="width:100%;">
                                                    <h5 class="text-left h5 margin-top-0 margin-bottom-0">Guías:
                                                      <ng-container *ngFor="let guia of item.guias">
                                                        <strong *ngIf="guia"> | {{guia.nombre}} {{guia.apellidos}}</strong>
                                                      </ng-container>
                                                      <ng-container *ngIf="!item.guias[0]">
                                                        <strong>Sin asignar</strong>
                                                      </ng-container>
                                                    </h5>
                                                  </div>
                                                  <span *ngIf="item.id_colaborador" class="autor">Creada por: {{item.nombre_colaborador}}</span>
                                                  <h3 class="widget-heading h4">
                                                    <img src="assets/flag-en.svg" class="idioma" alt="" *ngIf="item.idioma_res == 'en'">
                                                    <img src="assets/flag-it.svg" class="idioma" alt="" *ngIf="item.idioma_res == 'it'">
                                                    <img src="assets/flag-fr.svg" class="idioma" alt="" *ngIf="item.idioma_res == 'fr'">
                                                    <img src="assets/flag-pt.svg" class="idioma" alt="" *ngIf="item.idioma_res == 'pt'">
                                                    <strong class="nombre persona">{{item.cli_nombre}} {{item.cli_apellidos}}</strong>
                                                  </h3>
                                                  <div class="h4 counter-person"><strong>{{item.personas}}</strong> personas</div>
                                                  <div class="h4 nombre-vehiculo" *ngIf="item.vehiculo"><strong>{{item.vehiculo}}: {{item.vehiculo_plazas}}</strong></div>
                                                  <div class="h4 nombre-vehiculo" *ngIf="item.vehiculo_2"><strong>{{item.vehiculo_2}}: {{item.vehiculo_2_plazas}}</strong></div>
                                                  <div class="h4 nombre-vehiculo" *ngIf="item.vehiculo_3"><strong>{{item.vehiculo_3}}: {{item.vehiculo_3_plazas}}</strong></div>
                                                  <div class="h4 nombre-vehiculo" *ngIf="item.vehiculo_4"><strong>{{item.vehiculo_4}}: {{item.vehiculo_4_plazas}}</strong></div>
                                                  <div class="text-muted breakword" *ngIf="item.cli_email"><i class="fa fa-envelope-o"></i> {{item.cli_email}}</div>
                                                  <div class="text-muted" *ngIf="item.cli_telefono"><i class="fa fa-phone"></i> {{item.cli_telefono}}</div>
                                                  <div class="m-t-10">
                                                    <a class="btn btn-sm btn-alert m-l-10" *ngIf="misreservas" (click)="VistaPrevia(item)" href="javascript:void(0)"><i class="fa fa-eye font-14"></i></a>
                                                    <ng-container *ngIf="!misreservas">
                                                      <a class="btn btn-sm btn-primary" (click)="EnviarEmail(item, $event)" *ngIf="item.cli_email && item.id_estado == 3"><i class="fa fa-envelope font-14"></i></a>
                                                      <a class="btn btn-sm btn-warning m-l-10" (click)="Editar(item)" href="javascript:void(0)"><i class="fa fa-pencil font-14"></i></a>
                                                      <a class="btn btn-sm btn-success m-l-10" (click)="EnviarWhatsapp(item, $event)" *ngIf="item.cli_telefono"><i class="fa fa-whatsapp font-14"></i></a>
                                                    </ng-container>
                                                  </div>
                                              </div>
                                          </a>
                                          </div>
                                      </ng-container>
                                  </div>
                                </ng-container>
                                <!-- <div class="text-center p-t-10" style="border-top: 1px solid #EEE" *ngIf="reservas && reservas.length">
                                    <a class="btn btn-primary" (click)="Nuevo()"><i class="fa fa-plus"></i> Añadir reserva</a>
                                </div> -->
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane" id="tab-vista-calendario">

                    <div class="row">
                      <div class="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                          <div class="block full">
                              <div class="block-title"><h2>Actividad</h2></div>
                              <ul class="nav nav-pills nav-stacked content-list-actividades-calendar" style="max-height:80vh;overflow:auto;">
                                  <li id="todas-actividades" class="active">
                                      <a class="cursor" (click)="SetTodasActividadesCalendario()">Todas las actividades</a>
                                  </li>
                                  <li class="{{item.active ? 'active' : null}}" *ngFor="let item of actividades;let i = index" (click)="SetActividadCalendario(item)">
                                      <a class="cursor display-flex">
                                        <img [src]="item.imagen" alt="" *ngIf="item.imagen" class="m-r-5" style="height:30px;width:30px;object-fit:cover;border-radius:.4rem;">
                                        <p>{{item.nombre}}</p>
                                      </a>
                                  </li>
                              </ul>
                          </div>
                      </div>
                      <div class="col-lg-9 col-md-12 col-sm-12 col-xs-12">
                          <div class="block full">
                              <div class="block-title"><h2>Calendario</h2></div>
                              <div id="calendar" class="calendario-actividades"></div>
                          </div>
                      </div>
                    </div>

                  </div>
                  <div class="tab-pane" id="tab-vista-listado">
                    <div class="table-responsive table-grid block full break-padding">
                        <table class="table table-striped table-bordered table-vcenter table-hover">
                            <thead>
                                <tr>
                                  <th order="referencia">Referencia</th>
                                  <th>Via reserva</th>
                                  <th style="width:100px" order="alta">Alta</th>
                                  <th style="width:150px" order="estado">Estado</th>
                                  <th>Fecha/Sesión</th>
                                  <th>Personas</th>
                                  <th>Cliente</th>
                                  <th style="width:110px">Total</th>
                                  <th>Validada</th>
                                  <ng-container *ngIf="!misreservas">
                                    <th style="width:50px">Editar</th>
                                    <th style="width:50px">Borrar</th>
                                  </ng-container>
                                </tr>
                            </thead>
                            <tbody>
                              <ng-container *ngIf="!misreservas">
                                <tr *ngFor="let item of reservas_listado" class="cursor {{item.id_estado == 4 || item.id_estado == 5  || item.id_estado == 6  ? 'bg-tr-cancelada' : null}}">
                                  <td class="" (click)="Editar(item)">
                                    <div class="font-bold text-primary">Reserva #{{item.referencia}}</div>
                                    <div class="font-bold" *ngIf="item.codigo_bono != ''">Bono {{item.codigo_bono}}</div>
                                    <div class="text-secondary">{{item.evento}}</div>
                                    <div class="text-primary" *ngIf="item.asiento">{{item.asiento}}</div>
                                  </td>
                                  <td>
                                    <img *ngIf="item.tipo == 'Web'" src="assets/icon-ref-web.svg" style="height:20px;" />
                                    <img *ngIf="item.tipo == 'Civitatis'" src="assets/icon-ref-c.svg" style="height:20px;" />
                                    <img *ngIf="item.tipo == 'Partner'" src="assets/icon-ref-p.svg" style="height:20px;" />
                                    <img *ngIf="item.tipo == 'GetYourGuide'" src="assets/icon-ref-g.svg" style="height:20px;" />
                                    <img *ngIf="item.tipo == 'Admin'" src="assets/icon-ref-admin.svg" style="height:20px;" />
                                    <img *ngIf="item.tipo == 'Airbnb'" src="assets/icon-ref-airbnb.svg" style="height:20px;" />
                                    {{item.tipo}} <span *ngIf="item.tipo == 'Partner'">({{item.nombre_colaborador}})</span>
                                  </td>
                                  <td>
                                    {{item.alta_format}}
                                    <br>
                                    {{item.alta_hora_format}}
                                  </td>
                                  <td>
                                    <div class="font-bold"
                                      [style.color]="(item.id_estado == 3 ? '#7AAC3F' : (item.id_estado == 8 ? 'rgb(244, 179, 0)' : 'red'))">
                                      {{item.estado}}
                                    </div>
                                    <div *ngIf="item.cli_tipo_pago && item.cli_tipo_pago != '0'" class="font-11 text-primary">{{item.cli_tipo_pago}}</div>
                                  </td>
                                  <td>
                                      <div>{{item.fecha_format}}</div>
                                      <div>{{item.sesion}}</div>
                                  </td>
                                  <td>
                                    <ng-container *ngIf="item.precios && item.precios.length">
                                      <div *ngFor="let tarifa of item.precios">{{tarifa.nombre}}: <b>{{tarifa.cantidad}}</b></div>
                                    </ng-container>
                                  </td>
                                  <td>
                                      <div class="nombre-listado">
                                        <img src="assets/flag-en.svg" class="idioma" alt="" *ngIf="item.idioma_res == 'en'">
                                        <img src="assets/flag-it.svg" class="idioma" alt="" *ngIf="item.idioma_res == 'it'">
                                        <img src="assets/flag-fr.svg" class="idioma" alt="" *ngIf="item.idioma_res == 'fr'">
                                        <img src="assets/flag-pt.svg" class="idioma" alt="" *ngIf="item.idioma_res == 'pt'">
                                        {{item.cli_nombre_completo}}
                                      </div>
                                      <div *ngIf="item.cli_email"><a href="mailto:{{item.cli_email}}"><i class="fa fa-envelope"></i> {{item.cli_email}}</a></div>
                                      <div *ngIf="item.cli_telefono"><a href="tel:{{item.cli_telefono}}"><i class="fa fa-phone"></i> {{item.cli_telefono}}</a></div>
                                  </td>
                                  <td class="font-bold text-primary text-center m-l-20" (click)="Editar(item)">
                                    <div class="font-26">{{globals.FloatES(item.total)}} &euro;</div>
                                  </td>
                                  <td class="text-center btntd">
                                    <div class="cel-validada" *ngIf="item.validada == 0"><i class="fa fa-times" style="color: red; font-size: 22px;"></i></div>
                                    <div class="cel-validada" *ngIf="item.validada == 1"><i class="fa fa-check" style="color: green; font-size: 22px;"></i></div>
                                  </td>
                                  <td class="text-center btntd"><a class="btn btn-primary btn-xs" (click)="Editar(item)"><i class="fa fa-pencil text-white"></i></a></td>
                                  <td class="text-center btntd"><a class="btn btn-danger btn-xs" (click)="Borrar(item)"><i class="fa fa-trash text-white"></i></a></td>
                                </tr>
                              </ng-container>
                              <ng-container *ngIf="misreservas">
                                <tr *ngFor="let item of reservas_listado" class="cursor {{item.id_estado == 4 || item.id_estado == 5 ? 'bg-tr-cancelada' : null}}" (click)="VistaPrevia(item)">
                                  <td class="">
                                    <div class="font-bold text-primary">Reserva #{{item.referencia}}</div>
                                    <div class="font-bold" *ngIf="item.codigo_bono != ''">Bono {{item.codigo_bono}}</div>
                                    <div class="text-secondary">{{item.evento}}</div>
                                  </td>
                                  <td>
                                    <img *ngIf="item.tipo == 'Partner'" src="assets/icon-ref-p.svg" style="height:20px;" />
                                    {{item.tipo}} ({{item.nombre_colaborador}})
                                  </td>
                                  <td>{{item.alta_format}}</td>
                                  <td>
                                    <div class="font-bold"
                                      [style.color]="(item.id_estado == 3 ? '#7AAC3F' : (item.id_estado == 8 ? 'rgb(244, 179, 0)' : 'red'))">
                                      {{item.estado}}
                                    </div>
                                    <div *ngIf="item.cli_tipo_pago && item.cli_tipo_pago != '0'" class="font-11 text-primary">{{item.cli_tipo_pago}}</div>
                                  </td>
                                  <td>
                                      <div>{{item.fecha_format}}</div>
                                      <div>{{item.sesion}}</div>
                                  </td>
                                  <td>
                                    <!-- <div *ngFor="let precio of item.precios">{{precio.nombre}}: {{precio.cantidad}}</div> -->
                                  </td>
                                  <td class="breaktd">
                                      <div>{{item.cli_nombre_completo}}</div>
                                      <div><a href="mailto:{{item.cli_email}}"><i class="fa fa-envelope"></i> {{item.cli_email}}</a></div>
                                      <div><a href="tel:{{item.cli_telefono}}"><i class="fa fa-phone"></i> {{item.cli_telefono}}</a></div>
                                  </td>
                                  <td class="font-bold text-primary text-center breaktd">
                                    <div class="font-20">{{globals.FloatES(item.total)}} &euro;</div>
                                  </td>
                                  <td class="text-center">
                                    <div class="cel-validada" *ngIf="item.validada == 0"><i class="fa fa-times"></i></div>
                                    <div class="cel-validada" *ngIf="item.validada == 1"><i class="fa fa-check"></i></div>
                                  </td>
                                </tr>
                              </ng-container>
                            </tbody>
                        </table>
                        <div class="dataTables_paginate paging_simple_numbers m-r-10">
                          <ul class="pagination justify-content-end">
                            <li class="paginate_button page-item previous {{params.page_index <= 1 ? 'disabled' : ''}}">
                              <a (click)="Paging('prev')" class="page-link">Anterior</a>
                            </li>
                            <li class="paginate_button page-item" *ngIf="params.page_index > 1">
                              <a (click)="Paging('prev')" class="page-link">{{params.page_index-1}}</a>
                            </li>
                            <li class="paginate_button page-item active">
                              <a class="page-link cursor">{{params.page_index}}</a>
                            </li>
                            <li class="paginate_button page-item" *ngIf="params.page_index < params.pages">
                              <a (click)="Paging('next')" class="page-link">{{params.page_index+1}}</a>
                            </li>
                            <li class="paginate_button page-item next {{params.page_index >= params.pages ? 'disabled' : ''}}">
                              <a (click)="Paging('next')" class="page-link">Siguiente</a>
                            </li>
                          </ul>
                        </div>
                    </div>
                  </div>
                </div>

            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->

        <app-backend-footer></app-backend-footer>
    </div>
    <!-- END Page Container -->
</div>