<ng-container *ngIf="globals.GetIDCliente()">
    <div class="fab-container">
        <div class="bg"></div>
        <ng-container *ngIf="!lectorabierto">
            <div class="dropdown">
                <div class="dropdown-menu" id="fab-menu-general">
                    <a class="dropdown-item" (click)="Opcion('qr')"><i class="fa fa-qrcode"></i> Leer QR</a>
                    <a class="dropdown-item" (click)="Opcion('crear-reserva')"><i class="fa fa-plus"></i> Crear Reserva</a>
                    <a class="dropdown-item" (click)="Opcion('crear-actividad')"><i class="fa fa-plus"></i> Crear Actividad</a>
                    <a class="dropdown-item" (click)="Opcion('crear-evento')"><i class="fa fa-plus"></i> Crear Evento</a>
                </div>
            </div>
            <a class="btn-fab" (click)="Fab()"><i class="fa fa-plus"></i></a>
        </ng-container>
        <ng-container *ngIf="lectorabierto">
            <a class="btn-fab" (click)="cerrarLector()"><i class="fa fa-times"></i></a>
        </ng-container>
    </div>
    <div id="reader" style="margin-top:60px;display:none;"></div>
</ng-container>