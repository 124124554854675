import { ConditionalExpr } from '@angular/compiler';
import { Component, OnInit, NgZone, ApplicationRef, ViewEncapsulation  } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let ICAL: any;
declare let Chart: any;
declare let toastr: any;

@Component({
  selector: 'app-backend-dashboard-superadmin',
  templateUrl: './dashboard-superadmin.component.html',
  styleUrls: ['./dashboard-superadmin.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardSuperadminComponent { 

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }


  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();

  }

}
