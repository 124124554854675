import { ApplicationRef, Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let toastr: any;

@Component({
  selector: 'app-edit-cupon',
  templateUrl: './edit-cupon.component.html'
})
export class EditCuponComponent implements OnInit {
  public title:string = 'Nuevo cupón';
  public data:any = {tipo: 'porcentaje', activo: 1, limite_cliente: 1, id_actividad: 0, id_sesion: 0};
  public actividades:any = [];
  public sesiones:any = [];
  public primeraCarga = true;

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
    $.checkrolredirect(3); //No clientes
    if (window.location.pathname.indexOf('add') != -1) this.globals.passData = null;
    if (window.location.pathname.indexOf('edit') != -1 && !Object.keys(this.globals.passData).length) window.location.href = window.location.href.replace('edit', 'add');
    if (this.globals.passData && this.globals.passData != '' && Object.keys(this.globals.passData).length > 0) this.data = this.globals.passData;
    if (!this.data.id || this.data.id == '') {
      this.title = 'Nuevo cupón';
    } else {
      this.title = this.data.nombre;
      this.data.fecha_inicio = moment(this.data.fecha_inicio).format('YYYY-MM-DD');
      this.data.fecha_fin = moment(this.data.fecha_fin).format('YYYY-MM-DD');
      this.CargarSesiones();
    }
    this.CargarActividades();
  }

  CargarActividades(){
    this.globals.Api('/eve-actividades').subscribe(data => {
      if (!data || !data.length) {
        this.actividades = [];
        return;
      }
      this.actividades = data;
    });
  }
  CargarSesiones(){
    if(this.primeraCarga){
      this.primeraCarga = false;
    }else{
      this.data.id_sesion = null;
    }
    if(!this.data.id_actividad) return;
    this.globals.Api('/eve-actividades-sesiones-actividad', {id: this.data.id_actividad}).subscribe(data => {
      if (!data || !data.length) return;
      this.sesiones = data;
    });
  }
  Cancelar() {
    this.ngZone.run(() => this.router.navigateByUrl('/cupones')).then();
  }
  Guardar() {
    if (
      !this.data.nombre
      || !this.data.codigo
      || this.data.nombre == ''
      || this.data.codigo == ''
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }

    this.title = this.data.nombre;
    if (!this.data.id || this.data.id == '') {
      $.buttonloading('.btn-guardar', 'Enviando');
      this.globals.Api('/eve-cupones-add', this.data).subscribe(data => {
        $.buttoncancelloading('.btn-guardar');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.Cancelar();
      }, error => {
        $.buttoncancelloading('.btn-guardar');
      });
    } else {
      $.buttonloading('.btn-guardar', 'Enviando');
      this.globals.Api('/eve-cupones-edit', this.data).subscribe(data => {
        $.buttoncancelloading('.btn-guardar');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        toastr.success(`Datos guardados correctamente`, 'Guardado');
      }, error => {
        $.buttoncancelloading('.btn-guardar');
      });
    }
  }

}
