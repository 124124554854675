<div id="page-wrapper" class="page-loading">
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                <div class="content-header">
                    <div class="row-equal-cols">
                        <div class="col-lg-8 col-md-6 col-xs-12">
                            <div class="header-section">
                                <h1 class="middle">Configuración</h1>
                                <!-- <button class="btn btn-xs btn-secondary middle m-l-10" (click)="CargarGrid()"><i class="fa fa-refresh"></i></button> -->
                            </div>
                            <ul class="breadcrumb breadcrumb-top text-left">
                                <li><a [routerLink]="['/dashboard']">Inicio</a></li>
                                <li>Configuración</li>
                            </ul>
                        </div>
                        <div class="col-lg-4 col-md-6 col-xs-12 col-botones text-center">
                          <a class="btn btn-primary btn-guardar m-l-20" (click)="Guardar()">Guardar</a>
                        </div>
                    </div>
                </div>

                <div class="block full break-padding">
                    <div class="block-title">
                        <!-- <div class="block-options pull-right">
                            <a href="javascript:void(0)" class="btn btn-effect-ripple btn-default" data-toggle="tooltip" title=""
                                style="overflow: hidden; position: relative;" data-original-title="Settings"><i
                                    class="fa fa-cog"></i></a>
                        </div> -->
                        <ul class="nav nav-tabs" data-toggle="tabs">
                            <li class="active"><a href="#tab-general">General</a></li>
                            <li><a href="#tab-personalizacion">Personalización</a></li>
                            <li><a href="#tab-email">Email</a></li>
                            <li><a href="#tab-whatsapp">WhatsApp</a></li>
                            <li><a href="#tab-pagos">Métodos pago</a></li>
                            <li><a href="#tab-analytics">Google Analytics</a></li>
                            <li class="hidden"><a href="#tab-integraciones">Integraciones</a></li>
                        </ul>
                    </div>
                    <div class="tab-content">
                        <div class="tab-pane active" id="tab-general">
                            <div class="row">
                                <div class="col-md-6 col-xs-12">
                                    <div class="block">
                                        <div class="block-title"><h2>Configuración principal</h2></div>
                                        <div class="form-group row">
                                            <label class="col-lg-4 col-form-label">Porcentaje anticipo</label>
                                            <div class="col-lg-8">
                                                <div class="input-group">
                                                    <input type="number" class="form-control text-right" [(ngModel)]="data.porcentaje_anticipo" maxlength="20" pattern="/^\d+$/" min="1" max="100" step="1">
                                                    <span class="input-group-addon"><i class="fa fa-percent"></i></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-lg-4 col-form-label">Días mínimo reserva</label>
                                            <div class="col-lg-8">
                                                <div class="input-group">
                                                    <input type="number" class="form-control text-right" [(ngModel)]="data.dias_min_reserva" maxlength="20" pattern="/^\d+$/" min="1" max="100" step="1">
                                                    <span class="input-group-addon">día(s)</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-lg-4 col-form-label">Teléfono por defecto contacto</label>
                                            <div class="col-lg-8">
                                                <div class="input-group">
                                                    <span class="input-group-addon"><i class="fa fa-phone"></i></span>
                                                    <input type="tel" class="form-control" [(ngModel)]="data.tel_contacto" maxlength="20">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-xs-12">
                                    <div class="block">
                                        <div class="block-title"><h2>Configuración vehículos</h2></div>
                                        <div class="form-group row">
                                            <label class="col-lg-4 col-form-label">¿Gestión de vehículos para reservas?</label>
                                            <div class="col-lg-8">
                                                <select type="text" class="form-control" [(ngModel)]="data.vehiculos">
                                                    <option value="0">No</option>
                                                    <option value="1">Sí</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="block">
                                        <div class="block-title"><h2>Configuración bonos regalo</h2></div>
                                        <div class="form-group row">
                                            <label class="col-lg-4 col-form-label">¿Permitir bonos regalo?</label>
                                            <div class="col-lg-8">
                                                <select type="text" class="form-control" [(ngModel)]="data.bonos_regalo">
                                                    <option value="0">No</option>
                                                    <option value="1">Sí</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-lg-4 col-form-label">Logo empresa (250x250)</label>
                                            <div class="col-lg-8">
                                                <input type="text" class="form-control" [(ngModel)]="data.logo_empresa_bono" pattern="/^\d+$/" step="1">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-xs-12">
                                    <div class="block">
                                        <div class="block-title"><h2>Políticas de cancelación email cliente</h2></div>
                                        <div class="form-group row">
                                            <div class="col-lg-12">
                                                <textarea id="txtcancelacion" type="text" class="form-control text-editor"
                                                    placeholder="Escriba aqu&iacute; las políticas de cancelación" [(ngModel)]="data.politicas_cancelacion"
                                                    maxlength="10000" rows="7"></textarea>
                                                <!-- <textarea id="txtcancelacion-en" type="text" class="form-control text-editor"
                                                    placeholder="Escriba aqu&iacute; las políticas de cancelación" [(ngModel)]="data.en.politicas_cancelacion"
                                                    maxlength="10000" rows="7"></textarea>
                                                <textarea id="txtcancelacion-fr" type="text" class="form-control text-editor"
                                                    placeholder="Escriba aqu&iacute; las políticas de cancelación" [(ngModel)]="data.fr.politicas_cancelacion"
                                                    maxlength="10000" rows="7"></textarea>
                                                <textarea id="txtcancelacion-pt" type="text" class="form-control text-editor"
                                                    placeholder="Escriba aqu&iacute; las políticas de cancelación"[(ngModel)]="data.pt.politicas_cancelacion"
                                                    maxlength="10000" rows="7"></textarea>
                                                <span class="input-group-addon" style="vertical-align:top">
                                                    <select class="form-control select-idioma" [(ngModel)]="idioma" style="width:auto;height:auto;padding:0">
                                                        <option value="es">ES</option>
                                                        <option value="en">EN</option>
                                                        <option value="fr">FR</option>
                                                        <option value="pt">PT</option>
                                                    </select>
                                                </span> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane" id="tab-personalizacion">

                          <div class="row">
                            <div class="col-lg-5 col-md-4 col-sm-12 col-xs-12">
                              <div class="form-group">
                                  <label>Nombre público</label>
                                  <input type="text" class="form-control" [(ngModel)]="data.nombre" maxlength="80">
                              </div>
                              <div class="form-group">
                                  <label>URL cliente</label>
                                  <input type="url" class="form-control" [(ngModel)]="data.url_cliente" maxlength="400">
                              </div>
                              <!-- <div class="form-group">
                                  <label>URL carta</label>
                                  <input type="url" class="form-control" [(ngModel)]="data.config.url_carta" maxlength="400">
                              </div> -->
                              <div class="form-group">
                                  <label>URL política de cancelación</label>
                                  <input type="url" class="form-control" [(ngModel)]="data.url_politica_cancelacion" maxlength="400">
                              </div>
                              <div class="form-group">
                                  <label>URL política de privacidad</label>
                                  <input type="url" class="form-control" [(ngModel)]="data.url_politica_privacidad" maxlength="400">
                              </div>
                              <div class="form-group">
                                  <label>Esquema de color</label>
                                  <select class="form-control" [(ngModel)]="data.id_esquema_color">
                                    <option [value]="item.id" *ngFor="let item of esquemas_color">{{item.nombre}}</option>
                                  </select>
                              </div>
                              <!-- <div class="form-group">
                                  <label><i class="icofont-image text-primary"></i> URL imagen fondo login</label>
                                  <img *ngIf="data.config.bg_login" [src]="data.config.bg_login" class="display-block m-t-10 m-b-10" style="max-height:80px;background-color: #F1F1F1;">
                                  <input type="url" class="form-control url-paste" [(ngModel)]="data.config.bg_login" maxlength="400">
                              </div>
                              <div class="form-group">
                                  <label><i class="icofont-image text-primary"></i> URL imagen logo login</label>
                                  <img *ngIf="data.config.logo_login" [src]="data.config.logo_login" class="display-block m-t-10 m-b-10" style="max-height:80px;background-color: #F1F1F1;">
                                  <input type="url" class="form-control url-paste" [(ngModel)]="data.config.logo_login" maxlength="400">
                              </div>
                              <div class="form-group">
                                  <label><i class="icofont-image text-primary"></i> URL imagen logo menú</label>
                                  <img *ngIf="data.config.logo_menu" [src]="data.config.logo_menu" class="display-block m-t-10 m-b-10" style="max-height:80px;background-color: #F1F1F1;">
                                  <input type="url" class="form-control url-paste" [(ngModel)]="data.config.logo_menu" maxlength="400">
                              </div> -->
                            </div>
                            <div class="col-lg-7 col-md-8 col-sm-12 col-xs-12">
                              <label>CSS Personalizado</label>
                              <div id="txtcustomcss" [innerHTML]="data.customcss" style="height:500px;"></div>
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane" id="tab-email">
                            <div class="row">
                                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                    <div class="block">
                                        <div class="block-title"><h2>Correo salida</h2></div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Host</label>
                                                    <input type="text" class="form-control" [(ngModel)]="data.emailconf_host" maxlength="80">
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label>Port</label>
                                                    <input type="number" class="form-control" [(ngModel)]="data.emailconf_port" maxlength="12">
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label>SSL</label>
                                                    <select class="form-control" [(ngModel)]="data.emailconf_ssl">
                                                    <option value="1">Sí</option>
                                                    <option value="0" selected>No</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label>Email para env&iacute;os</label>
                                            <input type="email" class="form-control" [(ngModel)]="data.emailconf_email" maxlength="80">
                                        </div>
                                        <div class="form-group">
                                            <label>Contraseña de email para env&iacute;os</label>
                                            <input type="text" class="form-control" [(ngModel)]="data.emailconf_pass" maxlength="80">
                                        </div>
                                        <div class="form-group">
                                            <label>Alias de email para env&iacute;os</label>
                                            <input type="text" class="form-control" [(ngModel)]="data.emailconf_alias" maxlength="80">
                                        </div>
                                        <hr>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                    <div class="block">
                                        <div class="block-title"><h2>Correo administrador</h2></div>
                                        <div class="form-group">
                                            <label>Alias email administrador</label>
                                            <input type="text" class="form-control" [(ngModel)]="data.emailconf_emailadmin_alias" maxlength="80">
                                        </div>
                                        <div class="form-group">
                                            <label>Email administrador 1</label>
                                            <input type="email" class="form-control" [(ngModel)]="data.emailconf_emailadmin1" maxlength="80">
                                        </div>
                                        <div class="form-group">
                                            <label>Email administrador 2</label>
                                            <input type="email" class="form-control" [(ngModel)]="data.emailconf_emailadmin2" maxlength="80">
                                        </div>
                                        <div class="form-group">
                                            <label>Email administrador 3</label>
                                            <input type="email" class="form-control" [(ngModel)]="data.emailconf_emailadmin3" maxlength="80">
                                        </div>
                                    </div>
                                    <div>
                                        <a class="btn btn-default btn-block m-b-20" (click)="EmailTest()"><i class="icofont-envelope"></i> Enviar Test</a>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                    <div class="block">
                                        <div class="block-title"><h2>Configuración plantillas email</h2></div>
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div class="form-group">
                                                    <label>T&iacute;tulo cabecera email</label>
                                                    <input type="text" class="form-control" [(ngModel)]="data.emailconf_titulo_email" maxlength="80">
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div class="form-group">
                                                    <label><i class="icofont-image text-primary"></i> URL imagen logo</label>
                                                    <img *ngIf="data.emailconf_logo" [src]="data.emailconf_logo" class="display-block m-t-10 m-b-10" style="max-height:80px;background-color: #F1F1F1;">
                                                    <input type="url" class="form-control url-paste" [(ngModel)]="data.emailconf_logo" maxlength="400">
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div class="form-group">
                                                    <label><i class="icofont-color-bucket text-primary"></i> Color BG Cabecera</label>
                                                    <div class="input-group">
                                                        <span class="input-group-addon">
                                                        <input type="color" class="form-control" [(ngModel)]="data.emailconf_bgcolor_cabecera">
                                                        </span>
                                                        <input type="text" class="form-control" [(ngModel)]="data.emailconf_bgcolor_cabecera" maxlength="20">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div class="form-group">
                                                    <label><i class="icofont-color-bucket text-primary"></i> Color texto destacado</label>
                                                    <div class="input-group">
                                                        <span class="input-group-addon">
                                                        <input type="color" class="form-control" [(ngModel)]="data.emailconf_color_destacado">
                                                        </span>
                                                        <input type="text" class="form-control" [(ngModel)]="data.emailconf_color_destacado" maxlength="20">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div class="form-group">
                                                    <label class="col-form-label">Enlace email opiniones</label>
                                                    <input type="text" class="form-control" [(ngModel)]="data.url_email_opiniones" pattern="/^\d+$/">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                    <div class="block">
                                        <div class="block-title display-flex justify-content-between">
                                            <h2>Previsualización</h2>
                                            <div class="display-flex justify-content-between m-r-10" style="gap:10px;align-items:center;">
                                                <select class="form-control select-idioma" [(ngModel)]="email_template" (change)="LoadPreviewEmail()" style="width:auto;height:25px;padding:0">
                                                    <option value="opiniones">Opiniones</option>
                                                    <option value="reserva-cliente">Reserva cliente</option>
                                                    <option value="reserva-admin">Reserva admin</option>
                                                    <option value="pago-anticipo">Anticipo pago</option>
                                                </select>
                                                <select class="form-control select-idioma" *ngIf="email_template != 'reserva-admin'" [(ngModel)]="idioma_email_template" (change)="LoadPreviewEmail()" style="width:auto;height:25px;padding:0">
                                                  <option value="es">ES</option>
                                                  <option value="en">EN</option>
                                                  <option value="fr">FR</option>
                                                  <option value="pt">PT</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="">
                                            <div id="preview-email-opinion"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane" id="tab-whatsapp">
                            <div class="row">
                                <div class="col-md-6 col-xs-12">
                                    <div class="block">
                                        <div class="block-title"><h2><i class="fa fa-whatsapp"></i> WhatsApp</h2></div>
                                        <div class="form-group">
                                            <label>Facebook ID Cuenta</label>
                                            <input type="text" class="form-control" [(ngModel)]="data.whatsapp_fbid_account" maxlength="80">
                                        </div>
                                        <div class="form-group">
                                            <label>Facebook ID Número</label>
                                            <input type="text" class="form-control" [(ngModel)]="data.whatsapp_fbid" maxlength="80">
                                        </div>
                                        <div class="form-group">
                                            <label>Facebook Access Token</label>
                                            <input type="text" class="form-control" [(ngModel)]="data.whatsapp_access_token" maxlength="400">
                                        </div>
                                        <div class="form-group">
                                            <label>Número para recibir notificaciones</label>
                                            <input type="text" class="form-control" [(ngModel)]="data.whatsapp_numero_notificar" maxlength="20">
                                        </div>
                                        <div class="form-group">
                                            <label>URL plantilla QR</label>
                                            <input type="text" class="form-control" [(ngModel)]="data.whatsapp_plantilla_qr" maxlength="500">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane" id="tab-pagos"><div class="block">
                            <div class="block-title"><h2>Formas de pago</h2></div>
                            <ng-container *ngFor="let item of data.formas_pago">
                                <div class="row m-b-20" style="border-bottom:1px solid #EEE;padding-bottom:10px;">
                                    <div class="col-md-3 col-xs-12">
                                        <div class="font-bold m-b-10">{{item.forma_pago}}</div>
                                        <div>
                                            <label class="switch switch-success switch-sm m-r-10">
                                                <div style="font-weight:normal;">Visible web</div>
                                                <input type="checkbox" [(ngModel)]="item.visible"><span></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-xs-12">
                                        {{item.slug}}
                                    </div>
                                    <div class="col-md-6 col-xs-12">
                                        <div>
                                            <ng-container *ngIf="item.datos && item.slug == 'paypal'">
                                                <a class="btn btn-primary m-b-10" data-toggle="collapse" href="#config-paypal" role="button" aria-expanded="false">
                                                    Configuración Paypal <i class="fa fa-chevron-down"></i>
                                                </a>
                                                <div id="config-paypal" class="row collapse">
                                                    <div class="col-sm-6 col-xs-12">
                                                        <div class="form-group">
                                                            <label>Email</label>
                                                            <input type="text" class="form-control" [(ngModel)]="item.datos.email" maxlength="80">
                                                        </div>
                                                        <div class="form-group">
                                                            <label>Token</label>
                                                            <input type="text" class="form-control" [(ngModel)]="item.datos.token" maxlength="200">
                                                        </div>
                                                        <div class="form-group">
                                                            <label class="switch switch-warning switch-sm">
                                                                <div style="font-weight:normal;">Entorno pruebas</div>
                                                                <input type="checkbox" [(ngModel)]="item.datos.entorno_pruebas"><span></span>
                                                            </label>
                                                        </div>
                                                        <div class="form-group">
                                                            <label>Moneda</label>
                                                            <input type="text" class="form-control" [(ngModel)]="item.datos.moneda" maxlength="10">
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 col-xs-12">
                                                        <div class="form-group">
                                                            <label>Url Retorno</label>
                                                            <input type="url" class="form-control" [(ngModel)]="item.datos.url_retorno" maxlength="400">
                                                        </div>
                                                        <div class="form-group">
                                                            <label>Url Cancelación</label>
                                                            <input type="url" class="form-control" [(ngModel)]="item.datos.url_cancelacion" maxlength="400">
                                                        </div>
                                                        <div class="form-group">
                                                            <label>Url Notificación IPN</label>
                                                            <input type="url" class="form-control" [(ngModel)]="item.datos.url_notificacion_ipn" maxlength="400">
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="item.datos && item.slug == 'tarjeta'">
                                                <a class="btn btn-primary m-b-10" data-toggle="collapse" href="#config-tarjeta" role="button" aria-expanded="false">
                                                    Configuración Tarjeta (RedSys) <i class="fa fa-chevron-down"></i>
                                                </a>
                                                <div id="config-tarjeta" class="row collapse">
                                                    <div class="col-sm-6 col-xs-12">
                                                        <div class="form-group">
                                                            <label>Sistema</label>
                                                            <input type="text" class="form-control" [(ngModel)]="item.datos.sistema" maxlength="10">
                                                        </div>
                                                        <div class="form-group">
                                                            <label>Nombre comercio</label>
                                                            <input type="text" class="form-control" [(ngModel)]="item.datos.nombre_comercio" maxlength="80">
                                                        </div>
                                                        <div class="form-group">
                                                            <label>FUC comercio</label>
                                                            <input type="text" class="form-control" [(ngModel)]="item.datos.fuc_comercio" maxlength="50">
                                                        </div>
                                                        <div class="form-group">
                                                            <label>Clave SHA256</label>
                                                            <input type="text" class="form-control" [(ngModel)]="item.datos.clave_sha256" maxlength="50">
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 col-xs-12">
                                                        <div class="form-group">
                                                            <label>Terminal</label>
                                                            <input type="text" class="form-control" [(ngModel)]="item.datos.terminal" maxlength="4">
                                                        </div>
                                                        <div class="form-group">
                                                            <label>Moneda</label>
                                                            <input type="text" class="form-control" [(ngModel)]="item.datos.moneda" maxlength="10">
                                                        </div>
                                                        <div class="form-group">
                                                            <label>Url Retorno</label>
                                                            <input type="url" class="form-control" [(ngModel)]="item.datos.url_retorno" maxlength="400">
                                                        </div>
                                                        <div class="form-group">
                                                            <label>Url Error</label>
                                                            <input type="url" class="form-control" [(ngModel)]="item.datos.url_error" maxlength="400">
                                                        </div>
                                                        <div class="form-group">
                                                            <label>Url Notificación IPN</label>
                                                            <input type="url" class="form-control" [(ngModel)]="item.datos.url_notificacion_ipn" maxlength="400">
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="item.datos && item.slug == 'stripe'">
                                                <a class="btn btn-primary m-b-10" data-toggle="collapse" href="#config-stripe" role="button" aria-expanded="false">
                                                    Configuración Stripe <i class="fa fa-chevron-down"></i>
                                                </a>
                                                <div id="config-stripe" class="row collapse">
                                                    <div class="col-sm-6 col-xs-12">
                                                        <div class="form-group">
                                                            <label>PK Test</label>
                                                            <input type="text" class="form-control" [(ngModel)]="item.datos.pk_test" maxlength="200">
                                                        </div>
                                                        <div class="form-group">
                                                            <label>SK Test</label>
                                                            <input type="text" class="form-control" [(ngModel)]="item.datos.sk_test" maxlength="200">
                                                        </div>
                                                        <div class="form-group">
                                                            <label class="switch switch-warning switch-sm">
                                                                <div style="font-weight:normal;">Entorno pruebas</div>
                                                                <input type="checkbox" [(ngModel)]="item.datos.entorno_pruebas"><span></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 col-xs-12">
                                                        <div class="form-group">
                                                            <label>PK Live</label>
                                                            <input type="text" class="form-control" [(ngModel)]="item.datos.pk_live" maxlength="200">
                                                        </div>
                                                        <div class="form-group">
                                                            <label>SK Live</label>
                                                            <input type="text" class="form-control" [(ngModel)]="item.datos.sk_live" maxlength="200">
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                        <div>
                                            <a class="btn btn-default" data-toggle="collapse" href="#texto-{{item.slug}}" role="button" aria-expanded="false">
                                                Editar texto Email <i class="fa fa-chevron-down"></i>
                                            </a>
                                            <div id="texto-{{item.slug}}" class="collapse">
                                                <textarea type="text" class="form-control text-editor"
                                                placeholder="Introduce aquí el texto que se incluirá en el email" [(ngModel)]="item.descripcion_email" rows="4"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div></div>
                        <div class="tab-pane" id="tab-analytics">
                            <div class="row">
                                <div class="col-md-6 col-xs-12">
                                    <div class="block">
                                        <div class="block-title"><h2><i class="fa fa-line-chart"></i> Tag Manager - Google Ads (HEAD)</h2></div>
                                        <div class="form-group">
                                            <textarea class="form-control" [(ngModel)]="data.tagmanagerga" maxlength="10000" rows="7"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-xs-12">
                                    <div class="block">
                                        <div class="block-title"><h2><i class="fa fa-line-chart"></i> Tag Manager - Google Ads (BODY)</h2></div>
                                        <div class="form-group">
                                            <textarea class="form-control" [(ngModel)]="data.tagmanagergabody" maxlength="10000" rows="7"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane" id="tab-integraciones"></div>
                    </div>
                </div>

                <div class="col-botones">
                  <a class="btn btn-primary btn-guardar m-l-20" (click)="Guardar()">Guardar</a>
                </div>

            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->

        <app-backend-footer></app-backend-footer>
    </div>
    <!-- END Page Container -->
</div>