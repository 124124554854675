import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ActividadesComponent } from './backend/actividades/actividades.component';
import { EditActividadComponent } from './backend/actividades/edit-actividad/edit-actividad.component';
import { EventosComponent } from './backend/eventos/eventos.component';
import { EditEventoComponent } from './backend/eventos/edit-evento/edit-evento.component';
import { ConfigAppComponent } from './backend/config-app/config-app.component';
import { ConfiguracionComponent } from './backend/configuracion/configuracion.component';
import { CuponesComponent } from './backend/cupones/cupones.component';
import { EditCuponComponent } from './backend/cupones/edit-cupon/edit-cupon.component';
import { DashboardComponent } from './backend/dashboard/dashboard.component';
import { LoginComponent } from './backend/login/login.component';
import { MiPerfilComponent } from './backend/mi-perfil/mi-perfil.component';
import { EditReservaComponent } from './backend/reservas/edit-reserva/edit-reserva.component';
import { ReservasComponent } from './backend/reservas/reservas.component';
import { TemporadasComponent } from './backend/temporadas/temporadas.component';
import { EditUsuarioComponent } from './backend/usuarios/edit-usuario/edit-usuario.component';
import { UsuariosComponent } from './backend/usuarios/usuarios.component';
import { ConfirmacionReservaComponent } from './frontend/confirmacion-reserva/confirmacion-reserva.component';
import { ReservaCanceladaComponent } from './frontend/reserva-cancelada/reserva-cancelada.component';
import { ReservaErrorComponent } from './frontend/reserva-error/reserva-error.component';
import { ConfirmacionReseniaComponent } from './frontend/confirmacion-resenia/confirmacion-resenia.component';
import { MensajesComponent } from './backend/mensajes/mensajes.component';
import { NuevaReservaActividadComponent } from './frontend/nueva-reserva-actividad/nueva-reserva-actividad.component';
import { VehiculosComponent } from './backend/vehiculos/vehiculos.component';
import { EditVehiculoComponent } from './backend/vehiculos/edit-vehiculo/edit-vehiculo.component';
import { PagoAnticipoComponent } from './frontend/pago-anticipo/pago-anticipo.component';
import { BonosRegalosComponent } from './backend/bonos-regalos/bonos-regalos.component';
import { EditBonoRegaloComponent } from './backend/bonos-regalos/edit-bono-regalo/edit-bono-regalo.component';
import { ClientesSpaComponent } from './backend/superadmin/clientes-spa/clientes-spa.component';
import { EditClienteSpaComponent } from './backend/superadmin/clientes-spa/edit-cliente-spa/edit-cliente-spa.component';
import { DashboardSuperadminComponent } from './backend/dashboard-superadmin/dashboard-superadmin.component';
import { NuevaReservaComponent } from './frontend/nueva-reserva/nueva-reserva.component';
import { NuevaReservaEventoComponent } from './frontend/nueva-reserva-evento/nueva-reserva-evento.component';
import { EstadisticasComponent } from './backend/estadisticas/estadisticas.component';

const routes: Routes = [
  {path: '', component: LoginComponent},
  {path: 'login', component: LoginComponent},
  {path: 'admincontrol', component: LoginComponent},
  {path: 'dashboard-superadmin', component: DashboardSuperadminComponent},
  {path: 'dashboard', component: DashboardComponent},
  {path: 'config-app', component: ConfigAppComponent},
  {path: 'configuracion', component: ConfiguracionComponent},
  {path: 'mi-perfil', component: MiPerfilComponent},
  {path: 'usuarios', component: UsuariosComponent},
  {path: 'usuarios/add', component: EditUsuarioComponent},
  {path: 'usuarios/edit', component: EditUsuarioComponent},
  {path: 'clientes', component: UsuariosComponent},
  {path: 'clientes/add', component: EditUsuarioComponent},
  {path: 'clientes/edit', component: EditUsuarioComponent},
  {path: 'colaboradores', component: UsuariosComponent},
  {path: 'colaboradores/add', component: EditUsuarioComponent},
  {path: 'colaboradores/edit', component: EditUsuarioComponent},
  {path: 'temporadas', component: TemporadasComponent},
  {path: 'actividades', component: ActividadesComponent},
  {path: 'actividades/add', component: EditActividadComponent},
  {path: 'actividades/edit/:id_actividad', component: EditActividadComponent},
  {path: 'eventos', component: EventosComponent},
  {path: 'eventos/add', component: EditEventoComponent},
  {path: 'eventos/edit/:id_evento', component: EditEventoComponent},
  {path: 'reservas', component: ReservasComponent},
  {path: 'mis-reservas', component: ReservasComponent},
  {path: 'reservas/add', component: EditReservaComponent},
  {path: 'reservas/edit/:id_reserva', component: EditReservaComponent},
  {path: 'cupones', component: CuponesComponent},
  {path: 'cupones/add', component: EditCuponComponent},
  {path: 'cupones/edit', component: EditCuponComponent},
  {path: 'mensajes', component: MensajesComponent},
  {path: 'guias', component: UsuariosComponent},
  {path: 'guias/add', component: EditUsuarioComponent},
  {path: 'guias/edit', component: EditUsuarioComponent},
  {path: 'vehiculos', component: VehiculosComponent},
  {path: 'vehiculos/add', component: EditVehiculoComponent},
  {path: 'vehiculos/edit', component: EditVehiculoComponent},
  {path: 'bonos-regalo', component: BonosRegalosComponent},
  {path: 'bonos-regalo/add', component: EditBonoRegaloComponent},
  {path: 'bonos-regalo/edit', component: EditBonoRegaloComponent},
  {path: 'clientes-spa', component: ClientesSpaComponent},
  {path: 'clientes-spa/add', component: EditClienteSpaComponent},
  {path: 'clientes-spa/edit', component: EditClienteSpaComponent},
  {path: 'estadisticas', component: EstadisticasComponent},

  //FRONEND
  {path: 'nueva-reserva/:id_cliente', component: NuevaReservaComponent},
  {path: 'nueva-reserva/:id_cliente/:id_actividad', component: NuevaReservaActividadComponent},
  {path: 'nueva-reserva/:id_cliente/:id_actividad/:fecha', component: NuevaReservaActividadComponent},
  {path: 'nueva-reserva-evento/:id_cliente/:id_evento', component: NuevaReservaEventoComponent},
  {path: 'nueva-reserva-evento/:id_cliente/:id_evento/:fecha', component: NuevaReservaEventoComponent},
  {path: 'confirmacion-reserva', component: ConfirmacionReservaComponent},
  {path: 'reserva-cancelada', component: ReservaCanceladaComponent},
  {path: 'reserva-error', component: ReservaErrorComponent},
  {path: 'confirmacion-resenia', component: ConfirmacionReseniaComponent},
  {path: 'pago-anticipo', component: PagoAnticipoComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
