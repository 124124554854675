import { Component, OnInit, NgZone, ApplicationRef, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let tinymce: any;
declare let toastr: any;
declare let ICAL: any;
declare let L: any;

@Component({
  selector: 'app-edit-vehiculo',
  templateUrl: './edit-vehiculo.component.html',
  styleUrls: ['./edit-vehiculo.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditVehiculoComponent implements OnInit {

  public data:any = {
    id: '',
    estado: true,
    id_tipo: 1
  };
  public title:string = 'Nuevo vehículo';
  public imgsubir:string = '';
  public tipos:any = [];

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
    ) { }

  ngOnInit(): void {
    App.init();
    if(!this.globals.cliente && this.globals.me.id_rol == 0){
      this.ngZone.run(() => this.router.navigateByUrl('/dashboard-superadmin')).then();
    }
    $.disableinputnumberwheel();
    $.checkrolredirect(3); //No clientes
    if (window.location.pathname.indexOf('add') != -1) this.globals.passData = null;
    if (window.location.pathname.indexOf('edit') != -1 && !Object.keys(this.globals.passData).length) window.location.href = window.location.href.replace('edit', 'add');
    if (this.globals.passData && this.globals.passData != '' && Object.keys(this.globals.passData).length > 0) this.data = this.globals.passData;
    if (!this.data.id || this.data.id == '') {
      this.title = 'Nuevo vehículo';
      this.CargarTipos();
    } else {
      this.title = this.data.nombre;
      this.CargarTipos();
    }
  }
  CargarTipos(){
    this.globals.Api('/eve-vehiculos-tipos').subscribe(data => {
      if (!data || !data.length) {
        this.tipos = [];
        return;
      }
      this.tipos = data;
    });
  }
  Guardar() {
    if (
      !this.data.nombre
      || this.data.nombre == ''
      || this.data.plazas == ''
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }

    this.data.img = null;
    if (this.imgsubir != '') this.data.img = this.imgsubir;
    this.title = this.data.nombre;

    if (!this.data.id || this.data.id == '') {
      $.buttonloading('.btn-guardar', 'Enviando');
      this.globals.Api('/eve-vehiculos-add', this.data).subscribe(data => {
        $.buttoncancelloading('.btn-guardar');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.Cancelar();
      }, error => {
        $.buttoncancelloading('.btn-guardar');
      });
    } else {
      $.buttonloading('.btn-guardar', 'Enviando');
      this.globals.Api('/eve-vehiculos-edit', this.data).subscribe(data => {
        $.buttoncancelloading('.btn-guardar');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error al editar', 'error');return;
        }
        // swal('Guardado', 'Datos guardados correctamente', 'success');
        toastr.success(`Datos guardados correctamente`, 'Guardado');
        //this.LoadFechasBloqueadas();
      }, error => {
        $.buttoncancelloading('.btn-guardar');
      });
    }
  }
  Cancelar() {
    this.ngZone.run(() => this.router.navigateByUrl('/vehiculos')).then();
  }
  SelecImagen(){

  }
}
