import { Component, OnInit, NgZone, ApplicationRef, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let tinymce: any;
declare let toastr: any;
declare let ICAL: any;
declare let L: any;

@Component({
  selector: 'app-edit-evento',
  templateUrl: './edit-evento.component.html',
  styleUrls: ['./edit-evento.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditEventoComponent implements OnInit {
  public title:string = '';
  public params:any = {
    page_index: 1,
    page_size: 50,
    total: 0,
    pages: 0,
    filtro: null,
    order: null,
    dir: null,
  };
  public data:any = {
    id: '',
    web: 1,
    mismo_dia: 1,
    reserva_externa: 0,
    tarifas: [],
    gestion_asientos: 0,
    precio_persona_grupo: 0,
    categorias: [],
    caracteristicas: [],
    asientos: [],
    en: {}, fr: {}, pt: {}
  };
  public asiento:any = {
    nombre: '',
    num_asientos: '',
    en: {}, fr: {}, pt: {}
  };
  public asientos:any = [];
  public asientos_temp:any = [];

  public imgsubir:string = '';
  public dataitem:any = {
    en: {}, fr: {}, pt: {}
  };
  public categoriasUsadas:any = [];
  public srcGMap = '';

  //Variables tarifas
  public tempNombreTarifa = '';
  public tempPrecioTarifa = '';
  public tempNumeroPersonasTarifa = '';

  public idioma:string = 'es';

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals,
    public sanitizer: DomSanitizer,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
    $.checkrolredirect(3); //No clientes
    // if (window.location.pathname.indexOf('add') != -1) this.globals.passData = null;
    // if (window.location.pathname.indexOf('edit') != -1 && !Object.keys(this.globals.passData).length) window.location.href = window.location.href.replace('edit', 'add');
    // if (this.globals.passData && this.globals.passData != '' && Object.keys(this.globals.passData).length > 0) this.data = this.globals.passData;
    const id_evento = this.route.snapshot.paramMap.get('id_evento');
    if (!id_evento || id_evento == '') {
      this.title = 'Nuevo evento';
    } else {
      this.globals.Api('/eve-eventos', {id: id_evento}).subscribe(data => {
        if (!data || !data.length) {
          return;
        }
        this.data = data[0];
        if (!this.data.en) this.data.en = {};
        if (!this.data.fr) this.data.fr = {};
        if (!this.data.pt) this.data.pt = {};
        if(!this.data.tarifas) this.data.tarifas = [];
        this.title = this.data.nombre;
        if(this.data.localizacion_maps){
          this.ActualizarMapa();
        }
        this.cargarTarifasPorPersona();
        this.loadAsientos();
      });
    }
    if (!this.data.en) this.data.en = {};
    if (!this.data.fr) this.data.fr = {};
    if (!this.data.pt) this.data.pt = {};
    this.loadCategoriasUsadas();
    tinymce.remove();
    $(document).on('change', '.select-idioma', () => {
      this.ChangeIdioma();
    });
    setTimeout(() => {
      let move = function (arr, from, to) {
        arr.splice(to, 0, arr.splice(from, 1)[0]);
      };
      let that = this;
      tinymce.init({
        selector:'.text-editor',
        height: 300,
        plugins: 'print preview powerpaste casechange importcss tinydrive searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount tinymcespellchecker a11ychecker imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap tinycomments mentions quickbars linkchecker emoticons advtable',
        toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
        language: 'es'
      });
      $('#tab-imagenes .gallery').sortable({
        stop: (event, ui) => {
          let arr = [];
          $('#tab-imagenes .gallery .ui-sortable-handle').each(function() {
            let id = $(this).attr('itemid');
            if (!id || id == '') return;
            arr.push(id);
          });
          if (!that.data.id || that.data.id == '') return;
          that.globals.Api('/eve-eventos-imagenes-order', {id_evento: that.data.id, ordenid: arr}).subscribe(data => {
            toastr.success(`Orden cambiado correctamente`, 'Orden cambiado');
          });
        }
      });
      $('#tab-general .gallery').sortable({
        stop: (event, ui) => {
          let arr = [];
          $('#tab-general .gallery .ui-sortable-handle').each(function() {
            let id = $(this).attr('itemid');
            if (!id || id == '') return;
            arr.push(id);
          });
          if (!that.data.id || that.data.id == '') return;
          that.globals.Api('/eve-eventos-imagenes-order', {id_evento: that.data.id, ordenid: arr}).subscribe(data => {
            toastr.success(`Orden cambiado correctamente`, 'Orden cambiado');
          });
        }
      });
      $('#tab-caracteristicas table tbody').sortable({
        stop: (event, ui) => {
          let arr = [];
          $('#tab-caracteristicas table tbody tr').each(function() {
            let id = $(this).attr('itemid');
            if (!id || id == '') return;
            arr.push(id);
          });
          if (!that.data.id || that.data.id == '') return;
          that.globals.Api('/eve-eventos-caracteristicas-order', {id_evento: that.data.id, ordenid: arr}).subscribe(data => {
            toastr.success(`Orden cambiado correctamente`, 'Orden cambiado');
          });
        }
      });
      $('#tab-servicios table tbody').sortable({
        stop: (event, ui) => {
          let arr = [];
          $('#tab-servicios table tbody tr').each(function() {
            let id = $(this).attr('itemid');
            if (!id || id == '') return;
            arr.push(id);
          });
          if (!that.data.id || that.data.id == '') return;
        }
      });
      $('#tab-tarifas table tbody').sortable({
        stop: (event, ui) => {
          let arr = [];
          $('#tab-tarifas table tbody tr').each(function() {
            let id = $(this).attr('itemid');
            if (!id || id == '') return;
            arr.push(id);
          });
          if (!that.data.id || that.data.id == '') return;
          that.globals.Api('/alo-alojamientos-tarifas-order', {id_alojamiento: that.data.id, ordenid: arr}).subscribe(data => {
            this.globals.Api('/eve-eventos', {id: this.data.id}).subscribe(data => {
              if (data && data.length) this.data.tarifas = data[0].tarifas;
            });
          });
        }
      });
    }, 100);
    if(this.data.dias_evento != null && this.data.id != ''){

    }
    if (this.data.ubicacion && this.data.localidad && this.data.provincia) {
      this.data.link_mapa = this.sanitizer.bypassSecurityTrustResourceUrl(`https://maps.google.com/maps?q=${this.globals.CleanedString(this.data.ubicacion.replace('c/', 'calle').replace('C/', 'calle'), '%20')}${this.globals.CleanedString(this.data.localidad, '%20')}${this.globals.CleanedString(this.data.provincia, '%20')}&t=&z=14&ie=UTF8&iwloc=&output=embed`);
    }
  }
  cargarTarifasPorPersona(){
    this.globals.Api('/eve-eventos-tarifas-por-persona-evento', {id: this.data.id}).subscribe(data => {
      if (!data || !data.length) return;
      this.data.tarifas = data;
      setInterval(()=>{
          $('#table-precio-por-persona tbody').sortable({
            handle: '.btn-sort',
            stop: (event:any, ui:any) => {
              let arr:any = [];
              let elements = $(event.target).find('tr');
              let id_tarifa = $(event.target).attr('itemid');
              let tarifa = this.data.tarifas.find((el:any) => el.id == id_tarifa);
              if (!tarifa) return;
              if (!elements || !elements.length) return;
              let index = 0;
              for (let item of elements) {
                let arritem = tarifa.tarifas.find((el:any) => el.id == item.getAttribute('itemid'));
                if (!arritem) continue;
                arr.push(arritem);
              }
              if (arr && arr.length) tarifa.tarifas = JSON.parse(JSON.stringify(arr));
              this.appref.tick();
            }
          });
      },500);
    });
  }
  loadAsientos(){
    this.globals.Api('/eve-eventos-asientos-evento', {id: this.data.id}).subscribe(data => {
      if (!data || !data.length) return;
      this.asientos = data;
      setTimeout(() => {
        for(var i = 0; i < this.asientos.length; i++){
          $('#tab-tarifas .table-'+this.asientos[i].id+' tbody').sortable({
            handle: '.btn-sort',
            stop: (event:any, ui:any) => {
              let arr:any = [];
              let elements = $(event.target).find('tr');
              let id_asiento = $(event.target).attr('itemid');
              let asiento = this.asientos.find((el:any) => el.id == id_asiento);
              if (!asiento) return;
              if (!elements || !elements.length) return;
              let index = 0;
              for (let item of elements) {
                let arritem = asiento.tarifas.find((el:any) => el.id == item.getAttribute('itemid'));
                if (!arritem) continue;
                arr.push(arritem);
              }
              if (arr && arr.length) asiento.tarifas = JSON.parse(JSON.stringify(arr));
              this.appref.tick();
              console.log(this.asientos);
            }
          });
        }
      }, 500);
    });
  }
  loadCategoriasUsadas(){
    this.globals.Api('/eve-eventos-categorias-usadas').subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Se ha producido un error', 'error');return;
      }
      this.categoriasUsadas = data;
      //console.log(this.categoriasUsadas);
    });
  }

  ChangeIdioma() {
    $('#txtdescripcion + div,#txtdescripcion-en + div,#txtdescripcion-fr + div,#txtdescripcion-pt + div').hide();
    let id = 'txtdescripcion';
    if (this.idioma != 'es') id += '-' + this.idioma;
    $('#' + id + ' + div').show();
  }

  SelecImagen() {
    let input = this.globals.FileInput('image/*', event => {
      this.globals.Base64Image(input, base64 => {
        this.data.imagen = base64;
        this.imgsubir = base64;
        this.appref.tick();
      });
    });
    input.click();
  }
  BorrarImagen(event:any) {
    event.stopPropagation();
    swal({
      title: "¿Borrar imagen principal del alojamiento?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      if (!this.data.id || this.data.id == '') {
        this.imgsubir = '';
        this.data.imagen = '';
        return;
      }
      this.globals.Api('/borrar-imagen-evento', {id: this.data.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.imgsubir = '';
        this.data.imagen = '';
        this.appref.tick();
      });
    });
  }

  Cancelar() {
    this.ngZone.run(() => this.router.navigateByUrl('/eventos')).then();
  }
  Guardar() {
    this.data.descripcion = tinymce.EditorManager.get('txtdescripcion').getContent();
    if (tinymce.EditorManager.get('txtdescripcion-en')) this.data.en.descripcion = tinymce.EditorManager.get('txtdescripcion-en').getContent();
    if (tinymce.EditorManager.get('txtdescripcion-fr')) this.data.fr.descripcion = tinymce.EditorManager.get('txtdescripcion-fr').getContent();
    if (tinymce.EditorManager.get('txtdescripcion-pt')) this.data.pt.descripcion = tinymce.EditorManager.get('txtdescripcion-pt').getContent();
    if (
      !this.data.nombre
      //|| !this.data.adultos
      || this.data.nombre == ''
      //|| this.data.adultos == ''
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }
    // if (!this.data.num_minimo_noches
    //   || isNaN(this.data.num_minimo_noches)
    //   || this.data.num_minimo_noches == ''
    //   || parseInt(this.data.num_minimo_noches) < 1) {
    //   swal('Número mínimo no válido', 'El número de noches mínimas para reservar no es válido', 'error');return;
    // }
    // if (!this.data.aforo
    //   || isNaN(this.data.aforo)
    //   || this.data.aforo == ''
    //   || parseInt(this.data.aforo) < 1) {
    //   swal('Número mínimo no válido', 'El número de aforo no es válido', 'error');return;
    // }
    this.data.tipo = 'evento';

    this.data.img = null;
    if (this.imgsubir != '') this.data.img = this.imgsubir;
    this.title = this.data.nombre;

    if (!this.data.id || this.data.id == '') {
      $.buttonloading('.btn-guardar', 'Enviando');
      this.globals.Api('/eve-eventos-add', this.data).subscribe(data => {
        $.buttoncancelloading('.btn-guardar');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.Cancelar();
      }, error => {
        $.buttoncancelloading('.btn-guardar');
      });
    } else {
      $.buttonloading('.btn-guardar', 'Enviando');
      this.globals.Api('/eve-eventos-edit', this.data).subscribe(data => {
        $.buttoncancelloading('.btn-guardar');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error al editar', 'error');return;
        }
        // swal('Guardado', 'Datos guardados correctamente', 'success');
        toastr.success(`Datos guardados correctamente`, 'Guardado');
        //this.LoadFechasBloqueadas();
      }, error => {
        $.buttoncancelloading('.btn-guardar');
      });
    }
  }

  //Galeria de imagenes
  AddImagen() {
    let input = this.globals.FileInput('image/*', event => {
      this.globals.Base64Image(input, base64 => {
        // this.data.imagen = base64;
        // this.imgsubir = base64;
        this.appref.tick();
        if (!this.data.id || this.data.id == '') {
          if (!this.data.imagenes) this.data.imagenes = [];
          this.data.imagenes.push({imagen: base64});
          return;
        }
        $.buttonloading('btn-add-imagen', 'Enviando');
        this.globals.Api('/eve-eventos-imagenes-add', {id_evento: this.data.id, imagen: base64}).subscribe(data => {
          $.buttoncancelloading('btn-add-imagen');
          if (!data || data.error) {
            swal('Error', 'Se ha producido un error', 'error');return;
          }
          if (!this.data.imagenes) this.data.imagenes = [];
          this.data.imagenes.push({id: data.value, imagen: base64});
        }, error => {
          $.buttoncancelloading('btn-add-imagen');
        });

      });
    });
    input.click();
  }
  DelImagen(event:any, item:any) {
    event.stopPropagation();
    if (!item || !item.id) {
      this.data.imagenes.splice(this.data.imagenes.findIndex(el => el.imagen == item.imagen), 1);
      return;
    }
    if (!this.data.id || this.data.id == '') return;
    swal({
      title: "¿Borrar imagen?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/eve-eventos-imagenes-del', {id_evento: this.data.id, id: item.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.data.imagenes.splice(this.data.imagenes.findIndex(el => el.id == item.id), 1);
        this.appref.tick();
      });
    });
  }

  //Caracteristicas
  CaracteristicaDefecto(icon, label) {
    this.dataitem.caracteristica = label;
    this.dataitem.icono = icon;
    $('.cmb-icono-caracteristica .chosen-single span').html(`<i class="${icon}"></i> ${icon}`);
  }
  AddCaracteristica() {
    this.idioma = 'es';
    $('#modal-crear-caracteristica').modal('show');
    $('#modal-crear-caracteristica .modal-title').html('Nueva característica');
    Object.keys(this.dataitem).forEach(item => {this.dataitem[item] = ''});
    this.dataitem.en = {};
    this.dataitem.fr = {};
    this.dataitem.pt = {};
    setTimeout(() => {
      $('#modal-crear-caracteristica input[type="text"]')[0].focus();
    }, 500);
  }
  EditCaracteristica(item:any) {
    $('#modal-crear-caracteristica').modal('show');
    $('#modal-crear-caracteristica .modal-title').html('Editar característica');
    this.dataitem = item;
    if (!this.dataitem.en) this.dataitem.en = {};
    if (!this.dataitem.fr) this.dataitem.fr = {};
    if (!this.dataitem.pt) this.dataitem.pt = {};
    setTimeout(() => {
      $('#modal-crear-caracteristica input[type="text"]')[0].focus();
    }, 500);
  }
  DelCaracteristica(item:any) {
    if (!item || !item.id) {
      this.data.caracteristicas.splice(this.data.caracteristicas.findIndex(el => el.caracteristica == item.caracteristica), 1);
      return;
    }
    if (!this.data.id || this.data.id == '') return;
    swal({
      title: "¿Borrar característica?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/eve-eventos-caracteristicas-del', {id_evento: this.data.id, id: item.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.data.caracteristicas.splice(this.data.caracteristicas.findIndex(el => el.id == item.id), 1);
        this.appref.tick();
      });
    });
  }
  GuardarCaracteristica() {
    if (
      this.dataitem.caracteristica == ''
      || !this.dataitem.caracteristica
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }

    if (!this.data.id || this.data.id == '') {
      if (!this.data.caracteristicas) this.data.caracteristicas = [];
      this.data.caracteristicas.push({caracteristica: this.dataitem.caracteristica, icono: this.dataitem.icono});
      $('#modal-crear-caracteristica').modal('hide');
      return;
    }

    $.buttonloading('btn-guardar-caracteristica', 'Enviando');
    this.dataitem.id_evento = this.data.id;
    if (!this.dataitem.id || this.dataitem.id == '') {
      this.globals.Api('/eve-eventos-caracteristicas-add', this.dataitem).subscribe(data => {
        $.buttoncancelloading('btn-guardar-caracteristica');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        if (!this.data.caracteristicas) this.data.caracteristicas = [];
        this.data.caracteristicas.push({
          id: data.value,
          caracteristica: this.dataitem.caracteristica,
          en: {caracteristica: this.dataitem.caracteristica},
          fr: {caracteristica: this.dataitem.caracteristica},
          pt: {caracteristica: this.dataitem.caracteristica}
        });
        $('#modal-crear-caracteristica').modal('hide');
      }, error => {
        $.buttoncancelloading('btn-guardar-caracteristica');
      });
    } else {
      this.globals.Api('/eve-eventos-caracteristicas-edit', this.dataitem).subscribe(data => {
        $.buttoncancelloading('btn-guardar-caracteristica');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        $('#modal-crear-caracteristica').modal('hide');
      }, error => {
        $.buttoncancelloading('btn-guardar-caracteristica');
      });
    }
  }

  //Categorías
  CategoriaDefecto(icon, label) {
    this.dataitem.categoria = label;
    this.dataitem.icono = icon;
    $('.cmb-icono-categoria .chosen-single span').html(`<i class="${icon}"></i> ${icon}`);
  }
  AddCategoria() {
    this.idioma = 'es';
    $('#modal-crear-categoria').modal('show');
    $('#modal-crear-categoria .modal-title').html('Nueva categoria');
    Object.keys(this.dataitem).forEach(item => {this.dataitem[item] = ''});
    if (!this.dataitem.en) this.dataitem.en = {};
    if (!this.dataitem.fr) this.dataitem.fr = {};
    if (!this.dataitem.pt) this.dataitem.pt = {};
    setTimeout(() => {
      $('#modal-crear-categoria input[type="text"]')[0].focus();
    }, 500);
  }
  EditCategoria(item:any) {
    $('#modal-crear-categoria').modal('show');
    $('#modal-crear-categoria .modal-title').html('Editar categoria');
    this.dataitem = item;
    if (!this.dataitem.en) this.dataitem.en = {};
    if (!this.dataitem.fr) this.dataitem.fr = {};
    if (!this.dataitem.pt) this.dataitem.pt = {};
    setTimeout(() => {
      $('#modal-crear-categoria input[type="text"]')[0].focus();
    }, 500);
  }
  DelCategoria(item:any) {
    if (!item || !item.id) {
      this.data.categorias.splice(this.data.categorias.findIndex(el => el.categoria == item.categoria), 1);
      return;
    }
    if (!this.data.id || this.data.id == '') return;
    swal({
      title: "¿Borrar categoria?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/eve-eventos-categorias-del', {id_evento: this.data.id, id: item.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.data.categorias.splice(this.data.categorias.findIndex(el => el.id == item.id), 1);
        this.appref.tick();
        this.loadCategoriasUsadas();
      });
    });
  }
  GuardarCategoria() {
    if (
      this.dataitem.categoria == ''
      || !this.dataitem.categoria
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }

    if (!this.data.id || this.data.id == '') {
      if (!this.data.categorias) this.data.categorias = [];
      this.data.categorias.push({categoria: this.dataitem.categoria, icono: this.dataitem.icono});
      $('#modal-crear-categoria').modal('hide');
      this.loadCategoriasUsadas();
      return;
    }

    $.buttonloading('btn-guardar-categoria', 'Enviando');
    this.dataitem.id_evento = this.data.id;
    if (!this.dataitem.id || this.dataitem.id == '') {
      this.globals.Api('/eve-eventos-categorias-add', this.dataitem).subscribe(data => {
        $.buttoncancelloading('btn-guardar-categoria');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        if (!this.data.categorias) this.data.categorias = [];
        this.data.categorias.push({
          id: data.value, categoria: this.dataitem.categoria,
          en: {categoria: this.dataitem.categoria},
          fr: {categoria: this.dataitem.categoria},
          pt: {categoria: this.dataitem.categoria}
        });
        $('#modal-crear-categoria').modal('hide');
        this.loadCategoriasUsadas();
      }, error => {
        $.buttoncancelloading('btn-guardar-categoria');
      });
    } else {
      this.globals.Api('/eve-eventos-categorias-edit', this.dataitem).subscribe(data => {
        $.buttoncancelloading('btn-guardar-categoria');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        $('#modal-crear-categoria').modal('hide');
        this.loadCategoriasUsadas();
      }, error => {
        $.buttoncancelloading('btn-guardar-categoria');
      });
    }
  }

  //Servicios
  AddServicio() {
    $('#modal-crear-servicio').modal('show');
    $('#modal-crear-servicio .modal-title').html('Nuevo servicio');
    Object.keys(this.dataitem).forEach(item => {this.dataitem[item] = ''});
    setTimeout(() => {
      $('#modal-crear-servicio input[type="text"]')[0].focus();
    }, 500);
  }
  DelServicio(item:any) {
    if (!item || !item.id) {
      this.data.servicios.splice(this.data.servicios.findIndex(el => el.servicio == item.servicio), 1);
      return;
    }
    if (!this.data.id || this.data.id == '') return;
    swal({
      title: "¿Borrar servicio?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {

    });
  }
  GuardarServicio() {
    this.dataitem.icono = $('.cmb-icono-servicio .chosen-single i').attr('class');
    if (
      this.dataitem.servicio == ''
      || !this.dataitem.servicio
      || this.dataitem.icono == ''
      || !this.dataitem.icono
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }

    if (!this.data.id || this.data.id == '') {
      if (!this.data.servicios) this.data.servicios = [];
      this.data.servicios.push({servicio: this.dataitem.servicio, icono: this.dataitem.icono});
      $('#modal-crear-servicio').modal('hide');
      return;
    }

    $.buttonloading('btn-guardar-servicio', 'Enviando');
    this.dataitem.id_alojamiento = this.data.id;
  }

  ActualizarMapa(){
    this.srcGMap = 'https://maps.google.com/maps?width=100%25&height=600&hl=es&q='+this.data.localizacion_maps+'+(dsf)&t=&z=14&ie=UTF8&iwloc=B&output=embed';
    var intervalLoadMap = setInterval(()=>{
      if($('#content-iframe-gmaps iframe').attr('src') == this.srcGMap){
        clearInterval(intervalLoadMap);
        return;
      }
      $('#content-iframe-gmaps iframe').attr('src',this.srcGMap);
    },1000);
  }

  AbrirMapa() {
    $('#modal-mapa').modal('show');
    $('#mapa').remove();
    $('#mapa-container').html('<div id="mapa" style="width:100%;height:400px;"></div>');
    $('#mapa').removeAttr('class');
    setTimeout(() => {
      let point = [40.036198, -6.088190];
      $.localizaciontemp = point[0]+','+point[1];
      // let m = L.DomUtil.get('mapa'); if (m != null) { m._leaflet_id = null; }
      var mymap = L.map('mapa').setView(point, 8);
      if (this.data.localizacion_maps) {
        point = [this.data.localizacion_maps.split(',')[0], this.data.localizacion_maps.split(',')[1]];
        $.localizaciontemp = point[0]+','+point[1];
        mymap.setView(point, 14);
      }
      L.tileLayer('http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
        maxZoom: 20,
        subdomains:['mt0','mt1','mt2','mt3']
      }).addTo(mymap);
      var marker = L.marker(point).addTo(mymap);
      mymap.on('click', function(e) {
        marker.setLatLng([e.latlng.lat, e.latlng.lng]);
        $.localizaciontemp = e.latlng.lat+','+e.latlng.lng;
      });
    }, 500);
  }
  SelecLocalizacion() {
    if ($.localizaciontemp) this.data.localizacion_maps = $.localizaciontemp;
    $('#modal-mapa').modal('hide');
    this.ActualizarMapa();
    this.appref.tick();
  }
  SetLocalidad(){
    this.globals.Api('/localidad', {codpostal: this.data.cod_postal}).subscribe(data => {
      if (!data || data.error) {
        // swal('Error', 'Se ha producido un error', 'error');return;
      }
      this.data.localidad = data.localidad;
      this.data.provincia = data.provincia;
    }, error => {
      // toastr.error(`Al bloquear sesión`, 'Error');
    });
  }

  //Tarifas por personas
  AddTarifaS(){
    console.log('Add tarifa S');
    this.data.tarifas.push({
      nombre: this.data.tempNombreTarifa, precio: this.data.tempPrecioTarifa,
      en: {nombre: this.data.tempNombreTarifa},
      fr: {nombre: this.data.tempNombreTarifa},
      pt: {nombre: this.data.tempNombreTarifa},
    });
    this.data.tempNombreTarifa = '';
    this.data.tempPrecioTarifa = '';
  }
  DelTarifaS(item:any){
    this.data.tarifas.splice(this.data.tarifas.findIndex(el => el == item), 1);
  }

  KeyS(event:any, item:any, field:any) {
    if (event.which == 13 || event.keyCode == 13) {
      if (!event.target.textContent || event.target.textContent.trim() == '') return;
      item[field] = event.target.textContent.trim();
      event.target.blur();
      this.appref.tick();
    }
    if (event.which == 9 || event.keyCode == 9) {
      if (field == 'precio') {
        event.target.blur();
        this.AddTarifaS();
        this.appref.tick();
      }
    }
  }
  BlurS(event:any, item:any, field:any) {
    if (!event.target.textContent || event.target.textContent.trim() == '') return;
    item[field] = event.target.textContent.trim();
    item[field] = this.globals.StripHTML(item[field]);
    this.appref.tick();
  }

  //Tarifas por grupos de personas
  AddTarifaG(){
    console.log('Add tarifa S');
    this.data.tarifas_grupos.push({
      nombre: this.data.tempNombreTarifa,num_personas: this.data.tempNumeroPersonasTarifa, precio: this.data.tempPrecioTarifa
    });
    this.data.tempNombreTarifa = '';
    this.data.tempPrecioTarifa = '';
    this.data.tempNumeroPersonasTarifa = '';
  }
  DelTarifaG(item:any){
    this.data.tarifas_grupos.splice(this.data.tarifas.findIndex(el => el == item), 1);
  }

  //Asientos
  AddAsientos() {
    this.idioma = 'es';
    this.asientos_temp = [];
    this.asiento = {};
    this.asiento.en = {};
    this.asiento.fr = {};
    this.asiento.pt = {};
    $('#modal-crear-asiento').modal('show');
    $('#modal-crear-asiento .modal-title').html('Nuevo asiento');
    // Object.keys(this.data.asientos).forEach(item => {this.data.asientos[item] = ''});
    setTimeout(() => {
      $('#modal-crear-asiento .table tbody').sortable({
        handle: '.btn-sort',
        stop: (event:any, ui:any) => {
          let arr:any = [];
          let elements = $('#modal-crear-asiento .table tbody tr');
          if (!elements || !elements.length) return;
          let index = 0;
          for (let item of elements) {
            let arritem = this.asientos_temp.find((el:any) => el.id == item.getAttribute('itemid'));
            if (!arritem) continue;
            arr.push(arritem);
          }
          if (arr && arr.length) this.asientos_temp = JSON.parse(JSON.stringify(arr));
          this.appref.tick();
        }
      });

    }, 500);
  }
  EditAsiento(item) {
    this.asiento = item;
    if (!this.asiento.en) this.asiento.en = {};
    if (!this.asiento.fr) this.asiento.fr = {};
    if (!this.asiento.pt) this.asiento.pt = {};
    if (this.data.id && this.data.id != '') {
      this.globals.Api('/eve-eventos-asiento-tarifas-evento', {id_evento: this.data.id, id_asiento: this.asiento.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.asientos_temp = data;
        // this.data.asientos.splice(this.data.asientos.findIndex(el => el == this.asientos_temp), 1);
        $('#modal-editar-asiento .table tbody').sortable({
          handle: '.btn-sort',
          stop: (event:any, ui:any) => {
            let arr:any = [];
            let elements = $('#modal-editar-asiento .table tbody tr');
            if (!elements || !elements.length) return;
            let index = 0;
            for (let item of elements) {
              let arritem = this.asientos_temp.find((el:any) => el.id == item.getAttribute('itemid'));
              if (!arritem) continue;
              arr.push(arritem);
            }
            if (arr && arr.length) this.asientos_temp = JSON.parse(JSON.stringify(arr));
            var orden = 0;
            this.asientos_temp.forEach(asiento => {
              asiento.orden = orden;
              orden++;
            });
            this.appref.tick();
          }
        });
      $('#modal-editar-asiento').modal('show');
      $('#modal-editar-asiento .modal-title').html('Editar asiento');
      });
    } else {
      this.asientos_temp = [];
      this.data.asientos.forEach(item => {
        if (item.nombre_asiento == this.asiento.nombre) {
          if (!item.en) item.en = {};
          if (!item.fr) item.fr = {};
          if (!item.pt) item.pt = {};
          this.asientos_temp.push(item);
        }
      });

      $('#modal-editar-asiento').modal('show');
      $('#modal-editar-asiento .modal-title').html('Editar asiento');
    }
  }
  GuardarEditModalAsiento() {
    if (this.data.id && this.data.id != '') {
      let params:any = {id_evento: this.data.id, nombre_asiento: this.asiento.nombre, num_asientos: this.asiento.num_asientos, id_asiento: this.asiento.id, tarifas: this.asientos_temp};
      params.en = this.asiento.en;
      params.fr = this.asiento.fr;
      params.pt = this.asiento.pt;
      this.globals.Api('/eve-eventos-asiento-tarifas-edit', params).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.asientos_temp = [];
        this.asientos = [];
        this.asiento = {};
        if (!this.asiento.en) this.asiento.en = {};
        if (!this.asiento.fr) this.asiento.fr = {};
        if (!this.asiento.pt) this.asiento.pt = {};
        this.loadAsientos();
        $('#modal-editar-asiento').modal('hide');
      });
    }else{
      let asientoExiste = false;
      this.asientos.forEach(asiento => {
        if(asiento.nombre == this.asiento.nombre){
          asientoExiste = true;
        }
      });
      if(!asientoExiste){
        this.asientos.push(this.asiento);
      }
      this.asientos_temp.forEach(asiento_temp => {
        let asientoExiste = false;
        this.data.asientos.forEach(item => {
            if(item.nombre_asiento == asiento_temp.nombre_asiento && item.nombre == asiento_temp.nombre){
              // this.data.asientos.push(this.asientos_temp);
              item.precio = asiento_temp.precio;
              asientoExiste = true;
            }
          });
          if(!asientoExiste){
            this.data.asientos.push(asiento_temp);
            asientoExiste = true;
          }
      });
      this.asientos_temp = [];
      this.asiento = {};
      if (!this.asiento.en) this.asiento.en = {};
      if (!this.asiento.fr) this.asiento.fr = {};
      if (!this.asiento.pt) this.asiento.pt = {};
      $('#modal-editar-asiento').modal('hide');
    }
  }
  GuardarAsientos() {
    if (this.asiento.nombre == '' || this.asiento.num_asientos == '') {
      swal('Complete campos', 'Por favor, rellene el campo para continuar', 'error');return;
    }

    if (!this.data.id || this.data.id == '') {
      this.asientos.push(this.asiento);
      this.asientos_temp.forEach(asiento_temp => {
        if (!asiento_temp.en) asiento_temp.en = {};
        if (!asiento_temp.fr) asiento_temp.fr = {};
        if (!asiento_temp.pt) asiento_temp.pt = {};
        this.data.asientos.push(asiento_temp);
      });
      this.asientos_temp = [];
      this.asiento = {};
      $('#modal-crear-asiento').modal('hide');
      return;
    }
    this.asientos.push({asiento: this.asiento});
    $.buttonloading('btn-guardar-asiento', 'Enviando');
    // console.log(this.sesiones);
    this.globals.Api('/eve-eventos-asiento-tarifas-add', {id_evento: this.data.id, asiento: this.asiento, asientos_tarifas: this.asientos_temp}).subscribe(data => {
      $.buttoncancelloading('btn-guardar-asiento');
      if (!data || data.error) {
        swal('Error', 'Se ha producido un error', 'error');return;
      }
      this.asientos_temp = [];
      this.asientos = [];
      this.asiento = {};
      if (!this.asiento.en) this.asiento.en = {};
      if (!this.asiento.fr) this.asiento.fr = {};
      if (!this.asiento.pt) this.asiento.pt = {};
      this.loadAsientos();
      $('#modal-crear-asiento').modal('hide');
    }, error => {
      $.buttoncancelloading('btn-guardar-asiento');
    });
  }
  DelAsientoTarifas(item:any) {
    console.log(item);
    if (!item) {
      this.asientos.splice(this.asientos.findIndex(el => el == item), 1);
      this.data.asientos.splice(this.data.asientos.findIndex(el => el == item), 1);
      return;
    }
    if (!this.data.id || this.data.id == ''){
      this.data.asientos.splice(this.asientos_temp.findIndex(el => el == item), 1);
      this.asientos_temp.splice(this.asientos_temp.findIndex(el => el == item), 1);
    }else{
      this.asientos_temp.splice(this.asientos_temp.findIndex(el => el == item), 1);
    }
  }
  DelAsientoTarifas2(tarifa:any) {
    for(var i = 0; i <= this.asientos.length; i++){
      console.log(this.asientos[i].id);
      console.log(tarifa.id_asiento);
      if(this.asientos[i].id == tarifa.id_asiento){
        console.log('coincidencia');
        this.asientos[i].tarifas.splice(this.asientos[i].tarifas.findIndex(el => el == tarifa), 1);
      }
    }
  }
  DelAsiento(item:any){
    console.log(item);
    swal({
      title: "¿Borrar asiento?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      if (!this.data.id || this.data.id == '') {

        return;
      }
      this.globals.Api('/eve-eventos-asiento-tarifas-del', {id: item.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.asientos_temp = [];
        this.asientos = [];
        this.asiento = {};
        this.loadAsientos();
      });
    });
  }
  GetPropiedadesGrupo(asientos:number) {
    return this.asientos_temp.filter((el:any) => el.asientos == asientos);
  }
  Key(event:any, item:any, field:any) {
    if (event.which == 13 || event.keyCode == 13) {
      if (!event.target.textContent || event.target.textContent.trim() == '') return;
      item[field] = event.target.textContent.trim();
      event.target.blur();
      this.appref.tick();
    }
    if (event.which == 9 || event.keyCode == 9) {
      if (field == 'precio') {
        // let index:number = this.GetPropiedadesGrupo(item.nombre_asiento).findIndex((el:any) => el.id == item.id);
        // if (index == this.GetPropiedadesGrupo(item.nombre_asiento).length-1) {
        // }
        event.target.blur();
        this.AddAsiento(item.nombre_asiento);
        this.appref.tick();
      }
    }
  }
  Blur(event:any, item:any, field:any) {
    if (!event.target.textContent || event.target.textContent.trim() == '') return;
    item[field] = event.target.textContent.trim();
    item[field] = this.globals.StripHTML(item[field]);
    this.appref.tick();
  }
  AddAsiento(asiento:any = null) {
    // console.log(asiento);
    if (!this.data.id || this.data.id == ''){
      let nombre:any = this.asiento.nombre;
      let num_asientos:any = this.asiento.num_asientos;
      this.asientos_temp.push({
        nombre: '',num_asientos: num_asientos, nombre_asiento: nombre, precio: ''
      });
    }else{
      if(asiento == null || asiento.length == 0){
        let nombre:any = this.asiento.nombre;
        let num_asientos:any = this.asiento.num_asientos;
        this.asientos_temp.push({
          nombre: '',num_asientos: num_asientos, nombre_asiento: nombre, precio: ''
        });
      }else{
        for(var i = 0; i <= this.asientos.length; i++){
          if(this.asientos[i].id == asiento.id){
            this.asientos[i].tarifas.push({
              id_evento: asiento.id_evento, id_asiento: asiento.id, nombre: '', nombre_asiento: asiento.nombre, precio: ''
            });
          }
        }
      }
    }
  }
}
