<div id="page-wrapper" class="page-loading">
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                <div class="content-header">
                    <div class="row-equal-cols">
                        <div class="col-lg-8 col-md-6 col-xs-12">
                            <div class="header-section">
                                <h1 class="middle">Configuración</h1>
                                <!-- <button class="btn btn-xs btn-secondary middle m-l-10" (click)="CargarGrid()"><i class="fa fa-refresh"></i></button> -->
                            </div>
                            <ul class="breadcrumb breadcrumb-top text-left">
                                <li><a [routerLink]="['/dashboard']">Inicio</a></li>
                                <li>Configuración</li>
                            </ul>
                        </div>
                        <div class="col-lg-4 col-md-6 col-xs-12 col-botones text-center">
                          <a class="btn btn-primary btn-guardar m-l-20">Guardar</a>
                        </div>
                    </div>
                </div>

                <div class="block full break-padding">
                    <div class="block-title">
                        <!-- <div class="block-options pull-right">
                            <a href="javascript:void(0)" class="btn btn-effect-ripple btn-default" data-toggle="tooltip" title=""
                                style="overflow: hidden; position: relative;" data-original-title="Settings"><i
                                    class="fa fa-cog"></i></a>
                        </div> -->
                        <ul class="nav nav-tabs" data-toggle="tabs">
                            <li class="active"><a href="#tab-resumen">Resumen</a></li>
                            <li><a href="#tab-personalizacion">Personalización</a></li>
                            <li><a href="#tab-email">Email</a></li>
                            <li><a href="#tab-whatsapp">WhatsApp</a></li>
                            <li><a href="#tab-pagos">Métodos pago</a></li>
                            <li><a href="#tab-analytics">Google Analytics</a></li>
                            <li class="hidden"><a href="#tab-integraciones">Integraciones</a></li>
                        </ul>
                    </div>
                    <div class="tab-content">
                        <div class="tab-pane active" id="tab-resumen">
                            <div class="col-lg-4">
                                <div class="row">
                                    <h2>Reserva de actividades</h2>
                                    <div>
                                        <canvas id="grafico-reserva-actividades" height="300">

                                        </canvas>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-8">
                                <div class="row">
                                    <h2>Reserva del mes</h2>
                                    <div>
                                        <canvas id="grafico-reserva-dias-mes" height="300">

                                        </canvas>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane" id="tab-personalizacion">
                        </div>
                        <div class="tab-pane" id="tab-email">
                        </div>
                        <div class="tab-pane" id="tab-whatsapp">
                        </div>
                        <div class="tab-pane" id="tab-pagos">

                        </div>
                        <div class="tab-pane" id="tab-analytics">
                        </div>
                        <div class="tab-pane" id="tab-integraciones"></div>
                    </div>
                </div>
            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->

        <app-backend-footer></app-backend-footer>
    </div>
    <!-- END Page Container -->
</div>