import { ApplicationRef, Component, NgZone, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let toastr: any;
declare let moment: any;

@Component({
  selector: 'app-mensajes',
  templateUrl: './mensajes.component.html'
})
export class MensajesComponent implements OnInit {
  public url_iframe:any = '';

  constructor(
    private activeRoute: ActivatedRoute,
    public sanitize: DomSanitizer,
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
    this.activeRoute.params.subscribe(routeParams => {
      this.CargarDatos();
    });
  }
  CargarDatos() {
    this.globals.Api('/configuracion-cliente').subscribe(data => {
      if (!data || data.error) return;
      // this.globals.config = data;
      let timestamp = Date.now();

      if (!data.whatsapp_fbid) return;
      this.url_iframe = this.sanitize.bypassSecurityTrustResourceUrl(
        'https://manager.pecesgordosestudio.es/whatsapp-web?fbid='+data.whatsapp_fbid+'&token=DX9FO2BV46QYVD53JQ02&t='+timestamp
      );

    });
  }

}
