<div id="page-wrapper" class="page-loading">
    <!-- Preloader -->
    <!-- Preloader functionality (initialized in js/app.js) - pageLoading() -->
    <!-- Used only if page preloader enabled from inc/config (PHP version) or the class 'page-loading' is added in #page-wrapper element (HTML version) -->
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <!-- END Preloader -->

    <!-- Page Container -->
    <!-- In the PHP version you can set the following options from inc/config file -->
    <!--
        Available #page-container classes:

        'sidebar-light'                                 for a light main sidebar (You can add it along with any other class)

        'sidebar-visible-lg-mini'                       main sidebar condensed - Mini Navigation (> 991px)
        'sidebar-visible-lg-full'                       main sidebar full - Full Navigation (> 991px)

        'sidebar-alt-visible-lg'                        alternative sidebar visible by default (> 991px) (You can add it along with any other class)

        'header-fixed-top'                              has to be added only if the class 'navbar-fixed-top' was added on header.navbar
        'header-fixed-bottom'                           has to be added only if the class 'navbar-fixed-bottom' was added on header.navbar

        'fixed-width'                                   for a fixed width layout (can only be used with a static header/main sidebar layout)

        'enable-cookies'                                enables cookies for remembering active color theme when changed from the sidebar links (You can add it along with any other class)
    -->
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">

                <ng-container *ngIf="globals && globals.me">
                    <!-- ADMIN -->
                    <ng-container *ngIf="globals.me.id_rol == 0 || globals.me.id_rol == 1 || globals.me.id_rol == 2">

                        <div class="row">
                            <div class="col-sm-12 col-lg-8">
                                <div class="row">
                                    <div class="col-sm-12 col-lg-6 order-0 order-sm-1">
                                        <div class="row">
                                            <div class="col-sm-6 col-lg-6">
                                                <a [routerLink]="['/reservas']" class="widget custom-widget">
                                                    <span class="title-counter sen-extrabold counter-total-reservas">Reservas para hoy</span>
                                                    <hr class="separador-general" />
                                                    <h2 class="counter-dashboard counter-min" *ngIf="data">
                                                        <strong>
                                                            <span data-toggle="counter" [attr.data-to]="data.total_reservas_hoy">{{data.total_reservas_hoy}}</span>
                                                            <span class="reservas-hoy-personas" data-toggle="counter" [attr.data-to]="data.total_reservas_hoy">({{data.total_reservas_personas_hoy}} personas)</span>
                                                        </strong><br/>
                                                        <span class="subtitle-counter sen-extrabold ">Totales</span>
                                                    </h2>
                                                    <div class="widget-content widget-content-mini text-left clearfix no-padding-right">
                                                        <span class="text-new-mas reservas">Ver todas<br/>las reserva</span>
                                                        <div class="widget-icon pull-right no-background">
                                                            <img src="assets/icon-eye.svg" />
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div class="col-sm-6 col-lg-6">
                                                <a [routerLink]="['/colaboradores']" class="widget custom-widget">
                                                    <span class="title-counter sen-extrabold ">Colaboradores</span>
                                                    <hr class="separador-general" />
                                                    <h2 class="counter-dashboard counter-min" *ngIf="data">
                                                        <strong><span data-toggle="counter" [attr.data-to]="data.total_colaboradores">{{data.total_colaboradores}}</span></strong><br/>
                                                        <span class="subtitle-counter sen-extrabold ">Totales</span>
                                                    </h2>
                                                    <div class="widget-content widget-content-mini text-left clearfix no-padding-left no-padding-right">
                                                        <span class="text-new-mas colaborador">Nuevo<br/>Colaborador</span>
                                                        <div class="widget-icon pull-right no-background">
                                                            <img src="assets/icon-mas-alt.svg" />
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div class="col-sm-12 col-lg-12">
                                                <div class="widget custom-widget">
                                                    <span class="title-counter sen-extrabold ">Ventas colaboradores</span>
                                                    <hr class="separador-general" />
                                                    <ul class="lista-ventas-colaboradores">
                                                        <li class="cursor-pointer" *ngFor="let colaborador of rankingColaboradores" (click)="VerColaborador(colaborador)">
                                                            <ng-container *ngIf="colaborador.empresa">{{colaborador.empresa}}<span>{{colaborador.cont_reservas}} reservas</span></ng-container>
                                                            <ng-container *ngIf="!colaborador.empresa">{{colaborador.nombre}} {{colaborador.apellidos}}<span>{{colaborador.cont_reservas}} reservas</span></ng-container>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-lg-6 order-1 order-sm-0" id="ancla-calendar">
                                        <!-- <ng-container *ngIf="actividades">
                                            <select id="selector-actividades-calendario" class="form-control" placeholder="Rol" [(ngModel)]="actividades.id">
                                              <option *ngFor="let actividad of actividades" [value]="actividad.id">{{actividad.nombre}}</option>
                                            </select>
                                          </ng-container> -->
                                        <div id="calendar" class="calendario calendario-dashboard"></div>
                                    </div>
                                    <div class="col-sm-12 col-lg-12">
                                        <div class="widget custom-widget">
                                            <div class="row">
                                                <div class="col-sm-4 col-lg-3 hide-br-movil">
                                                    <span id="title-grafica" class="title-counter sen-extrabold  sen-extrabold">Reservas <br/>acumuladas</span>
                                                    <hr class="separador-general" />
                                                </div>
                                                <div class="col-sm-8 col-lg-9 margin-top-15-movil">
                                                    <div class="row">
                                                        <div class="col-sm-3 col-lg-3 col-xs-4 center">
                                                            <h2 class="counter-dashboard" *ngIf="data">
                                                                <strong style="line-height: 0px;"><span data-toggle="counter" [attr.data-to]="data.total_reservas_semana">{{data.total_reservas_semana}}</span><br/><span style="font-size: 12px;">({{data.total_reservas_personas_semana}} Personas)</span><br/><span style="font-size: 16px;">{{data.total_reservas_dinero_semana}}€</span></strong><br/>
                                                                <span class="subtitle-counter subtitle-red">Semana</span>
                                                            </h2>
                                                        </div>
                                                        <div class="col-sm-3 col-lg-3 col-xs-4 center separador-grafica-left-right">
                                                            <h2 class="counter-dashboard" *ngIf="data">
                                                                <strong style="line-height: 0px;"><span data-toggle="counter" [attr.data-to]="data.total_reservas_mes">{{data.total_reservas_mes}}</span><br/><span style="font-size: 12px;">({{data.total_reservas_personas_mes}} Personas)</span><br/><span style="font-size: 16px;">{{data.total_reservas_dinero_mes}}€</span></strong><br/>
                                                                <span class="subtitle-counter subtitle-red">Mes</span>
                                                            </h2>
                                                        </div>
                                                        <div class="col-sm-3 col-lg-3 col-xs-4 center">
                                                            <h2 class="counter-dashboard" *ngIf="data">
                                                                <strong style="line-height: 0px;"><span data-toggle="counter" [attr.data-to]="data.total_reservas">{{data.total_reservas_anio}}</span><br/><span style="font-size: 12px;">({{data.total_reservas_personas_anio}} Personas)</span><br/><span style="font-size: 16px;">{{data.total_reservas_dinero_anio}}€</span></strong><br/>
                                                                <span class="subtitle-counter subtitle-red">Año</span>
                                                            </h2>
                                                        </div>
                                                    </div>
                                                    <hr class="separador-grafica" />
                                                    <br/>
                                                </div>
                                            </div>
                                            <div id="content-grafica" height="100px">
                                                <canvas id="myChart"></canvas>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-lg-4">
                                <a class="widget custom-widget content-table content-timeline">
                                    <span class="title-counter sen-extrabold ">Actividad</span>
                                    <hr class="separador-general" />
                                    <div class="div-table">
                                        <table class="table-dashboard">
                                            <thead>
                                                <tr>
                                                    <th>Nombre</th>
                                                    <th>Plazas</th>
                                                    <th>Fecha</th>
                                                    <th>Ref</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="cursor-pointer" *ngFor="let historicoReserva of historicoReservas" (click)="Editar(historicoReserva)">
                                                    <td>{{historicoReserva.cli_nombre_completo}}</td>
                                                    <td>{{historicoReserva.entradas}}</td>
                                                    <td>{{historicoReserva.fecha}}</td>
                                                    <td>
                                                        <img *ngIf="historicoReserva.tipo == 'Web'" src="assets/icon-ref-web.svg">
                                                        <img *ngIf="historicoReserva.tipo == 'Civitatis'" src="assets/icon-ref-c.svg">
                                                        <img *ngIf="historicoReserva.tipo == 'Partner'" src="assets/icon-ref-p.svg">
                                                        <img *ngIf="historicoReserva.tipo == 'GetYourGuide'" src="assets/icon-ref-g.svg">
                                                        <img *ngIf="historicoReserva.tipo == 'Admin'" src="assets/icon-ref-admin.svg">
                                                        <img *ngIf="historicoReserva.tipo == 'Airbnb'" src="assets/icon-ref-airbnb.svg">
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="content-leyenda">
                                        <div class="fit-content-right">
                                            <p><img class="icon-leyenda" src="assets/icon-ref-web.svg"> Web</p>
                                        </div>
                                        <div class="fit-content-right hide">
                                            <p><img class="icon-leyenda" src="assets/icon-ref-c.svg"> Civitatis</p>
                                        </div>
                                        <div class="fit-content-right">
                                            <p><img class="icon-leyenda" src="assets/icon-ref-p.svg"> Partner</p>
                                        </div>
                                        <div class="fit-content-right hide">
                                            <p><img class="icon-leyenda" src="assets/icon-ref-g.svg"> GetYourGuide</p>
                                        </div>
                                        <div class="fit-content-right">
                                            <p><img class="icon-leyenda" src="assets/icon-ref-admin.svg"> Admin</p>
                                        </div>
                                        <div class="fit-content-right">
                                            <p><img class="icon-leyenda" src="assets/icon-ref-airbnb.svg"> Airbnb</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-sm-12 col-lg-4" style="margin-top: 18px;">
                                <a [routerLink]="['/reservas']" class="widget custom-widget">
                                    <span class="title-counter sen-extrabold ">Control de versión</span>
                                    <hr class="separador-general" />
                                    <div>
                                        <img src="assets/ce-logo.svg" width="50px" style="margin-top: 25px"/>
                                        <ul style="margin-top: 15px; list-style: none; padding-left: 0px;">
                                            <li style="padding-top: 5px; padding-bottom: 5px; border-bottom: 1px solid gray;">Verión: 3.0.0 (Versión estable)</li>
                                            <li style="padding-top: 5px; padding-bottom: 5px; border-bottom: 1px solid gray;"><i class="fa fa-check"></i> Cantidad de archivos: ilimitado</li>
                                            <li style="padding-top: 5px; padding-bottom: 5px; border-bottom: 1px solid gray;"><i class="fa fa-check"></i> Usuarios: +10</li>
                                            <li style="padding-top: 5px; padding-bottom: 5px; border-bottom: 1px solid gray;"><a href="#">Contrato de usuario</a></li>
                                            <li style="padding-top: 5px; padding-bottom: 5px; border-bottom: 1px solid gray;"><a href="#">Aviso de privacidad</a></li>
                                            <li style="padding-top: 5px; padding-bottom: 5px; border-bottom: 1px solid gray;"><a href="#">Términos y condiciones</a></li>
                                            <li style="padding-top: 5px; padding-bottom: 5px; border-bottom: 1px solid gray;"><a href="#">Ayuda y soporte</a></li>
                                        </ul>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <!----------------------------------------------Comentado anterior dashboard ------------------------------->
                        <div class="row" style="display: none;">
                            <div class="col-sm-6 col-lg-12">
                                <h4>Actividades</h4>
                                <div class="row">
                                    <div class="col-sm-6 col-lg-3">
                                        <a [routerLink]="['/reservas']" class="widget">
                                            <div class="widget-content widget-content-mini text-right clearfix">
                                                <div class="widget-icon pull-left themed-background-dark">
                                                    <i class="icofont-travelling text-light-op"></i>
                                                </div>
                                                <h2 class="widget-heading h3 text-success" *ngIf="data">
                                                    <strong>&nbsp;<span data-toggle="counter" [attr.data-to]="data.total_reservas">{{data.total_reservas}}</span></strong>
                                                </h2>
                                                <span class="text-muted">Reservas</span>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="col-sm-6 col-lg-3">
                                        <a [routerLink]="['/reservas/add']" class="widget">
                                            <div class="widget-content widget-content-mini text-right clearfix">
                                                <div class="widget-icon pull-left themed-background-dark">
                                                    <i class="icofont-travelling text-light-op"></i>
                                                </div>
                                                <h2 class="widget-heading h3">
                                                    <strong><i class="icofont-plus-circle"></i></strong>
                                                </h2>
                                                <span class="text-muted">Nueva reserva</span>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="col-sm-6 col-lg-3">
                                        <a [routerLink]="['/actividades']" class="widget">
                                            <div class="widget-content widget-content-mini text-right clearfix">
                                                <div class="widget-icon pull-left themed-background-success">
                                                    <i class="icofont-direction-sign text-light-op"></i>
                                                </div>
                                                <h2 class="widget-heading h3 text-success" *ngIf="data">
                                                    <strong>&nbsp;<span data-toggle="counter" [attr.data-to]="data.total_eventos">{{data.total_eventos}}</span></strong>
                                                </h2>
                                                <span class="text-muted">Actividades</span>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="col-sm-6 col-lg-3">
                                        <a [routerLink]="['/actividades/add']" class="widget">
                                            <div class="widget-content widget-content-mini text-right clearfix">
                                                <div class="widget-icon pull-left themed-background-success">
                                                    <i class="icofont-direction-sign text-light-op"></i>
                                                </div>
                                                <h2 class="widget-heading h3">
                                                    <strong><i class="icofont-plus-circle"></i></strong>
                                                </h2>
                                                <span class="text-muted">Nuevo actividad</span>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6 col-lg-3">
                                        <a [routerLink]="['/clientes']" class="widget">
                                            <div class="widget-content widget-content-mini text-right clearfix">
                                                <div class="widget-icon pull-left themed-background-warning">
                                                    <i class="icofont-ui-user-group text-light-op"></i>
                                                </div>
                                                <h2 class="widget-heading h3 text-success" *ngIf="data">
                                                    <strong>&nbsp;<span data-toggle="counter" [attr.data-to]="data.total_clientes">{{data.total_clientes}}</span></strong>
                                                </h2>
                                                <span class="text-muted">Clientes</span>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="col-sm-6 col-lg-3">
                                        <a [routerLink]="['/clientes/add']" class="widget">
                                            <div class="widget-content widget-content-mini text-right clearfix">
                                                <div class="widget-icon pull-left themed-background-warning">
                                                    <i class="icofont-ui-user text-light-op"></i>
                                                </div>
                                                <h2 class="widget-heading h3">
                                                    <strong><i class="icofont-plus-circle"></i></strong>
                                                </h2>
                                                <span class="text-muted">Nuevo cliente</span>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <h4>Equipo</h4>
                                <div class="row">
                                    <div class="col-sm-6 col-lg-3">
                                        <a [routerLink]="['/usuarios']" class="widget">
                                            <div class="widget-content widget-content-mini text-right clearfix">
                                                <div class="widget-icon pull-left themed-background-info">
                                                    <i class="icofont-people text-light-op"></i>
                                                </div>
                                                <h2 class="widget-heading h3 text-success" *ngIf="data">
                                                    <strong>&nbsp;<span data-toggle="counter" [attr.data-to]="data.total_usuarios">{{data.total_usuarios}}</span></strong>
                                                </h2>
                                                <span class="text-muted">Usuarios</span>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="col-sm-6 col-lg-3">
                                        <a [routerLink]="['/usuarios/add']" class="widget">
                                            <div class="widget-content widget-content-mini text-right clearfix">
                                                <div class="widget-icon pull-left themed-background-info">
                                                    <i class="icofont-ui-user text-light-op"></i>
                                                </div>
                                                <h2 class="widget-heading h3">
                                                    <strong><i class="icofont-plus-circle"></i></strong>
                                                </h2>
                                                <span class="text-muted">Nuevo usuario</span>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </ng-container>
                    <!-- Colaborador -->
                    <ng-container *ngIf="globals.me.id_rol == 4">
                        <div class="row">
                            <div class="col-sm-12 col-lg-8 show-movil">
                                <p class="mensage-bienvenida"><span style="font-weight:normal;">Bienvenido</span> {{globals.me.nombre}} {{globals.me.apellidos}}</p>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <div class="row">
                                    <div class="col-sm-12 col-lg-6">
                                        <!-- <ng-container *ngIf="actividades">
                                            <select id="selector-actividades-calendario" class="form-control" placeholder="Rol" [(ngModel)]="actividades.id">
                                              <option *ngFor="let actividad of actividades" [value]="actividad.id">{{actividad.nombre}}</option>
                                            </select>
                                          </ng-container> -->
                                        <div id="calendar" class="calendario calendario-dashboard"></div>
                                    </div>
                                    <div class="col-sm-12 col-lg-6">
                                        <div class="row">
                                            <div class="col-sm-6 col-lg-6">
                                                <a [routerLink]="['/mis-reservas']" class="widget custom-widget">
                                                    <span class="title-counter sen-extrabold ">Reservas hoy</span>
                                                    <hr class="separador-general" />
                                                    <h2 class="counter-dashboard counter-min" *ngIf="data">
                                                        <strong><span data-toggle="counter" [attr.data-to]="data.total_reservas">{{contadorReservas}}</span></strong><br/>
                                                        <span class="subtitle-counter sen-extrabold ">Reservas</span>
                                                    </h2>
                                                    <div class="widget-content widget-content-mini text-left clearfix no-padding-right">
                                                        <span class="text-new-mas reservas">Todas mis<br/>Reserva</span>
                                                        <div class="widget-icon pull-right no-background">
                                                            <img src="assets/icon-eye.svg" />
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            <!-- <div class="col-sm-6 col-lg-6">
                                                <a [routerLink]="['/reservas']" class="widget custom-widget">
                                                    <span class="title-counter sen-extrabold ">Colaboradores</span>
                                                    <hr class="separador-general" />
                                                    <h2 class="counter-dashboard counter-min" *ngIf="data">
                                                        <strong><span data-toggle="counter" [attr.data-to]="data.total_reservas">{{data.total_reservas}}</span></strong><br/>
                                                        <span class="subtitle-counter sen-extrabold ">Totales</span>
                                                    </h2>
                                                    <div class="widget-content widget-content-mini text-left clearfix no-padding-left no-padding-right">
                                                        <span class="text-new-mas colaborador">Nuevo<br/>Colaborador</span>
                                                        <div class="widget-icon pull-right no-background">
                                                            <img src="assets/icon-mas-alt.svg" />
                                                        </div>
                                                    </div>
                                                </a>
                                            </div> -->
                                            <!-- <div class="col-sm-12 col-lg-12">
                                                <a [routerLink]="['/reservas']" class="widget custom-widget">
                                                    <span class="title-counter sen-extrabold ">Ventas colaboradores</span>
                                                    <hr class="separador-general" />
                                                    <ul class="lista-ventas-colaboradores">
                                                        <li>Hotel Eurostars Regina</li>
                                                        <li>Hotel Eurostars Regina</li>
                                                        <li>Hotel Eurostars Regina</li>
                                                        <li>Hotel Eurostars Regina</li>
                                                        <li>Hotel Eurostars Regina</li>
                                                    </ul>
                                                </a>
                                            </div> -->
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-lg-12">
                                        <!-- <div class="widget custom-widget">
                                            <div class="row">
                                                <div class="col-sm-4 col-lg-3">
                                                    <span id="title-grafica" class="title-counter sen-extrabold  sen-extrabold">Reservas <br/>acumuladas</span>
                                                    <hr class="separador-general" />
                                                </div>
                                                <div class="col-sm-8 col-lg-9">
                                                    <div class="row">
                                                        <div class="col-sm-3 col-lg-3 center">
                                                            <h2 class="counter-dashboard" *ngIf="data">
                                                                <strong><span data-toggle="counter" [attr.data-to]="data.total_reservas">{{data.total_reservas}}</span></strong><br/>
                                                                <span class="subtitle-counter subtitle-red">Semana</span>
                                                            </h2>
                                                        </div>
                                                        <div class="col-sm-3 col-lg-3 center separador-grafica-left-right">
                                                            <h2 class="counter-dashboard" *ngIf="data">
                                                                <strong><span data-toggle="counter" [attr.data-to]="data.total_reservas">{{data.total_reservas}}</span></strong><br/>
                                                                <span class="subtitle-counter subtitle-red">Mes</span>
                                                            </h2>
                                                        </div>
                                                        <div class="col-sm-3 col-lg-3 center">
                                                            <h2 class="counter-dashboard" *ngIf="data">
                                                                <strong><span data-toggle="counter" [attr.data-to]="data.total_reservas">{{data.total_reservas}}</span></strong><br/>
                                                                <span class="subtitle-counter subtitle-red">Año</span>
                                                            </h2>
                                                        </div>
                                                    </div>
                                                    <hr class="separador-grafica" />
                                                    <br/>
                                                </div>
                                            </div>
                                            <div id="content-grafica" height="100px">
                                                <canvas id="myChart"></canvas>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->

        <app-backend-footer></app-backend-footer>
    </div>
    <!-- END Page Container -->
</div>
<!-- Modal reserva desde calendario -->
<div class="modal inmodal fade" id="modal-reservar-calendar" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content modal-lg">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" style="font-size: 35px;" (click)="cerrarModalCalendario()"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
                <h3 class="modal-title" style="text-align: center;"><i class="icofont-ticket"></i> Añadir reserva</h3>
            </div>
        <div>
          <div id="content-paso-1" class="row modal-body">
            <div class="col-md-1 col-xs-12"></div>
            <div class="col-md-10 col-xs-12">
                <h3 class="title-paso">Paso 1</h3>
                <div class="form-group row">
                    <div id="content-date-reserva" class="display-flex">
                        <label class="col-lg-4 col-form-label label-modal-title"><i class="icofont-calendar"></i> Fecha seleccionada</label>
                        <div class="col-lg-8">
                            <input id="date-reservar" type="date" class="form-control" maxlength="5" style="width: fit-content;" disabled>
                        </div>
                    </div>
                    <div id="content-select-actividad" class="display-flex">
                        <label class="col-lg-4 col-form-label label-modal-title"><i class="icofont-direction-sign"></i> Actividad seleccionada</label>
                        <div class="col-lg-8">
                            <select id="select-actividad-reservar" class="form-control" (change)="SeleccionarActividad()">
                                <option value="default">Elige una actividad</option>
                                <option value="{{evento.id}}" *ngFor="let evento of eventos">{{evento.nombre}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="margin-top-10 display-flex" id="content-radio-reserva">
                        <label class="col-lg-4 col-xs-4 col-form-label label-modal-title" ><i class="icofont-clock-time"></i> Sesiones</label>
                        <ng-container *ngIf="sesiones">
                            <div class="col-lg-4 col-xs-4 display-flex" *ngFor="let sesion of sesiones">
                                <input id="{{sesion.sesion}}" type="radio" class="form-control radios-sesion" value="{{sesion.sesion}}-{{sesion.aforo_restante}}" *ngIf="sesion.sesion && evento.gestion_asientos == 0" (click)="setSesionCheck(sesion.sesion,sesion.aforo_restante)" name="sesion" style="width: fit-content; height: fit-content; margin-right: 10px;"><label for="{{sesion.sesion}}" *ngIf="sesion.sesion && evento.gestion_asientos == 0">{{sesion.sesion}} ({{sesion.aforo_restante}} plazas)</label>
                                <input id="{{sesion.sesion}}" type="radio" class="form-control radios-sesion" value="{{sesion.sesion}}-{{sesion.aforo_restante}}" *ngIf="sesion.sesion && evento.gestion_asientos == 1" (click)="setSesionCheck(sesion.sesion,sesion.aforo_restante)" name="sesion" style="width: fit-content; height: fit-content; margin-right: 10px;"><label for="{{sesion.sesion}}" *ngIf="sesion.sesion && evento.gestion_asientos == 1">{{sesion.sesion}} ({{sesion.asientos}} plazas)</label>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!sesiones || sesiones.length == 0">
                            <div class="col-lg-6 col-xs-4 display-flex">
                                No hay sesiones disponibles para este día
                            </div>
                        </ng-container>
                    </div>
                    <div class="margin-top-10 display-flex" id="content-radio-asientos" *ngIf="asientos.length > 0">
                        <label class="col-lg-4 col-xs-4 col-form-label label-modal-title" ><i class="icofont-ticket"></i> Asientos</label>
                        <div class="col-lg-4 col-xs-4 display-flex" *ngFor="let asiento of asientos">
                            <input id="{{asiento.id}}" type="radio" class="form-control radios-asientos" value="{{asiento.id}}" (click)="setAsientoCheck(asiento)" name="asiento" style="width: fit-content; height: fit-content; margin-right: 10px;"><label for="{{asiento.id}}">{{asiento.nombre}} ({{asiento.plazas}} plazas)</label>
                        </div>
                    </div>
                    <div class="margin-top-10" id="content-radio-personas">
                        <ng-container *ngIf="tarifas.length > 0">
                            <div class="col-lg-12 margin-bottom-10" *ngFor="let tarifa of tarifas">
                                <div class="row">
                                    <div class="col-lg-5 col-xs-6">
                                        <label class="col-form-label label-modal-title" ><i class="icofont-user-alt-1"></i> {{tarifa.nombre}}</label><br class="hide-movil">
                                        <span class="precio-persona hide-movil">{{tarifa.precio}}€</span>
                                    </div>
                                    <div class="col-lg-4 col-xs-3 no-padding-movil">
                                        <div class="display-flex">
                                            <i id="menos-{{tarifa.id}}" class="icofont-minus-circle custom-menos disabled-custom-input custom-menos-icon" (click)="resCantidadTarifa(tarifa)"></i><input class="form-control custom-input-number" type="text" maxlength="2" [(ngModel)]="tarifa.cantidad" disabled><i class="icofont-plus-circle custom-mas custom-mas-icon" (click)="sumCantidadTarifa(tarifa)"></i>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-xs-3">
                                        <p class="precio-calculo precio-calulo-adulto">{{tarifa.total}}€</p>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <div class="col-lg-12 margin-bottom-10">
                            <div class="row">
                                <div class="col-lg-2 col-xs-2">
                                </div>
                                <div class="col-lg-2 col-xs-2">
                                </div>
                                <div class="col-lg-8 col-xs-8">
                                    <p class="precio-calculo-total">Total: {{dataReserva.precio}}€</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-1 col-xs-12"></div>
            <div class="modal-footer col-md-12 col-xs-12">
                <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-primary" id="btn-siguiente" *ngIf="dataReserva.precio > 0" (click)="SiguienteModal()">Siguiente</button>
            </div>
          </div>
          <div id="content-paso-2" class="row modal-body" style="display: none;">
            <div class="col-md-2 col-xs-12"></div>
            <div class="col-md-8 col-xs-12">
                <h3 class="title-paso">Paso 2</h3>
                <div class="row">
                    <div class="col-lg-8 col-xs-12">
                        <h4 *ngIf="globals.me.id_rol == 0 || globals.me.id_rol == 1 || globals.me.id_rol == 2">¿Quién realiza la reserva?</h4>
                        <div class="form-group row" *ngIf="globals.me.id_rol == 0 || globals.me.id_rol == 1 || globals.me.id_rol == 2">
                            <div class="col-lg-12 col-xs-12">
                                <select class="form-control" id="select-colaboradores-reserva-admin" (change)="SelectReservaProveedor($event)">
                                    <option value="admin" selected>Administrador</option>
                                    <option *ngFor="let colaboradorReserva of rankingColaboradores" [value]="colaboradorReserva.id">{{colaboradorReserva.empresa}}</option>
                                </select>
                            </div>
                        </div>
                        <h4>Datos</h4>
                        <div class="form-group row">
                            <div class="col-lg-6 col-xs-6 margin-bottom-10">
                                <input class="form-control" type="text" placeholder="Nombre*" [(ngModel)]="dataReserva.nombre">
                            </div>
                            <div class="col-lg-6 col-xs-6 margin-bottom-10">
                                <input class="form-control" type="text" placeholder="Apellidos*" [(ngModel)]="dataReserva.apellidos">
                            </div>
                            <div class="col-lg-6 col-xs-6 margin-bottom-10">
                                <input class="form-control" type="phone" placeholder="Teléfono*" [(ngModel)]="dataReserva.telefono">
                            </div>
                            <div class="col-lg-6 col-xs-6 margin-bottom-10">
                                <input class="form-control" type="mail" placeholder="Email*" [(ngModel)]="dataReserva.email">
                            </div>
                            <div class="col-lg-6 col-xs-6 margin-bottom-10">
                                <input class="form-control" type="text" placeholder="CIF/DNI" [(ngModel)]="dataReserva.cli_nifcif">
                            </div>
                            <div class="col-lg-6 col-xs-6 margin-bottom-10">
                                <select required name="provincia" class="form-control" [(ngModel)]="dataReserva.cli_provincia" placeholder="Elige provincia">
                                    <option value="Elige provincia">Elige provincia</option>
                                    <option value="Álava/Araba">Álava/Araba</option>
                                    <option value="Albacete">Albacete</option>
                                    <option value="Alicante">Alicante</option>
                                    <option value="Almería">Almería</option>
                                    <option value="Asturias">Asturias</option>
                                    <option value="Ávila">Ávila</option>
                                    <option value="Badajoz">Badajoz</option>
                                    <option value="Baleares">Baleares</option>
                                    <option value="Barcelona">Barcelona</option>
                                    <option value="Burgos">Burgos</option>
                                    <option value="Cáceres">Cáceres</option>
                                    <option value="Cádiz">Cádiz</option>
                                    <option value="Cantabria">Cantabria</option>
                                    <option value="Castellón">Castellón</option>
                                    <option value="Ceuta">Ceuta</option>
                                    <option value="Ciudad Real">Ciudad Real</option>
                                    <option value="Córdoba">Córdoba</option>
                                    <option value="Cuenca">Cuenca</option>
                                    <option value="Gerona/Girona">Gerona/Girona</option>
                                    <option value="Granada">Granada</option>
                                    <option value="Guadalajara">Guadalajara</option>
                                    <option value="Guipúzcoa/Gipuzkoa">Guipúzcoa/Gipuzkoa</option>
                                    <option value="Huelva">Huelva</option>
                                    <option value="Huesca">Huesca</option>
                                    <option value="Jaén">Jaén</option>
                                    <option value="La Coruña/A Coruña">La Coruña/A Coruña</option>
                                    <option value="La Rioja">La Rioja</option>
                                    <option value="Las Palmas">Las Palmas</option>
                                    <option value="León">León</option>
                                    <option value="Lérida/Lleida">Lérida/Lleida</option>
                                    <option value="Lugo">Lugo</option>
                                    <option value="Madrid">Madrid</option>
                                    <option value="Málaga">Málaga</option>
                                    <option value="Melilla">Melilla</option>
                                    <option value="Murcia">Murcia</option>
                                    <option value="Navarra">Navarra</option>
                                    <option value="Orense/Ourense">Orense/Ourense</option>
                                    <option value="Palencia">Palencia</option>
                                    <option value="Pontevedra">Pontevedra</option>
                                    <option value="Salamanca">Salamanca</option>
                                    <option value="Segovia">Segovia</option>
                                    <option value="Sevilla">Sevilla</option>
                                    <option value="Soria">Soria</option>
                                    <option value="Tarragona">Tarragona</option>
                                    <option value="Tenerife">Tenerife</option>
                                    <option value="Teruel">Teruel</option>
                                    <option value="Toledo">Toledo</option>
                                    <option value="Valencia">Valencia</option>
                                    <option value="Valladolid">Valladolid</option>
                                    <option value="Vizcaya/Bizkaia">Vizcaya/Bizkaia</option>
                                    <option value="Zamora">Zamora</option>
                                    <option value="Zaragoza">Zaragoza</option>
                                  </select>
                            </div>
                            <div class="col-lg-12 col-xs-12 margin-bottom-10">
                                <label class="hide">Notas</label>
                                <textarea placeholder="Notas o comentarios" [(ngModel)]="dataReserva.cli_notas" style="width: 100%;"></textarea>
                            </div>
                            <!-- <div class="col-lg-4 col-xs-12 margin-bottom-10">
                                <input id="pago-tarjeta" type="radio" name="pago"><label style="margin-left: 10px;" for="pago-tarjeta">Tarjeta</label>
                            </div>
                            <div class="col-lg-4 col-xs-12 margin-bottom-10">
                                <input id="pago-paypal" type="radio" name="pago"><label style="margin-left: 10px;" for="pago-paypal">Paypal</label>
                            </div> -->
                            <div class="col-lg-12 col-xs-12 margin-bottom-10">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-xs-12">
                                        <label for="pago-en-mano" (click)="setTipoPago(1)"><input id="pago-en-mano" type="radio" name="pago" value="Pago en mano"> Pago en mano</label>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-xs-12" *ngIf="globals.me.id_rol == 0 || globals.me.id_rol == 1 || globals.me.id_rol == 2">
                                        <label for="pago-anticipo" (click)="setTipoPago(7)"><input id="pago-anticipo" type="radio" name="pago" value="pago-anticipo"> Enviar petición de pago al cliente</label>
                                        <ng-container  *ngIf="dataReserva.id_estado == 7">
                                            <br>
                                            <span>Establece fecha y hora de expiración del pago</span>
                                            <input type="datetime-local" id="meeting-time"
                                            name="meeting-time" [(ngModel)]="dataReserva.anticipo_fecha_expiracion">
                                            <br>
                                            <br>
                                            <label>Establecer importe personalizado<br><span style="font-size: 12px;">(*dejar en blanco para enviar el total de la reserva)</span></label>
                                            <input type="number" id="anticipo-importe"
                                            name="anticipo-importe" placeholder="{{dataReserva.precio}}" [(ngModel)]="dataReserva.anticipo_importe">
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-xs-12">
                        <h4>Resumen</h4>
                        <div class="row">
                            <div class="col-lg-12 col-xs-12">
                                <p class="no-margin-bottom">Fecha: <span>{{dataReserva.fecha}}</span></p>
                            </div>
                            <div class="col-lg-12 col-xs-12">
                                <p class="no-margin-bottom">Sesión: <span>{{dataReserva.sesion}}</span></p>
                            </div>
                            <div class="col-lg-12 col-xs-12" *ngIf="dataReserva.asiento">
                                <p class="no-margin-bottom">Asiento: <span>{{dataReserva.asiento.nombre}}</span></p>
                            </div>
                            <div class="col-lg-12 col-xs-12" *ngFor="let tarifa of tarifas">
                                <p class="no-margin-bottom">{{tarifa.nombre}}: <span>{{tarifa.cantidad}}</span></p>
                            </div>
                            <div class="col-lg-12 col-xs-12">
                                <p class="no-margin-bottom">Precio: <span>{{dataReserva.precio}}€</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-2 col-xs-12"></div>
            <div class="modal-footer col-md-12 col-xs-12">
                <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-white" id="btn-anterior" (click)="AnteriorModal()">Anterior</button>
                <button type="button" class="btn btn-primary" id="btn-guardar-reserva" (click)="ComprobarGuardarReserva()">Pagar y finalizar</button>
            </div>
          </div>
        </div>

        </div>
    </div>
</div>