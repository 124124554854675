import { Component, OnInit, NgZone, ApplicationRef, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let toastr: any;

@Component({
  selector: 'app-eventos',
  templateUrl: './eventos.component.html',
  styleUrls: ['./eventos.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EventosComponent implements OnInit {
  public eventos:any = [];
  public params:any = {
    page_index: 1,
    page_size: 50,
    total: 0,
    pages: 0,
    filtro: null,
    order: null,
    dir: null,
  };

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }


  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
    this.CargarGrid();
   }

   CargarGrid(paginationload:boolean = true) {
    if (this.globals.GetData()) this.eventos = this.globals.GetData();
    this.globals.Api('/eve-eventos', this.params).subscribe(data => {
      if (!data || !data.length) {
        this.eventos = [];
        this.globals.SaveData([]);
        return;
      }
      this.globals.SaveData(data);
      this.eventos = data;
      this.appref.tick();
      $('.content-actividades-gestion-aforo').click(function(){
        $('.content-actividades-gestion-aforo').removeClass('active');
        $(this).addClass('active');
      });
      if (!paginationload) return;
      this.globals.Api('/eve-eventos/total', this.params).subscribe(data => {
        if (!data || !data.length) return;
        this.params.total = data[0].total;
        this.params.pages = Math.ceil(this.params.total / this.params.page_size);
      });
    });
  }
  Paging(dir, table = '') {
    if (dir == 'prev') {
      this.params.page_index -= 1;
    }
    if (dir == 'next') {
      this.params.page_index += 1;
    }
    this.CargarGrid(false);
  }
  Buscar() {
    this.params.page_index = 1;
    this.CargarGrid();
  }
  Nuevo() {
    this.globals.passData = '';
    this.ngZone.run(() => this.router.navigateByUrl('/eventos/add')).then();
  }
  Editar(item:any) {
    this.globals.passData = JSON.parse(JSON.stringify(item));
    this.ngZone.run(() => this.router.navigateByUrl('/eventos/edit/'+item.id)).then();
  }
  Borrar(item:any) {
    swal({
      title: "¿Borrar evento?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/eve-eventos-del', {id: item.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.eventos.splice(this.eventos.findIndex(el => el.id == item.id), 1);
        this.appref.tick();
      });
    });
  }
  Portapapeles(event:any, item:any) {
    event.stopPropagation();
    this.globals.CopyClipboard(item.id);
    toastr.info(`ID Alojamiento: ${item.id}`,'Copiado al portapapeles');
  }
  Duplicar(id_evento:any){
    swal({
      title: "¿Duplicar evento?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/eve-eventos-duplicate', {id_evento: id_evento}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.appref.tick();
        this.CargarGrid();
      });
    });
  }
}