<div id="page-wrapper" class="page-loading">
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                <div class="content-header">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-xs-12 my-auto">
                            <div class="header-section">
                                <h1 class="middle">Temporadas</h1>
                                <button class="btn btn-xs btn-secondary middle m-l-10" (click)="LoadTemporadas()"><i class="fa fa-refresh"></i></button>
                            </div>
                            <ul class="breadcrumb breadcrumb-top text-left">
                                <li><a [routerLink]="['/dashboard']">Inicio</a></li>
                                <li>Temporadas</li>
                            </ul>
                        </div>
                        <div class="col-lg-8 col-md-6 col-xs-12 my-auto" style="justify-content: flex-end;">
                          <a class="btn btn-primary" (click)="AddTemporada()"><i class="fa fa-plus"></i> Nueva temporada</a>
                        </div>
                    </div>
                </div>
                <!-- Table Styles Content -->
                <div class="table-responsive table-grid block full break-padding">
                    <div class="table-responsive table-grid">
                        <table class="table table-striped table-bordered table-vcenter table-hover">
                            <thead>
                                <tr>
                                <th style="width:300px" order="temporada">Temporada</th>
                                <th order="fecha_inicio">Inicio</th>
                                <th order="fecha_fin">Fin</th>
                                <th order="dias_semana">Días semana</th>
                                <th style="width:50px">Editar</th>
                                <th style="width:50px">Borrar</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of temporadas" class="cursor">
                                <td class="font-bold text-primary" (click)="EditarTemporada(item)">
                                    <div>{{item.temporada}}</div>
                                </td>
                                <td>{{item.fecha_inicio_format}}</td>
                                <td>{{item.fecha_fin_format}}</td>
                                <td>
                                    <div *ngIf="!item.dias_semana">Todos</div>
                                    <div *ngIf="item.dias_semana">{{item.dias_semana}}</div>
                                </td>
                                <td class="text-center btntd"><a class="btn btn-primary btn-xs" (click)="EditarTemporada(item)"><i class="fa fa-pencil text-white"></i></a></td>
                                <td class="text-center btntd"><a class="btn btn-danger btn-xs" (click)="BorrarTemporada(item)"><i class="fa fa-trash text-white"></i></a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->

        <app-backend-footer></app-backend-footer>
    </div>
    <!-- END Page Container -->
</div>
<div class="modal inmodal fade" id="modal-crear-temporada" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
            <h4 class="modal-title"></h4>
        </div>
        <div class="modal-body">
            <div class="form-group row">
                <label class="col-lg-4 col-form-label">Temporada <span class="text-danger">*</span></label>
                <div class="col-lg-8">
                    <input type="text" class="form-control" [(ngModel)]="data.temporada" maxlength="80">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-lg-4 col-form-label">Fecha inicio <span class="text-danger">*</span></label>
                <div class="col-lg-6">
                    <input type="text" class="form-control datepicker" id="txtfechainicio" [(ngModel)]="data.fecha_inicio" maxlength="20">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-lg-4 col-form-label">Fecha fin <span class="text-danger">*</span></label>
                <div class="col-lg-6">
                    <input type="text" class="form-control datepicker" id="txtfechafin" [(ngModel)]="data.fecha_fin" maxlength="20">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-lg-4 col-form-label">Días semana</label>
                <div class="col-lg-8">
                    <label class="switch switch-success m-r-5">Lunes<br><input type="checkbox" dia="LU" class="chk-dia-temporada"><span></span></label>
                    <label class="switch switch-success m-r-5">Martes<br><input type="checkbox" dia="MA" class="chk-dia-temporada"><span></span></label>
                    <label class="switch switch-success m-r-5">Miércoles<br><input type="checkbox" dia="MI" class="chk-dia-temporada"><span></span></label>
                    <label class="switch switch-success m-r-5">Jueves<br><input type="checkbox" dia="JU" class="chk-dia-temporada"><span></span></label>
                    <label class="switch switch-success m-r-5">Viernes<br><input type="checkbox" dia="VI" class="chk-dia-temporada"><span></span></label>
                    <label class="switch switch-success m-r-5">Sábado<br><input type="checkbox" dia="SA" class="chk-dia-temporada"><span></span></label>
                    <label class="switch switch-success m-r-5">Domingo<br><input type="checkbox" dia="DO" class="chk-dia-temporada"><span></span></label>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
            <button type="button" class="btn btn-primary" id="btn-guardar-temporada" (click)="GuardarTemporada()">Guardar</button>
        </div>
        </div>
    </div>
</div>