<div id="sidebar">
    <!-- Sidebar Brand -->
    <div id="sidebar-brand" class="themed-background">
        <a [routerLink]="['/dashboard']" class="sidebar-title">
            <img src="assets/logo-header.svg" alt="">
        </a>
    </div>
    <!-- END Sidebar Brand -->

    <!-- Wrapper for scrolling functionality -->
    <div id="sidebar-scroll">
        <!-- Sidebar Content -->
        <div class="sidebar-content">
            <!-- Sidebar Navigation -->
            <ng-container *ngIf="globals && globals.me">
                <!-- ADMIN -->
                <ng-container *ngIf="[0].includes(globals.me.id_rol)">
                    <ul class="sidebar-nav" *ngIf="!globals.cliente">
                        <li>
                            <a [routerLink]="['/dashboard-superadmin']" routerLinkActive="active"><i class="gi gi-home sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Inicio</span></a>
                        </li>
                        <li>
                            <a [routerLink]="['/config-app']" routerLinkActive="active"><i class="icofont-settings sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Configuración</span></a>
                        </li>
                        <li>
                            <a [routerLink]="['/clientes-spa']" routerLinkActive="active"><i class="icofont-ui-user-group sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Clientes</span></a>
                        </li>
                        <li>
                            <a [routerLink]="['/usuarios']" routerLinkActive="active"><i class="fa fa-users sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Usuarios</span></a>
                        </li>
                    </ul>
                </ng-container>
                <ng-container *ngIf="[0,1,2].includes(globals.me.id_rol)">
                    <ul class="sidebar-nav" *ngIf="globals.cliente || globals.me.id_rol == 1 || globals.me.id_rol == 2">
                        <ng-container *ngIf="globals.me.id_rol == 0">
                            <li class="m-b-10" style="border-bottom:1px solid #FFFFFF44;">
                                <a class="cursor" style="line-height:1.1em;height:auto;padding:10px 3px;"><i class="icofont-ui-user-group sidebar-nav-icon"
                                    style="float:left;height:20px;line-height: 18px;"></i><span class="sidebar-nav-mini-hide">{{globals.cliente.empresa}}</span>
                                </a>
                            </li>
                        </ng-container>
                        <li>
                            <a [routerLink]="['/dashboard']" routerLinkActive="active"><i class="gi gi-home sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Inicio</span></a>
                        </li>
                        <li>
                            <a [routerLink]="['/configuracion']" routerLinkActive="active"><i class="icofont-settings sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Configuración</span></a>
                        </li>
                        <li>
                            <a [routerLink]="['/actividades']" routerLinkActive="active"><i class="icofont-calendar sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Actividades</span></a>
                        </li>
                        <li>
                            <a [routerLink]="['/eventos']" routerLinkActive="active"><i class="icofont-ticket sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Eventos <span class="label-pro-max" *ngIf="globals.config.tarifa == 1 || globals.config.tarifa == 2"><i class="fa fa-trophy sidebar-nav-icon" style="
                                margin-right: 5px;
                            "></i>PRO MAX</span></span></a>
                        </li>
                        <ng-container *ngIf="globals.GetConfigCliente().vehiculos">
                            <li>
                                <a [routerLink]="['/vehiculos']" routerLinkActive="active"><i class="icofont-car-alt-4 sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Vehículos <span class="label-pro" *ngIf="globals.config.tarifa == 1"><i class="fa fa-trophy sidebar-nav-icon" style="
                                    margin-right: 5px;
                                "></i>PRO</span></span></a>
                            </li>
                        </ng-container>
                        <li>
                            <a [routerLink]="['/reservas']" routerLinkActive="active"><i class="icofont-ticket sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Reservas</span></a>
                        </li>
                        <li>
                            <a [routerLink]="['/bonos-regalo']" routerLinkActive="active"><i class="icofont-gift sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Bonos regalo</span></a>
                        </li>
                        <li>
                            <a [routerLink]="['/cupones']" routerLinkActive="active"><i class="icofont-sale-discount sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Cupones</span></a>
                        </li>
                        <li>
                            <a [routerLink]="['/colaboradores']" routerLinkActive="active"><i class="icofont-user-suited sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Colaboradores <span class="label-pro" *ngIf="globals.config.tarifa == 1"><i class="fa fa-trophy sidebar-nav-icon" style="
                                margin-right: 5px;
                            "></i>PRO</span></span></a>
                        </li>
                        <li *ngIf="globals.config && globals.config.whatsapp_fbid">
                            <a routerLink="/mensajes" routerLinkActive="active" >
                                <i class="fa fa-whatsapp sidebar-nav-icon"></i>
                                <span class="nav-label">Mensajes <span class="label-pro" *ngIf="globals.tarifa == 1"><i class="fa fa-trophy sidebar-nav-icon" style="
                                    margin-right: 5px;
                                "></i>PRO</span></span>
                                <span class="badge badge-whatsapp" *ngIf="globals.cmensajeswhatsapp">{{globals.cmensajeswhatsapp}}</span>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/clientes']" routerLinkActive="active"><i class="icofont-ui-user-group sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Clientes</span></a>
                        </li>
                        <li>
                            <a [routerLink]="['/guias']" routerLinkActive="active"><i class="icofont-user-suited sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Guías</span></a>
                        </li>
                        <!-- <li class="sidebar-separator"><i class="fa fa-ellipsis-h"></i></li> -->
                        <li>
                            <a [routerLink]="['/usuarios']" routerLinkActive="active"><i class="fa fa-users sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Usuarios</span></a>
                        </li>
                        <li style="display: none;">
                            <a [routerLink]="['/estadisticas']" routerLinkActive="active"><i class="fa fa-bar-chart sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Estadísticas</span></a>
                        </li>
                        <!-- <li>
                            <a class="sidebar-nav-menu cursor"
                                [class.open]="rlacolaboradores.isActive"
                                [class.open]="rlacolaboradores.isActive"
                                >
                                <i class="fa fa-chevron-left sidebar-nav-indicator sidebar-nav-mini-hide"></i>
                                <i class="icofont-user-suited sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Colaboradores</span>
                            </a>
                            <ul>
                                <li>
                                    <a [routerLink]="['/colaboradores']" routerLinkActive="active" #rlacolaboradores="routerLinkActive">Todos los colaboradores</a>
                                </li>
                                <li>
                                    <a [routerLink]="['/colaboradores/add']" routerLinkActive="active" #rlacolaboradores="routerLinkActive"><i class="fa fa-plus"></i> Nuevo colaborador</a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a class="sidebar-nav-menu cursor"
                                [class.open]="rlaclientes.isActive"
                                [class.open]="rlaclientes.isActive"
                                >
                                <i class="fa fa-chevron-left sidebar-nav-indicator sidebar-nav-mini-hide"></i>
                                <i class="icofont-ui-user-group sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Clientes</span>
                            </a>
                            <ul>
                                <li>
                                    <a [routerLink]="['/clientes']" routerLinkActive="active" #rlaclientes="routerLinkActive">Todos los clientes</a>
                                </li>
                                <li>
                                    <a [routerLink]="['/clientes/add']" routerLinkActive="active" #rlaclientes="routerLinkActive"><i class="fa fa-plus"></i> Nuevo cliente</a>
                                </li>
                            </ul>
                        </li> -->
                        <!-- <li>
                            <a href="javascript:void(0);" class="sidebar-nav-menu" [class.open]="rlaalojamientos.isActive"><i class="fa fa-chevron-left sidebar-nav-indicator sidebar-nav-mini-hide"></i>
                                <i class="icofont-ui-movie sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Actividades</span></a>
                            <ul>
                                <li>
                                    <a [routerLink]="['/actividades']" routerLinkActive="active" #rlaalojamientos="routerLinkActive">Todas las actividades</a>
                                </li>
                                <li>
                                    <a [routerLink]="['/actividades/add']" routerLinkActive="active" #rlaalojamientos="routerLinkActive"><i class="fa fa-plus"></i> Nueva actividad</a>
                                </li>
                            </ul>
                        </li> -->
                        <!-- <li>
                            <a href="javascript:void(0);" class="sidebar-nav-menu" [class.open]="rlareservas.isActive"><i class="fa fa-chevron-left sidebar-nav-indicator sidebar-nav-mini-hide"></i>
                                <i class="icofont-travelling sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Reservas</span></a>
                            <ul>
                                <li>
                                    <a [routerLink]="['/reservas']" routerLinkActive="active" #rlareservas="routerLinkActive">Todas las reservas</a>
                                </li>
                                <li>
                                    <a [routerLink]="['/reservas/add']" routerLinkActive="active" #rlareservas="routerLinkActive"><i class="fa fa-plus"></i> Nueva reserva</a>
                                </li>
                            </ul>
                        </li> -->
                        <!-- <li class="sidebar-separator">
                            <i class="fa fa-ellipsis-h"></i>
                        </li>
                        <li>
                            <a href="javascript:void(0);" class="sidebar-nav-menu" [class.open]="rlausuarios.isActive"><i class="fa fa-chevron-left sidebar-nav-indicator sidebar-nav-mini-hide"></i>
                                <i class="fa fa-users sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Usuarios</span></a>
                            <ul>
                                <li>
                                    <a [routerLink]="['/usuarios']" routerLinkActive="active" #rlausuarios="routerLinkActive">Todos los usuarios</a>
                                </li>
                                <li>
                                    <a [routerLink]="['/usuarios/add']" routerLinkActive="active" #rlausuarios="routerLinkActive"><i class="fa fa-plus"></i> Nuevo usuario</a>
                                </li>
                            </ul>
                        </li> -->
                    </ul>
                </ng-container>
                <!-- CLIENTE -->
                <ng-container *ngIf="globals.me.id_rol == 4">
                    <ul class="sidebar-nav">
                        <li>
                            <a [routerLink]="['/dashboard']" routerLinkActive="active"><i class="gi gi-home sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Inicio</span></a>
                        </li>
                        <li>
                            <a [routerLink]="['/mi-perfil']" routerLinkActive="active"><i class="icofont-user sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Mi perfil</span></a>
                        </li>
                        <li>
                            <a [routerLink]="['/mis-reservas']" routerLinkActive="active"><i class="icofont-travelling sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Mis reservas</span></a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" (click)="PrimerosPasos()"><i class="icofont-question-circle sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Primeros pasos</span></a>
                        </li>
                    </ul>
                </ng-container>
            </ng-container>
            <!-- END Sidebar Navigation -->
        </div>
        <!-- END Sidebar Content -->
    </div>
    <!-- END Wrapper for scrolling functionality -->

    <!-- Sidebar Extra Info -->
    <!-- <div id="sidebar-extra-info" class="sidebar-content sidebar-nav-mini-hide">
        <div class="text-center">
            <small class="copyright-pge">Gestor de reservas SPG Planner<br/>
                <a href="https://pecesgordos.es/" target="_blank" class="text-primary" style="color: white;">©Copyright Peces Gordos Estudio</a>
            </small><br/>
        </div>
    </div> -->
    <!-- END Sidebar Extra Info -->
    <!-- Sidebar Extra Info -->
    <div class="licencia sidebar-nav-mini-hide" *ngIf="globals.me && globals.me.licencia">
        <div class="licencia-content-success" *ngIf="!globals.me.licencia.error">
            <b>Licencia activa</b>
            <p *ngIf="globals.me.licencia.fecha_expiracion_format">Hasta el: <span>{{globals.me.licencia.fecha_expiracion_format}}</span></p>
        </div>
        <div class="licencia-content-error" *ngIf="globals.me.licencia.error">
        <b>¡Licencia expirada!</b>
        <a href="https://pecesgordos.es" target="_blank">Por favor, contacte con <b>Peces Gordos Estudio</b> para renovar la licencia.</a>
        </div>
    </div>
    <div class="copyright sidebar-nav-mini-hide">
        <div class="copy-title">SPG Planner</div>
        <div class="copy-subtitle">Gestor de reservas</div>
        <a class="copy-enlace" href="https://pecesgordos.es" target="_blank">© Peces Gordos Estudio</a>
    </div>
    <!-- END Sidebar Extra Info -->
</div>